import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContentBox } from '../Index';
import { Input } from '../../../components/genericComponents/Input';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import { Redirect, Link } from 'react-router-dom';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY } from '../../../util/Validator';
import { getCommonFunctions } from '../add/ci_flow/SourceDetails';
import { makeStyles } from '@material-ui/styles';
import { Loading } from '../../utils/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { GetPermitedURLs } from '../../../util/security';
const AddServiceFrom = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [showLoading, setShowLoading] = useState(false);
    const [canRedirect, setRedirect] = useState(false);
    const application_id = props.application_id;
    const edit = props.edit;
    const [state, setState] = useState({
        data: {
            build_strategy: "EVERY",
        },
        error: {},
        validations: {
            name: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY],
            build_strategy: [VALIDATION_TYPE_REQUIRED],
        },
        availableBuildStrategies: [
            {
                label: "Build once and promote",
                value: "ONCE"
            },
            {
                label: "Build for every environment",
                value: "EVERY"
            }
        ],
        service_id: null

    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    function validateAndSave() {

        // var temp_validation = { ...state.validations };

        var result = ValidateDataSet(state.data, state.validations);
        var post_url = GenerateURL({ application_id: application_id }, properties.api.service_basic_details_post_url)
        if (props.component_id) {
            post_url = GenerateURL({ service_id: props.component_id }, properties.api.service_basic_details_get_url)
        }
        if (result.valid) {
            var post_data = {
                project_id: Number(application_id),
                ...state.data
            }
            if (state.data.external_name) {
                post_data.external_name = state.data.external_name
            } else {
                post_data.external_name = null
            }
            post_data = {
                ...post_data,
                repo_url: "NA"
            }
            setShowLoading(true);
            PostData(post_url, post_data, postSuccess, postFail);
        }
        else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }
    }
    function postSuccess(response) {
        setShowLoading(false);
        console.log(response, "pipi")
        setState(new_state => ({
            ...new_state,
            service_id: response.id
        }))
        GetPermitedURLs();
        setRedirect(true);
    }
    function postFail(response) {
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            error: response
        }))
    }

    useEffect(() => {
        if (props.component_id)
            getService();

    }, []);

    function getService() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: props.component_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setShowLoading(true);
        InvokeApi(requestInfo, handleGetServiceSuccess, () => { setShowLoading(false); });
    }
    const handleGetServiceSuccess = (data) => {
        setShowLoading(false);
        setState(new_state => ({
            ...new_state,
            ...data,
            data: {
                name: data.name,
                build_strategy: data.build_strategy,
                external_name: data.external_name ? data.external_name : ""
            }
        }))
    }

    console.log(state,'st_plm')
    return (
        <div className="" style={{ width: '96%' }}>

            <IconButton className="ml-auto d-block">
                {edit ?
                    <Link to={"/application/" + application_id + "/service/" + props.component_id + "/detail"}>  <CloseIcon fontSize="large" /></Link> :
                    <Link to="/applicationDashboard">  <CloseIcon fontSize="large" /></Link>
                }
            </IconButton>
            {
                canRedirect ?
                    props.component_id ? <Redirect to={"/application/" + application_id + "/service/" + state.service_id + "/detail"} /> :
                        <Redirect to={"/application/" + application_id + "/service/" + state.service_id + "/env/add"} /> : null
            }
            {
                showLoading ?
                    <Loading /> : null
            }
            <div className="service-form-heading-section mb-32 heading-div">
                {
                    props.component_id ?
                        <p className="heading-primary">Edit Service</p> :
                        <p className="heading-primary">Create a New Service</p>
                }

                <p className="sub-heading-primary">Microservice are used to group pipeline and other setting together</p>
            </div>
            <div className={classes.formSec}>
                <ContentBox>
                    <div style={{paddingBottom:(state?.error?.name!=undefined && state?.error?.name!='')?'20px':'0px' }}  className="">
                        <Input
                            type="text"
                            placeholder="demo-app"
                            mandatorySign
                            name="name"
                            label="Give a name to your service"
                            subHeading="AlphaNumeric with hyphen"
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandlerSmallInput}
                            data={state.data} />
                    </div>
                    <div className="">
                        <Input
                            type="text"
                            placeholder="Sample-Service"
                            // mandatorySign
                            name="external_name"
                            label="External Name"
                            // subHeading="AlphaNumeric with hyphen"
                            error={state.error}
                            onChangeHandler={commonFunctions.onChangeHandlerSmallInput}
                            data={state.data} />
                    </div>
                    <div className="">
                        <Input
                            type="radio"
                            mandatorySign
                            name="build_strategy"
                            list={state.availableBuildStrategies}
                            label="How do you build"
                            error={state.error}
                            data={state.data}
                            onChangeHandler={commonFunctions.onChangeHandler} />
                    </div>
                    <div className="d-flex align-center space-between">
                        {edit ?
                            <Link to={"/application/" + application_id + "/service/" + props.component_id + "/detail"}> <button className="btn btn-outline-grey">Cancel</button></Link> :
                            <Link to="/applicationDashboard"> <button className="btn btn-outline-grey">Cancel</button></Link>
                        }
                        <button className="btn btn-submit" onClick={validateAndSave}>Save &amp; Continue</button>
                    </div>

                </ContentBox>
            </div>
        </div>
    )
}
AddServiceFrom.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default AddServiceFrom;


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        '& .heading-div': {
            padding: '50px 50px 0px'
        }
    },
    grid: {
        height: '100%'
    },
    leftContainer: {
        backgroundColor: '#1a212c',
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        '& .logo': {
            '& img': {
                display: 'block',
                margin: 'auto'
            }
        }
    },
    readable: {
        fontSize: '42px',
        letterSpacing: '3px',
        lineHeight: '52px',
        color: '#ffffff',
        fontWeight: 400,
        fontFamily: "Inter",
        textAlign: 'center',
        marginTop: '3rem'
    },
    formSec: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 50px 0px'
    }
}));

