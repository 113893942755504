import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import GenerateURL from '../../util/APIUrlProvider';
import { GetAuth, IsSuperUser } from '../../util/security';
import ErrorPage from '../../views/ErrorPage';
import LicenseExpirationStatusComponent from '../genericComponents/LicenseExpirationStatusComponent';



const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={matchProps => (
        <>
          {
            isAutherized(matchProps.match, props.autherization) ?
                <Layout>
                  <ErrorBoundary>
                    <>
                    {/* <LicenseExpirationStatusComponent user={IsSuperUser('loading_routes') ? "super-user" :"normal-user"} /> */}
                    <Component  {...matchProps} />
                    </>
                  </ErrorBoundary>
                </Layout>

              
              : <ErrorPage is_unautherized={true} />
          }
        </>
      )}
    />
  );
};

function isAutherized(match, autherization) {
  let autherized = false;
  let permitted_routes = GetAuth().permission;
  if (autherization) {
    autherization.forEach(element => {
      let url = GenerateURL(match.params, element.route, true);
      let types = permitted_routes[element.type];
      if (types.includes(url)) {
        autherized = true;
      };

    }); 
  } else {
    autherized = true;
  }
  return autherized;
}

RouteWithLayout.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default RouteWithLayout;


export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }


  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }
 
  render() {
    if (this.state.errorInfo) {
      this.state = { error: null, errorInfo: null };
      return (
        <div className="global-error">
          <h2>Something went wrong. Please try again!!</h2>
        </div>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
