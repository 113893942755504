import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GetAuth } from '../../../../util/security';
import { Loading } from '../../../utils/Loading';
import CurrentProgressData from './CurrentProgressData';

import CloseIcon from '@material-ui/icons/Close';
import { Dialog, IconButton } from '@material-ui/core';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import '../../../../assets/buttons.css'
import { evaluateAgentStatus } from '../../../../util/util';
import BpAgentStatusActionAlert from '../../../../components/genericComponents/BpAgentStatusActionAlert';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import BuildFlowActionAlert from '../../../../components/genericComponents/BuildFlowActionAlert';
import { error } from 'highcharts';


const TriggerActionCard = (props) => {
    const type = 'BUILD';
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const top_summary_data = props.top_summary_data;
    const is_env_down = props.is_env_down ? props.is_env_down : null
    const deploy_env_offline_flag = props.deploy_env_offline_flag ? props.deploy_env_offline_flag : null;
    const disable_parallel_build = props.disable_parallel_build ? props.disable_parallel_build : null
    const env = service_data.env;
    const configuration_data = props.configuration_data;
    const ci_data = props.ci_data ? props.ci_data : null;
    const service_env_name = props.service_env_name;
    const env_list = [];
    const all_available_deployments = props.all_available_deployments && props.all_available_deployments.length > 0 ?
        props.all_available_deployments.map(item => {
            return { label: item.deployment_name ? item.deployment_name : item.label, id: item.deployment_name ? item.deployment_name : item.label }
        }) : []
    var url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_permited = GetAuth().permission.POST.includes(url);
    console.log(configuration_data, "disable_parallel_build")
    const [state, setState] = useState({
        tag: "",
        data: {

        },
        branch_list: [],
        tag_loaded_status: false,
        errors: {},
        refresh_count: 1,
        open: false,
        branch_loading: false,
        show_bp_agent_warning: false,
        down_bp_agent_names: null,
        type_of_action: 'only-build',
        open_custom: false,
        trigger: null,
        parallBuildFeatureData: null,
        branchRequired: false
    });
    var flag_for_feature_tag = properties.flag_for_feature_id
    const build_action_card = props.build_action_card;
    const handleCloseDialog = props.handleCloseDialog;
    // Logic to form env->subenv list
    if (env) {
        env.forEach(element => {
            if (element.id != env_id) {
                env_list.push({
                    id: element.id,
                    label: element.env_master.name + "->" + element.env_name
                });
            }
        });
    }


    useEffect(() => {
        console.log(ci_data, "fdsiahfsah")
        var current_ci_data = ci_data?.find(item => item.status === "APPROVED")
        console.log(current_ci_data, "fdsiahfsah")

        if (current_ci_data && current_ci_data.git_repo) {
            if (current_ci_data.git_repo.git_url) {

                testAndGetBranches(current_ci_data.git_repo.git_url, current_ci_data.git_repo.credential_id)

            }
        }
        fetchSystemSettingsData()
    }, [])


    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING"
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var branchRequired = data.find(item => item.key == "BRANCH_SELECTION_REQUIRED_FOR_EVERY_BUILD");

        console.log(branchRequired, 'bgf')
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            branchRequired: branchRequired?.value == 'true' ? true : false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: "FAILED"
        }))
    }


    function testAndGetBranches(git_url, credential_id) {
        const post_data = {
            git_url: git_url,
            credential_id: credential_id,
        };
        if (!post_data.credential_id) {
            delete post_data.credential_id;
        }
        if (props.variant == "ServiceCard") {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail, true);
        } else {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail);
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: true,
        }));

    }

    function onFetchSuccess(data) {
        console.log("fjkdsjnfdsahjfhjsa")
        if (data.branches && data.branches.length > 0) {
            var branch_list = data.branches.map(item => {
                return { id: item, label: item }
            })
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            branch_list: branch_list && branch_list.length > 0 ? branch_list : [],
            action_card: !new_state.action_card
        }));

    }
    console.log(state, "checking what is saving")
    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            errors: {
                ...new_state.errors,
                branch_name: error
            }

        }));
    }

    function getEnvNamefromId() {
        var env_data = {};
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_data.env_name = item.project_env.name
                    env_data.env_type = service_data.type
                }
            })
        }
        return env_data;
    }

    function successBuildTriggerCallBack(response) {
        setState(prevState => ({
            ...prevState,
            build_status: response.status,
            build_id: response.id,
            trigger: "success",
            tag: "",
            custom_tag: "",
            feature_tag: "",
            branch_name: "",
            docker_cache: false,
            is_deploy: false,
            error: {},
            show_bp_agent_warning: false
        }));
        refreshGrid();
    }

    function failedBuildTriggerCallBack(error) {

        setState(prevState => ({
            ...prevState,
            trigger: "failed",
            error_msg: error.detail ? error.detail : error,
            show_bp_agent_warning: false
        }));
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }

    function checkForParallelBuild(serviceId, envId) {
        return new Promise(function (myResolve, myReject) {

            var requestInfo = {
                endPoint: GenerateURL({ service_id: serviceId, env_id: envId }, properties.api.buildPossible),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (fetchedData) => {
                myResolve(fetchedData)
            }, (error) => { myReject(error) })
        });

    }
    function successBuildTriggerCallBackFromActionDialouge(response) {
        setState(prevState => ({
            ...prevState,
            build_status: response.status,
            build_id: response.id,
            trigger: "success",
            tag: "",
            custom_tag: "",
            feature_tag: "",
            branch_name: "",
            docker_cache: false,
            is_deploy: false,
            error: {},
            show_bp_agent_warning: false,
            parallBuildFeatureData: null
        }));
        refreshGrid();
    }

    function failedBuildTriggerCallBackFromActionDialouge(error) {

        setState(prevState => ({
            ...prevState,
            trigger: "failed",
            error_msg: error.detail ? error.detail : error,
            show_bp_agent_warning: false,
            parallBuildFeatureData: null
        }));
    }
    const triggerPipelineFromActionDialouge = (actionType) => {
        if (state.parallBuildFeatureData && actionType != undefined)
            PostData(state.parallBuildFeatureData?.url, { ...state.parallBuildFeatureData?.data, action: actionType }, successBuildTriggerCallBackFromActionDialouge, failedBuildTriggerCallBackFromActionDialouge);
    }

    function validateAndTrigger(deploy_this_tag) {
        var result = { valid: true }
        if (state.build_and_deploy) {
            result = ValidateDataSet(
                {
                    deployment_name: state.deployment_name,
                },
                {
                    deployment_name: [VALIDATION_TYPE_REQUIRED]
                })
        }

        if (state.branchRequired) {
            const resultForBuild = ValidateDataSet(
                {
                    branch_name: state.branch_name,
                },
                {
                    branch_name: [VALIDATION_TYPE_REQUIRED]
                }
            )
            if (!resultForBuild.valid) {
                const branchError = resultForBuild.error
                if (!result.valid) {
                    result.error = { ...result.error, ...branchError }
                }
                else {
                    result.error = branchError
                    result.valid = false
                }
            }
            console.log(resultForBuild, 'rbgh')

        }
        console.log(state.docker_cache,"resultForBuild")
        if (result && result.valid) {
            var data = {
                // custom_tag: state.tag,
                branch_name: state.branch_name,
                no_cache: state.docker_cache == 'on' ? false: true,
                feature_tag: state.feature_tag,
                is_deploy: deploy_this_tag === "DEPLOY_THIS_TAG" ? true : false
            }
            if (deploy_this_tag === "DEPLOY_THIS_TAG") {
                data.deployment_name = state.deployment_name
            } else {

                delete data.deployment_name

            }
            if (state.open_custom && state.tag != "" && state.custom_tag != "") {
                data.custom_tag = state.custom_tag;
                delete data.tag
            } else {
                data.tag = state.tag
                delete data.custom_tag
            }
            if (state.open_custom && (state.tag && state.tag != "")) {
                delete data.tag
            }
            if (!state.open_custom && (state.custom_tag && state.custom_tag != "")) {
                delete data.custom_tag
            }
            if (!data.custom_tag) {
                delete data.custom_tag;
            }
            if (!data.tag) {
                delete data.tag;
            }
            if (!data.branch_name) {
                delete data.branch_name;
            }
            if (!data.feature_tag) {
                delete data.feature_tag
            }
            if (deploy_this_tag === "DEPLOY_THIS_TAG" && all_available_deployments && all_available_deployments.length == 1) {
                data.deployment_name = all_available_deployments[0].label ? [all_available_deployments[0].label] : null
            }
            if (disable_parallel_build && disable_parallel_build.value && disable_parallel_build.value === "true") {
                checkForParallelBuild(service_id, env_id).then(parallelBuildData => {

                    console.log(data, 'd_fgbn')
                    if (!parallelBuildData?.parallel_build_status) {
                        const possibleActions = parallelBuildData?.possible_action
                        setState(prevState => ({
                            ...prevState,
                            parallBuildFeatureData: {
                                parallelBuildData: parallelBuildData,
                                possibleActions: possibleActions,
                                data: data,
                                url: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url)
                            }
                        }))
                    }
                    else {
                        var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);

                        PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
                    }

                }).catch(error => {

                    console.log(error, 'error->>')
                })
            } else {
                var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);

                PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
            }



            // var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);

            // var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.buildPossible);

            // PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);

        } else {
            console.log(result, 'rsgt_pps')
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    ...result.error
                }
            });
        }

    }

    const onChangeHandler = (e) => {

        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            setState({
                ...state,
                [e.target.name]: e.target.checked,
                errors: {
                    ...state.errors,
                    [e.target.name]: "",
                }
            });
        }
    }

    function checkForFeatureTag() {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem("feature_tag", flag_for_feature_tag);
            var feature_tag = localStorage.getItem("feature_tag");
            feature_tag = JSON.parse(feature_tag) === true;
            if (feature_tag) {
                return (
                    <div className={type == "DEPLOY" ? "deploy_class" : ""}>
                        <Input
                            type="text"
                            mandatorySign={type == "BUILD" ? false : true}
                            data={{ feature_tag: state.feature_tag }}
                            name="feature_tag"
                            error={{ feature_tag: state.errors.feature_tag }}
                            onChangeHandler={onChangeHandler}
                            label="Feature Id"
                            placeholder="Enter Feature Id"
                        />
                    </div>
                )
            }
        } else {
            return (
                <p>Sorry, your browser does not support Web Storage.</p>
            )

        }
    }

    function onClose() {
        setState(prevState => ({
            ...prevState,
            show_bp_agent_warning: false
        }))
    }

    function refreshGrid() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));

    }
    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }
    const toggleTagState = () => {
        var custom_tag_flag = state.open_custom;
        if (custom_tag_flag) {
            setState({
                ...state,
                loading_tag: true,
                tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    tag: null
                });
            }, 200);
        } else {
            setState({
                ...state,
                loading_tag: true,
                custom_tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    custom_tag: null
                });
            }, 200);
        }

    }



    const onKeyValueChangeHandler = (k, v) => {

        setState({
            ...state, [k]: v,
            errors: { ...state.errors, [k]: "" }
        });
    }


    const evaluateAgentStatusOnTrigger = (deployTag) => {
        console.log(deployTag, 'inside_function')
        const resultOfEvalution = evaluateAgentStatus(null, 'build')
        console.log(resultOfEvalution, 'bp_agent_state_ev_fun_d001');
        if (resultOfEvalution && resultOfEvalution.type === "unHealthy") {
            console.log("function_running")
            setState(prevState => ({
                ...prevState,
                show_bp_agent_warning: true,
                type_of_action: deployTag === 'DEPLOY_THIS_TAG' ? 'deploy-too' : 'only-build'
            }))
        }
        else {
            deployTag === 'DEPLOY_THIS_TAG' ? validateAndTrigger("DEPLOY_THIS_TAG") : validateAndTrigger();
        }
    }
    console.log(state, "fdajsdja")
    const onChangeToggleState = (e) => {
        console.log(state, e.target.name, "yyy")
        const name = e.target.name;
        setState(new_state => ({
            ...new_state,
            [name]: !state[name],
        }));
    }
    function onChangeHandlerForKeyValue(key, value) {

        if (key == "deployment_name") {
            value.forEach((id, index) => {
                value[index] = id;
            });
        }
        setState({
            ...state, [key]: value,
            errors: { ...state.errors, [key]: "" }
        });
    }



    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;


    return (

        <>

            <Dialog
                open={build_action_card}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog action_card_dialog_width"
                aria-describedby="alert-dialog-description"
            >

                {
                    is_permited ?
                        <>

                            <div className='card' style={{ borderRadius: 0, border: 0 }}>
                                {
                                    state.branch_loading ?
                                        <div style={{ height: '219px' }}><Loading varient="light" /></div> :
                                        <>
                                            <div className='card-header' style={{ backgroundColor: '#124d9b' }}>
                                                <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                                                    <div className='d-flex align-center'>
                                                        <div className="build-round-icon">
                                                            <span className='flaticon-flash-on-indicator'></span>
                                                        </div>
                                                        <span className='font-14 ml-5 d-inline-block' style={{ textTransform: 'capitalize', color: '#fff' }}>
                                                            Trigger a new Build
                                                        </span>

                                                    </div>
                                                    <div className='d-flex align-center'>
                                                        <IconButton onClick={props.closebox} >
                                                            <CloseIcon style={{ color: '#fff' }} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-sub-header pd-10 border-bottom'>
                                                <p className=" font-12 ">
                                                    <span> Please note You are building image in Env. &nbsp;</span>
                                                    <span className="chip-sq dev-chip" style={{ textTransform: "uppercase" }}>
                                                        {top_summary_data ?
                                                            top_summary_data.type :
                                                            getEnvNamefromId().env_type ? getEnvNamefromId().env_type : null}
                                                    </span>
                                                    <ArrowRightAltIcon className='font-24' style={{ verticalAlign: 'middle' }} />
                                                    <span style={{ verticalAlign: 'middle' }}>Sub Env: <span className='text-grey-83'>
                                                        {top_summary_data ?
                                                            top_summary_data.env_name :
                                                            getEnvNamefromId().env_name ? getEnvNamefromId().env_name : null}</span>  </span>
                                                </p>
                                            </div>
                                            <BpAgentStatusActionAlert open={state.show_bp_agent_warning} onClose={onClose} onTrigger={state.type_of_action === 'only-build' ? () => validateAndTrigger() : () => validateAndTrigger("DEPLOY_THIS_TAG")} type='Build Agent' />

                                            <div className='card-body ' style={{ paddingBottom: 0, }}>
                                                
                                                    {configuration_data && configuration_data.env_ci_detail ?
                                                        <>
                                                            {console.log(configuration_data, "hbchdbzbsdbcdbc tt")}
                                                            <div
                                                                className="section-input-grid-5 pd-10"
                                                                style={{
                                                                        gridTemplateColumns: '1fr'
                                                                    }}>

                                                                <div>
                                                                    <div className="d-grid mb-5" style={{ gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                                                                        {
                                                                            state.loading_tag ? <Loading varient="light" /> :
                                                                                <Input
                                                                                    type={"text"}
                                                                                    name={state.open_custom ? "custom_tag" : "tag"}
                                                                                    placeholder={state.open_custom ? "custom-tag" : "tag"}
                                                                                    subHeading={"(Optional)"}
                                                                                    label={state.open_custom ? "Enter Custom Tag" : "Enter Tag"}
                                                                                    mandatory
                                                                                    data={{ tag: state.tag }}
                                                                                    error={{ tag: state.errors.tag }}
                                                                                    onChangeHandler={onChangeHandler}
                                                                                    extraDiv={<span role='button' tabIndex={0}  className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Choose Tag" : "Provide Custom Tag"}</span>}
                                                                                />
                                                                        }
                                                                        {
                                                                            state.branch_loading ? <Loading varient="light" /> :
                                                                                <div className='section-input d-block'>
                                                                                    <Input
                                                                                        type={state.open ? "text" : "auto-complete-dropdown"}
                                                                                        name="branch_name"
                                                                                        placeholder="Master"
                                                                                        subHeading="(Optional)"
                                                                                        getOptionLabel={(option) => option.label}
                                                                                        list={state.branch_list && state.branch_list.length > 0 ? state.branch_list : []}
                                                                                        label="Branch Name"
                                                                                        data={{ branch_name: state.branch_name }}
                                                                                        error={{ branch_name: state.errors.branch_name }}
                                                                                        onChangeHandler={state.open ? onChangeHandler : onKeyValueChangeHandler}
                                                                                        extraDiv={<span role='button' tabIndex={0}  className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleState}>{state.open ? "Choose Branch" : "Provide Custom Branch"}</span>}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                        <div className='disable-enable-switch'>
                                                                        <Input
                                                                            type="enabled-disabled-switch"
                                                                            name="docker_cache"
                                                                            label="Docker Cache"
                                                                            data={{}}
                                                                            onChangeHandler={onChangeHandler}
                                                                            error={{}}
                                                                        />
                                                                        </div>
                                                                        {checkForFeatureTag()}
                                                                    </div>
                                                                    {
                                                                        all_available_deployments && all_available_deployments.length > 1 &&
                                                                        <div className='card m-10' style={{ width: '100%' }}>
                                                                            <div className={`${state.build_and_deploy ? "card-header border-bottom" : "card-header"}`} style={{ width: '100%', backgroundColor: '#f4f4f4' }}>
                                                                                <p className='font-12'>Do you want to deploy this tag?</p>
                                                                                <Input
                                                                                    type="switch"
                                                                                    name="build_and_deploy"
                                                                                    label=""
                                                                                    data={{ is_deploy: state.is_deploy }}
                                                                                    onChangeHandler={onChangeToggleState}
                                                                                    error={{ is_deploy: state.is_deploy }}
                                                                                />


                                                                            </div>
                                                                            {
                                                                                state.build_and_deploy &&
                                                                                <div className='card-body pd-10'>
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        name="deployment_name"
                                                                                        label="Available Deployments"
                                                                                        mandatorySign
                                                                                        data={{ deployment_name: state.deployment_name }}
                                                                                        error={{ deployment_name: state.errors.deployment_name }}
                                                                                        list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                                        onChangeHandler={onChangeHandlerForKeyValue}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>


                                                            </div>
                                                            <div className='d-flex align-center space-between input-switch-f8'
                                                                style={{
                                                                    backgroundColor: '#fefefe',
                                                                    padding: '10px',
                                                                    borderTop: '1px solid #efefef'
                                                                }}
                                                            >
                                                                <div></div>
                                                                <div className="section-2 d-flex align-center" style={{ position: 'relative' }}>
                                                                    <button className="btn btn-outline-grey" style={{ padding: '1rem', minWidth: "108px" }} onClick={props.closebox} >Cancel</button>
                                                                    {

                                                                        <button
                                                                            className="btn btn-primary-v2 d-flex align-center"
                                                                            style={{ padding: '0.5rem 1rem' }}
                                                                            onClick={
                                                                                state.build_and_deploy ?
                                                                                    () => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }
                                                                                    :
                                                                                    evaluateAgentStatusOnTrigger} >{
                                                                                state.build_and_deploy ?
                                                                                    "Trigger Build & Deploy" :
                                                                                    "Trigger Build"
                                                                            } &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>

                                                                    }

                                                                    {
                                                                        all_available_deployments && all_available_deployments.length == 1 &&
                                                                        <>
                                                                            {
                                                                                deploy_env_offline_flag && deploy_env_offline_flag.value && deploy_env_offline_flag.value === "true" ?
                                                                                    (top_summary_data && top_summary_data.is_env_down) || (is_env_down) ?
                                                                                        <button className="btn btn-v2-default d-flex align-center" style={{ padding: '0.5rem 1rem' }} onClick={handleClickOpenPopover} >Trigger Build &amp; Deploy &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>
                                                                                        :
                                                                                        <button className="btn btn-v2-default d-flex align-center" style={{ padding: '0.5rem 1rem' }} onClick={() => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }} >Trigger Build &amp; Deploy &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>
                                                                                    :
                                                                                    <button className="btn btn-v2-default d-flex align-center" style={{ padding: '0.5rem 1rem' }} onClick={() => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }} >Trigger Build &amp; Deploy &nbsp;<PlayCircleOutlineOutlinedIcon className="font-27" /> </button>
                                                                            }
                                                                        </>

                                                                    }
                                                                    {/* all_available_deployments && all_available_deployments.length == 1 */}
                                                                    {/* {
                                                                        state.parallBuildFeatureData && 
                                                                        
                                                                    
                                                                   
                                                                    } */}
                                                                    <BuildFlowActionAlert
                                                                        onClose={() => setState(prevState => ({ ...prevState, parallBuildFeatureData: null }))}
                                                                        onTrigger={triggerPipelineFromActionDialouge}
                                                                        buidFlowData={state.parallBuildFeatureData}

                                                                    />

                                                                </div>
                                                            </div>
                                                        </>
                                                        : <p className="font-12 text-center pd-10 mt-12 mb-12">Build Details not configured</p>}
                                                
                                            </div>
                                        </>
                                }

                            </div>
                            <div className="popup-card mb-15">
                                {/* {
                                state.branch_loading ? 
                                <div style={{height: '195.5px'}}><Loading varient="light" /></div>: */}
                                <CurrentProgressData
                                    top_summary_data={top_summary_data}
                                    is_env_down={top_summary_data && top_summary_data.is_env_down ? top_summary_data.is_env_down : is_env_down ? is_env_down : null}
                                    type={type}
                                    recent_history={configuration_data}
                                    service_id={service_id}
                                    service_data={service_data}
                                    build_status={state.build_status}
                                    refresh={refreshGrid}
                                    closebox={props.closebox}
                                    refresh_count={state.refresh_count}
                                    application_id={props.application_id}
                                    service_env_name={service_env_name}
                                    env_id={env_id} />
                                {/* } */}
                            </div>

                        </> :

                        <div style={{ margin: '15px' }} className='d-flex align-center space-between'>
                            <h3 className="popup-card-main-heading pd-10">
                                You are not allowed to perform this action, Contact Project Admin
                            </h3>
                            <div>
                                <IconButton onClick={props.closebox} >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={state.trigger === "success" || state.trigger === "failed"}
                    onClose={handleClose}
                    className="z-index-top"
                    autoHideDuration={3000}>

                    {
                        state.trigger === "success" ?
                            
                                <Alert severity="success" >
                                    {"SUCCESSFULLY SCHEDULED " + type}
                                </Alert>
                             : state.trigger == "failed" ?
                                <Alert severity="error" >
                                    {type + " SCHEDULE FAILED"}
                                    <div className="error-div">
                                        {state.error_msg}
                                    </div>
                                </Alert>

                                : null
                    }
                </Snackbar>
            </Dialog>
            <Popover
                id={id}
                open={OpenPopover}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover-content" style={{ padding: '20px', width: '400px', }}>

                    <div className='' style={{ width: '100%', position: 'relative' }}>
                        <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
                        <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-10 text-center' />
                        <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                        <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger build as the envrionment is offline now.
                            Please contact your team administrator to get the environment online.</p>
                    </div>

                </div>
            </Popover>
        </>

    )
}

TriggerActionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default TriggerActionCard;
