import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Grid, IconButton } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
import { ValidateDataSet } from '../../../../util/Validator';
import AddIcon from '@material-ui/icons/Add';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const AddLinkIssuesDialog = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    console.log(prev_state,"add_link_issues")
    const [state, setState] = useState(prev_state ? prev_state : addLinkIssueDefaultState())
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const jira_reference_name_list = props.jira_reference_name_list;
    const fetchFinalIssuesList = props.fetchFinalIssuesList ? props.fetchFinalIssuesList : () => { }

    useEffect(() => {
        setState(new_state => ({
            ...new_state,
            ...prev_state
        }))
    }, [prev_state])
    

    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,
        }))
    }
    const handleClickClose = () => {
        setState(new_state => ({
            ...new_state,
            open: false,
            update_state_fn: false
        }))
        clearState();
    }
    const fetchIssueType = (id) => {
        var requestInfo = {
            endPoint: GenerateURL({ job_temp_id: id }, properties.api.issue_type),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, FetchIssueTypeResponseSuccess, FetchIssueTypeResponseFailed);
        setState(new_state => ({
            ...new_state,
            loading_issues_list: true
        }));
    }

    const FetchIssueTypeResponseSuccess = (response) => {
        if (response.issue_link_types) {
            var issue_type = response.issue_link_types.map(item => {
                return { id: item, label: item }
            })
        } else {
            var issue_type = [{ label: "No Repo Available", id: null }]
        }
        setState(new_state => ({
            ...new_state,
            issue_type: issue_type,
            loading_issues_list: false,
            enable_issue_link_type: true
        }));

    }
    const FetchIssueTypeResponseFailed = (error_resposne) => {
        var response = error_resposne.detail;

        setState(new_state => ({
            ...new_state,
            issue_type: [{ id: null, label: response }],
            loading_issues_list: false
        }));
    }
    const onChangeHitApi = (e) => {
        const k = e.target.name;
        const v = e.target.value
        var find_template_id = jira_reference_name_list.filter(item => item.issue_name == v);

        var template_id = find_template_id[0] && find_template_id[0].job_template ? find_template_id[0].job_template : null;
        if (template_id) {
            fetchIssueType(template_id)
        }

        commonFunctions.onChangeHandler(e);
        
    }
    const onChangeHandler = (e) => {
        setState(new_state => ({
            ...new_state,
            search_enabled: true
        }));
        commonFunctions.onChangeHandler(e)
    }
    const onKeyValueChangeHandler = (k, v) => {
        setState({
            ...state, data: { ...state.data, [k]: v },
            error: { ...state.error, [k]: null }
        });
    }

    function onKeyEnter(event) {
        var jira_name = state.data.jira_reference_name
        var find_template_id = jira_reference_name_list.filter(item => item.issue_name == jira_name);

        var template_id = find_template_id[0] && find_template_id[0].job_template ? find_template_id[0].job_template : null
        if (event.key == 'Enter') {

            fetchIssuesList(template_id, state.data.search_issue_type);


        }
    }
    function onClickSearch() {
        var jira_name = state.data.jira_reference_name;
        var serached_data = state.data.search_issue_type;
        var find_template_id = jira_reference_name_list.filter(item => item.issue_name == jira_name);

        var template_id = find_template_id[0] && find_template_id[0].job_template ? find_template_id[0].job_template : null

        fetchIssuesList(template_id, serached_data);

    }
    const fetchIssuesList = (id, data) => {
        var requestInfo = {
            endPoint: GenerateURL({ job_temp_id: id, search_string: data }, properties.api.get_issues_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, FetchIssuesListResponseSuccess, FetchIssuesListResponseFailed);
        setState(new_state => ({
            ...new_state,
            loading_issues_list: true,
            search_keyword: state.data.search_issue_type
        }));
    }

    const FetchIssuesListResponseSuccess = (response) => {
        if (response.jira_issue_list) {
            var issues_list = response.jira_issue_list.map(item => {
                return { id: item, label: item }
            })
        } else {
            var issues_list = [{ label: "No Repo Available", id: null }]
        }
        setState(new_state => ({
            ...new_state,
            issues_list: issues_list,
            loading_issues_list: false,
            no_issues_to_show: response.jira_issue_list && response.jira_issue_list.length > 0 ? false : true
        }));

    }
    const FetchIssuesListResponseFailed = (error_resposne, exception) => {
        var response = error_resposne.detail;
        console.log(error_resposne, exception, "lapdlapd")
        setState(new_state => ({
            ...new_state,
            issues_list: [],
            show_error_msg: error_resposne,
            loading_issues_list: false
        }));
    }

    function clearState() {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                search_issue_type: "",
                issue_link_type: "",
                jira_reference_name:"",
                issue: []
            },
            update_state_fn: false,
            issues_list: []
        }))
    }

    const validateAndSaveLinkedIssues = () => {
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            var variable_type = state.data.jira_reference_name;
            var final_arr = state.issue_link;
            var issue_type = state.data.issue_link_type;
            var issues = state.data.issue;
            if (issues.length > 0) {
                if (final_arr.length > 0) {
                    let found_duplicate = final_arr.find((item, key) => item.issue_link_type == state.data.issue_link_type && item.jira_ref_name_key == state.data.jira_reference_name)
                    if (!found_duplicate) {
                        final_arr.push({ jira_ref_name_key: variable_type, issue_link_type: issue_type, issue: issues })
                    }
                } else {
                    final_arr.push({ jira_ref_name_key: variable_type, issue_link_type: issue_type, issue: issues })
                }
               
                setState(new_state => ({
                    ...new_state,
                    issue_link: [
                        ...final_arr
                    ],
                    update_state_fn: false
                }))
                console.log(final_arr,"final_arry_one")
                fetchFinalIssuesList(final_arr);
                handleClickClose()
            }

        } else {
            setState(new_state => ({
                ...new_state,
                error: result.error
            }))
        }

    }
    const updateSingleFormRow = (current_obj) => {
        var result = ValidateDataSet(state.data, state.validations);
        if (result.valid) {
            let updatedList = state.issue_link.map(item => {
                if (item.issue_link_type == current_obj.issue_link_type) {
                    return { ...item, issue_link_type: state.data.issue_link_type, issue: state.data.issue };
                } else {
                    return item;
                }
            }
            )
            setState(new_state => ({
                ...new_state,
                update_state_fn: false,
                issue_link: [
                    ...updatedList
                ],
                data: {
                    ...new_state.data,
                    search_issue_type: "",
                    issue_link_type: "",
                    issue: []
                },
                issues_list: []

            }))
            fetchFinalIssuesList(updatedList);
            handleClickClose()
        }
    }
    return (
        <div className={classes.dialog}>
            {
                state.issue_link.length > 0 ?

                    <div className="card-sub-header d-flex space-between">
                        <p className="heading-with-icon">
                            <span className="material-icons material-symbols-outlined"
                                style={{ verticalAlign: 'text-bottom' }}
                            >
                                link
                            </span>
                            <span style={{ marginLeft: '3px' }}>
                                Linked Issues
                            </span>
                        </p>
                        <button className='transparent-btn nowrap text-center' onClick={handleClickOpen}><AddIcon style={{ verticalAlign: 'middle' }} /> Link an Issue</button>
                    </div>
                    :
                    <div className="no-issues-added">
                        <p className="mb-15">
                            There are no link issues configured
                        </p>
                        <button className='btn btn-primary-v2 text-center' onClick={handleClickOpen}><AddIcon style={{ color: '#fff', verticalAlign: 'middle' }} /> Link an Issue</button>
                    </div>
            }
            <Dialog
                open={state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickClose}
                className={classes.dialog_root + " " + " integrations-dialog"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className={classes.cardHeader}>
                    <p>Link Jira Issues</p>
                    <IconButton onClick={handleClickClose}>
                        <CloseIcon style={{ fontSize: 20, color: '#fff' }} />
                    </IconButton>
                </div>
                <div className={classes.cardBody}>
                    <Grid className='mb-5' container alignItems="center" spacing={2}>
                        <Grid item lg={4}>
                            <Input
                                type="select"
                                name="jira_reference_name"
                                list={jira_reference_name_list.map(item => { return { label: item.issue_name, id: item.issue_name } })}
                                label="Select Jira Reference Name"
                                onChangeHandler={onChangeHitApi}
                                data={state.data}
                                error={state.error} />
                        </Grid>
                        <Grid item lg={4}>
                            <Input
                                type="select"
                                name="issue_link_type"
                                list={state.issue_type}
                                label="Linked Issue Type"
                                onChangeHandler={state.enable_issue_link_type ? onChangeHandler : null}
                                data={state.data}
                                error={state.error} />
                        </Grid>
                        <Grid item lg={4}>
                            <span className="font-12" style={{ color: '#828282' }}>Search with description</span>
                            <div className="input-searchbar mb-15">

                                <Input
                                    type="text"
                                    name="search_issue_type"
                                    label=""
                                    placeholder="search"
                                    onChangeHandler={state.search_enabled ? commonFunctions.onChangeHandler: null}
                                    data={state.data}
                                    error={state.error}
                                    onKeyPress={state.search_enabled ? onKeyEnter : null}
                                    varient="handle-keys"
                                />
                                <button className="btn " onClick={state.search_enabled ? onClickSearch : ()=>{}}>
                                    <SearchIcon fontSize='large' style={{ color: 'rgb(130, 130, 130)' }} />
                                </button>
                            </div>

                        </Grid>
                        {
                            state.loading_issues_list ?
                                <Loading varient="light" /> :
                                state.issues_list && state.issues_list.length > 0 ?
                                    <Grid item lg={12}>
                                        <div className='auto-dropdown-with-checkbox-wrapper'>
                                            <Input
                                                type="select-with-checkbox"
                                                name="issue"
                                                label="Select Issues"
                                                subHeading="(You can select multiple)"
                                                list={state.issues_list}
                                                placeholder="Enter Issue Name"
                                                onChangeHandler={onKeyValueChangeHandler}
                                                data={state.data}
                                                error={state.error}
                                            />
                                        </div>
                                    </Grid>
                                    :
                                    state.no_issues_to_show || state.show_error_msg ?
                                        <AlertStrip
                                            variant="error"
                                            message={state.show_error_msg ? state.show_error_msg : " No Data Found with " + state.search_keyword + " keyword"}
                                        />
                                        : null
                        }
                        <div className="d-flex align-center justify-end pd-10" style={{width: '100%'}}>
                            <button className="btn btn-outline-grey" onClick={clearState}>Cancel</button>
                            <button 
                                className={state.data.issue&&state.data.issue.length > 0 ? "btn btn-submit": "btn btn-submit btn-disable cursor-not-allowed"} 
                                onClick={state.data.issue&&state.data.issue.length > 0 ? state.update_state_fn ?()=>{updateSingleFormRow(prev_state.data)} : validateAndSaveLinkedIssues : ()=>{}} 
                                style={{minWidth:'63px'}}>Add</button>
                        </div>
                    </Grid>
                </div>
            </Dialog>
        </div>
    )
}

AddLinkIssuesDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default AddLinkIssuesDialog;

export const addLinkIssueDefaultState = () => {
    return {
        open: false,
        fullWidth: true,
        maxWidth: 'md',
        update_state_fn: false,
        enable_issue_link_type: false,
        search_enabled:false,
        data: {
            search_issue_type: "",
            issue: []
        },
        error: {},
        issue_link: [],
        validations: {

        },
    }
}


const useStyles = makeStyles(() => ({
    dialog_root: {
        '& .MuiDialog-paperScrollPaper': {
            width: '100rem',
            backgroundColor: '#fff;'
        },
        '& .MuiPaper-root': {
            maxWidth: '1000px'
        }
    },
    dialog: {
        width: '100%',
        '& .card-sub-header': {
            backgroundColor: '#fefefe',
            borderBottom: '1px solid #eaeaea',
            padding: '5px',
            '& .heading-with-icon': {
                color: '#484848',
                fontSize: '12px',
                '& .material-icons': {
                    // fontSize: 
                }
            }
        },
        '& .no-issues-added': {
            width: '300px',
            textAlign: 'center',
            fontSize: '12px',
            margin: 'auto',
            display: 'block',
            padding: '20px'
        },
        '& .sq-chip': {
            backgroundColor: '#626262',
            color: '#fff',
            padding: '1px 3px',
            borderRadius: '4px',
            marginLeft: '3px'
        }
    },
    button_class: {
        fontSize: '12px',
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        },
        '& .small-icon-button': {
            backgroundColor: '#fff',
            marginLeft: '5px',
            border: 'none',
            boxShadow: '0.877px 1.798px 8px 0px rgba(0, 0, 0, 0.2)',
            height: '20px',
            width: '20px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0086ff'
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #C5C5C5',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: 'rgb(18, 77, 155)',
        // fontFamily: "'inter', nunito-sans, heebo, nunito, sans-serif",
        padding: '10px 20px'
    },
    cardBody: {
        padding: '20px 20px',
        '& ul': {
            '&.headerul': {
                '& li': {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '12px',
                    // fontWeight: 300,
                    // fontFamily: "'inter', nunito-sans, heebo, nunito, sans-serif",
                    borderBottom: '1px solid #a3a3a3',
                    color: '#000',
                    '&.active': {
                        color: '#000',
                        borderBottom: '2px solid #124D9B'
                    }
                }
            }
        }
    }
}));