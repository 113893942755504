import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HelmIntegrationHeader from "./components/HelmIntegrationHeader";
import { ErrorComponent } from "../../utils/Error";
import SearchBar from "../../../components/SearchBar";
import { Grid } from "@material-ui/core";
import Pagination from "../../../components/Pagination";
import HelmListCard from "./HelmListCard";
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";
import { GetAuth } from "../../../util/security";
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import InvokeApi from "../../../util/apiInvoker";
import { Loading } from "../../utils/Loading";
import AddHelmIntegration from "./AddHelmIntegration";

const HelmIntegrationList = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        data: {},
        error: null,
        showLoadingIcon: false,
        helm_chart_list: null,
        total_page: "",
        curr_page: "",
        search_query_name: false,
        refresh_count: 0,
        open_dialog: false,
        edit_id:null
    });
    var url = GenerateURL({}, properties.api.credential_all, true)
    const is_permitted = GetAuth().permission.POST.includes(url);

    function refreshState() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1
        }));
    }
    useEffect(() => {
        fetchHelmChartData()
    }, [state.refresh_count])


    const fetchHelmChartData = (data, url) => {

        const apiUrl = properties.api.get_post_helm_chart

        let requestInfo = {
            endPoint: GenerateURL({}, apiUrl),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }

        InvokeApi(requestInfo, fetchHelmChartDataHandleSuccessApiHit, fetchHelmChartDataHandleFailedApiHit);
        setState(newState => ({
            ...newState,
            showLoadingIcon: true,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
            search_query_name: data ? data.name ? data.name : "" : "",
        }))

    }

    const fetchHelmChartDataHandleSuccessApiHit = (data) => {

        setState(prevState => ({
            ...prevState,
            helm_chart_list: data.results.length !== 0 ? data.results : null,
            showLoadingIcon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: 1
        }));
    }

    const fetchHelmChartDataHandleFailedApiHit = (error) => {
        setState(prevState => ({
            ...prevState,
            error: error,
            showLoadingIcon: false
        }))
    }
    function fetchNextHemlChartData(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_post_helm_chart),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));
        InvokeApi(requestInfo, fetchNextHemlChartDataInfoFetchSuccess, fetchNextHemlChartDataInfoFetchFailure);

    }

    function fetchNextHemlChartDataInfoFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            helm_chart_list: data.results.length !== 0 ? data.results : null,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: new_state.curr_page + 1
        }));
    }
    function fetchNextHemlChartDataInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: true
        }));
    }

    function fetchPrevHemlChartInfo(data, url) {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_post_helm_chart),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }


        if (url) {
            requestInfo.endPoint = url;
        }
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));
        InvokeApi(requestInfo, fetchPrevHemlChartInfoSuccess, fetchPrevHemlChartInfoFailure);

    }

    function fetchPrevHemlChartInfoSuccess(data) {
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            helm_chart_list: data.results.length !== 0 ? data.results : null,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: new_state.curr_page - 1
        }));
    }
    function fetchPrevHemlChartInfoFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            data_loading: "FAILED",
            error: true
        }));
    }

    const invokeSearch = (data) => {
        fetchHelmChartData(data);
    }

    function fetchPageHelmChartInfo(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_post_helm_chart),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        setState((new_state) => ({
            ...new_state,
            current: requestInfo.endPoint,
            data_loading: "LOADING",
        }));

        var current_page = enteredPageNumber;

        InvokeApi(
            requestInfo,
            (response) => {
                RegistriesPageInfoFetchSuccess(response, current_page);
            },
            RegistriesPageInfoFetchFailure
        );
    }

    function RegistriesPageInfoFetchSuccess(data, count) {
        let helm_chart_list = data.results;
        setState((new_state) => ({
            ...new_state,
            data_loading: "SUCCESS",
            helm_chart_list: helm_chart_list.length !== 0 ? helm_chart_list : null,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10)
                ? (Number(data.count) / 10).toFixed(0)
                : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
                    ? (Number(data.count) / 10 + 1).toFixed(0) - 1
                    : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: Number(count),
        }));
    }

    function RegistriesPageInfoFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            data_loading: "FAILED",
            error: true,
        }));
    }
    const handleOpenDialog = () => {
        setState((new_state) => ({
            ...new_state,
            open_dialog: true
        }));
    }
    const handleCloseDialog = () => {
        setState((new_state) => ({
            ...new_state,
            open_dialog: false
        }));
    }
    const onClickOpenDialogAndSetData = (data) => {
        console.log(data,"onClickOpenDialogAndSetData")
        setState((new_state) => ({
            ...new_state,
            open_dialog: true,
            edit_id:data
        }));
    }
    return (
        <>
            <div className={classes.reposList}>
                <HelmIntegrationHeader
                    OpenDialogButton={<>
                        {
                            is_permitted ?
                                <button className='btn btn-primary-v2  mb-15 m-auto  d-flex align-center justify-center text-center' onClick={handleOpenDialog}> <AddIcon /> Add Helm Repo</button>
                                :
                                <Tooltip title="You are not allowed to perform this action">
                                    <button className="btn btn-disable cursor-not-allowed">
                                        <AddIcon /> Add Helm Repo
                                    </button>
                                </Tooltip>

                        }
                    </>}
                />
                {state.showLoadingIcon ? (
                    <div className="" style={{ height: "60vh", margin: "auto" }}>
                        <Loading varient="light" />
                    </div>
                ) : state.error ? (
                    <ErrorComponent variant="msg-box" error={state.error} />
                ) : (
                    <>
                        <Grid container spacing={2}>
                            <Grid item lg={9}>
                                <SearchBar
                                    search_data={state.search_data}
                                    default_filter={{ name: "name", label: "Name" }}
                                    search_call_back={invokeSearch}
                                    clear_search_callback={fetchHelmChartData}
                                />
                            </Grid>

                            <Grid item lg={3} sm={12} xl={3} xs={12}>
                                <Pagination
                                    total_count={state.total_page}
                                    current_page_count={state.curr_page}
                                    next={state.next}
                                    previous={state.previous}
                                    on_previous_click={() => {
                                        fetchPrevHemlChartInfo(null, state.previous);
                                    }}
                                    on_next_click={() => {
                                        fetchNextHemlChartData(null, state.next);
                                    }}
                                    on_pageNumber_click={(pageNumber) => {
                                        fetchPageHelmChartInfo(pageNumber);
                                    }}
                                />
                            </Grid>
                            <></>
                            {state.helm_chart_list ? (
                                state.helm_chart_list.map((helm_chart) => {
                                    return (
                                        <Grid item lg={12} key={helm_chart.id}>

                                            <HelmListCard
                                                data={helm_chart}
                                                onClickOpenDialogAndSetData={onClickOpenDialogAndSetData}
                                                apiUrl={GenerateURL(
                                                    { id: helm_chart.id },
                                                    properties.api.update_helm_chart
                                                )}
                                                refreshFun={fetchHelmChartData}
                                            />
                                        </Grid>
                                    );
                                })
                            ) : state.search_query_name ? (
                                <div
                                    className="main-container-error"
                                    style={{ height: "60vh", margin: "auto" }}
                                >
                                    <div class="svg">
                                        <div class="servicePanel">
                                            <div class="blank-div">
                                                <div class="blank-div-text">
                                                    No Helm found with the name :{" "}
                                                    {state.search_query_name}
                                                </div>
                                                <button
                                                    className="btn btn-submit"
                                                // onClick={fetchRegistries}
                                                >
                                                    Refresh
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="main-container-error"
                                    style={{ height: "60vh", margin: "auto" }}
                                >
                                    <div class="svg">
                                        <div class="servicePanel">
                                            <div class="blank-div">
                                                <div class="blank-div-text">
                                                    You currently have no Helm associations.
                                                </div>
                                                <button className='btn btn-primary-v2  mb-15 m-auto  d-flex align-center justify-center text-center' onClick={handleOpenDialog}> <AddIcon /> Add Helm Repo</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Grid>
                    </>
                )}
            </div>
            {
                state.open_dialog &&
                <AddHelmIntegration
                    open={state.open_dialog}
                    handleClose={handleCloseDialog}
                    edit_id={state.edit_id}
                    prev_state={state.current_obj_data}
                    refreshFn={refreshState} />
            }
        </>
    )
}

const useStyles = makeStyles({
    reposList: {
        padding: "20px 20px 0px 20px",
        "& .reposcard": {
            padding: "10px",
            "& .reposcard-inner": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#fff",
                borderRadius: "5px",
                padding: "10px",
                marginTop: "10px",
                fontSize: "13px",
                filter: "drop-shadow(0.877px 1.798px 13.5px rgba(0,0,0,0.05))",
                "& .r-col1": {
                    padding: "10px",
                    marginRight: "15px",
                    display: "flex",
                },
                "& .repos-card-content": {
                    display: "flex",
                    alignItems: "center",
                    "& .img": {
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#ededed",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "12px",
                        "& img": {
                            width: "60%",
                            height: "60%",
                        },
                    },
                },
                "& .text": {
                    "& .title-heading": {
                        fontWeight: "400",
                        marginBottom: "3px",
                        display: "flex",
                        alignItems: "center",
                    },
                    "& .title-name": {
                        color: "#999999",
                    },
                    "& .moreverticon": {
                        fontSize: "1.5em",
                        color: "#3d73eb",
                        cursor: "pointer",
                    },
                },
                "& .text.connected": {
                    display: "flex",
                    flexDirection: "row",
                    "& svg": {
                        fontSize: "1.5em",
                        marginRight: "6px",
                    },
                    "& .title-heading": {
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#999999",
                    },
                },
                " & .conn": {
                    textTransform: "uppercase",
                    fontWeight: "500",
                },
                "& p": {
                    color: "#121212",
                },
                "& b": {
                    fontWeight: "500",
                },
                "& .r-col7": {
                    marginRight: "15px",
                    "& .test-btn": {
                        border: "2px solid #3d73eb",
                        padding: "6px 15px",
                        borderRadius: "5px",
                        fontWeight: "500",
                        color: "#3d73eb",
                    },
                },
            },
        },
    },
});

export default HelmIntegrationList