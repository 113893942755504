import React from 'react';
import PropTypes from 'prop-types';


export const CompactDataCard = (props) =>{
    const icon = props.icon;
    const headContent = props.headContent;
    const subContent = props.subContent;
    const color = props.color;
    return(
        <div className="data-card" style={{backgroundColor:color}}>
            <div className="data-card-icon">
                {icon}
            </div>
            <div className="data-card-text">
                <h3>{headContent}</h3>
                <p>{subContent}</p>
            </div>
        </div>
    );  
}

CompactDataCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };