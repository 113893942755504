import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';

const CanaryAnalysisReport = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        selectedTabOrder:0
    })
    const barChartOptions = {
        chart: {
            type: 'spline'
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [
                '12:30PM',
                '01:00PM',
                '01:30PM',
                '02:00PM',
                '02:30PM',
                '03:00PM',
                '03:30PM',
            ]
        },
       
        series: [{
            name: 'Canary',
            data: [20, 30, 60, 90, 40, 90, 100]
        }, {
            name: 'Baseline',
            data: [10, 50, 70, 30, 60, 60, 80]
        }],
        legend: {
            enabled: true,
        },
        colors: [
            '#0086FF',
            '#FEA111'
        ],
    }
    const statusList = [
        {
            tabName: 'CPU',
            order: 0,
            status: 'pass'
        },
        {
            tabName: 'Error Request',
            order: 1,
            status: 'failed'
        },
        {
            tabName: 'Incoming TCP Connections',
            order: 2,
            status: 'pass'
        },
        {
            tabName: 'latency 50th',
            order: 3,
            status: 'pass'
        },
        {
            tabName: 'latency 90th',
            order: 4,
            status: 'pass'
        },
        {
            tabName: 'Load Average',
            order: 5,
            status: 'pass'
        },
        {
            tabName: 'Outgoing TCP Connections',
            order: 6,
            status: 'pass'
        },
        {
            tabName: 'Successful requests',
            order: 7,
            status: 'failed'
        },
        {
            tabName: 'Threads',
            order: 8,
            status: 'failed'
        },
        {
            tabName: 'Error Request',
            order: 9,
            status: 'pass'
        }
    ]
    const updateStateHandler = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selectedTabOrder: tab_order,
        }));
    }

    return (
        <div className={classes.root}>
            <div className='font-16 font-weight-500 mb-20' style={{ color: '#2f2f2f' }}>
                Example-config <span className='metric-chip'>23 METRICS</span>
            </div>
            <div className='header-section mb-20'>
                <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                    <p className='text-anchor-blue font-12 font-weight-600'>BASELINE</p>
                    <div className='font-12' >
                        <span className='key' style={{ color: '#787878' }}>scope: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>Myapp--baseline</span>
                        &nbsp;&nbsp;
                        <span className='key' style={{ color: '#787878' }}>Location: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>India East 1</span>
                    </div>
                </div>
                <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                    <p className='text-anchor-blue font-12 font-weight-600'>CANARY</p>
                    <div className='font-12' >
                        <span className='key' style={{ color: '#787878' }}>scope: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>Myapp--baseline</span>
                        &nbsp;&nbsp;
                        <span className='key' style={{ color: '#787878' }}>Location: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>India East 1</span>
                    </div>
                </div>
                <div>
                    <p className='text-anchor-blue font-12 font-weight-600'>THRESHOLD</p>
                    <div className='font-12' >
                        <span className='key' style={{ color: '#787878' }}>scope: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>Myapp--baseline</span>
                        &nbsp;&nbsp;
                        <span className='key' style={{ color: '#787878' }}>Location: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>India East 1</span>
                    </div>
                </div>
            </div>
            <div className="canary_analysis_card">
                <div className='canary_analysis_card_header'>
                    <div className='d-flex align-center font-12 space-between ' style={{ width: '100%' }}>
                        <p style={{ color: '#505050' }}>METRICS</p>
                        <p style={{ color: '#505050' }}>RESULTS</p>
                    </div>
                    <p className='ml-auto font-12'>
                        <span className='key' style={{ color: '#787878' }}>Start Time: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>24-01-22</span>&nbsp;&nbsp;
                        <span className='key' style={{ color: '#787878' }}>End Time: </span>
                        <span className='value' style={{ color: '#2f2f2f' }}>24-01-22</span>
                    </p>
                </div>
                <div className='canary_analysis_card_body'>
                    <div className='canary_analysis_card_body_particals'>
                        <CommonHorizontalTab
                         tabList={statusList}
                         variant="canary_analysis_report_tab"
                         selectedTabOrder={state.selectedTabOrder}
                         updateSelectedTab={updateStateHandler}
                        />
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={barChartOptions} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CanaryAnalysisReport

const useStyles = makeStyles(() => ({
    root: {
        padding: 20,
        '& .metric-chip': {
            backgroundColor: '#0086ff',
            color: '#fff',
            borderRadius: '4px',
            padding: '4px 6px',
            fontSize: 12,
            fontWeight: 500,
        },
        '& .header-section': {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            borderRadius: 4
        },
        '& .canary_analysis_card': {
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)"
        },
        '& .canary_analysis_card_header': {
            borderBottom: '1px solid #E5E5E5',
            backgroundColor: '#F5FAFF',
            padding: '12px',
            display: 'grid',
            gridTemplateColumns: '284px 1fr'
        },
        '& .canary_analysis_card_body': {
            display: 'grid',
            gridTemplateColumns: '284px 1fr'
        },
        '& .canary_analysis_card_body_particals':{
            height: '45rem',
            overflowY: 'scroll'
        },
        '& .success_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#129E5B',
            backgroundColor: '#E6FBEA',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .failed_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#C11212',
            backgroundColor: '#FFEBEB',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .canary_analysis_card_body_particals_row':{
            cursor:'pointer',
            '&.active':{
                backgroundColor:'#DFEDFF',
                '&:hover':{
                    backgroundColor:'#DFEDFF'
                }
            },
            '&:hover':{
                backgroundColor:'#fafafa'
            }
        }
    }
}));