import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { Grid } from '@material-ui/core';

import GitRepo, { GitRepoDefaultState } from '../../../../components/genericComponents/Forms/GitRepo';
import FileUpload, { FileUploadDefaultState } from '../../../../components/genericComponents/Forms/FileUpload';

import { getCommonFunctions, ResetComponent } from './SourceDetails';
import MultiRow from '../../../../components/genericComponents/MultiRow';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
const ServiceHooks = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getDefaultServiceHookState());
    const hookType = props.extraProps ? props.extraProps.hookType : "";
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const type = props.type;


    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state }))

    }, [prev_state])


    console.log(state,"dskbfhsbfjsd")

    return (
        <div className={type == "manifest"? "" :'ci-cd-edit-input-div'}>
            {
                type != "manifest" &&
                <Grid item lg={12} direction="row" className="card-add-service-head" style={{ borderRadius: '0px', borderTop: '1px solid #dedede' }}>
                    <div className="formTag">
                        <h4 className='mainHeading'>Hooks Details</h4>
                    </div>
                </Grid>
            }
            <div className={type == "manifest" ? classes.manifest : classes.root}>
                <div className="card">
                    <div className='heading'>
                        <Input
                            type="switch"
                            name="pre_hook_enabled"
                            label={"Do you have " + hookType + " pre hooks?"}
                            onChangeHandler={commonFunctions.toggleState}
                            data={state.data}
                            error={state.error}
                            enabledComponent={<AddPreHooks prev_state={state.add_pre_hook} inherits={state.child_inherits.add_pre_hook} />}
                            disabledComponent={<ResetComponent inherits={state.child_inherits.add_pre_hook} />} />
                    </div>
                </div>
                <div className="card" style={type == "manifest" ? {marginTop : "20px"} : {}}>
                    <div className='heading'>
                        <Input
                            type="switch"
                            name="post_hook_enabled"
                            label={"Do you have " + hookType + " post hooks?"}
                            onChangeHandler={commonFunctions.toggleState}
                            data={state.data}
                            error={state.error}
                            enabledComponent={<AddPostHooks prev_state={state.add_post_hook} inherits={state.child_inherits.add_post_hook} />}
                            disabledComponent={<ResetComponent inherits={state.child_inherits.add_post_hook} />}
                        />
                    </div>
                </div>
                {/* <Grid item lg={12} style={{marginTop : "20px", marginLeft : "10px", marginRight : "10px"}}>
                    <Input
                        type="select"
                        placeholder="queue_name"
                        list = {queueList}
                        name="queue_name"
                        // mandatorySign
                        label="Queue Name"
                        error={state.error}
                        onChangeHandler={commonFunctions.onChangeHandler}
                        data={state.data} />

                </Grid> */}
            </div>
        </div>
    )
}
ServiceHooks.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ServiceHooks;

export function getDefaultServiceHookState() {
    return {
        data: {
            pre_hook_enabled: false,
            post_hook_enabled: false
        },
        error: {},
        child_inherits: {
            add_pre_hook: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            add_post_hook: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        },
        validations: {}
    }
}

function AddPostHookHeader() {
    return (
        <Grid item lg={11}>
            <p className="text-black" style={{ verticalAlign: 'baseline' }}>Add Post Hook<span className="hint-text">(You can add multiple)</span></p>
        </Grid>
    )
}

export function getAddPostHookState() {
    return {
        child_inherits: {
            post_hook_childs: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
    }
}

export function AddPostHooks(props) {
    const prev_state = props.prev_state ? props.prev_state : null
    console.log(prev_state, "fdsakj")
    const [state, setState] = useState(prev_state ? prev_state : getAddPostHookState)
    const commonFunctions = getCommonFunctions(state, setState, props.inherits)
    const [commandVisible, setCommandVisible] = useState(false)

    useEffect(() => {
        loadValueVisibilityStatus()
    }, [prev_state])

    function loadValueVisibilityStatus() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.getCommandHookVisibility),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        console.log('req_0001', requestInfo);
        InvokeApi(requestInfo, onSuccess, onFailure);

    }

    function onSuccess(response) {

        if (response) {
            let data = response['detail']
            setCommandVisible(!data['hook_command_hidden'])
        }
    }

    function onFailure(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data of this template " + " " + error
        }));
    }


    return (
        <div className="" style={{ backgroundColor: '#fff' }}>

            <MultiRow variant='reorder-version' repeatableComponentProps={{ isCommandVisible: commandVisible }} RepeatableComponent={HookChild} HeaderComponent={AddPostHookHeader} prev_state={state.post_hook_childs} inherits={state.child_inherits.post_hook_childs} zeroRowsAllowed={false} />
        </div>

    )
}
AddPostHooks.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function AddPreHookHeader() {
    return (
        <Grid item lg={11}>
            <p className="text-black" style={{ verticalAlign: 'baseline' }}>Add Pre Hook<span className="hint-text">(You can add multiple)</span></p>
        </Grid>
    )
}
export function getAddPreHookState() {
    return {
        child_inherits: {
            pre_hook_childs: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
    }
}

export function AddPreHooks(props) {
    const prev_state = props.prev_state ? props.prev_state : null
    const [state, setState] = useState(prev_state ? prev_state : getAddPreHookState()
    )
    const commonFunctions = getCommonFunctions(state, setState, props.inherits)
    const [commandVisible, setCommandVisible] = useState(false)

    useEffect(() => {
        loadValueVisibilityStatus()
    }, [prev_state])

    function loadValueVisibilityStatus() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.getCommandHookVisibility),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        console.log('req_0001', requestInfo);
        InvokeApi(requestInfo, onSuccess, onFailure);

    }

    function onSuccess(response) {

        if (response) {
            let data = response['detail']
            setCommandVisible(!data['hook_command_hidden'])
        }
    }

    function onFailure(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data of this template " + " " + error
        }));
    }


    return (
        <div className="" style={{ backgroundColor: '#fff' }}>

            <MultiRow variant='reorder-version' repeatableComponentProps={{ isCommandVisible: commandVisible }} RepeatableComponent={HookChild} HeaderComponent={AddPreHookHeader} prev_state={state.pre_hook_childs} inherits={state.child_inherits.pre_hook_childs} zeroRowsAllowed={false} />
        </div>
    )
}
AddPreHooks.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export function getDefaultChildHookstate() {
    return {
        data: {
            properties_files: "manual_command",
            command: "",
        },
        error: {},
        config_list: [
            { value: "manual_command", label: "Via Command" },
            { value: "fileupload", label: "Via File Upload" },
            { value: "vcs", label: "Via Git" }
        ],
        child_inherits: {
            gitRepo: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            },
            fileUpload: {
                "validateForm": () => { return { valid: true } },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }

        }

    }
}


const HookChild = ({ children, extraProps, ...props }) => {
    const { reorderIcon: ReOrderIcon } = props;
    const { isCommandVisible } = extraProps;
    const inherits = props.inherits ? props.inherits : {};
    const data = props.data ? props.data : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state ? prev_state : getDefaultChildHookstate())
    const commonFunctions = getCommonFunctions(state, setState, inherits)

    function getFileUploadState() {
        var state_temp = FileUploadDefaultState();
        if (state.fileUpload) {
            state.fileUpload.files && state.fileUpload.files.forEach(file_name => {

                if (typeof (file_name) == "string") {
                    state_temp.form_data.data.files.push({
                        name: file_name
                    });
                    state_temp.form_data.data.cmd_agrs = state.fileUpload.cmd_agrs && state.fileUpload.cmd_agrs.length > 0 ? state.fileUpload.cmd_agrs : []
                } else {
                    state_temp.form_data.data.files.push(file_name);
                    // state_temp.form_data.data.cmd_agrs= state.fileUpload.cmd_agrs&& state.fileUpload.cmd_agrs.length > 0? state.fileUpload.cmd_agrs : []
                }
            });
        }
        return state_temp;
    }

    function getGitRepoState() {
        var state_temp = GitRepoDefaultState();
        if (state.gitRepo) {
            state_temp.form_data.data = { ...state_temp.form_data.data, ...state.gitRepo };

            state_temp.branch_data.branches = state.gitRepo.branches
        }
        return state_temp;
    }
    return (
        <Grid item lg={12}>
            <div style={{ border: '1px solid #dedede', borderRadius: '4px', marginBottom: '10px', overflow: 'hidden', backgroundColor: '#ffffff' }}>
                <div style={{ backgroundColor: '#f4f4f4', height: '60px' }} className=" ds-comp sub-header d-flex align-center space-between">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '20px', cursor: 'pointer' }}>< ReOrderIcon /></div>
                        <Input
                            type="radio"
                            name="properties_files"
                            label=""
                            list={state.config_list}
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                            error={{}} />
                    </div>

                    <div>
                        {children}
                    </div>
                </div>
                <div style={{ padding: '35px 35px 35px 50px' }} className="pd-10">

                    {
                        state.data.properties_files == "fileupload" ?
                            <FileUpload with_command_args={true} inherits={state.child_inherits.fileUpload} varient="single" state={state.fileUpload ? state.fileUpload ? getFileUploadState() : null : null} />
                            : <ResetComponent inherits={state.child_inherits.fileUpload} />
                    }
                    {
                        state.data.properties_files == "vcs" ?
                            <>

                                <GitRepo with_command_args={true} inherits={state.child_inherits.gitRepo} state={state.gitRepo ? getGitRepoState() : null} single_path={true} />

                            </>
                            : <ResetComponent inherits={state.child_inherits.gitRepo} />}
                    {
                        state.data.properties_files == "manual_command" ?
                            <Input
                                type="hook-commands"
                                isHooksCommandsVisible={isCommandVisible}
                                placeholder="Run Command"
                                label="Command"
                                mandatorySign
                                name="command"
                                rows={1}
                                data={state.data}
                                error={state.error}
                                onChangeHandler={commonFunctions.onChangeHandler}
                            /> : null}
                </div>
            </div>
        </Grid>
    )
}
HookChild.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export function hookDataParserForReorderComponent(data, action_type) {

    console.log(data, 'data_in_hook')
    var final_array = [];
    var order = 0;
    data && data.order_array.forEach(hook => {
        final_array = [...final_array, {
            order: ++order,
            action_type: action_type,
            manifest_meta_data: !(data[hook].data.properties_files == "manual_command") ? {
                strategy: data[hook].data.properties_files == "fileupload" ? "UPLOADED_MANIFEST" : "GIT_MANIFEST",
                manifest_file_paths: data[hook].data.properties_files == "fileupload" ? data[hook].fileUpload.files : (data[hook].data.properties_files == "vcs") ? [data[hook].gitRepo.file_paths] : null,
                manifest_git_repo_id: data[hook].gitRepo.git_url,
                manifest_git_repo_branch: data[hook].gitRepo.branch_name,
                manifest_git_repo: data[hook].data.properties_files == "vcs" ? {
                    git_url: data[hook].gitRepo.git_url,
                    branch_name: data[hook].gitRepo.branch_name,
                    credential_id: data[hook].gitRepo.credential_id,
                    file_paths: [data[hook].gitRepo.file_paths],

                } : null,
                key_value_pair: data[hook].data.properties_files == "fileupload" ? {
                    cmd_agrs: data[hook].fileUpload.cmd_agrs
                } : { cmd_agrs: data[hook].gitRepo.cmd_agrs }
            } : null,
            command: data[hook].data.properties_files == "manual_command" ? data[hook].data.command : null

        }]
    })
    return final_array;
}

export function hookDataParser(data, action_type) {

    console.log(data, 'data_in_hook')
    var final_array = new Array();
    var order = 0;
    Object.keys(data).forEach(hook => {
        if (hook == "data" || hook == "child_inherits" || hook == 'show_view' || hook == "count" || hook == "order_array") { }
        else {
            final_array = [...final_array, {
                order: ++order,
                action_type: action_type,
                manifest_meta_data: !(data[hook].data.properties_files == "manual_command") ? {
                    strategy: data[hook].data.properties_files == "fileupload" ? "UPLOADED_MANIFEST" : "GIT_MANIFEST",
                    manifest_file_paths: data[hook].data.properties_files == "fileupload" ? data[hook].fileUpload.files : (data[hook].data.properties_files == "vcs") ? [data[hook].gitRepo.file_paths] : null,
                    manifest_git_repo: data[hook].data.properties_files == "vcs" ? {
                        git_url: data[hook].gitRepo.git_url,
                        branch_name: data[hook].gitRepo.branch_name,
                        credential_id: data[hook].gitRepo.credential_id,
                        file_paths: [data[hook].gitRepo.file_paths],

                    } : null,
                    key_value_pair: data[hook].data.properties_files == "fileupload" ? {
                        cmd_agrs: data[hook].fileUpload.cmd_agrs
                    } : { cmd_agrs: data[hook].gitRepo.cmd_agrs }
                } : null,
                command: data[hook].data.properties_files == "manual_command" ? data[hook].data.command : null

            }]

        }

    })
    return final_array;
}

export function hooksEditDataParser(data, type) {
    console.log(data,type,"info_from_parent")
    var final_pre_hook = {};
    var final_post_hook = {};
    var pre_counter = 0;
    var post_counter = 0;
    if (data) {
        if (type == "PRE") {
            Object.keys(data).forEach(hook => {
                if (data[hook].action_type == "PRE")
                    var final_list = data[hook].manifest_meta_data && data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" ? data[hook].manifest_meta_data.key_value_pair ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs &&
                        data[hook].manifest_meta_data.key_value_pair.cmd_agrs.length > 0 ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs : null
                        : null : null;
                final_pre_hook = {
                    ...final_pre_hook,
                    [++pre_counter]: {
                        ...getDefaultChildHookstate(),
                        ...{
                            data: {
                                command: data[hook].command,
                                properties_files: data[hook].command ? "manual_command" : data[hook].manifest_meta_data ? data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" ? "fileupload" : "vcs" : "manual_command"
                            },

                            fileUpload: data[hook].manifest_meta_data && data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" ? {
                                files: data[hook].manifest_meta_data.manifest_file_paths[0] ? [data[hook].manifest_meta_data.manifest_file_paths[0]] : [],
                                cmd_agrs: final_list
                            } : {},
                            gitRepo: data[hook].manifest_meta_data ? data[hook].manifest_meta_data.manifest_git_repo ? {
                                branch_name: data[hook].manifest_meta_data.manifest_git_repo_branch,
                                credential_id: data[hook].manifest_meta_data.manifest_git_repo.credential_id ?
                                    data[hook].manifest_meta_data.manifest_git_repo.credential_id : null,
                                file_paths: data[hook].manifest_meta_data.manifest_file_paths[0],
                                git_url: data[hook].manifest_meta_data.manifest_git_repo_id,
                                cmd_agrs: data[hook].manifest_meta_data.key_value_pair ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs &&
                                    data[hook].manifest_meta_data.key_value_pair.cmd_agrs.length > 0 ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs : null
                                    : null,
                                branches: []
                            } : {} : {}
                        }
                    }
                }
            })
            console.log(final_pre_hook,"final_pre_hook")
            return final_pre_hook;
        } else {
            Object.keys(data).forEach(hook => {
                if (data[hook].action_type == "POST")
                    final_post_hook = {
                        ...final_post_hook,
                        [++post_counter]: {
                            ...getDefaultChildHookstate(),
                            ...{
                                data: {
                                    command: data[hook].command,
                                    properties_files: data[hook].command ? "manual_command" : data[hook].manifest_meta_data ? data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" ? "fileupload" : "vcs" : "manual_command"
                                },

                                fileUpload: data[hook].manifest_meta_data ? {
                                    files: data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" && data[hook].manifest_meta_data.manifest_file_paths[0] ? [data[hook].manifest_meta_data.manifest_file_paths[0]] : [],
                                    cmd_agrs: data[hook].manifest_meta_data.strategy == "UPLOADED_MANIFEST" ?
                                        data[hook].manifest_meta_data.key_value_pair ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs &&
                                            data[hook].manifest_meta_data.key_value_pair.cmd_agrs.length > 0 ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs : null
                                            : null : null
                                } : {},
                                gitRepo: data[hook].manifest_meta_data ? data[hook].manifest_meta_data.manifest_git_repo ? {
                                    branch_name: data[hook].manifest_meta_data.manifest_git_repo_branch ? data[hook].manifest_meta_data.manifest_git_repo_branch : "",
                                    credential_id: data[hook].manifest_meta_data.manifest_git_repo.credential_id ?
                                        data[hook].manifest_meta_data.manifest_git_repo.credential_id : null,
                                    file_paths: data[hook].manifest_meta_data.manifest_file_paths[0],
                                    git_url: data[hook].manifest_meta_data.manifest_git_repo_id,
                                    cmd_agrs: data[hook].manifest_meta_data.key_value_pair ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs &&
                                        data[hook].manifest_meta_data.key_value_pair.cmd_agrs.length > 0 ? data[hook].manifest_meta_data.key_value_pair.cmd_agrs : null
                                        : null,
                                    branches: []
                                } : {} : {}
                            }
                        }
                    }
            })
            console.log(final_post_hook,"final_post_hook")
            return final_post_hook;

        }
    }
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px',
        backgroundColor: '#fff',
        // height: "230px",
        // overflowY: "auto",

        '&>.card': {
            marginTop: '10px',
            borderRadius: '8px!important',
            border: '1px solid #dedede!important',
            '& .card-header': {
                fontSize: '12px'
            },
            '& .pd-20': {
                padding: '10px 20px!important'
            },
            '& .with-header': {
                borderTop: '1px solid #dedede'
            },
            '& .card': {

                '& .image-upload-wrap': {
                    height: '40px'
                }
            }
        },
        '& .sub-header': {
            padding: '5px 10px',
            borderBottom: '1px solid #dedede',
            backgroundColor: '#f6f8f8',
            fontSize: '12px',
            '& .input-component': {
                marginBottom: '0px'
            }
        },
        '& .card-controller': {
            '& .card': {
                borderRadius: '8px'
            },
            '& .makeStyles-cardHeader-228': {
                padding: '5px 10px'
            }
        }
    },


    manifest: {
        // padding: '10px',
        marginBottom : "10px",
        backgroundColor: '#fff',
        // height: "230px",
        // overflowY: "auto",

        '&>.card': {
            marginTop: '10px',
            borderRadius: '8px!important',
            border: '1px solid #dedede!important',
            '& .card-header': {
                fontSize: '12px'
            },
            '& .pd-20': {
                padding: '10px 20px!important'
            },
            '& .with-header': {
                borderTop: '1px solid #dedede'
            },
            '& .card': {

                '& .image-upload-wrap': {
                    height: '40px'
                }
            }
        },
        '& .sub-header': {
            padding: '5px 10px',
            borderBottom: '1px solid #dedede',
            backgroundColor: '#f6f8f8',
            fontSize: '12px',
            '& .input-component': {
                marginBottom: '0px'
            }
        },
        '& .card-controller': {
            '& .card': {
                borderRadius: '8px'
            },
            '& .makeStyles-cardHeader-228': {
                padding: '5px 10px'
            }
        }
    },
}));
