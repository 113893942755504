import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import {GetAuth} from '../../../util/security';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '64px',
        '& .welcome-text':{
            display:'block!important',
            height:'auto!important'
        }
    },
    helloText:{
        fontSize:'36px',
        fontFamily:'nunito',
        fontWeight: 900,
        color:'#5262c8 '
    },
    paraWithButton:{
        display:' flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    textPara:{
        fontSize:'20px',
        fontWeight:'700',
        fontFamily:'nunito',
        lineHeight: '1.5',
        color:'#525a8c'
    }
  }));


const ApplicationSelector = (props) =>{
    const classes = useStyles();
    const application = props.application;
    const application_ids = Object.keys(application);
    const [state,setState] = useState({
        sectionOpen:false,
        selected_application:application_ids[0]
    });

    const auth = GetAuth();

    const toggleSectionOpen = () => {
        setState({
            ...state,
            sectionOpen:!state.sectionOpen
        })
    }  
    return(
        <div className={classes.root}>
        <div className="welcome-text">
            <h1 className={classes.helloText}>Hello {auth.name}!</h1>
            <div className={classes.paraWithButton}>
                <div className={classes.textPara}>
                    To get started you need to onboard your first microservice<br />
                    Its quick and easy with just two simple steps
                </div>
            </div>
        </div>
        <p className="heading-app-selector">Select your App</p>
        <div className="select-application">
        <div className="custom-drop">
            <div className="selected">
                <div className="container-dropdown">
                <SquareAvatar name={application[state.selected_application].name} />
                <div className="service-name-dropdown">{application[state.selected_application].name}</div>
                </div>
                <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={toggleSectionOpen}  className={state.sectionOpen ? "m-l-auto flaticon-expand-arrow" : "m-l-auto flaticon-downwards-arrow-key"}></span>
            </div>
            {
                state.sectionOpen ?
            <div className="custom-drop-down-list">
                <div className="options">
                    {
                        application_ids.map((id)=>(
                            id != state.selected_application ? <div role='button' tabIndex={0} onKeyDown={()=>{}} onClick={()=>{setState({...state,selected_application:id})}} className="option">
                            <SquareAvatar name={application[id].name} />
                            <div className="service-name-dropdown">{application[id].name}</div>
                        </div>:null
    ))
                    }
                </div>
            </div>: null}
        </div>
        <Link to={"/application/"+state.selected_application+"/service/add"} className="btn-blue-next btn-lg height-57 ml-20">Continue <span className="flaticon-keyboard-right-arrow-button"></span></Link>
        </div>

    </div>
    )
}

ApplicationSelector.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default ApplicationSelector;