import React from "react";
import PropTypes from 'prop-types';
import { CircularMeterList } from "./CircularMeter";
import { CircularMeterListNew } from "./CircularMeterNew";

const HealthStatusRoundMeter = (props) => {
    const useNewMeter = props.useNewMeter ? props.useNewMeter : false;

    const data = getResourceQuotaHealth(props.data);


    function getColor(percentage) {
        if (percentage == "normal") {
            return "#129E5B"
        }
        if (percentage  == "warning") {
            return "#FEA111"
        }
        if (percentage  == "danger") {
            return "#C11212"
        }
        return "#008000"
    }

    function getResourceQuotaHealth(data) {

        const array = data;
        const keys = Object.keys(array);
        let resourceQuotaList = [];
        for (let index = 0; index < keys.length; index++) {
            const element = array[keys[index]];
            const resourceQuota = {
                label:keys[index].charAt(0).toUpperCase() + keys[index].slice(1),
                percentage: element.percentage,
                color: getColor(element.status),
                allocatedLabel: "Utilized/Allocated",
                allocatedValue: element.utilization+"/"+element.allocated,
                

            }
            resourceQuotaList.push(resourceQuota);

        }

        return resourceQuotaList;
    }


    return (
        <>
            {
                useNewMeter ? 
                    <CircularMeterListNew list={data} />    
                :
                
                    <CircularMeterList list={data} />
            }
        </>
        
        
    )
}

HealthStatusRoundMeter.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default HealthStatusRoundMeter;