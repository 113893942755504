import React, { useState } from 'react'
import PropTypes from 'prop-types';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AlphaThumbnail from '../../../../components/genericComponents/AlphaThumbnail'
import { Link } from 'react-router-dom';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Delete from '../../../../components/genericComponents/Delete';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { GetAuth } from '../../../../util/security';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Zoom from '@material-ui/core/Zoom';
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TopSummary = ({ isMenuOpen, handleMenuClick, onCloseMenu, anchorEl, selectedServiceEnv, afterDeleteEnvRefresh, ...props }) => {
    const [open, setOpen] = useState(false)
    const topSummaryData = props.data;
    const serviceName = topSummaryData.serviceName;
    const appName = topSummaryData.appName;
    var masterEnvList = [];
    const service_id = props.service_id
    const envs = topSummaryData.envs;
    const edit = props.edit;
    const application_id = props.application_id;
    var masterEnvList = [];
    var url = GenerateURL({ service_id: service_id }, properties.api.service_basic_details_get_url, true);
    const is_permitted = GetAuth().permission.POST.includes(url);

    const editEnvUrl = GenerateURL({ service_id: service_id }, properties.api.env_data_post_url, true);
    const is_env_permitted = GetAuth().permission.POST.includes(editEnvUrl);
    const history = useHistory();
    if (envs) {
        Object.keys(envs).forEach((key) => {
            var env = envs[key];
            masterEnvList.push(
                <>
                    <span>{env.name} &nbsp;</span>
                    <span className="text__box-main">Sub Env:&nbsp;</span>
                    {Object.keys(env.sub_env).length}
                    <span>&nbsp;</span>
                </>
            )
        })
    }
    console.log(isMenuOpen, 'dg_pp')

    console.log('xmp_0mkl', selectedServiceEnv)
    return (
        <div style={{ padding: '10px 24px 0px 12px' }} className="d-flex align-center space-between mb-20">
            <div className="heading-section-env">
                <AlphaThumbnail smallSize="small" variant={"double"} alphabet={serviceName} thumbnailStatus="green" />
                <div className="text-icon-section">
                    <div className="d-flex align-center">
                        <div style={{//styleName: Captions/Caption1-Bold;
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textAlign: 'left',
                            color: '#2F2F2F',
                            textTransform: 'uppercase'
                        }} className="lh-12 mr-5">
                            {/* {serviceName} */}
                            {serviceName.length > 30 ?
                                <Tooltip title={serviceName.toString()} TransitionComponent={Zoom}>
                                    <span>{serviceName.substring(0, 30) + "..."}</span>
                                </Tooltip>

                                :
                                serviceName
                            }
                        </div>
                        {/* {
                            is_permitted ?
                                <Link
                                    to={{
                                        pathname: "/application/" + application_id + "/service/" + service_id + "/edit",
                                        // state: { edit: true }
                                    }}
                                >
                                    <button className="btn btn-with-icon btn-round" >
                                        <BorderColorIcon className="" />
                                    </button>
                                </Link> :
                                
                                    <Tooltip title="You are not allowed to perform this action">
                                        <button className="btn btn-with-icon btn-round" >
                                            <BorderColorIcon style={{ color: '#818078' }} />
                                        </button>
                                    </Tooltip>
                                </>
                        } */}

                    </div>

                    <div className="d-flex align-center">
                        {/* <PersonIcon className="mr-3" style={{color:'#979fa7', fontSize:13}} /> */}
                        <span className="font-13 text-grey-83">Project:&nbsp;</span>
                        <span className="font-13 text-grey-b6">{appName}</span>
                        {/* <FavoriteBorderIcon className="d-inline-block mr-3 ml-3 font-17 text-grey-icon" /> */}
                        {/* <span className="font-11 text-grey-83">&nbsp;Health: </span>
                        <span className="font-11 text-grey-b6">&nbsp; N/A </span>
                        <a className="text-anchor-blue" href="#">&nbsp;Check Status</a> */}
                    </div>
                    {/* <div className="owner-name sub-heading-text">
                    <span style={{display:'none'}}>
                    <span className="text__box-main">Env: </span>
                    {masterEnvList.length > 0 ? <span className="text__box-sub">{masterEnvList}</span> : <span className="text__box-sub">No Env Added</span> }
                    </span>
                    
                </div> */}
                </div>
            </div>
            {
                is_permitted &&
                <>
                    <div style={{ position: 'absolute', right: '16px', top: '15px' }} className="d-flex align-centre">
                        <button
                            className="transparent-btn nowrap"
                            onClick={handleMenuClick}
                        >
                            <MoreVertOutlinedIcon style={{ color: 'black', fontSize: '24px' }} />
                        </button>
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        id="long-menu"
                        keepMounted
                        open={isMenuOpen}
                        onClose={onCloseMenu}
                        PaperProps={{
                            style: {
                                maxHeight: '200px',
                                width: "180px",
                                backgroundColor: "#f0f7f7",
                            },
                        }}
                    >
                        <div>
                            <MenuItem
                                style={{padding:'0px'}}
                                onClick={() => { history.push("/application/" + application_id + "/service/" + service_id + "/edit") }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                    <BorderColorIcon
                                        style={{ marginRight: "8px", color: "#3696db" }}
                                    />
                                    <label style={{ fontSize: "14px", cursor: "pointer" }}>Edit Service </label>
                                </div>
                            </MenuItem>
                            <MenuItem
                                style={{padding:'0px'}}
                                onClick={() => { }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                    {
                                        is_permitted ?

                                            <Delete
                                                display_data_name={serviceName}
                                                varient="service-summary"
                                                data={{ entity_id: service_id, name: "component" }}
                                                refresh={() => { props.refresh() }}
                                                api_link={GenerateURL({ service_id: service_id }, properties.api.service_basic_details_update_url)}
                                                is_edit_permited={is_permitted}
                                            />
                                            :
                                            <>
                                                <Tooltip title="You are not allowed to perform this action">
                                                    <IconButton className="btn btn-with-icon btn-round" >
                                                        <DeleteIcon style={{ color: '#818078' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </MenuItem>
                            <MenuItem
                                style={{padding:'0px'}}
                                onClick={() => {
                                    history.push({
                                        pathname: "/application/" + application_id + "/service/" + service_id + "/env/edit",
                                        state: { edit: true, env_id: selectedServiceEnv?.id }
                                    })
                                }}
                            >
                                {
                                    is_env_permitted ?
                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                            <BorderColorIcon
                                                style={{ marginRight: "8px", color: "#3696db" }}
                                            />
                                            <label style={{ fontSize: "14px", cursor: "pointer" }}>Edit Environment </label>
                                        </div>
                                        :
                                        <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                            <Tooltip title="You are not allowed to perform this action" >
                                                {/* <CreateIcon style={{ color: '#818078' }} /> */}
                                                <button className='btn-sq-icon-primary'>
                                                    <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                                </button>
                                            </Tooltip>
                                        </div>
                                }

                            </MenuItem>
                            <MenuItem
                                style={{padding:'0px'}}
                                onClick={() => { }}
                            >
                                <div className='menu-list-service' style={{ display: "flex", alignItems: 'center', width: "100%" }}>

                                    {
                                        is_env_permitted ?

                                            <Delete
                                                varient="service-summary-env"
                                                display_data_name={selectedServiceEnv ? selectedServiceEnv?.project_env?.name : ""}
                                                data={{ entity_id: selectedServiceEnv?.id, name: "component_env" }}
                                                fontSize="font-18"
                                                refresh={() => { afterDeleteEnvRefresh(service_id) }}
                                                api_link={GenerateURL({ service_id: service_id, env_id: selectedServiceEnv?.id }, properties.api.env_data_update_url)}
                                            />
                                            :
                                            <>
                                                <Tooltip title="You are not allowed to perform this action">
                                                    <IconButton className="btn btn-with-icon btn-round" >
                                                        <DeleteIcon style={{ color: '#818078' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                    }
                                </div>

                            </MenuItem>
                        </div>

                    </Menu>

                </>

            }

        </div >
    )
}

TopSummary.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default TopSummary