import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import { getListableArray } from '../../../../util/util';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import AlertStrip from '../../../../components/AlertStrips';
import { getSelectedEnv } from '../../../serviceRevamp/add/cd_flow/CdInfo';
import { Loading } from '../../../utils/Loading';
import { makeStyles } from '@material-ui/core/styles';
import AdvanceSearchFilterCombo from '../../../../components/genericComponents/AdvanceSearchFilter/AdvanceSearchFilterCombo';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OnGoingActivity from '../../../superAdmin/activityFramework/OnGoingActivity';
import OverrideVariablesForNonDependentJobs from './OverrideVariablesForNonDependentJobs';
import { Checkbox } from "@material-ui/core";
import { error } from 'highcharts';




const defaultStateForEnvForm = (key, value) => {
    return {
        is_value_from_output_var: false,
        [key]: value,
        outputForm: {
            previous_job: null,
            step: null,
            output_var: null,
            stage: null,
            task_list: null,
            step_list: null,
            step_order: null,
            output_var_list: null,
            outputVariableMapping: false
        }
    }
}

const AddVersionThreeJobs = ({
    job_templates,
    errors,
    prevState,
    classes,
    hideBottomSaveContainer,
    validateAndSaveJobForVersion3,
    stages,
    job_index,
    stage_index,
    onSaveParamsForm,
    editRuntimeFormState,
    onlyIncreaseResetCount,
    editResetCount,
    jobJsonData,
    setJobJson,
    independentJobData,
    setParentState,
    editFlowJobStepJson,
    resetStateOfUpdatedEnv,
    ...props }) => {

    const inherits = props.inherits ? props.inherits : {};

    const prevStateForEdit = prevState ? prevState : {};
    console.log(prevStateForEdit, 'edit_00p_lmn')
    const runTimeParamClasses = useStyles();

    const ModifiableJobs = [
        {
            "order": 0,
            "step_code": "CLONING_REPOSITORY",
            "step_name": "Clone Repository",
            "environment_variables": [
                {
                    "key": "GIT_BRANCH",
                    "value": "elasticache",
                    "is_trigger_time_modify": true
                },
                {
                    "key": "URL",
                    "value": "/heatlth",
                    "input_type": "text",
                    "is_required": true,
                    "default_value": "plan",
                    "does_default_active": "Y",
                    "is_trigger_time_modify": true,
                }
            ]
        },
        {
            "order": 1,
            "step_code": "AWS_EC2_STEP",
            "step_name": "AWS EC2 STEP",
            "environment_variables": [
                {
                    "key": "INSTRUCTION",
                    "value": "plan",
                    "input_type": "select",
                    "is_required": true,
                    "default_value": "plan",
                    "does_default_active": "Y",
                    "is_trigger_time_modify": true,
                    "select_dropdown_options": "plan,apply,destroy"
                }
            ]
        }
    ]
    const [state, setState] = useState(
        {
            data: { ...prevStateForEdit },
            error: {},
            all_services: [],
            version_three_templates: [],
            job_name_list: [],
            stepsOfSelectedJob: [],
            loading_components: false,
            dataForRuntimeParamsOfComponents: {              // component_id :  {stepName: value , selectedFromJObValue : true or false, }

            },
            validations: {
                env: [VALIDATION_TYPE_REQUIRED],
                project_job_template_id: [VALIDATION_TYPE_REQUIRED],
                task_type: [VALIDATION_TYPE_REQUIRED],
                // components: [VALIDATION_TYPE_REQUIRED]
            },
            runTimeParamsFormState: {
                resetCount: 0,
                isAdvanceRunTimeParamsForm: false,
                updatedRuntimeParams: null,
                varFormStateAccordingToStepsAndServices: {},
                loaderVarFormStateAccordingToStepsAndServices: {},
                staticComponentList: [
                ],
                runTimeModifiableVariables: {},
                selectedServicesForParams: [],
                selectedTabOrder: null,
                modeType: null,
                loading: false,
                tabList: [

                ],
                globalLevelEdit: {
                    isEditActive: prevStateForEdit?.output_variable_mapping ? true : false,
                    dataForEdit: prevStateForEdit?.output_variable_mapping,
                    resetCount: 0
                }
            }
        }
    )
    console.log(state, 'fcuk')

    useEffect(() => {
        // if (editRuntimeFormState) {
        //     setState(prevState => ({
        //         ...prevState,
        //         runTimeParamsFormState: { ...editRuntimeFormState }
        //     }))
        // }
        // else
        // setState(prevState => ({
        //     ...prevState,
        //     runTimeParamsFormState: {
        //         ...prevState.runTimeParamsFormState,
        //         tabList: [{ tabName: 'All', order: '00' }],
        //         selectedServicesForParams: ['00'],
        //         selectedTabOrder: '00'
        //     }
        // }))
    }, [editRuntimeFormState, editResetCount])


    useEffect(() => {
        //change3

        // const envValue = state.data?.env
        // if (envValue !== '' && envValue != null) {

        //     const selectedEnv = getSelectedEnv(envValue, props.env_list)

        //     setState(prevState => ({
        //         ...prevState,

        //         data: {
        //             ...prevState.data,
        //             selected_project_env: selectedEnv,
        //             project_job_template_id: ''
        //         }
        //     }))
        // }
        // else
        //     setState(prevState => ({
        //         ...prevState,
        //         selected_project_env: null,
        //         data: { ...prevState.data, project_job_template_id: '' }
        //     }))

    }, [state.data?.env])

    useEffect(() => {

        // const outputVariableMapping = prevStateForEdit?.output_variable_mapping
        const outputVariableMapping = state.runTimeParamsFormState?.globalLevelEdit?.dataForEdit


        if (outputVariableMapping && state.data?.env != undefined) {
            let selectedServicesForParams = []
            let envId = state.data?.env

            let selectedTabOrder = null
            let foundAll = false
            let tabList = []
            if (envId !== '' && envId != null) {

                const selectedEnv = getSelectedEnv(envId, props.env_list)

                setState(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        selected_project_env: selectedEnv
                    }
                }))
            }
            Object.keys(outputVariableMapping)?.forEach(serviceName => {
                if (state.all_services?.length > 0) {
                    if (serviceName == 'all_0') {
                        selectedServicesForParams.push('00')
                        foundAll = true
                        selectedTabOrder = '00'
                        tabList.push({ order: '00', tabName: 'All' })
                        // setvarFormStateAccordingToStepsAndServices('all_0', outputVariableMapping)
                    }
                    else {
                        let serviceId = state.all_services?.find(service => service.label == serviceName)?.id
                        selectedServicesForParams.push(String(serviceId))
                        tabList.push({ order: serviceId, tabName: serviceName })
                        // const targetDataForEditFlow = {
                        //     stepKey: targetKey,
                        //     componentLabel: componentLabel
                        // }
                        // getEnvStateAccordingToEditMode(serviceId,targetDataForEditFlow)

                    }
                }
            })
            if (!foundAll) {
                if (selectedServicesForParams?.length > 0) {
                    selectedTabOrder = selectedServicesForParams[0]
                }
            }
            setState(prevState => ({ //1
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    selectedServicesForParams: selectedServicesForParams,
                    selectedTabOrder: selectedTabOrder,
                    tabList: tabList
                }
            }))
        }

    }, [state.all_services, state.runTimeParamsFormState?.globalLevelEdit?.resetCount])


    const getDependentStepListForEdit = (taskList, selectedTask) => {
        if (taskList) {
            const selectedTaskData = taskList?.find(task => task.order == selectedTask)
            const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
            const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
            const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs
            const stepsOfdataOfConfiguredJobTemplateInTargetTask = jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code)?.steps
            return stepsOfdataOfConfiguredJobTemplateInTargetTask
        }
    }

    const getDependentTaskListForEdit = (sourceValue) => {
        //fetching tasks 
        if (sourceValue != null || sourceValue != '') {
            const tasks = stages?.find(stage => stage.name == sourceValue)?.task
            const updatedTasks = tasks?.map((task, index) => {
                task['order'] = index + 1
                return task
            })
            return updatedTasks

        }

    }
    const getStepOutputVarsWithPromise = (stepCode) => {
        return new Promise(function (myResolve, myReject) {
            let myendpoint = GenerateURL({}, properties.api.stepCatalogs);
            var requestInfo = {
                endPoint: GenerateSearchURL({ step_code: stepCode, version: 'v3' }, myendpoint),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (response) => {
                if (response) {
                    const result = response.results
                    if (result.length > 0) {
                        const stepData = result[0]
                        const outPutVars = stepData.output_variables
                        myResolve(outPutVars)
                    }
                }
            }, (error) => { myReject(error) })
        });
    }

    function getEnvStateAccordingToEditMode(envVariableKey, targetMetaData) {

        const componentLabel = targetMetaData?.componentLabel
        const stepKey = targetMetaData?.stepKey
        let editStateData = state.runTimeParamsFormState?.globalLevelEdit?.dataForEdit
        if (editStateData[componentLabel] && editStateData[componentLabel][stepKey] && editStateData[componentLabel][stepKey][envVariableKey]) {
            const dataOfEnvVars = editStateData[componentLabel][stepKey][envVariableKey]

            const isValueFromOutput = dataOfEnvVars.is_value_from_output_var
            const value = dataOfEnvVars?.value

            if (!isValueFromOutput) {
                return {
                    is_value_from_output_var: false,
                    [envVariableKey]: value,
                    outputForm: {
                        previous_job: null,
                        step: null,
                        output_var: null,
                        stage: null,
                        task_list: null,
                        step_list: null,
                        step_order: null,
                        output_var_list: null,
                        ouputVariableLoading: false
                    }
                }
            }
            else {
                const values = value.split('.');
                const stage = values[1]; // "stage"
                const task = values[3]; // 2
                const serviceName = values[4]; // "image-list"
                const stepCode = values[5]; // "test_step_001"
                const outputVariable = values[6]; // "output_var_2.5"
                const stepOrder = values[7]
                let taskList = null
                let stepList = null
                let outputVariablesList = null
                if (stage != undefined) {
                    taskList = getDependentTaskListForEdit(stage)
                    if (taskList) {
                        stepList = getDependentStepListForEdit(taskList, task)
                    }
                    // getStepOutputVarsWithPromise(stepCode)

                    // getStepOutputVarsWithPromise(stepCode).then(result => {
                    //     console.log(result,'after_sppsd')
                    //     return {

                    //         is_value_from_output_var: true,
                    //         [envVariableKey]: value,
                    //         outputForm: {
                    //             previous_job: task,
                    //             step: stepCode,
                    //             output_var: outputVariable,
                    //             stage: stage,
                    //             task_list: taskList,
                    //             step_list: stepList,
                    //             step_order: stepOrder,
                    //             output_var_list: result,
                    //             ouputVariableLoading: false
                    //         }
                    //     }
                    // }).catch(error => {
                    //     return {

                    //         is_value_from_output_var: true,
                    //         [envVariableKey]: value,
                    //         outputForm: {
                    //             previous_job: task,
                    //             step: stepCode,
                    //             output_var: outputVariable,
                    //             stage: stage,
                    //             task_list: taskList,
                    //             step_list: stepList,
                    //             step_order: stepOrder,
                    //             output_var_list: null,
                    //             ouputVariableLoading: false
                    //         }
                    //     }
                    // })
                    return {
                        is_value_from_output_var: true,
                        [envVariableKey]: value,
                        outputForm: {
                            previous_job: task,
                            step: stepCode,
                            output_var: outputVariable,
                            stage: stage,
                            task_list: taskList,
                            step_list: stepList,
                            step_order: stepOrder,
                            output_var_list: null,
                            ouputVariableLoading: false
                        }
                    }

                }
                else {
                    return {
                        is_value_from_output_var: true,
                        [envVariableKey]: value,
                        outputForm: {
                            previous_job: task,
                            step: stepCode,
                            output_var: outputVariable,
                            stage: stage,
                            task_list: taskList,
                            step_list: stepList,
                            step_order: stepOrder,
                            output_var_list: null,
                            ouputVariableLoading: false
                        }
                    }
                }



            }
        }
        else
            return { ...defaultStateForEnvForm(envVariableKey, null) }

        // Object.keys(targetData)?.forEach(stepCode => {
        //     let allenvVarsObj = targetData[stepCode]
        //     Object.keys(allenvVarsObj)?.forEach(envVarKey => {
        //         let envData = allenvVarsObj[envVarKey]
        //         console.log('env_dt-2323', envData, envVarKey)
        //         let data = getStateForEnvForm(envVarKey, envData)
        //         allenvVarsObj[envVarKey] = data

        //     })
        // })



        // Object.keys(varformState)?.forEach(serviceKey => {
        //     if(serviceKey=='all_0'){
        //         let serviceObjStepEnvVars = {}
        //         const serviceData = varformState[serviceKey]
        //         Object.keys(serviceData)?.forEach(stepKey => {
        //             const envVariableOfSteps = serviceData[stepKey]
        //             Object.keys(envVariableOfSteps)?.forEach(envVariable => {
        //                 const dataOfEnvVars = envVariableOfSteps[envVariable]
        //                 console.log(dataOfEnvVars,'gre_2wewe')
        //                 const updatdData = getStateForEnvForm(envVariable, dataOfEnvVars)
        //                 envVariableOfSteps[envVariable]=updatdData
        //                 // console.log('upd_date2232332',updatdData)
        //             })
        //         })
        //     }
        // })

    }

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            error: errors ? errors : {}
        }))
    }, [errors])

    useEffect(() => {
        if (state.data.components) {
            let staticList = [{ value: "00", label: "All" }]
            let services = state.all_services
            let components = state.data.components
            let componentIdNameObject = {}
            components.forEach(component => {
                const id = component
                services?.forEach(service => {
                    if (service.id == id) {
                        staticList.push({ value: id, label: service.label, component_env: service.component_env })
                        componentIdNameObject[id] = service
                    }
                })
            })

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    staticComponentList: staticList,
                    componentIdNameObject: componentIdNameObject
                }
            }))
        }
    }, [state.data, state.all_services])

    useEffect(() => {
        if (state.data.project_job_template_id != null && state.data.project_job_template_id != '') {
            const selectedJobTemplate = job_templates && job_templates.find(template => template.id == state.data.project_job_template_id)
            const versionOfSelectedTemplate = selectedJobTemplate?.version
            let selectedTempalteJobs = []
            selectedJobTemplate?.job_template?.jobs?.forEach(({ job_code, job_name, is_dynamic }) => {
                if (versionOfSelectedTemplate == 'v2') {
                    if (is_dynamic) {
                        selectedTempalteJobs.push({ id: job_code, label: job_name })
                    }
                }
                else {
                    selectedTempalteJobs.push({ id: job_code, label: job_name })

                }
            })

            setState(prevState => ({
                ...prevState,
                job_name_list: selectedTempalteJobs?.length > 0 ? selectedTempalteJobs : [],
                versionOfSelectedTemplate: versionOfSelectedTemplate,
            }))

        }
        else
            setState(prevState => ({
                ...prevState,
                data: { ...prevState.data, project_job_template_id: '', task_type: '' },
                job_name_list: [],
                stepsOfSelectedJob: []
            }))

    }, [state.data.project_job_template_id, job_templates])

    useEffect(() => {
        if (state.data.task_type !== '' && state.data.task_type != null) { // fix here
            let selectedJObIndex = null
            let labelOfJob = ''
            let selectedSteps = null
            let selectedJob = null
            let jobDependetData = null

            state.job_name_list?.forEach((job, index) => {
                if (job.id === state.data.task_type) {
                    labelOfJob = job.label
                    selectedJObIndex = index
                }
            })

            const selectedTempalteJobs = job_templates && job_templates.find(template => template.id == state.data.project_job_template_id)?.job_template
            selectedTempalteJobs?.jobs.forEach((job, index) => {
                if (job.job_code === state.data.task_type) {
                    labelOfJob = job.label
                    selectedJObIndex = index
                }
            })

            // console.log(selectedTempalteJobs,selectedJObIndex, 'selecteed_jobs_2323223');
            if (selectedTempalteJobs) {

                selectedJob = selectedTempalteJobs['jobs'][selectedJObIndex]
                selectedSteps = selectedTempalteJobs['jobs'][selectedJObIndex]?.steps
                let dependentJob = (selectedJob?.is_dependent == true || selectedJob?.is_dependent == 'true')
                let dependent_entity
                if (dependentJob) {
                    dependent_entity = selectedJob?.dependent_entity
                }
                if (dependent_entity == "SERVICE" || true) {
                    jobDependetData = {   // fix here
                        "is_dependent": dependentJob ? true : false,
                        "dependent_entity": dependentJob ? dependent_entity : null,
                        "entity_resource_data": {
                            [dependent_entity]: [],
                            "is_overridden": true
                        },
                        jobData: selectedJob
                    }
                    if (!dependentJob) {
                        delete jobDependetData.entity_resource_data
                    }

                    setState(prevState => ({
                        ...prevState, data: { ...prevState.data, name: labelOfJob },
                        stepsOfSelectedJob: selectedSteps,
                    }))
                    setJobJson && setJobJson(jobDependetData)
                    dependent_entity && fetchServicesForTempalte(dependent_entity)
                }
            }
            else {
                // setJobJson({
                //     "is_dependent": false,
                //     "dependent_entity": "SERVICE",
                //     "entity_resource_data": {
                //         "SERVICE": [],
                //         "is_overridden": false
                //     }
                // })

                // setState(prevState => ({
                //     ...prevState,
                //     jobDepedent: {
                //         is_dependent_job: false,
                //         jobData: null,
                //         dependentEntity: null
                //     }
                // }))

            }
        }
        else {

            setState(prevState => ({
                ...prevState,
                all_services: [],
                data: {
                    ...prevState.data,
                    components: []
                }
            }))
        }
    }, [state.data.task_type])
    console.log('edit_00plmn', editRuntimeFormState, 'local_state', state.runTimeParamsFormState)

    useEffect(() => {         // it is responsible for the first time selecting all and values

        // !editRuntimeFormState && state.runTimeParamsFormState.isAdvanceRunTimeParamsForm
        if (!editRuntimeFormState && state.runTimeParamsFormState.isAdvanceRunTimeParamsForm && !state.runTimeParamsFormState?.globalLevelEdit?.isEditActive) {   //change1
            setState(prevState => ({ //2
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: [{ tabName: 'All', order: '00' }],
                    selectedServicesForParams: ['00'],
                    selectedTabOrder: '00'
                }
            }))
        }
        if (editRuntimeFormState) {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: { ...editRuntimeFormState, isAdvanceRunTimeParamsForm: state.runTimeParamsFormState.isAdvanceRunTimeParamsForm }
            }))
        }
    }, [state.runTimeParamsFormState.isAdvanceRunTimeParamsForm])

    useEffect(() => {

        if (state.runTimeParamsFormState?.selectedServicesForParams?.length > 0) {

            const serviceArray = state.runTimeParamsFormState.selectedServicesForParams
            const componentIdNameObject = state.runTimeParamsFormState?.componentIdNameObject

            const tablist = serviceArray?.map(item => {
                if (item == '00') {
                    return { order: '00', tabName: 'All' }
                }
                return { order: item, tabName: componentIdNameObject[item]?.label }
            })
            setState(prevState => ({ //3
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: tablist
                }
            }))

        }
        else {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    tabList: []
                }
            }))
        }

    }, [state.runTimeParamsFormState.selectedServicesForParams])

    useEffect(() => {

        const alreadyExistForms = state.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices
        if (state.runTimeParamsFormState.selectedTabOrder) {
            if (state.runTimeParamsFormState.selectedTabOrder == '00') {
                if (state?.runTimeParamsFormState?.staticComponentList?.length > 0) {
                    const componentId = state.runTimeParamsFormState.staticComponentList?.find(({ value }) => value != '00')?.value
                    if (alreadyExistForms[state.runTimeParamsFormState.selectedTabOrder] == undefined) {
                        fetchRunTimeParams(componentId, true)
                    }
                }
            }
            else {
                if (alreadyExistForms[state.runTimeParamsFormState.selectedTabOrder] == undefined) {
                    fetchRunTimeParams(state.runTimeParamsFormState.selectedTabOrder)
                }
            }
        }

    }, [state.runTimeParamsFormState.selectedTabOrder])


    const commonFunctions = getCommonFunctions(state, setState, inherits)

    function handleEnvInput(e) {
        let envValue = e.target.value
        commonFunctions.onChangeHandler(e);
        if (envValue !== '' && envValue != null) {

            const selectedEnv = getSelectedEnv(envValue, props.env_list)

            setState(prevState => ({
                ...prevState,

                data: {
                    ...prevState.data,
                    selected_project_env: selectedEnv,
                    project_job_template_id: ''
                }
            }))
        }
        else
            setState(prevState => ({
                ...prevState,
                selected_project_env: null,
                data: { ...prevState.data, project_job_template_id: '' }
            }))
    }

    function onChangeHandlerForKeyValue(key, value) {
        if (key == "components" || key == "pipelines") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }

    function fetchServicesForTempalte(dependent_entity) {

        var requestInfo = {
            // endPoint: GenerateURL({ env_id: state.data.env, template_id: state.data.project_job_template_id, job_code: state.data.task_type }, properties.api.get_services_for_pipeline_version_3_jobs),
            endPoint: GenerateURL({ env_id: state.data.env, template_id: state.data.project_job_template_id, job_code: state.data.task_type, entity_type: dependent_entity }, properties.api.get_entity_resources),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setTimeout(() => InvokeApi(requestInfo, (data) => {

            let fetchedServices = []

            if (data?.length > 0) {
                const services = data?.map(service => {
                    return { id: service.id, label: service.name, component_env: service.component_env }
                })
                fetchedServices = services
            }

            setState(prevState => ({
                ...prevState,
                all_services: fetchedServices,
                loading_components: false
            }))
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                loading_components: false,
                error: error
            }))

        }),
            500)


        setState(currentState => ({
            ...currentState,
            loading_components: true
        }));
    }


    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;

        var switch_key = [
            "latest_enable",
            "pipeline_suspend",
            "response_update_context_param",
            "no_cache",
            "on_failure_exit",
            "on_success_exit",
            "suspend_pipeline",
            "deployment_status_check",
            "skip_task",
            "mark_success_upon_skip",
            "is_linked_issue"
        ]

        if (switch_key.includes(key)) {
            value = !state.data[key];
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });
    }
    function fetchModifyEnvVarsSuccess(data, componentId, isForAll) {

        let apiFethcedCanModifiedVariables = data
        let componentObj = {}
        let loaderObj = {}
        let componentLabel = null
        if (componentId == '00') {
            componentLabel = 'all_0'
        }
        else {
            componentLabel = state.all_services?.find(service => service.id == componentId)?.label
        }
        let target = '00'
        if (!isForAll) {
            target = componentId
        }
        componentObj = {
            [target]: {}
        }

        loaderObj = {
            [target]: {}
        }

        apiFethcedCanModifiedVariables && apiFethcedCanModifiedVariables.forEach((step, index) => {

            step && step.environment_variables.forEach(envVar => {

                if (envVar.integration) {
                    envVar.integration_values?.forEach(integrationObj => {
                        Object.keys(integrationObj)?.forEach(integrationKey => {
                            const integrationDataArray = integrationObj[integrationKey]
                            integrationDataArray?.forEach(integration => {
                                if (integration.key === 'GIT_BRANCH') {
                                    const targetKey = `${step.step_code}_${step.order}`
                                    const targetDataForEditFlow = {
                                        stepKey: targetKey,
                                        componentLabel: componentLabel
                                    }
                                    if (componentObj[target][targetKey]) {

                                        componentObj[target][targetKey] = {
                                            ...componentObj[target][targetKey],
                                            [integration.key]: state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(integration.key, targetDataForEditFlow) } : { ...defaultStateForEnvForm(integration.key, null) },

                                        }
                                    }
                                    else {
                                        componentObj[target][targetKey] = {
                                            [integration.key]: state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(integration.key, targetDataForEditFlow) } : { ...defaultStateForEnvForm(integration.key, null) },

                                        }
                                    }
                                    if (loaderObj[target][targetKey]) {

                                        loaderObj[target][targetKey] = {
                                            ...loaderObj[target][targetKey],
                                            [integration.key]: false
                                        }
                                    }
                                    else {
                                        loaderObj[target][targetKey] = {
                                            [integration.key]: false
                                        }
                                    }
                                }
                            })
                        })
                    })
                }
                else {
                    const targetKey = `${step.step_code}_${step.order}`
                    const targetDataForEditFlow = {
                        stepKey: targetKey,
                        componentLabel: componentLabel
                    }
                    const key = envVar['key']
                    const value = envVar['value']
                    if (componentObj[target][targetKey]) {

                        componentObj[target][targetKey] = {
                            ...componentObj[target][targetKey],
                            [key]: state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(key, targetDataForEditFlow) } : { ...defaultStateForEnvForm(key, !isForAll ? value : null) }
                        }
                    }
                    else {
                        componentObj[target][targetKey] = {
                            [key]: state.runTimeParamsFormState.globalLevelEdit?.isEditActive ? { ...getEnvStateAccordingToEditMode(key, targetDataForEditFlow) } : { ...defaultStateForEnvForm(key, !isForAll ? value : null) }
                        }
                    }
                    if (loaderObj[target][targetKey]) {

                        loaderObj[target][targetKey] = {
                            ...loaderObj[target][targetKey],
                            [key]: false
                        }
                    }
                    else {
                        loaderObj[target][targetKey] = {
                            [key]: false
                        }
                    }
                }
                // formData[index] = { ...formData[index], [envVar['key']]: envVar['value'] }
            })
        })

        setState(prevState => {
            return {
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    runTimeModifiableVariables: {
                        ...prevState.runTimeParamsFormState.runTimeModifiableVariables,
                        [prevState.runTimeParamsFormState.selectedTabOrder]: data
                    },
                    varFormStateAccordingToStepsAndServices: {
                        ...prevState.runTimeParamsFormState.varFormStateAccordingToStepsAndServices,
                        ...componentObj
                    },
                    loaderVarFormStateAccordingToStepsAndServices: {
                        ...prevState.runTimeParamsFormState.loaderVarFormStateAccordingToStepsAndServices,
                        ...loaderObj
                    },
                    loading: false
                }
            }
        })
    }

    const handleChange = (e, type, targetObj) => {

        const envKey = e.target.name
        const value = e.target.value
        const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices

        varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][envKey][envKey] = value
        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                varFormStateAccordingToStepsAndServices: {
                    ...varFormState
                },
            }
        }));

    }

    const getStepCatelogData = (targetObj, stepCode) => {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs);
        var requestInfo = {
            endPoint: GenerateSearchURL({ step_code: stepCode, version: 'v3' }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        const loaderState = state?.runTimeParamsFormState.loaderVarFormStateAccordingToStepsAndServices
        loaderState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey] = true
        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                loaderVarFormStateAccordingToStepsAndServices: {
                    ...loaderState
                },
            }
        }));

        InvokeApi(requestInfo,
            (response) => {
                if (response) {
                    const result = response.results
                    if (result.length > 0) {
                        const stepData = result[0]
                        const outPutVars = stepData.output_variables
                        const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices
                        varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['output_var_list'] = outPutVars
                        const loaderState = state?.runTimeParamsFormState.loaderVarFormStateAccordingToStepsAndServices
                        loaderState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey] = false
                        setState(prevState => ({
                            ...prevState,
                            runTimeParamsFormState: {
                                ...prevState.runTimeParamsFormState,
                                varFormStateAccordingToStepsAndServices: {
                                    ...varFormState
                                },
                                loaderVarFormStateAccordingToStepsAndServices: {
                                    ...loaderState
                                }
                            }
                        }));
                    }
                }
            },
            (error) => {

            });

    }

    const handleChangeForOutputVariableForm = (e, targetObj) => {

        const targetKey = e.target.name
        const value = e.target.value
        if (targetKey == 'stage') {
            const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm'][targetKey] = value
            //fetching tasks 
            if (value != null || value != '') {
                const tasks = stages?.find(stage => stage.name == value)?.task
                const updatedTasks = tasks?.map((task, index) => {
                    task['order'] = index + 1
                    return task
                })
                varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['task_list'] = updatedTasks

            }
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    varFormStateAccordingToStepsAndServices: {
                        ...varFormState
                    },
                }
            }));
        }
        else if (targetKey == 'previous_job') {
            const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices
            const taskList = varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['task_list']
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm'][targetKey] = value
            if (taskList) {
                const selectedTaskData = taskList?.find(task => task.order == value)
                const configuredJobTemplateOfTask = selectedTaskData?.project_job_template_id
                const dataOfConfiguredJobTemplateInTargetTask = job_templates?.find(jobTemplate => jobTemplate.id == configuredJobTemplateOfTask)?.job_template
                const jobsOfdataOfConfiguredJobTemplateInTargetTask = dataOfConfiguredJobTemplateInTargetTask?.jobs
                const stepsOfdataOfConfiguredJobTemplateInTargetTask = jobsOfdataOfConfiguredJobTemplateInTargetTask?.find(job => job.job_code == selectedTaskData.job_code)?.steps
                varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['step_list'] = stepsOfdataOfConfiguredJobTemplateInTargetTask
                setState(prevState => ({
                    ...prevState,
                    runTimeParamsFormState: {
                        ...prevState.runTimeParamsFormState,
                        varFormStateAccordingToStepsAndServices: {
                            ...varFormState
                        },
                    }
                }));
            }

        }
        else if (targetKey == 'step_order') {

            const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm'][targetKey] = value
            const stepList = varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['step_list']
            const selectedStepData = stepList?.find(step => step.order == value)
            const stepOrder = selectedStepData?.order
            const stepCode = selectedStepData?.step_code
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['step'] = stepCode
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm']['step_order'] = stepOrder

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    varFormStateAccordingToStepsAndServices: {
                        ...varFormState
                    },
                }
            }));
            // getting step_order outputvars

            getStepCatelogData(targetObj, stepCode)

        }
        else if (targetKey == 'output_var') {
            const varFormState = state?.runTimeParamsFormState.varFormStateAccordingToStepsAndServices
            varFormState[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['outputForm'][[targetKey]] = value
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    varFormStateAccordingToStepsAndServices: {
                        ...varFormState
                    },
                }
            }));
        }
    }


    const onHandleChangeForInputType = (targetObj) => {

        const allServiceData = state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices
        const targetedServiceData = allServiceData[targetObj?.selectedServiceID]
        const is_value_from_output_var = targetedServiceData[targetObj?.targetKey][targetObj.envKey].is_value_from_output_var

        allServiceData[targetObj?.selectedServiceID][targetObj?.targetKey][targetObj.envKey]['is_value_from_output_var'] = !is_value_from_output_var

        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                varFormStateAccordingToStepsAndServices: {
                    ...allServiceData
                },
            }
        }));

    }

    const activeAdvanceRuntimeParams = (value, isEditMode) => {

        if (isEditMode)
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    is_advance_configuration: value
                },
                runTimeParamsFormState: {

                    ...prevState.runTimeParamsFormState,
                    isAdvanceRunTimeParamsForm: value,
                    globalLevelEdit: {
                        ...prevState.runTimeParamsFormState.globalLevelEdit,
                        isEditActive: false,
                        dataForEdit: null,
                        resetCount: prevState.runTimeParamsFormState.globalLevelEdit.resetCount + 1
                    }
                }
            }))
        else
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    is_advance_configuration: value
                },
                runTimeParamsFormState: {

                    ...prevState.runTimeParamsFormState,
                    isAdvanceRunTimeParamsForm: value
                }
            }))
        hideBottomSaveContainer(value)
    }

    const handleServiceSelectUpdate = (uniqueId, serviceArray) => {

        if (serviceArray.length > 0) {

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    selectedServicesForParams: [...serviceArray],
                }
            }))

            // fetchin Runtime params from here 
            // const prevSelectedService = state.runTimeParamsFormState.selectedServicesForParams
        }
        else {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    runTimeModifiableVariables: {},
                    selectedServicesForParams: [...serviceArray],
                }
            }))
        }
    }

    const fetchModifyEnvVars = (component_id, sub_env_id, job_code, isForAll) => {

        if (component_id != null && sub_env_id != null && job_code != null) {

            const requestInfo = {
                // endPoint: GenerateURL({ component_id: component_id, sub_env_id: sub_env_id, job_code: job_code }, properties.api.get_trigger_time_modify_env_vars),
                endPoint: GenerateURL({ env_id: state.data.env, job_code: job_code, entity_type: "SERVICE", entity_resource_id: component_id }, properties.api.get_runtime_params_for_dependent_job),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    loading: true
                }
            }))
            InvokeApi(requestInfo, (response) => {

                fetchModifyEnvVarsSuccess(response, component_id, isForAll)
            },
                (error) => {
                    setState(prevState => ({
                        ...prevState,
                        runTimeParamsFormState: {
                            ...prevState.runTimeParamsFormState,
                            loading: false,
                            error: error
                        }
                    }))
                });
        }
    }

    const toggleState = () => {

        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                is_advance_configuration: !prevState.data.is_advance_configuration
            },
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                isAdvanceRunTimeParamsForm: !prevState.data.is_advance_configuration

            }
        }))
        hideBottomSaveContainer(!state?.data?.is_advance_configuration)

    }

    const fetchRunTimeParams = (selectedServiceID, isForAll) => {

        // searching subEnvId 

        const componentEnv = state.runTimeParamsFormState?.componentIdNameObject[selectedServiceID]?.component_env
        const selectedEnvname = state.data.selected_project_env?.name
        const subEnvId = componentEnv?.find(componentEnv => componentEnv.name == selectedEnvname)?.id
        let jobCode = state.data?.task_type
        fetchModifyEnvVars(selectedServiceID, 1, jobCode, isForAll)
    }

    const onSelectTab = (order) => {

        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                selectedTabOrder: order
            }
        }))
    }

    const onRemoveTab = (removedTabOrder) => {

        let tabs = state.runTimeParamsFormState.selectedServicesForParams
        let newSelectedTabOrder = state.runTimeParamsFormState.selectedTabOrder
        let updatedTabs = tabs.filter(tab => {
            if (tab != removedTabOrder) {
                return tab
            }
        })
        if (removedTabOrder == state.runTimeParamsFormState.selectedTabOrder && updatedTabs.length != 0) {


            const indexOfRemovedTab = tabs.indexOf(removedTabOrder)
            if (indexOfRemovedTab == 0) {
                newSelectedTabOrder = updatedTabs[0]
            }
            else
                newSelectedTabOrder = updatedTabs[indexOfRemovedTab - 1]
        }

        if (updatedTabs.length == 0) {
            newSelectedTabOrder = null
        }

        let varFormStateAccordingToStepsAndServices = { ...state.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices }
        delete varFormStateAccordingToStepsAndServices[removedTabOrder]

        setState(prevState => ({
            ...prevState,
            runTimeParamsFormState: {
                ...prevState.runTimeParamsFormState,
                selectedServicesForParams: updatedTabs,
                varFormStateAccordingToStepsAndServices: varFormStateAccordingToStepsAndServices,
                selectedTabOrder: newSelectedTabOrder
            }
        }))

    }

    const handleOnSaveRuntimeParams = () => {

        // changing services name
        onSaveParamsForm({ ...state.runTimeParamsFormState, isAdvanceRunTimeParamsForm: false })
        activeAdvanceRuntimeParams(false, state?.runTimeParamsFormState?.globalLevelEdit?.isEditActive)
    }

    const handleOnDeleteServiceRuntimeParamsEdit = (serviceKey) => {
        let tablistData = editRuntimeFormState.tabList
        let selectedTabOrder = editRuntimeFormState.selectedTabOrder
        let varFormstate = editRuntimeFormState?.varFormStateAccordingToStepsAndServices
        let resetCount = editRuntimeFormState.resetCount
        let updatedTablist = tablistData?.filter(tab => tab.order != serviceKey)
        delete varFormstate[serviceKey]
        if (updatedTablist?.length > 0) {
            if (serviceKey == selectedTabOrder) {
                selectedTabOrder = updatedTablist[0]?.order
            }
            // if(updatedTablist.length==1){

            // }
            onSaveParamsForm({
                ...editRuntimeFormState, tabList: [...updatedTablist],
                varFormStateAccordingToStepsAndServices: { ...editRuntimeFormState?.varFormStateAccordingToStepsAndServices },
                resetCount: resetCount + 1,
                selectedTabOrder: selectedTabOrder
            })
        }
        else
            onSaveParamsForm(null)
        // need to update code it will be busted if all services removed

    }

    const handleOnEditClickOnServiceRuntimeParamsEdit = () => {

    }

    const checkForAvailableLists = (targetData, targetType) => {

        if (targetType == 'stage') {
            if (targetData['outputForm'][targetType] != null && targetData['outputForm'][targetType] != '') {
                return true
            }
            else
                return false
        }
    }

    const onhandleCancelTrigger = () => {
        if (editRuntimeFormState) {
            onlyIncreaseResetCount(state.runTimeParamsFormState?.resetCount + 1)
            activeAdvanceRuntimeParams(false)
        }
        if (state.runTimeParamsFormState?.globalLevelEdit?.isEditActive) {
            setState(prevState => ({
                ...prevState,
                runTimeParamsFormState: {
                    ...prevState.runTimeParamsFormState,
                    globalLevelEdit: {
                        ...prevState.runTimeParamsFormState.globalLevelEdit,
                        resetCount: prevState.runTimeParamsFormState.globalLevelEdit.resetCount + 1
                    }
                }
            }))
            activeAdvanceRuntimeParams(false)
        }
        else
            activeAdvanceRuntimeParams(false)
    }

    const handleServiceOverridden = (e) => {

        if (jobJsonData && setJobJson) {
            setJobJson({
                ...jobJsonData,
                entity_resource_data: {
                    "SERVICE": [],
                    "is_overridden": e.target.checked
                }
            })
        }
    }


    const resetUpdatedRuntimevariablesData = () => {
        console.log('callked');
        //for normal case locally form 

        resetStateOfUpdatedEnv(); // for parent reset
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                components: []
            }, 
            runTimeParamsFormState: {
                resetCount: prevState.runTimeParamsFormState.resetCount+1,
                isAdvanceRunTimeParamsForm: false,
                updatedRuntimeParams: null,
                varFormStateAccordingToStepsAndServices: {},
                loaderVarFormStateAccordingToStepsAndServices: {},
                staticComponentList: [
                ],
                runTimeModifiableVariables: {},
                selectedServicesForParams: [],
                selectedTabOrder: null,
                modeType: null,
                loading: false,
                tabList: [

                ],
                globalLevelEdit: {
                    isEditActive:  false,
                    dataForEdit: null,
                    resetCount: prevState?.runTimeParamsFormState?.globalLevelEdit?.resetCount+1
                }
            }

        }))
    }

    const handleJobTemplateChange = (e) => {

        const k = e.target.name;
        const v = e.target.value;

        if (k == 'project_job_template_id') {
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [k]: v,
                    task_type: ''
                }
            }))
            resetUpdatedRuntimevariablesData()
        }

        else {
            setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [k]: v,
                }
            }))
            resetUpdatedRuntimevariablesData()
        }
    }

    return (
        <>
            {
                state.runTimeParamsFormState?.isAdvanceRunTimeParamsForm ? <Grid container>
                    <div style={{ width: '100%' }} className="d-flex align-center mb-12">
                        <div>
                            <div className="font-15 mr-20">Job Steps Environment Variables</div>
                        </div>
                        <div style={{ marginRight: '-12px' }}>
                            <AdvanceSearchFilterCombo
                                reset={state.runTimeParamsFormState.resetCount}
                                selectedCheckBoxes={state.runTimeParamsFormState.selectedServicesForParams}
                                staticList={state?.runTimeParamsFormState?.staticComponentList ? state.runTimeParamsFormState.staticComponentList : []}
                                searchVariable='service-name'
                                uniqueId='run-params-form-service-001'
                                labelName='Choose Service'
                                onUpdate={handleServiceSelectUpdate}
                            // getFetchUrl=null
                            // filterDataPraseFunction={advanceFilterJson[filterName]['filterDataPraseFunction']}
                            />
                        </div>

                    </div>
                    <div className={runTimeParamClasses.runTimeParamsContainer}>

                        {/* <div>
                            <label className="font-14">{state?.runTimeParamsFormState.selectedServiceName}</label>
                        </div> */}

                        <div className="border-tab step-condition-tab" style={{ marginBottom: '15px', overflowY: 'auto' }}>
                            <CommonHorizontalTab
                                variant="removable-tabs"
                                onRemove={onRemoveTab}
                                style={{ borderBottom: '1px solid #dedede' }}
                                tabList={state?.runTimeParamsFormState?.tabList}
                                selectedTabOrder={state?.runTimeParamsFormState?.selectedTabOrder}
                                updateSelectedTab={onSelectTab} />
                        </div>
                        <div className='runtime-form-container'>

                            <Grid className='pl-15 pr-15 pb-20' style={{ backgroundColor: '#ffffff', minHeight: '200px' }} container>
                                {
                                    state?.runTimeParamsFormState?.tabList.length > 0 && state?.runTimeParamsFormState?.selectedTabOrder != null ?
                                        <>
                                            {
                                                state?.runTimeParamsFormState?.loading ?
                                                    <Loading varient="light" />
                                                    :
                                                    <>
                                                        {
                                                            state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices && state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[state.runTimeParamsFormState.selectedTabOrder] && state.runTimeParamsFormState.runTimeModifiableVariables[state.runTimeParamsFormState.selectedTabOrder]?.map((step, order) => {
                                                                const selectedServiceID = state.runTimeParamsFormState.selectedTabOrder
                                                                const stepCode = step.step_code
                                                                const stepOrder = step.order
                                                                const targetKey = `${stepCode}_${stepOrder}`
                                                                const varForm = state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices
                                                                const loaderState = state?.runTimeParamsFormState?.loaderVarFormStateAccordingToStepsAndServices
                                                                const targetedServiceData = varForm[selectedServiceID][targetKey]
                                                                const targetServiceLoader = loaderState[selectedServiceID][targetKey]
                                                                const targetObj = {
                                                                    selectedServiceID: selectedServiceID,
                                                                    stepCode: stepCode,
                                                                    stepOrder: stepOrder,
                                                                    targetKey: targetKey
                                                                }
                                                                const data = state.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[selectedServiceID][targetKey]
                                                                // state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]
                                                                return (
                                                                    <Grid key={`${state.runTimeParamsFormState.selectedTabOrder}_${order}`} style={{ paddingTop: '15px' }} item xs={12}>

                                                                        <Grid style={{ height: '32px' }} key={order} item xs={12}>
                                                                            <span className='font-13 mr-12'>Step Name</span>
                                                                            <label style={{ color: 'gray' }} className='font-13'>{step.step_name ? step.step_name : step.step_code}</label>
                                                                        </Grid>

                                                                        <div style={{
                                                                            paddingTop: '20px', paddingBottom: '15px', border: '1px solid #dedede',
                                                                            backgroundColor: '#f8f8f8', padding: '10px 20px 5px 20px',
                                                                            borderRadius: '5px'
                                                                        }}>
                                                                            {
                                                                                step && step.environment_variables.map((variable, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                variable.integration ?
                                                                                                    <>

                                                                                                        {
                                                                                                            variable.integration_values?.map(integrationObj => {

                                                                                                                return (<>


                                                                                                                    {
                                                                                                                        Object.keys(integrationObj)?.map(integrationKey => {
                                                                                                                            const integrationData = integrationObj[integrationKey]
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {integrationData?.map(integration => {
                                                                                                                                        return (

                                                                                                                                            integration.key === 'GIT_BRANCH' ?
                                                                                                                                                <Grid container spacing={2}>
                                                                                                                                                    {
                                                                                                                                                        targetedServiceData[integration.key]?.is_value_from_output_var &&
                                                                                                                                                        <>
                                                                                                                                                            <Grid key={index} item xs={4}>

                                                                                                                                                                <Input
                                                                                                                                                                    type='select'
                                                                                                                                                                    name='stage'
                                                                                                                                                                    list={stages ? stages.map(item => {
                                                                                                                                                                        return { id: item.name, label: item.name }
                                                                                                                                                                    }) : []}
                                                                                                                                                                    label='Stage'
                                                                                                                                                                    placeholder=""
                                                                                                                                                                    onChangeHandler={(e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: integration.key }))}
                                                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][integration.key]['outputForm']}

                                                                                                                                                                    error={state.error}
                                                                                                                                                                />
                                                                                                                                                            </Grid>
                                                                                                                                                            <Grid key={index} item xs={4}>

                                                                                                                                                                <Input
                                                                                                                                                                    type='select'
                                                                                                                                                                    list={targetedServiceData[integration.key]['outputForm']['task_list'] ? targetedServiceData[integration.key]['outputForm']['task_list'].map(task => { return { id: task.order, label: `${task.name} order-${task.order}` } }) : []}
                                                                                                                                                                    name='previous_job'
                                                                                                                                                                    label='Previous Job'
                                                                                                                                                                    placeholder=""
                                                                                                                                                                    onChangeHandler={targetedServiceData[integration.key]['outputForm']['task_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: integration.key }) : false}
                                                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][integration.key]['outputForm']}
                                                                                                                                                                    error={state.error}
                                                                                                                                                                />
                                                                                                                                                            </Grid>
                                                                                                                                                            <Grid key={index} item xs={4}>

                                                                                                                                                                <Input
                                                                                                                                                                    type='select'
                                                                                                                                                                    list={targetedServiceData[integration.key]['outputForm']['step_list'] ? targetedServiceData[integration.key]['outputForm']['step_list'].map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                                                                                                                                                                    name='step_order'
                                                                                                                                                                    label='Step Name'
                                                                                                                                                                    placeholder=""
                                                                                                                                                                    onChangeHandler={targetedServiceData[integration.key]['outputForm']['step_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: integration.key }) : false}
                                                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][integration.key]['outputForm']}

                                                                                                                                                                    error={state.error}
                                                                                                                                                                />
                                                                                                                                                            </Grid>
                                                                                                                                                        </>
                                                                                                                                                    }
                                                                                                                                                    <Grid key={index} item xs={targetedServiceData[integration.key]?.is_value_from_output_var ? 6 : 12}>
                                                                                                                                                        {
                                                                                                                                                            targetServiceLoader[integration.key] ?
                                                                                                                                                                <Loading varient="light" />
                                                                                                                                                                :
                                                                                                                                                                <div style={{ position: 'relative' }}>
                                                                                                                                                                    <SideLabelForInput isValueFromOutputVar={targetedServiceData[integration.key]?.is_value_from_output_var} onHandleChange={() => onHandleChangeForInputType({ ...targetObj, envKey: integration.key })} />
                                                                                                                                                                    <Input
                                                                                                                                                                        type={targetedServiceData[integration.key]?.is_value_from_output_var ? 'select' : 'text'}
                                                                                                                                                                        name={targetedServiceData[integration.key]?.is_value_from_output_var ? 'output_var' : integration.key}
                                                                                                                                                                        label={targetedServiceData[integration.key]?.is_value_from_output_var ? 'Out put variable' : `${integration.key} for ${integrationKey}(Integration)`}
                                                                                                                                                                        placeholder=""
                                                                                                                                                                        list={targetedServiceData[integration.key]['outputForm']['output_var_list'] ? targetedServiceData[integration.key]['outputForm']['output_var_list'].map(task => { return { id: task.order, label: task.name } }) : []}
                                                                                                                                                                        onChangeHandler={targetedServiceData[integration.key]?.is_value_from_output_var ? targetedServiceData[integration.key]['outputForm']['output_var_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: integration.key }) : false : e => handleChange(e, order, targetObj)}
                                                                                                                                                                        data={
                                                                                                                                                                            targetedServiceData[integration.key]?.is_value_from_output_var ?
                                                                                                                                                                                state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][integration.key]['outputForm']
                                                                                                                                                                                :
                                                                                                                                                                                state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][integration.key]
                                                                                                                                                                        }
                                                                                                                                                                        error={state.error}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                        }
                                                                                                                                                    </Grid>
                                                                                                                                                </Grid>

                                                                                                                                                : null
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </>)

                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    variable.input_type === 'toggle' ?
                                                                                                        <Input
                                                                                                            type='env-var-toggle'
                                                                                                            name={variable.key}
                                                                                                            label={variable.key}
                                                                                                            placeholder=""
                                                                                                            onChangeHandler={(e => handleChange(e, order, 'toggle'))}
                                                                                                            data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key] === 'no' ? false : true}
                                                                                                            error={state.error}
                                                                                                        /> :
                                                                                                        variable.input_type == undefined || variable.input_type === 'text' ?
                                                                                                            <Grid container spacing={2}>
                                                                                                                {
                                                                                                                    targetedServiceData[variable.key]?.is_value_from_output_var &&
                                                                                                                    <>
                                                                                                                        <Grid key={index} item xs={4}>

                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                name='stage'
                                                                                                                                list={stages ? stages.map(item => {
                                                                                                                                    return { id: item.name, label: item.name }
                                                                                                                                }) : []}
                                                                                                                                label='Stage'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={(e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }))}
                                                                                                                                data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}

                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>

                                                                                                                        <Grid key={index} item xs={4}>
                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                list={targetedServiceData[variable.key]['outputForm']['task_list'] ? targetedServiceData[variable.key]['outputForm']['task_list'].map(task => { return { id: task.order, label: `${task.name} order-${task.order}` } }) : []}
                                                                                                                                name='previous_job'
                                                                                                                                label='Previous Job'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={targetedServiceData[variable.key]['outputForm']['task_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }) : false}
                                                                                                                                data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                        <Grid key={index} item xs={4}>

                                                                                                                            <Input
                                                                                                                                type='select'
                                                                                                                                list={targetedServiceData[variable.key]['outputForm']['step_list'] ? targetedServiceData[variable.key]['outputForm']['step_list'].map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                                                                                                                                name='step_order'
                                                                                                                                label='Step Name'
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={targetedServiceData[variable.key]['outputForm']['step_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }) : false}
                                                                                                                                data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}

                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }

                                                                                                                <Grid key={index} item xs={targetedServiceData[variable.key]?.is_value_from_output_var ? 6 : 12}>
                                                                                                                    {targetServiceLoader[variable.key] ?
                                                                                                                        <Loading varient="light" />
                                                                                                                        :
                                                                                                                        <div style={{ position: 'relative' }}>
                                                                                                                            <SideLabelForInput isValueFromOutputVar={targetedServiceData[variable.key]?.is_value_from_output_var} onHandleChange={() => onHandleChangeForInputType({ ...targetObj, envKey: variable.key })} />
                                                                                                                            <Input
                                                                                                                                type={targetedServiceData[variable.key]?.is_value_from_output_var ? 'select' : 'text'}
                                                                                                                                name={targetedServiceData[variable.key]?.is_value_from_output_var ? 'output_var' : variable.key}
                                                                                                                                label={targetedServiceData[variable.key]?.is_value_from_output_var ? 'Out put variable' : `${variable.key}`}
                                                                                                                                placeholder=""
                                                                                                                                list={targetedServiceData[variable.key]['outputForm'] &&
                                                                                                                                    targetedServiceData[variable.key]['outputForm']['output_var_list'] ?
                                                                                                                                    targetedServiceData[variable.key]['outputForm']['output_var_list'].map(task => { return { id: task.order, label: task.name } })
                                                                                                                                    : []}
                                                                                                                                onChangeHandler={targetedServiceData[variable.key]?.is_value_from_output_var ? targetedServiceData[variable.key]['outputForm']['output_var_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }) : false : e => handleChange(e, order, targetObj)}
                                                                                                                                data={
                                                                                                                                    targetedServiceData[variable.key]?.is_value_from_output_var ?
                                                                                                                                        state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']
                                                                                                                                        :
                                                                                                                                        state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]
                                                                                                                                }
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>

                                                                                                            :
                                                                                                            <Grid container spacing={2}>
                                                                                                                <>
                                                                                                                    {
                                                                                                                        targetedServiceData[variable.key]?.is_value_from_output_var &&
                                                                                                                        <>
                                                                                                                            <Grid key={index} item xs={4}>

                                                                                                                                <Input
                                                                                                                                    type='select'
                                                                                                                                    name='stage'
                                                                                                                                    list={stages ? stages.map(item => {
                                                                                                                                        return { id: item.name, label: item.name }
                                                                                                                                    }) : []}
                                                                                                                                    label='Stage'
                                                                                                                                    placeholder=""
                                                                                                                                    onChangeHandler={(e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }))}
                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}

                                                                                                                                    error={state.error}
                                                                                                                                />
                                                                                                                            </Grid>

                                                                                                                            <Grid key={index} item xs={4}>
                                                                                                                                <Input
                                                                                                                                    type='select'
                                                                                                                                    list={targetedServiceData[variable.key]['outputForm']['task_list'] ? targetedServiceData[variable.key]['outputForm']['task_list'].map(task => { return { id: task.order, label: `${task.name} order-${task.order}` } }) : []}
                                                                                                                                    name='previous_job'
                                                                                                                                    label='Previous Job'
                                                                                                                                    placeholder=""
                                                                                                                                    onChangeHandler={targetedServiceData[variable.key]['outputForm']['task_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }) : false}
                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}
                                                                                                                                    error={state.error}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            <Grid key={index} item xs={4}>
                                                                                                                                <Input
                                                                                                                                    type='select'
                                                                                                                                    list={targetedServiceData[variable.key]['outputForm']['step_list'] ? targetedServiceData[variable.key]['outputForm']['step_list'].map(step => { return { id: step.order, label: `${step.step_name} order-${step.order}` } }) : []}
                                                                                                                                    name='step_order'
                                                                                                                                    label='Step Name'
                                                                                                                                    placeholder=""
                                                                                                                                    onChangeHandler={targetedServiceData[variable.key]['outputForm']['step_list'] ? e => handleChangeForOutputVariableForm(e, { ...targetObj, envKey: variable.key }) : false}
                                                                                                                                    data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']}
                                                                                                                                    error={state.error}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                        </>

                                                                                                                    }
                                                                                                                    <Grid key={index} item xs={6}>

                                                                                                                        <div style={{ position: 'relative' }}>
                                                                                                                            <SideLabelForInput isValueFromOutputVar={targetedServiceData[variable.key]?.is_value_from_output_var} onHandleChange={() => onHandleChangeForInputType({ ...targetObj, envKey: variable.key })} />
                                                                                                                            <Input
                                                                                                                                type={variable.input_type === 'toggle' ? 'select' : variable.input_type}

                                                                                                                                name={variable.key}
                                                                                                                                list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                                                                                                    variable.select_dropdown_options.split(',').map(item => ({

                                                                                                                                        id: item.trim(), label: item.trim()
                                                                                                                                    }))

                                                                                                                                    : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                                                    : []
                                                                                                                                }
                                                                                                                                label={variable.key}
                                                                                                                                placeholder=""
                                                                                                                                onChangeHandler={(e => handleChange(e, order, targetObj))}
                                                                                                                                // data={
                                                                                                                                //     targetedServiceData[variable.key]?.is_value_from_output_var?
                                                                                                                                //     state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]['outputForm']
                                                                                                                                //     :
                                                                                                                                //     state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]
                                                                                                                                // }
                                                                                                                                data={state?.runTimeParamsFormState?.varFormStateAccordingToStepsAndServices[targetObj?.selectedServiceID][targetObj?.targetKey][variable.key]}
                                                                                                                                error={state.error}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </Grid>
                                                                                                                </>
                                                                                                            </Grid>


                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>

                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }


                                        </>

                                        :
                                        <>
                                            {
                                                state.runTimeParamsFormState?.tabList?.length == 0 ?
                                                    <div className="d-flex align-center justify-center font-12 full-width">
                                                        Please Select Service To See Runtime parameters
                                                    </div>
                                                    :
                                                    state.runTimeParamsFormState?.selectedTabOrder == null ?
                                                        <div className="d-flex align-center justify-center font-12 full-width">
                                                            Please Click On service tabs to update/override runtime parameters

                                                        </div>

                                                        : null
                                            }
                                        </>
                                }



                            </Grid>
                        </div>

                    </div>
                    <div style={{ width: '100%', borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit', background: '#fff' }} className="fill-details-footer border-top mt-10">
                        <div style={{ height: "30px", minWidth: "80px" }} onKeyDown={() => { }} onClick={onhandleCancelTrigger} className="btn btn-outline-grey d-flex align-center justify-center" tabIndex={0} role='button'>Cancel</div>
                        <div className="btn btn-add" onKeyDown={() => { }} onClick={handleOnSaveRuntimeParams} role='button' tabIndex={0}
                        >{'Save'}</div>
                    </div>

                </Grid>
                    :
                    <Grid container>
                        <Grid item lg={12}>
                            <Input
                                type="select"
                                mandatorySign
                                name="env"
                                list={getListableArray(props?.env_list)}
                                label="Project Env"
                                onChangeHandler={handleEnvInput
                                }
                                error={state.error}
                                data={state.data} />
                        </Grid>
                        {
                            // job_templates?.filter(template => template.version === 'v3')?.length != 0 ?
                            job_templates?.length != 0 ?

                                <Grid item lg={12}>
                                    <Input
                                        type="select"
                                        mandatorySign
                                        name="project_job_template_id"
                                        list={getListableArray(job_templates)}
                                        label="Job Template"
                                        onChangeHandler={(state.data.env != null && state.data.env != '') ? handleJobTemplateChange : false
                                        }
                                        error={state.error}
                                        data={state.data} />
                                </Grid> :
                                <AlertStrip message="Please add v3 job templates first" vairant="info" />
                        }

                        <Grid item lg={12}>
                            {
                                (state.job_name_list?.length == 0 && state?.versionOfSelectedTemplate == 'v2') ?

                                    <AlertStrip variant="p-v3-alert" message="No dynamic job exist in this template , please select a diffrent template" vairant="info" />

                                    :
                                    <Input
                                        type="select"
                                        mandatorySign
                                        name="task_type"
                                        list={state.job_name_list}
                                        label="Job Type"
                                        onChangeHandler={state.job_name_list?.length > 0 ? handleJobTemplateChange : false
                                        }
                                        error={state.error}
                                        data={state.data} />
                            }

                        </Grid>
                        {
                            state.loading_components ?
                                <Loading varient="light" />
                                : <>
                                    {
                                        jobJsonData?.is_dependent && state.all_services?.length > 0 ?
                                            <div className='service-container-v3 mt-5' style={{ width: '100%', position: 'relative ' }}>
                                                <div className='service-overridable-container' style={{ position: 'absolute', right: 0, top: '-12px' }}>
                                                    <Checkbox checked={jobJsonData?.entity_resource_data?.is_overridden} onChange={handleServiceOverridden} name={''} value={''} />
                                                    <label style={{ color: '#505050' }} className='font-12 font-weight-400'>Overridable at Runtime</label>
                                                </div>
                                                <Grid item lg={12}>

                                                    <Input
                                                        type="checkbox"
                                                        name="components"
                                                        mandatorySign
                                                        varient="inner_component"
                                                        label="Services"
                                                        list={state.all_services}
                                                        onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : false}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                                </Grid>
                                            </div>
                                            :
                                            jobJsonData?.is_dependent && state.data.task_type && state.data.task_type != '' && state.all_services?.length === 0 ?

                                                <div style={{ width: '100%' }}>
                                                    <AlertStrip variant="p-v3-alert" message={`${state.data.task_type} Job is not configured. Please select a different job.`} />
                                                </div>
                                                :

                                                !jobJsonData?.is_dependent && state.data.task_type && state.data.task_type != '' ?

                                                    <>

                                                        <OverrideVariablesForNonDependentJobs
                                                            jobData={jobJsonData?.jobData}
                                                            independentJobData={independentJobData}
                                                            setParentState={setParentState}
                                                            editFlowJobStepJson={editFlowJobStepJson}
                                                        />
                                                    </> : null
                                    }

                                </>

                        }
                        {
                            state?.data?.components?.length > 0 && jobJsonData?.is_dependent &&
                            <>
                                <div style={{
                                    height: "50px", border: '1px solid #dedede',
                                    borderRadius: '0px',
                                    width: '100%',
                                    padding: "10px",
                                    boxShadow: "none",
                                    marginTop: "12px",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    borderBottomLeftRadius: '6px',
                                    borderBottomRightRadius: '6px'
                                }} className={classes.card_shadow + " " + "bg-f8-grey border-top d-flex align-center space-between"}>
                                    <div style={{ color: '#000', lineHeight: '1.5' }} className="font-13">Provide/Update Runtime Environment Variables</div>
                                    {/* <div className='remove-margin-from-input'>
                                        <Input
                                            type="only-switch"
                                            label=""
                                            name="is_advance_configuration"
                                            data={state.data}
                                            error={state.error}
                                            onChangeHandler={toggleState} />

                                    </div> */}

                                    {
                                        (state.runTimeParamsFormState?.globalLevelEdit?.isEditActive || (editRuntimeFormState && Object.keys(editRuntimeFormState.varFormStateAccordingToStepsAndServices)?.length > 0)) ?

                                            <Tooltip title='Edit'>

                                                <EditIcon onClick={toggleState} style={{ cursor: 'pointer', color: '#4395d9', fontSize: '20px' }} />
                                            </Tooltip>
                                            :
                                            <span style={{ cursor: 'pointer', color: '#4395d9' }} onKeyDown={() => { }} onClick={toggleState} role="button" tabIndex={0}>+Add</span>
                                    }
                                </div>

                                <div style={{ height: "100px", width: "100%", border: "1px solid #dedede", borderTop: 'none', borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px", padding: '15px', overflow: 'auto' }}>
                                    {

                                        <>
                                            {
                                                editRuntimeFormState && Object.keys(editRuntimeFormState.varFormStateAccordingToStepsAndServices)?.length > 0 ?
                                                    <div style={{ width: '100%' }}>
                                                        {
                                                            Object.keys(editRuntimeFormState.varFormStateAccordingToStepsAndServices)?.map(serviceKey => {

                                                                let serviceName = ''
                                                                if (serviceKey == '00') {
                                                                    serviceName = 'All'
                                                                }
                                                                else {
                                                                    const componentKeyObjectData = editRuntimeFormState.componentIdNameObject
                                                                    if (componentKeyObjectData)
                                                                        serviceName = componentKeyObjectData[serviceKey]?.label
                                                                }

                                                                return (
                                                                    <div style={{ marginBottom: "20px", borderBottom: '1px solid #dedede' }} className='d-flex align-center space-between'>
                                                                        <label className='font-14'>{serviceName}</label>
                                                                        {/* <div>
                                                                            <EditIcon onClick={() => handleOnEditClickOnServiceRuntimeParamsEdit(serviceKey)} style={{ fontSize: '18px', marginRight: '15px', cursor: 'pointer' }} />
                                                                            <CloseIcon onClick={() => handleOnDeleteServiceRuntimeParamsEdit(serviceKey)} style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                                        </div> */}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> :
                                                    state?.runTimeParamsFormState?.globalLevelEdit?.isEditActive ?
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                {
                                                                    Object.keys(state.runTimeParamsFormState.globalLevelEdit.dataForEdit)?.map(serviceName => {
                                                                        return (
                                                                            <div style={{ marginBottom: "20px", borderBottom: '1px solid #dedede' }} className='d-flex align-center space-between'>
                                                                                <label className='font-14'>{serviceName}</label>
                                                                                {/*   <div> 
                                                                                  <EditIcon onClick={() => handleOnEditClickOnServiceRuntimeParamsEdit(serviceName)} style={{ fontSize: '18px', marginRight: '15px', cursor: 'pointer' }} />
                                                                                  <CloseIcon onClick={() => handleOnDeleteServiceRuntimeParamsEdit(serviceName)} style={{ fontSize: '18px', cursor: 'pointer' }} />
                                                                              </div> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ width: '100%', height: '100%' }} className='d-flex align-center justify-center'>
                                                            <label className="font-12">No Advance Configuration is added</label>

                                                        </div>

                                            }
                                        </>
                                    }

                                </div>
                            </>
                        }
                        <Grid className='mb-5' container alignItems="center">
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="on_success_exit"
                                        label="Terminate Pipeline on Success?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="on_failure_exit"
                                        label="Terminate Pipeline on Failure?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="skip_task"
                                        label="Skip Job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>
                            <Grid item lg={6}>
                                <div className={classes.card_shadow + " " + "bg-f8-grey border-top"}>
                                    <Input
                                        type="switch"
                                        name="mark_success_upon_skip"
                                        label="Mark success on skip job?"
                                        onChangeHandler={onChangeHandler}
                                        error={state.error}
                                        data={state.data} />
                                </div>
                            </Grid>

                        </Grid>


                    </Grid>
            }
        </>

    )
}

AddVersionThreeJobs.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default AddVersionThreeJobs;

const useStyles = makeStyles((theme) => ({
    runTimeParamsContainer: {
        border: "1px solid #dedede",
        borderRadius: "7px",
        width: "100%",
        '& .runtime-form-container': {
            padding: "10px",
        },
        '& .border-tab': {
            borderBottom: '1px solid #b7b7b7',
        }
    }
}));


const SideLabelForInput = ({ isValueFromOutputVar, onHandleChange }) => {

    const handleOnClick = () => {

        onHandleChange()
    }

    return <button
        onClick={handleOnClick}
        style={{ position: 'absolute', right: 0, cursor: 'pointer', top: "-5px", zIndex: 1 }}
        className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0">{!isValueFromOutputVar ? 'Select From Previous Job' : 'Provide Custom Value'}</button>

}

SideLabelForInput.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};