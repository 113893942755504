import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import properties from '../../properties/properties'
const useStyles = makeStyles(theme => ({
    root: {},
    avatar:{
        borderRadius : '8px',
        padding:'10px',
        height:'48px',
        width:'48px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#5784c8',
        fontSize:'18px',
        fontWeight:'700',
        color:'#fff'
    },
    round:{
        borderRadius : '50%',
        padding:'10px',
        height:'40px',
        width:'40px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#5784c8',
        fontSize:'18px',
        fontWeight:'700',
        color:'#fff',
        margin:'10px'
    },
    smRound:{
        borderRadius : '50%',
        padding:'10px',
        height:'18px',
        width:'18px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'12px',
        color:'#fff',
        fontWeight:'400',
        marginRight:'3px'
    },
    smBox:{
        borderRadius : '5px',
        padding:'10px',
        height:'35px',
        width:'35px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#5784c8',
        fontSize:'16px',
        fontWeight:'700',
        color:'#fff'
    },
    lgBox:{
        borderRadius : '5px',
        padding:'10px',
        height:'55px',
        width:'55px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#5784c8',
        fontSize:'16px',
        fontWeight:'700',
        color:'#fff'
    },
    dot: {
        width: "14px",
        height: "14px",
        border: "2px solid #FFFFFF",
        borderRadius: "50%",
        backgroundColor: "#129E5B",
        position: "absolute",
        left: "85%",
        top: "0",
        marginTop: "-4px",
    }
  }));


  function getColor(number){
    const availaible_colors = properties.colors;
    let temp_num=number>=0?number%10:1
    return availaible_colors[temp_num].color;
    }
    
const SquareAvatar = (props) =>{
    
    const classes = useStyles();
    const varient = props.varient;
    const shape = props.shape;
    const img_url = props.img_url;
    const name = props.name ? props.name.toUpperCase(): "";
    const dot = props.dot ? props.dot :false;
    var alphabet = "";
    var number = 69;
    if(name){
        alphabet = name[0];
        number = alphabet.charCodeAt(0) - 65;
    }

    let label = name[0];

    if(varient == "double"){
        let name_array = name.split("-");
        if(name_array.length > 1){
            label=name_array[0][0] + name_array[1][0]; 
        }else{
            label=name[0] + name[1];
        }
    }

    
 
    let color = getColor(number);

    return(
        <div className={shape == "round" ? classes.round+ " round-override" : shape == "sm-round" ? classes.smRound+ " sm-round-override" : shape == "sm-box" ? classes.smBox : shape == "lg-box" ? classes.lgBox : classes.avatar} style={{backgroundColor:color,position: "relative"}}>
            <span>{img_url ? img_url : label}</span>
            {dot ? 
                <span className={classes.dot}></span>
            : null}
        </div>
    )
}

SquareAvatar.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default SquareAvatar