import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BorderColorIcon from '@material-ui/icons/BorderColor';

import { Link } from 'react-router-dom';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import { Tooltip } from '@material-ui/core';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
const ResourceQuotaOverview = (props) => {
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    const show_sidecar_overview = extraProps.show_sidecar_overview;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const env_cd_id = extraProps.env_cd_id;
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const service_env_data = extraProps.service_env_data;
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
    });
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = GetAuth().permission.POST.includes(url);

    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])

    console.log(state, "fghui============>")

    return (
        <>
            {
                show_sidecar_overview ?
                    
                        <div className="section-service-overview">
                            <div className="d-flex align-center space-between">
                                <div className="overview-heading">
                                    Container Details and Request Quota
                                </div>
                                {is_permitted ?

                                    state.status == "DRAFT" ?
                                        <Link to={{
                                            pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/sidecars/edit/?env_cd_detail_id=" + env_cd_id,
                                            query: {
                                                selectedTabOrder: 1,
                                                sidecar_id: state.id,
                                                env_cd_id: env_cd_id,
                                                service_env_data: service_env_data
                                            }
                                        }}>
                                            <BorderColorIcon style={{ color: '#0086ff' }} />
                                        </Link> :
                                        <EditDetailsConfirmationDialog
                                            application_id={application_id}
                                            cd_data={prev_state}
                                            service_id={service_id}
                                            auto_approval_flag={auto_approval_flag}
                                            component_env_id={component_env_id}
                                            selectedTabOrder={1}
                                            sidecar_id={state.id}
                                            env_cd_id={env_cd_id}
                                            service_env_data={service_env_data}
                                        />

                                    : <Tooltip title="You are not allowed to perform this action" >
                                        <BorderColorIcon style={{ color: '#818078' }} />
                                    </Tooltip>}


                            </div>

                            <div className="overview-card-section grid-temp-3-widthfull">
                                <div className="overview-single-card">
                                    <div className="overview-card-heading-section">
                                        <div className="text__box">
                                            <p className="overview-card-value-text text-uppercase">
                                                Deployment Details
                                            </p>
                                            {/* <p className="no-data__msg ">NA</p> */}

                                        </div>
                                    </div>
                                    <div className="overview-card-value-access" style={{ gap: '0px 20px' }}>
                                        {/* <p className="overview-card-value-text">
                                        Service Name:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.name}
                                        </span>
                                        <span className="no-data__msg "></span>
                                    </p> */}
                                        
                                        <p className="overview-card-value-text">Container Name:&nbsp;<span className="overview-card-value-text">{state.name}</span><span className="no-data__msg "></span></p>
                                    <p className="overview-card-value-text">Container Type:&nbsp;<span className="overview-card-value-text">{state.container_type}</span><span className="no-data__msg "></span></p>
                                    <p className="overview-card-value-text">Tag:&nbsp;<span className="overview-card-value-text">{state.tag}</span><span className="no-data__msg "></span></p>
                                        {/* <p className="overview-card-value-text">
                                        Deployment Name:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.deployment_name}
                                        </span>
                                        <span className="no-data__msg "></span>

                                    </p> */}
                                        {/* <p className="overview-card-value-text">
                                        Desired Replication:&nbsp;
                                        <span className="overview-card-value-text">
                                            {state.desired_replication}
                                        </span>
                                        <span className="no-data__msg "></span>

                                    </p> */}
                                    </div>
                                </div>
                                <div className="overview-single-card">
                                    <div className="overview-card-heading-section">
                                        <p className="overview-card-heading">
                                            Requested Quota
                                        </p>
                                    </div>
                                    <div className="overview-card-value">
                                        <p className="overview-card-value-text">Memory Quota:&nbsp;<span className="overview-card-value-text">{state?.resource_quota?.requests_memory_quota}</span></p>
                                        {/* <p className="no-data__msg ">Data Not Available</p> */}
                                        <p className="overview-card-value-text">CPU Quota:&nbsp;<span className="overview-card-value-text">{state?.resource_quota?.requests_cpu_quota}</span></p>
                                    </div>
                                </div>
                                <div className="overview-single-card">
                                    <div className="overview-card-heading-section">
                                        <p className="overview-card-heading">
                                            Specified Limit Quota
                                        </p>
                                        {state?.resource_quota?.limits_memory_quota == state?.resource_quota?.requests_memory_quota || state?.resource_quota?.requests_cpu_quota == state?.resource_quota?.limits_cpu_quota ?
                                            <span className="overview-chip-no">
                                                No
                                            </span> :
                                            <span className="overview-chip-yes">
                                                Yes
                                            </span>
                                        }

                                    </div>
                                    <div className="overview-card-value">
                                        <p className="overview-card-value-text">Limit Memory Quota:&nbsp;<span className="overview-card-value-text">{state?.resource_quota?.limits_memory_quota}</span><span className="no-data__msg "></span></p>
                                        {/* <p className="no-data__msg ">Data Not Available</p> */}
                                        <p className="overview-card-value-text">Limit CPU Quota:&nbsp;<span className="overview-card-value-text">{state?.resource_quota?.limits_cpu_quota}</span><span className="no-data__msg "></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    :
                    <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
                        <div className="d-flex align-center space-between">
                            <div className="overview-heading">
                                Request Quota
                            </div>

                            {is_permitted ?

                                state.status == "DRAFT" ?
                                    <Link to={{
                                        pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id +  "/cd/"+cd_id+"/edit",
                                        query: { selectedTabOrder: 2, selected_cd_id : extraProps?.selected_cd_data?.id }
                                    }}>
                                        <BorderColorIcon style={{ color: '#0086ff' }} />
                                    </Link> :
                                    <EditDetailsConfirmationDialog
                                        application_id={application_id}
                                        service_id={service_id}
                                        auto_approval_flag={auto_approval_flag}
                                        component_env_id={component_env_id}
                                        selectedTabOrder={2}
                                        cd_data={prev_state}
                                        selected_cd_id = {extraProps?.selected_cd_data?.id}
                                    />

                                : <Tooltip title="You are not allowed to perform this action" >
                                    <BorderColorIcon style={{ color: '#818078' }} />
                                </Tooltip>}
                        </div>
                        <div className="overview-card-section">
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">
                                        Requested Quota
                                    </p>
                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">Memory Quota:&nbsp;<span className="overview-card-value-text">{state.requests_memory_quota}</span></p>
                                    {/* <p className="no-data__msg ">Data Not Available</p> */}
                                    <p className="overview-card-value-text">CPU Quota:&nbsp;<span className="overview-card-value-text">{state.requests_cpu_quota}</span></p>
                                    </div>
                            </div>
                            <div className="overview-single-card">
                                <div className="overview-card-heading-section">
                                    <p className="overview-card-heading">Specified Limit Quota</p>
                                    {state.limits_memory_quota == state.requests_memory_quota || state.requests_cpu_quota == state.limits_cpu_quota ?
                                        <span className="overview-chip-no">No</span> :<span className="overview-chip-yes">Yes</span>
                                    }

                                </div>
                                <div className="overview-card-value">
                                    <p className="overview-card-value-text">Limit Memory Quota:&nbsp;<span className="overview-card-value-text">{state.limits_memory_quota}</span>
                                        <span className="no-data__msg "></span>
                                    </p>
                                    {/* <p className="no-data__msg ">Data Not Available</p> */}
                                    <p className="overview-card-value-text">Limit CPU Quota:&nbsp;<span className="overview-card-value-text">{state.limits_cpu_quota}</span>
                                        <span className="no-data__msg "></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            }


        </>
    )
}

ResourceQuotaOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ResourceQuotaOverview;