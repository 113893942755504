import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Loading } from '../../utils/Loading';
import AlertStrip from '../../../components/AlertStrips';
import { Input } from '../../../components/genericComponents/Input';

const BuildComponent = ({ isBranchRequiredForBuild, ...props }) => {
    var branch_load = props.branch_load;
    var branch_data = props.branch_data;
    var ci_configured = props.ci_configured;
    const [state, setState] = useState({
        data: {},
        open: false,
        error: {},
        validations: isBranchRequiredForBuild ? {
            branch: [VALIDATION_TYPE_REQUIRED]
        } : {},
        open_custom: false
    })
    var inherits = props.inherits ? props.inherits : {};
    var commonFunctions = getCommonFunctions(state, setState, inherits);


    function toggleState() {
        setState({
            ...state,
            open: !state.open,
        });
    }
    const onChangeHandler = (e) => {
        var no_build_cache = e.target.name;
        var no_build_cache_value = e.target.checked;
        console.log(no_build_cache,no_build_cache_value,"bvhjsbhbdfhvbhfd")
        if (!(no_build_cache == "tag" && no_build_cache_value.includes(" "))) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    [no_build_cache]: no_build_cache_value ? false : true,
                },
                errors: {
                    ...state.errors,
                    [no_build_cache]: "",
                }
            });
        }
    }

    const toggleTagState = () => {
        var custom_tag_flag = state.open_custom;
        if (custom_tag_flag) {
            setState({
                ...state,
                loading_tag: true,
                tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    tag: null
                });
            }, 200);
        } else {
            setState({
                ...state,
                loading_tag: true,
                custom_tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    custom_tag: null
                });
            }, 200);
        }

    }

    console.log(state, 'indise_statedd')
    return (
        <>
            {
                ci_configured === undefined ? null : !ci_configured ?
                    <AlertStrip
                        variant="error"
                        dismissible={false}
                        message="Build Details are not configured for the selected service."
                        extraClasses="ml-10 mr-10" /> : 
                        <div className="section-input section-input-1-1 width-full pd-10" style={{ gridTemplateColumns: '280px 280px 1fr', alignItems : "flex-start" }}>
                            <div className="d-grid align-center">
                                <Input
                                    type={state.open ? "text" : "auto-complete-dropdown"}
                                    label="Branch"
                                    name="branch"
                                    id={"branch_select"}
                                    placeholder={"Enter value"}
                                    getOptionLabel={(option) => option.label}
                                    list={branch_data ? branch_data.branches : []}
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={state.open ? commonFunctions.onChangeHandler : commonFunctions.onKeyValueChangeHandler}
                                    extraDiv={<button className="btn btn-clear mr-0 btn-link text-anchor-blue pr-0" onClick={toggleState}>{state.open ? "Choose Tag" : "Enter Branch name"}</button>}
                                />
                            </div>
                            {
                                state.loading_tag ? <Loading varient="light" /> :
                                    <Input
                                        type={"text"}
                                        name={state.open_custom ? "custom_tag" : "tag"}
                                        placeholder={state.open_custom ? "custom-tag" : "tag"}
                                        subHeading="(Optional)"
                                        label="Tags"
                                        mandatory
                                        data={state.data}
                                        error={state.error}
                                        onChangeHandler={(branch_load === 'Loading' || branch_load === undefined) ? false : commonFunctions.onChangeHandler}
                                        extraDiv={<span role='button' tabIndex={0}  className="mr-0 cursor-pointer text-anchor-blue pr-0" onKeyDown={()=>{}} onClick={toggleTagState}>{state.open_custom ? "Choose Tag" : "Provide Custom Tag"}</span>}
                                    />
                            }
                            {/* <Input
                                type="text"
                                data={state.data}
                                error={state.error}
                                onChangeHandler={(branch_load === 'Loading' || branch_load === undefined) ? false : commonFunctions.onChangeHandler}
                                label="Tags"
                                name="build_tags"
                            /> */}
                            <div className="switch-build">
                                <Input
                                    type="switch"
                                    name="build_no_cache"
                                    label="Docker Cache"
                                    data={{}}
                                    onChangeHandler={(branch_load === 'Loading' || branch_load === undefined) ? false : onChangeHandler}
                                    error={{}}
                                />
                            </div>
                        </div>
                    
            }
        </>
    )
}

BuildComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default BuildComponent
