import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { RemoveFromArray } from '../../../util/util';
import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const CheckboxList = (props) => {
    const list = props.list ? props.list : [];
    const selected_checkboxes = props.selected_checkboxes ? props.selected_checkboxes : [];
    const name = props.name;
    const varient = props.varient;
    const onChangeHandler = props.onChangeHandler;
    const onTextClick = props.onTextClick;
    const text = props.text;
    const listError = props.listError ? props.listError : {}
    const jira_status = props.jira_status ? props.jira_status : false;
    console.log("dbhsa", list);
    console.log("njgnfjfsd", selected_checkboxes);
    console.log(listError, 'lsfttttp')


    const handleChange = (e) => {
        let return_list = selected_checkboxes;

        if (e.target.checked) {
            return_list.push(e.target.value);
            console.log("fsdjknsjkn", e, return_list, onChangeHandler)
            onChangeHandler(name, return_list);
        } else {
            return_list = RemoveFromArray(return_list, Number(e.target.value) ? Number(e.target.value) : e.target.value);
            onChangeHandler(name, return_list)
        }
    }

    const selectAll = (e) => {
        let return_list = [];
        if (e.target.checked) {
            list.forEach(element => {
                return_list.push(element.id);
            });
        }

        onChangeHandler(name, return_list);
    }

    const selectAllForJiraStatus = (e) => {
        var return_list = [];
        if (e.target.checked) {
            list.forEach(element => {
                return_list.push(element.label);
            });
        }

        onChangeHandler(name, return_list);
    }

    const handleOnTextClick = (e, data) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation()
        onTextClick(data)
    }
    const handleOnTextClickForEdit = (e, data) => {
        console.log(data, 'dgffff')
        e.preventDefault()
        onTextClick(data)
    }


    const Element = ({ label, change_text, data, error }) => {

        return (
            <div style={{ display: 'inline-block' }}>
                <span className="mr-10">{label}</span>
                {change_text && <span onKeyDown={() => { }} onClick={(e) => handleOnTextClick(e, data)} style={{ color: error ? 'red' : '#0086ff', cursor: 'pointer' }} role="button" tabIndex={0}>Change Branch and tag</span>}
            </div>)
    }

    Element.propTypes = {
        ...PropTypes.objectOf(PropTypes.any),
    }

    const ElementForEdit = ({ label, change_text, data, error }) => {

        return (
            <div style={{ display: 'inline-block' }}>
                <label className="mr-10">{label}</label>
                {change_text && <label onClick={(e) => handleOnTextClickForEdit(e, data)} style={{ color: '#0086ff', cursor: 'pointer' }}>{text ? text : 'Change Variables'}</label>}

            </div>)
    }


    if (varient == "inner_component") {
        console.log('list_list', list)
    }
    const classes = useStyles();
    console.log("dsbjdja", list.length == selected_checkboxes.length);
    function getCheckboxBasedOnVarient() {
        switch (varient) {
            case "inner_component":
                return (
                    <InnerCard>
                        <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={jira_status ? selectAllForJiraStatus : selectAll} name={name} value={""} />} label={"All"} />

                        <div className="divider"></div>
                        {
                            list.map(element => (

                                <FormControlLabel control={<Checkbox checked={jira_status ? selected_checkboxes.includes(element.label) : selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={jira_status ? element.label : element.id} />} label={element.label} />

                            ))
                        }
                    </InnerCard>
                );
            case "inner_component_with_input":
                return (
                    <InnerCard>
                        <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} />

                        <div className="divider"></div>
                        {
                            list.map(element => (
                                <div className='d-flex align-center'>
                                    <div style={{ width: '20%' }}>
                                        <FormControlLabel control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={element.label} />
                                    </div>
                                    <div style={{ width: '60%', marginTop: '20px' }} className={classes.root + ' ' + 'multi-select-checkbox'}>
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            options={list}
                                            getOptionLabel={(option) => option.label}
                                            value={[]}
                                            onChange={handleChange}
                                            getOptionSelected={(option, value) => option.label === value.label}
                                            renderOption={(option, { selected }) => (
                                                <div>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected || selected_checkboxes.includes(option.id)}
                                                        value={option.id}
                                                        name={name}
                                                    />
                                                    {option.label}
                                                </div>
                                            )}
                                            renderInput={(params) => (

                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label=""
                                                    placeholder={props.placeholder ? props.placeholder : 'Enter Issue Name'}
                                                />

                                            )}
                                        />

                                    </div>
                                </div>
                            ))
                        }
                    </InnerCard>
                );
            case "checkbox-with-change-text":
                return (
                    <Root>
                        <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} />
                        <div className="divider"></div>
                        <InnerDiv className='grid-layout-controller'>

                            {
                                list.map(element => (
                                    <><FormControlLabel onChange={() => { }} control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={<Element label={element.label} data={element} change_text={selected_checkboxes.includes(element.id)} />} />
                                        {/* <label>Change</label> */}
                                    </>
                                ))
                            }
                        </InnerDiv>
                    </Root>
                );
            case "checkbox-with-change-text-error":
                return (
                    <Root>
                        <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} />
                        <div className="divider"></div>
                        <InnerDiv className='grid-layout-controller'>

                            {
                                list.map(element => (
                                    <><FormControlLabel onChange={() => { }} control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={<Element error={listError[element.id]} label={element.label} data={element} change_text={selected_checkboxes.includes(element.id)} />} />
                                        {/* <label>Change</label> */}
                                    </>
                                ))
                            }
                        </InnerDiv>
                    </Root>
                );
            case "without-all-button":
                return (
                    <InnerCard className="checkbox-controller">

                        {
                            list.map(element => (

                                <FormControlLabel control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={element.label} />
                            ))
                        }
                    </InnerCard>
                );
            case "checkbox-with-change-text-and-edit":
                return (
                    <Root>
                        <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} />
                        <div className="divider"></div>
                        <InnerDiv className='grid-layout-controller'>

                            {
                                list.map(element => (
                                    <><FormControlLabel onChange={() => { }} control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={<ElementForEdit label={element.label} data={element} change_text={selected_checkboxes.includes(element.id)} />} />
                                        {/* <label>Change</label> */}
                                    </>
                                ))
                            }
                        </InnerDiv>
                    </Root>
                );

        }
    }

    console.log(list, 'c_t_b', list)
    return (
        <>
            {varient ?
                getCheckboxBasedOnVarient()
                :
                <Root>

                    <FormControlLabel control={<Checkbox checked={list.length == selected_checkboxes.length} onChange={selectAll} name={name} value={""} />} label={"All"} />

                    <div className="divider"></div>
                    <InnerDiv className='grid-layout-controller'>
                        {
                            list.map(element => (
                                <FormControlLabel control={<Checkbox checked={selected_checkboxes.includes(element.id)} onChange={handleChange} name={name} value={element.id} />} label={element.label} />
                            ))
                        }
                    </InnerDiv>
                </Root>
            }
        </>

    );
}

CheckboxList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default CheckboxList;

const Root = styled('div')({
    backgroundColor: '#fff',
    border: '1px solid #b7b7b7',
    borderRadius: '4px',
    minHeight: '50px',
    fontFamily: '"montserrat", sans-serif',
    '& .MuiFormControlLabel-root': {
        display: 'block',
        padding: '0px 10px',
        fontFamily: '"montserrat", sans-serif',
    }

})
const InnerCard = styled('div')({
    border: '1px solid #dedede',
    display: ' block',
    maxHeight: '200px',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    fontFamily: '"montserrat", sans-serif',
    '& .MuiFormControlLabel-root': {
        display: 'block',
        padding: '0px 10px',
        fontFamily: '"montserrat", sans-serif',
    }
})

const InnerDiv = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',

})
