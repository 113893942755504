import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import BasicInfo from './BasicInfo';

import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const AddClusterFlow = props => {
    const {cluster_id} = useParams();
    const classes = useStyles();
    return (
            <>
                <Grid container className="pd-20" justifyContent='center'>
                    <div className={classes.main}>
                        {/* <Grid item lg={12} >
                            <Heading />
                        </Grid > */}
                        <Grid item lg={12} >
                            <BasicInfo cluster_id = {cluster_id}  />
                        </Grid>
                    </div>
                </Grid >
            </>
    )
}
export const Heading = () => {
    const classes = useStyles();
    return (
        <div className={classes.heading}>
            <div>
                <img src='/images/cluster-head.svg' alt="no-img-found"/>
            </div>
            <div>
                <p className="font-20 font-weight-600 color-value font-family-v1">Onboarding your kubernete Clusters</p> 
                <p className="font-12 font-weight-400 color-icon-secondary" style={{width: "76%"}}>
                    Onboarding an existing cluster involves registering the existing cluster with BuildPiper. To register the cluster we need the kube-config Yaml file along with the ability to communicate with the cluster.&nbsp;
                    <Link to={"/cluster-permissions"}>
                        <span className='font-12 btn-lite-blue font-weight-500'>View required Permissions</span>
                    </Link>
                   
                </p>
            </div>
            
        </div>

    );
}

export default AddClusterFlow;

const useStyles = makeStyles(theme => ({
    main: {
        //backgroundColor: "#FFFFFF",
    },
    heading: {
        display: "flex",
        gap: "12px",
        padding: "24px 20px 26px 20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
    },
}))