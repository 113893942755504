import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

import { ErrorComponent } from "../../utils/Error";

import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import properties from "../../../properties/properties";
import InvokeApi from "../../../util/apiInvoker";
import { Link, useParams } from "react-router-dom";
import EnvCard from "./EnvCard";
import { Loading } from "../../utils/Loading";
import Pagination from "../../../components/Pagination";
import SearchBar from "../../../components/SearchBar";
import { PostData } from "../../../util/apiInvoker";
import { Tooltip } from '@material-ui/core';
import { GetAuth } from "../../../util/security";
const EnvList = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    environment_list: [],
    total_page: "",
    curr_page: "",
    compareData: {},
    count: 0,
    counter: 0,
  });

  const toggleOpen = () => {
    setState({
      ...state,
      open: !state.open,
    });
  };
  const { application_id } = useParams();
  const applicationId = application_id;
  const [showLoading, setShowLoading] = useState(false);

  var url = GenerateURL({ application_id: applicationId }, properties.api.save_env, true)
  const is_permitted = GetAuth().permission.POST.includes(url);

  console.log(is_permitted, "skdbfhdsbhfbsdfhbhsd")

  useEffect(() => {
    fetchEnvironmentInfo();
  }, [applicationId, state.counter]);

  function fetchEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_dashboard_new
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
      search_query_name: data ? (data.name ? data.name : "") : "",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentInfoFetchSuccess,
      EnvironmentInfoFetchFailure
    );
  }

  function EnvironmentInfoFetchSuccess(data) {
    console.log(data, "data success");
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
    setShowLoading(false);
  }
  function EnvironmentInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  function fetchPrevEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentPrevInfoFetchSuccess,
      EnvironmentPrevInfoFetchFailure
    );
  }

  function EnvironmentPrevInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
    setShowLoading(false);
  }
  function EnvironmentPrevInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  function fetchNextEnvironmentInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    InvokeApi(
      requestInfo,
      EnvironmentNextInfoFetchSuccess,
      EnvironmentNextInfoFetchFailure
    );
  }

  function EnvironmentNextInfoFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
    setShowLoading(false);
  }

  function EnvironmentNextInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  //start of page code----------------------------------------------

  const fetchPageEnvironmentInfo = (enteredPageNumber) => {
    // console.log(enteredPageNumber, "page number coming from pagination");

    var requestInfo = {
      endPoint: GenerateURL(
        { application_id: applicationId },
        properties.api.env_listing
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    setState((new_state) => ({
      ...new_state,
      current: requestInfo.endPoint,
      data_loading: "LOADING",
    }));
    setShowLoading(true);
    var current_page = enteredPageNumber;
    InvokeApi(
      requestInfo,
      (response) => {
        EnvironmentPageInfoFetchSuccess(response, current_page);
      },
      EnvironmentPageInfoFetchFailure
    );
  };

  function EnvironmentPageInfoFetchSuccess(data, count) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "SUCCESS",
      environment_list: data.results,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
    setShowLoading(false);
  }

  function EnvironmentPageInfoFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      data_loading: "FAILED",
      error: true,
    }));
    setShowLoading(false);
  }

  //end of page code---------------------------------------------------------------

  const invokeSearch = (data) => {
    fetchEnvironmentInfo(data);
  };

  function addToCompare(e, data) {
    if (e.target.value === true) {
      setState((new_state) => ({
        ...new_state,
        compareData: {
          ...new_state.compareData,
          [e.target.name]: data,
        },
      }));
    } else {
      var temp = state.compareData;
      delete temp[e.target.name];
      setState((new_state) => ({
        ...new_state,
        compareData: temp,
      }));
    }
  }

  if (Object.entries(state.compareData).length > 2) {
    window.alert("You cannot select more than 2 environments");
  }

  // const addEnvironmentLink = "/application/" + applicationId.toString() + "/Environment/add";
  window.localStorage.setItem("envs", JSON.stringify(state.compareData));
  console.log("env-list", state);

  function sendApprovalRequest(id) {
    var post_url = GenerateURL({}, properties.api.approval_request);

    var post_obj = {
      "name": "project_env",
      "entity_id": id
    }

    PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function sendApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_request_success_msg: msg,
    }));

  }

  function sendApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      deployment_failed_msg: error
    }));

  }

  function confirmForApproval(id) {
    var post_url = GenerateURL({}, properties.api.confirm_approval);

    var post_obj = {
      "name": "project_env",
      "entity_id": id
    }

    PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
    setState(new_state => ({
      ...new_state,
      loading: true,
      error_msg: null,
      open: true
    }));
  }

  function confirmApprovalRequestSuccess(response) {
    refreshState()
    var msg = response.detail
    setState(new_state => ({
      ...new_state,
      approval_confirm_success_msg: msg,
    }));
  }

  function confirmApprovalRequestFailed(error) {
    var error = error.toString()
    setState(new_state => ({
      ...new_state,
      approval_failed_msg: error
    }));
  }

  function refreshState() {
    setState(new_state => ({
      ...new_state,
      counter: new_state.counter + 1
    }));
  }

  return (
    <>
      {state.data_loading == "SUCCESS" ? (
        <div className={classes.root}>
          {/* <div className="heading-section-service pd-10 d-flex align-center space-between" style={{ marginBottom: '32px' }}>
                        <div>
                            <div className="main-heading">Environment Overview</div>
                            <div className="sub-heading-text">Displaying overall summary of the environments attached with this project.</div>
                        </div>
                        {Object.entries(state.compareData).length === 2 ?
                            <Link to={{
                                pathname: "/application/" + application_id + "/env/compare",
                                envs: state.compareData
                            }}>   <button className="btn btn-submit">
                                Compare Environements
                    </button></Link>
                            : null}
                        {state.environment_list.length > 0 ?
                            <Link to={"/application/" + application_id + "/env/add"}>   <button className="btn btn-submit">
                                Add Environment
                    </button></Link>
                            : null}
                    </div> */}

          <div className="d-flex align-center space-between" style={{marginBottom: "16px"}}>
            <div className="main-div">
              <p className="heading-primary">Environment Overview</p>
              <p className="sub-heading-primary">
                Displaying overall summary of the environments attached with
                this project.
              </p>
            </div>
            {
              is_permitted ?
                <Link
                  to={"/application/" + application_id + "/env/add"}
                  className="btn btn-primary"
                >
                  New Environment
                </Link>
                :
                <Tooltip title="You are not allowed to perform this action">
                  <button
                    className="btn btn-disable cursor-not-allowed"
                  >
                    New Environment
                  </button>
                </Tooltip>

            }
          </div>
          <Grid container spacing={2}>
            <Grid lg={12} item>
              {showLoading ? <Loading /> : null}
              {state.environment_list.length > 0 ? (
                <Grid container spacing="2" className="search-containter" justifyContent="space-between">
                  <Grid item lg={5}>
                    <SearchBar
                      search_data={state.search_data}
                      default_filter={{ name: "name", label: "Name" }}
                      search_call_back={invokeSearch}
                      clear_search_callback={fetchEnvironmentInfo}
                    />
                  </Grid>

                  <Grid item lg={3} sm={12} xl={3} xs={12}>
                    <Pagination
                      total_count={state.total_page}
                      current_page_count={state.curr_page}
                      next={state.next}
                      previous={state.previous}
                      on_previous_click={() => {
                        fetchPrevEnvironmentInfo(null, state.previous);
                      }}
                      on_next_click={() => {
                        fetchNextEnvironmentInfo(null, state.next);
                      }}
                      on_pageNumber_click={(pageNumber) => {
                        fetchPageEnvironmentInfo(pageNumber);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {state.environment_list.length > 0 ? (
              state.environment_list.map((env) => (
                <EnvCard
                  addToCompare={addToCompare}
                  refresh={fetchEnvironmentInfo}
                  data={env}
                  application_id={application_id}
                  sendForApproval={sendApprovalRequest}
                  confirmForApproval={confirmForApproval}
                />
              ))
            ) : state.search_query_name ? (
              <div
                className="main-container-error"
                style={{ height: "60vh", margin: "auto" }}
              >
                <div className="svg">
                  <div className="servicePanel">
                    <div className="blank-div">
                      <div className="blank-div-text">
                        No Env found with the name : {state.search_query_name}
                      </div>
                      <button
                        className="btn btn-submit"
                        onClick={fetchEnvironmentInfo}
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="main-container-error"
                style={{ height: "60vh", margin: "auto" }}
              >
                <div className="svg">
                  <div className="servicePanel">
                    <div className="blank-div">
                      <div className="blank-div-text">
                        You have 0 Environment associated with this app
                      </div>
                      {
                        is_permitted ?

                          <Link
                            to={"/application/" + application_id + "/env/add"}
                            className="btn btn-submit"
                          >
                            Add Environment
                          </Link>
                          :
                          <Tooltip title="You are not allowed to perform this action">
                            <button
                              className="btn btn-disable cursor-not-allowed"
                            >
                              Add Environment
                            </button>
                          </Tooltip>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </div>
      ) : state.data_loading == "FAILED" ? (
        <ErrorComponent variant="error-box" error={state.error} />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default EnvList;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    "& .main-heading": {
      fontSize: "24px",
      fontWeight: "300",
      lineHeight: 1,
    },
    "& .search-containter": {
      marginTop: -15,
    },
    "& .img-box": {
      width: "144px",
      height: "168.5px",
      margin: "8px",
      border: "1px solid #dedede",
      borderRadius: "8px",
      backgroundColor: "#fff",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      "& .img-pos": {
        width: "auto",
        border: "none",
        height: "50px",
        display: "block",
        margin: "auto",
      },
      "& .img-box-footer": {
        height: "41px",
        display: "flex",
        padding: "8px",
        background: "#f0f3f5",
        borderTop: "2px solid #dedede",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "11px",
        color: "#16232b",
      },
    },
    "& .card": {
      marginTop: "32px",
      "& .card-header": {
        fontSize: "12px",
        height: "auto",
        backgroundColor: "#fff",
      },
      "& .pd-20": {
        padding: "10px 20px!important",
      },
      "& .with-header": {
        borderTop: "1px solid #dedede",
      },
      "& .table": {
        "& th": {
          padding: "1.25rem",
        },
        "& .td": {
          padding: "1.25rem",
        },
      },
    },
    "& .sub-header": {
      padding: "5px 10px",
      borderBottom: "1px solid #dedede",
      backgroundColor: "#f6f8f8",
      fontSize: "12px",
      "& .input-component": {
        marginBottom: "0px",
      },
    },
    "& .card-controller": {
      "& .card": {
        border: "none",
        borderRadius: "0px",
      },
      "& .makeStyles-cardHeader-228": {
        padding: "5px 10px",
      },
    },
  },
}));
