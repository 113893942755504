import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SquareAvatar from '../../../../../components/genericComponents/AvatarSquare';
const AppBarHistory = props => {
  const classes = useStyles();
  const env_id = props.env_id;
  const service_data = props.service_data;
  var service_env = service_data && service_data.service_env;
  service_env = service_env ? service_env.toLowerCase() : null
  var service_env_name = service_env ? service_env : props.service_env_name ? props.service_env_name : null
  var varient = props.varient ? props.varient : null;
  const refresh = props.refresh ? props.refresh : null;
  // function getEnvName(service_data, env_id) {
  //     if (service_data.component_env) {
  //       for (let index = 0; index < service_data.component_env.length; index++) {
  //         if (env_id == service_data.component_env[index].id) {
  //           return service_data.component_env[index].project_env.name;
  //         }
  //       }
  //     }
  //   }
  const EnvBackgroundColor = {
    DEV: "#0086FF",
    QA: "#129E5B",
    UAT: "#FE9111",
    PROD: "#7B61FF",
    STAGING: "#EF5DBD",
  }
  return (
    <>
      {
        varient == "new" ?
          <div className={classes.headContainer}>
            <div className='d-flex' style={{ gap: "12px" }}>
              <SquareAvatar varient="double" name={service_data?.name.toUpperCase()} />
              <div className='head-info'>
                <div className='d-flex align-center'>
                  <span className='font-18 font-weight-600 color-value'>{service_data?.name.toUpperCase()}</span>
                  <span className={classes.envChip} style={{ backgroundColor: `${EnvBackgroundColor[service_data && service_data?.type]}` }}>{service_data && service_data.type}</span>
                </div>
                <div className='font-13 color-key-78'>
                  Environment : <span className='color-value'>{service_env_name}</span>
                </div>
              </div>
            </div>
            <div className='d-flex align-center' style={{ gap: "8px" }}>
              {refresh &&
                <button className='btn-sq-icon-primary d-flex align-center' onClick={refresh}>
                  <span className='ri-refresh-line font-20'></span>
                </button>
              }
              {props.handleClose &&
                <button className='icon-btn-v1 icon-btn-outline-danger' onClick={props.handleClose}>
                  <span className='ri-close-fill font-24'></span>
                </button>
              }

            </div>
          </div>
          :
          varient = 'updated-new-v3' ?
            <div className={classes.newContainer}>
              <div className='d-flex' style={{ gap: "12px" }}>
                <SquareAvatar varient="double" name={service_data?.name.toUpperCase()} />
                <div className='head-info'>
                  <div style={{ marginBottom: "3px" }} className='d-flex align-center'>
                    <span className='font-18 font-weight-600 color-value'>{service_data?.name.toUpperCase()}</span>
                    <span className='envChip' style={{ backgroundColor: `${EnvBackgroundColor[service_data && service_data?.type]}` }}>{service_data && service_data.type}</span>
                  </div>
                  <div className='font-12 color-key-78'>
                    Environment : <span className='color-value'>{service_env_name}</span>
                  </div>
                </div>
              </div>
              <div className='d-flex align-center' style={{ gap: "8px" }}>
                {refresh &&
                  <button className='btn-sq-icon-primary d-flex align-center' onClick={refresh}>
                    <span className='ri-refresh-line font-20'></span>
                  </button>
                }
                {props.handleClose &&
                  <button className='icon-btn-v1 icon-btn-outline-danger' onClick={props.handleClose}>
                    <span className='ri-close-fill font-24'></span>
                  </button>
                }

              </div>
            </div>
            :
            <AppBar className={classes.appBar}>
              <Toolbar>
                <div className="service-data">
                  <div className="service-name"><b>{service_data && service_data.name}</b></div>
                  <div className="d-flex">
                    {
                      !props.job_name ? <><div classname="service-env" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Environment Name: <b>{service_env_name}</b></div>
                        <div classname="service-env-type" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Type: <b>{service_data && service_data.type}</b></div>
                      </>
                        :
                        <>
                          <div classname="service-env" style={{ fontSize: '14px', marginRight: '3px', letterSpacing: '1px' }}>Job Name: <b>{props.job_name}</b></div>
                          {/* <div classname="service-env-type" style={{ fontSize: '11px', marginRight: '3px', letterSpacing: '1px' }}>Type: <b>{service_data && service_data.type}</b></div> */}
                        </>
                    }
                  </div>
                </div>
                <IconButton style={{ marginLeft: 'auto' }} edge="end" color="inherit" onClick={props.handleClose} aria-label="close">
                  <CloseIcon style={{ fontSize: '24px' }} />
                </IconButton>
              </Toolbar>
            </AppBar>
      }

    </>

  )
}

AppBarHistory.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default AppBarHistory;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    '& .service-name': {
      color: '#fff',
      fontWeight: 400,
      fontFamily: 'Nunito',
      lineHeight: 1.2,
      fontSize: ' 20px',
      margin: '0px',

    },
    '& .service-env': {
      fontSize: '11px',

    }
  },
  headContainer: {
    display: "flex",
    padding: "12px 20px",
    marginBottom: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    position: "sticky",
  },
  envChip: {
    padding: "4px 8px 4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "700",
    color: "#FFFFFF",
    marginLeft: "12px",
  },
  newContainer: {
    display: "flex",
    padding: "12px 20px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    position: "sticky",
    '& .envChip': {
      height:'23px',
      padding: "4px 8px 4px 8px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: "700",
      color: "#FFFFFF",
      marginLeft: "12px",
    }
  },

}));
