import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';

import moment from 'moment';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { LoadingText } from '../../../utils/Loading';


import { getDuration } from '../../../../util/util';

import { GetAuth } from '../../../../util/security';
import ActivityStepDetails from '../../../superAdmin/activityFramework/components/ActivityStepDetails';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import StorageIcon from "@material-ui/icons/Storage";
import { Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';

const CurrentProgressData = (props) => {
  const type = props.type;
  const service_id = props.service_id;
  const env_id = props.env_id;
  const application_id = props.application_id;
  const service_data = props.service_data;
  const env_name = props.env_name;
  const top_summary_data = props.top_summary_data;
  const env_name_service = props.service_env_name;
  const recent_history = props.recent_history ? props.recent_history : null;
  const is_env_down = props.is_env_down ? props.is_env_down : null;
  var component_env = service_data && service_data.component_env ? service_data.component_env.map(x =>
    (x.project_env && x.project_env.name)
  ) : []
  component_env = component_env ? component_env.map(item => (item)) : null
  var service_name = service_data.name ? service_data.name : top_summary_data ? top_summary_data.service_name : null;

  var service_env = service_data.env ? service_data.env : top_summary_data ? top_summary_data.env_name : null;
  service_env = service_env ? service_env.toLowerCase() : null;

  console.log(service_data, "service data in build strip =========>")

  console.log(top_summary_data, "top summary data in build strip =========>")

  var service_env_type = service_data.type ? service_data.type : top_summary_data ? top_summary_data.type : null;
  service_env_type = service_env_type.toLowerCase();
  var revokeUrlPath = type == "BUILD" ? properties.api.env_build_revoke_url : type == "DEPLOY" ? properties.api.env_deploy_revoke_url : properties.api.env_promote_revoke_url;
  var refresh_count = props.refresh_count;
  const [state, setState] = useState({
    history_list: [],
    activity_list: []
  });


  const buildId = state.last_build && state.last_build.id ? state.last_build.id : null
  const build_status = state?.last_build?.activity_status?.status
  const build_by_user = useMemo(() => getBuildByUserName(state.last_build), state.last_build)
  const image = state?.last_build?.build_tag
  const build_number = state?.last_build?.build_number

  const workspaceParams = { action_number: build_number, action_status: build_status, action_by: build_by_user, image: image }

  function getEnvNamefromId() {
    var env_name = "";
    if (service_data && service_data.component_env) {
      service_data.component_env.map(item => {
        if (item.id == env_id) {
          env_name = item.project_env.name
        }
      })
    }
    return env_name;
  }
  var service_env_name = service_env ? service_env : getEnvNamefromId()
  function fetchRecentBuildDeployData() {
    var requestInfo = {
      endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_ci_cd_data_url),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setState(new_state => ({
      ...new_state,
      loading: true
    }));
    InvokeApi(requestInfo, onfetchRecentBuildDeployDataSuccess, onfetchRecentBuildDeployDataFail);

  }

  function onfetchRecentBuildDeployDataSuccess(response) {
    if (buildId) {
      removeArrData()
    }
    setState(new_state => ({
      ...new_state,
      ci_cd_data: response,
      last_build: response.last_build,
      last_deploy: response.last_deploy,
      last_promote: response.last_promote,
      loading: false
    }));

  }

  function onfetchRecentBuildDeployDataFail(response) {
    setState(new_state => ({
      ...new_state,
      error: response,
      loading: false
    }));
  }

  useEffect(() => {
    fetchRecentBuildDeployData();

  }, [type, env_id, refresh_count])


  function onRevoke(task_id) {
    var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: task_id }, revokeUrlPath);
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
    setState(new_state => ({
      ...new_state, history_info_status: "REQUESTED",
    }));
  }
  function handleSuccessRevoke(respone) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      loading: false
    }));
    props.refresh();
  }
  function handleFailedRevoke(error) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      error: true,
      loading: false
    }));
  }

  function getBuildbyUser(history) {
    return (
      <>{history && history.build_by_pipeline ?
        <div className='text-ellipsis-twoline'>
          <p>Pipeline: {history.build_by_pipeline.pipeline.name}</p>
          <p>Triggered by: {history.build_by_pipeline.trigger_by}</p> </div>
        : <p className='text-ellipsis-110'>{history?.build_by_user}</p>
      }</>
    )

  }

  function getBuildByUserName(history) {
    console.log(history, 'hshhshshs');
    if (history) {

      if (history.build_by_pipeline) {
        return history.build_by_pipeline.trigger_by
      }

      else
        return history.build_by_user

    }
  }

  console.log(getBuildByUserName(state.last_build), 'hereh_data_now')

  function validateAndTrigger(type) {

    var data = {
      custom_tag: state.last_build && state.last_build.build_args_key_value ? state.last_build.build_args_key_value.custom_tag : null,
      branch_name: state.last_build && state.last_build.build_args_key_value ? state.last_build.build_args_key_value.branch_name : null,
      no_cache: state.last_build && state.last_build.build_args_key_value ? state.last_build.build_args_key_value.no_cache : null,
    }
    if (!data.custom_tag) {
      delete data.custom_tag;
    }
    if (!data.branch_name) {
      delete data.branch_name;
    }
    var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);
    PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);

    setState({
      ...state,
      refresh_count: state.refresh_count + 1,
    });
  }
  function successBuildTriggerCallBack(response) {
    setState({
      ...state,
      trigger: "success",
      rebuild_status: "Current tag build scheduled",
      tag: "",
      branch_name: "",
      no_cache: false,
    });
    props.refresh()
  }

  function failedBuildTriggerCallBack(error) {
    console.log(error, "fdsajjfasjk")
    setState({
      ...state,
      trigger: "failed",
      rebuild_status: error.detail ? error.detail : null
    });
  }

  function getClassbyActivityStatus(activity_status, type) {

    if (type == "Revoke") {
      if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
        return "btn btn-flaticon"
      else
        return "btn btn-disabeld-flaticon"
    } else {
      if (type === "Resume") {

        if ((activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING") || activity_status && activity_status == "SUCCESS")

          return "btn btn-disabeld-flaticon btn-mui-svg"
        else
          return "btn btn-flaticon btn-mui-svg"
      } else {

        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")

          return "btn btn-disabeld-flaticon"
        else
          return "btn btn-flaticon"
      }

    }
  }
  function callFunctionbyStatus(activity_status, id) {
    if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
      onRevoke(id)
  }

  function onResume(id) {
    var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: id }, properties.api.build_resume);
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    PostData(url_temp, {}, handleSuccessResume, handleFailedResume);

  }


  function handleSuccessResume(respone) {

    setState(new_state => ({
      ...new_state,
      show_loading_icon: false,
      trigger: "success",
      rebuild_status: "Rebuild Scheduled Successfully"
    }));
  }

  function handleFailedResume(error) {
    setState(new_state => ({
      ...new_state,
      error: true,
      show_loading_icon: false,
      trigger: "failed",
      rebuild_status: error.detail
    }));
  }

  function callResumeFunctionbyStatus(activity_status, id) {

    if ((activity_status != "IN_QUEUE" || activity_status != "RUNNING") || activity_status != "SUCCESS")

      onResume(id)
  }



  function getActivityCurrentInfo(activity_id) {
    var requestInfo = {
      endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);


  }

  function getActivityCurrentInfoSuccess(response) {
    if (response && response.results.length > 0) {
      setState(new_state => ({
        ...new_state,
        activity_list: [...response.results],
        activity_step_details: {
          open_div: true
        }
      }));
    }

  }
  function getActivityCurrentInfoFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      error: true
    }));
  }
  const removeArrData = () => {
    setState(new_state => ({
      ...new_state,
      activity_list: [],
      activity_step_details: {
        open_div: state.activity_list.length > 0 ? true : false
      }
    }));
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickOpenPopover = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClickClosePopover = () => {
      setAnchorEl(null);
  };

  const OpenPopover = Boolean(anchorEl);
  const id = OpenPopover ? 'simple-popover' : undefined;


  const onHandleBuildRefresh = (type) => {

    props.refresh()
    getActivityCurrentInfo(state.last_build && state.last_build.activity_status ? state.last_build.activity_status.id ? state.last_build.activity_status.id : "" : "")

  }



  var url_build = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_build_request_url, true);
  const is_permitted_build = GetAuth().permission.POST.includes(url_build);


  function getHeaderBasedOnType() {
    switch (type) {
      case "BUILD":
        return (
          <>
            <th>Type</th>
            <th>Status</th>
            <th>Duration</th>
            <th>Date/Time</th>
            <th>Artifact</th>
            <th>Build by</th>
            <th>Build Parameters</th>
            {/* <th></th> */}
            <th > <button className="btn-round border-navy bg-clear-btn" icon="flaticon" onClick={() => onHandleBuildRefresh(type)}><span className="flaticon-refresh-button-1"></span></button></th>
          </>
        );
      default:
        return null;
    }
  }
  // onClick={()=>{onLog(history.id,history.global_task_id,"Build #"+history.build_number)}}

  function handleClose() {
    setState({
      ...state,
      trigger: null
    });
  }

  function getBodyBasedOnType() {

    switch (type) {
      case "BUILD":
        return (
          <>
            {state.last_build && state.last_build.activity_status ?
              <tbody>
                <tr>
                  <td>
                    <a href={"/logs?global_task_id=" + state.last_build.global_task_id +
                      "&tab_id=" + state.last_build.id +
                      "&service_name=" + service_name +
                      "&service_env=" + service_env +
                      "&service_type=" + service_env_type +
                      "&Tab_name=BUILD" +
                      "&num=" + state.last_build.build_number} target="_blank">
                      <span className="cursor-pointer text-anchor-blue">Build #{state.last_build.build_number}</span>
                    </a>
                  </td>

                  <Tooltip title={state.last_build.activity_status && state.last_build.activity_status.status}>
                    <td>

                      <div className="d-flex align-center">

                        {state.loading_status &&
                          <div className="loading-div loading-text-hide">
                            <LoadingText />
                          </div>}
                        <div className={state.last_build.activity_status.status === "FAILED" ?
                          "round-chip bg-round-red status-font" :
                          state.last_build.activity_status.status === "RUNNING" ?
                            "round-chip bg-round-blue status-font" :
                            state.last_build.activity_status.status === "REVOKED" ?
                              "round-chip bg-round-purple status-font" :

                              state.last_build.activity_status.status === "IN_QUEUE" ?
                                "round-chip status-chip-info status-font" :
                                "round-chip bg-round-green status-font"}>{state.last_build.activity_status.status == 'IN_QUEUE' ? 'IN QUEUE' : state.last_build.activity_status.status}</div>

                      </div>
                    </td>
                  </Tooltip>
                  <td>

                    {state.last_build.activity_status.status && state.last_build.activity_status.status != "RUNNING" ? getDuration(new Date(state.last_build.activity_status && state.last_build.activity_status.start_time), new Date(state.last_build.activity_status && state.last_build.activity_status.end_time)) == 0 ? "0s" : getDuration(new Date(state.last_build.activity_status && state.last_build.activity_status.start_time), new Date(state.last_build.activity_status && state.last_build.activity_status.end_time)) : getDuration(new Date(state.last_build.activity_status && state.last_build.activity_status.start_time), new Date())}
                  </td>
                  <td>{moment(state.last_build.activity_status && state.last_build.activity_status.start_time).fromNow()}</td>
                  <Tooltip title={state.last_build.build_tag}><td >{state.last_build.build_tag}</td></Tooltip>
                  <td>{getBuildbyUser(state.last_build)}</td>

                  <td>
                    {
                      state.last_build.build_args_key_value ?
                        Object.keys(state.last_build.build_args_key_value).map((element, key) => (
                          <Tooltip title={element + " : " + JSON.stringify(state.last_build.build_args_key_value[element])}>
                            <p className="text-ellipsis-150">
                              {element} : {JSON.stringify(state.last_build.build_args_key_value[element])}
                            </p>
                          </Tooltip>
                        ))
                        : <div className="text-color-grey blank-div-text">No Build Params</div>
                    }
                  </td>

                  <td colSpan={2}>
                    <div className="btn-group btn-icon-group width-fitcontent ml-auto">
                      
                        <Tooltip title="View Activity Details">

                          <button className="btn btn-flaticon"
                            onClick={() => { getActivityCurrentInfo(state.last_build && state.last_build.activity_status ? state.last_build.activity_status.id ? state.last_build.activity_status.id : "" : "") }} >
                            <span className="material-icons material-symbols-outlined" style={{ color: '#0086ff' }}>
                              notes
                            </span>
                          </button>
                        </Tooltip>
                      
                      {
                        is_permitted_build ?
                          
                            <Tooltip title="Rebuild">
                            {
                              is_env_down ? 
                              <button
                                className={state.last_build.activity_status.status ? getClassbyActivityStatus(state.last_build.activity_status.status, "Rebuild") : null}
                                onClick={handleClickOpenPopover}
                              >
                                <span className="material-icons" style={{ color: '#0086ff' }}>
                                  flash_on
                                </span>
                              </button>
                              :
                              <button
                                className={state.last_build.activity_status.status ? getClassbyActivityStatus(state.last_build.activity_status.status, "Rebuild") : null}
                                onClick={
                                  state.last_build.activity_status.status ?
                                    state.last_build.activity_status.status == "FAILED" || state.last_build.activity_status.status == "SUCCESS" || state.last_build.activity_status.status == "REVOKED" ? () => { validateAndTrigger("BUILD") } : () => { } : () => { }}
                              >
                                <span className="material-icons" style={{ color: '#0086ff' }}>
                                  flash_on
                                </span>
                              </button> }
                            </Tooltip>
                          
                          :
                          
                            <Tooltip title="Rebuild">
                              <button
                                className={"btn btn-disabeld-flaticon"}>
                                <span className="material-icons" style={{ color: '#b9b8b8' }}>
                                  flash_on
                                </span>
                              </button>
                            </Tooltip>
                          
                      }
                      {
                        state.last_build && state.last_build.build_by_pipeline ||
                          (state.last_build.activity_status.status && state.last_build.activity_status.status != "FAILED") ?
                          
                            <Tooltip title="Resume">
                              <button
                                className={"btn btn-mui-svg btn-disabeld-flaticon"}

                              >
                                <span className="material-icons">
                                  skip_next
                                </span>
                              </button>
                            </Tooltip>
                           :
                          
                            <Tooltip title="Resume">
                              <button
                                className={state.last_build.activity_status.status ? getClassbyActivityStatus(state.last_build.activity_status.status, "Resume") : "btn btn-disabeld-flaticon"}
                                onClick={state.last_build.activity_status.status ? () => callResumeFunctionbyStatus(state.last_build.activity_status.status, state.last_build.id) : null}
                              >
                                <span className="material-icons" style={{ color: '#0086ff' }}>
                                  skip_next
                                </span>
                              </button>
                            </Tooltip>
                          
                      }
                      {
                        state.last_build.activity_status.status ?

                          
                            <Tooltip title={state.last_build.build_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Build"}>
                              <button
                                className={state.last_build.activity_status.status && (!state.last_build.build_by_pipeline) ? getClassbyActivityStatus(state.last_build.activity_status.status, "Revoke") : "btn btn-disabeld-flaticon"}

                                onClick={state.last_build.activity_status.status == "IN_QUEUE" || state.last_build.activity_status.status == "RUNNING" && (!state.last_build.build_by_pipeline) ? () => callFunctionbyStatus(state.last_build.activity_status.status, state.last_build.id) : () => { }}

                              >
                                <span className="material-icons">
                                  stop
                                </span>
                              </button>
                            </Tooltip>
                           :
                          
                            <Tooltip title="Revoke Build">
                              <button
                                className={"btn btn-disabeld-flaticon"}

                              >
                                <span className="material-icons">
                                  stop
                                </span>
                              </button>
                            </Tooltip>
                          
                      }
                      {
                        
                          <Tooltip title="Workspace">
                            <Link


                              to={{
                                pathname: "/application/" +
                                  application_id +
                                  "/service/" +
                                  service_id +
                                  "/build/" +
                                  state.last_build.id +
                                  "/workspace/?service_name=" +
                                  service_name +
                                  "&env_name=" +
                                  env_name + "&global_task_id=" + state.last_build.global_task_id
                                  + "&env_id=" + env_id + "&action_number=" + workspaceParams.action_number +
                                  "&action_status=" + workspaceParams.action_status + "&action_by=" + build_by_user + "&image=" + workspaceParams.image
                              }}
                              // state={workspaceParams}                            
                              target='_blank'

                            >
                              <button className="btn btn-flaticon">
                                <span
                                  className="material-icons"
                                  style={{ color: "#B9B8B8", display: 'flex', }}
                                >
                                  <StorageIcon style={{ color: 'rgb(0, 134, 255)', fontSize: '24px' }} />
                                </span>
                              </button>
                            </Link>
                          </Tooltip>
                        
                      }
                    </div>
                  </td>
                </tr>
                {

                  state.activity_list.length > 0 ?

                    <ActivityStepDetails
                      activityDetail={state.activity_list}
                      removeArrData={() => { removeArrData() }}
                      prev_state={{ "open_div": true }}
                      tableGird={true}
                      colSpan={9}
                      loading_step_status={state.loading_step_status}
                      task_id={state.last_build.global_task_id}
                      logUrl={"/logs?global_task_id=" + state.last_build.global_task_id +
                        "&tab_id=" + state.last_build.id +
                        "&service_name=" + service_name +
                        "&service_env=" + service_env +
                        "&service_type=" + service_env_type
                      }
                    />

                    : null
                }

              </tbody> : <tr><td colSpan="9"><div className="blank-div">
                <div className="blank-div-text">No Data Found</div>
              </div></td></tr>
            }

          </>
        );
      default:
        return null;
    }
  }
  function getStatusBasedOnType() {
    switch (type) {
      case "BUILD":
        return (
          <p className="font-12 pd-10 ">Recent Build History</p>
        );
      default:
        return null;
    }
  }
  var status = state.history_list.map(item => (item.status));

  return (
    <>
      {/* {
                status.includes("IN_QUEUE"|| "RUNNING" || "FAILED") && */}
      <div className="d-flex align-center space-between border-bottom">
        {getStatusBasedOnType()}

      </div>

      <table className="table table-responsive single-row single-row-data no-pad-table">
        <thead>
          {getHeaderBasedOnType()}
        </thead>
        {state.loading ?
          <tr>
            <td colSpan="8">
              <div className="h-12">
                <LoadingText />
              </div>

            </td>
          </tr>
          : <> {getBodyBasedOnType()}</>
        }
        <Popover
          id={id}
          open={OpenPopover}
          anchorEl={anchorEl}
          onClose={handleClickClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="popover-content" style={{ padding: '20px', width: '450px', }}>

            <div className='' style={{ width: '100%', position: 'relative' }}>
              <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
              <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-20 text-center' />
              <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
              <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger build as the envrionment is offline now.
                Please contact your team administrator to get the environment online.</p>
            </div>

          </div>
        </Popover>
      </table>
      {/* } */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="z-index-top"
        open={state.trigger === "success" || state.trigger === "failed"}
        onClose={handleClose}
        autoHideDuration={3000}>

        {
          state.trigger === "success" ?
            
              <Alert severity="success" >
                {state.rebuild_status ? state.rebuild_status : null}
              </Alert>
            : state.trigger == "failed" ?
              <Alert severity="error" >
                <div className="error-div">
                  {state.rebuild_status ? state.rebuild_status : null}
                </div>
              </Alert>

              : null
        }
      </Snackbar>

    </>
  )
}

CurrentProgressData.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default CurrentProgressData;