import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import properties from '../../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import { Loading } from '../../../../utils/Loading';
import { Tooltip, IconButton, Grid } from '@material-ui/core';
import moment from 'moment';
import { CopyToClipboard, getDuration } from '../../../../../util/util';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchBar from '../../../../../components/SearchBar';
import { VALIDATION_TYPE_REQUIRED, GenericValidator } from '../../../../../util/Validator';
import Pagination from '../../../../../components/Pagination';

import { GetAuth } from '../../../../../util/security';
import ActivityStepDetails from '../../../../superAdmin/activityFramework/components/ActivityStepDetails';
import { Link } from 'react-router-dom';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import StorageIcon from "@material-ui/icons/Storage";
import { useParams } from "react-router-dom";
import MonitoringDialogue from '../../../detail/components/MonitoringDialogue';
import { Input } from '../../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Popover, Tabs, Tab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import SelectWithInsideLabel from '../../../../../components/genericComponents/inputs/SelectWithInsideLabel';
const DeployHistory = props => {
  const classes = useStyles();
  const service_id = props.service_id;
  const env_id = props.env_id;
  const refresh_count = props.refresh_count;
  const selected_tab_id = props.selected_tab_id;
  const workspace_base_url = properties.workspace_url;
  const service_data = props.service_data;
  const openDialog = props.openDialog;
  const env_name = props.env_name;
  const masterLabel = props.masterLabel;
  const env_details = props.env_details;
  const is_env_down = props.is_env_down ? props.is_env_down : null;
  const deploy_env_offline_flag = props.deploy_env_offline_flag ? props.deploy_env_offline_flag : null
  const setSelectedDeploymentName = props.setSelectedDeploymentName;
  const selectedDeploymentName = props.selectedDeploymentName;
  const options_tabs = props.options_tabs;
  var service_env_type = service_data.type;
  service_env_type = service_env_type ? service_env_type.toLowerCase() : null
  var service_env = service_data.service_env;
  service_env = service_env ? service_env.toLowerCase() : props.service_env_name ? props.service_env_name : null;
  var service_name = service_data.name;

  const params = useParams();

  const [state, setState] = useState({
    show_loading_icon: true,
    loading: true,
    selected_activity_id: null,
    activity_details_loading: false,
    infiniteScrollInherits: {},
    curr_page: '',
    total_page: '',
    refresh_count: 1,
    data: {},
    error: {},
    deployment_list: [],
    history_list: []
  });


  const commonFunctions = getCommonFunctions(state, setState, {});
  useEffect(() => {
    fetchAllDeployments();
  }, [service_id, env_id, refresh_count, state.refresh_count])





  function fetchAllDeployments(data, url) {
    if (!url) {
      url = GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_cd);
    }
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }
    InvokeApi(requestInfo, fetchDeploymentSuccess, fetchDeploymentFailure);
    setState(new_state => ({
      ...new_state,
      deployment_list: [],
      loading_deployment: true,
      show_loading_icon: true
    }));
  }

  const fetchDeploymentSuccess = (data) => {

    var filter_approved_cd_details = data.filter(item => item.status === "APPROVED")

    if (filter_approved_cd_details && filter_approved_cd_details[0]) {


      fetchHistory(null, null, selectedDeploymentName ? selectedDeploymentName : filter_approved_cd_details[0].id ? filter_approved_cd_details[0].id : null);

      setState(new_state => ({
        ...new_state,
        deployment_list: filter_approved_cd_details.map(item => {
          return { id: item.id, label: item.deployment_name }
        }),
        approved_deployment_flag: true,
        data: {
          ...new_state.data,
          deployment: selectedDeploymentName ? selectedDeploymentName : filter_approved_cd_details && filter_approved_cd_details[0] ? filter_approved_cd_details[0].id ? filter_approved_cd_details[0].id : null : null
        },
        loading_deployment: false,
        show_loading_icon: false
      }));
      setSelectedDeploymentName(selectedDeploymentName ? selectedDeploymentName : filter_approved_cd_details && filter_approved_cd_details[0] ? filter_approved_cd_details[0].id ? filter_approved_cd_details[0].id : null : null)
    } else {
      setState(new_state => ({
        ...new_state,
        approved_deployment_flag: false,
        loading_deployment: false,
        show_loading_icon: false
      }));
    }


  }

  console.log(state.deployment_list, "deployment_listdeployment_list")
  const fetchDeploymentFailure = (error) => {
    setState(new_state => ({
      ...new_state,
      loading_deployment: false,
      unable_to_load_deployment: true,
      error_in_load_deployment: error,
      show_loading_icon: false
    }));
  }

  function fetchHistory(data, url, id) {
    console.log(data, url, id, "test_tesst")

    if (!url) {
      console.log("test_tesst")
      url = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: id }, properties.api.env_deploy_history_url);
    }
    console.log(url, "test_tesst")
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }
    InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);
    setState(new_state => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      show_loading_icon: true,
      loading: true,
      curr_page: 1
    }));
  }

  function historyFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      history_list: data.results,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      loading: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: new_state.curr_page === '' ? 1 : new_state.curr_page
    }));
  }

  function historyFetchFailure(error, exception) {
    console.log(error, exception, "exceptionexception")
    setState(new_state => ({
      ...new_state,
      history_info_status: "FAILED",
      show_loading_icon: false,
      loading: false,
      error: error,
      history_list: [],
      deployment_list: [],
    }));
  }
  function getDeployedByUser(history) {
    return (
      <p>{history.deploy_by_pipeline ?
        <>
          <p>Pipeline: {history.deploy_by_pipeline.pipeline.name}</p>
          <p>Triggered by: {history.deploy_by_pipeline.trigger_by}</p> </>
        : <p>{history.deploy_by_user}</p>
      }</p>
    )

  }
  function validateAndReTriggerDeploy(data) {
    console.log(data.env_cd_detail_id, "fsajhfhdshafjhsa")
    var cd_id = data.env_cd_detail_id ? data.env_cd_detail_id : null
    var errors = GenericValidator(data.deploy_tag, [VALIDATION_TYPE_REQUIRED])
    if (errors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          deploy_tag: errors,
        }
      });
    } else {
      var data = {
        tag: data && data.deploy_tag ? data.deploy_tag : "",
        deployment_status_check: data && data.deployment_status_check ? true : false
      }
      var endPoint = GenerateURL({ service_id: props.service_id, env_id: props.env_id, cd_id: cd_id }, properties.api.env_single_deploy_request_url);

      PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
    }
  }
  function successBuildTriggerCallBack(response) {
    setState({
      ...state,
      trigger: "success",
      tag: "",
      branch_name: "",
      no_cache: false,
      show_loading_icon: false,
      loading: false,
    });
    refreshComponent()
  }

  function failedBuildTriggerCallBack(error) {
    setState({
      ...state,
      trigger: "failed",
      error_msg: error,
      show_loading_icon: false,
      loading: false,
    });
  }
  function fetchNextHistory(data, tab_order, url) {
    if (url.split('/').pop() == 'null') {
    } else {
      if (!url) {
        url = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_deploy_request_url);
      }
      var requestInfo = {
        endPoint: url,
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
      }
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
      setState(new_state => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        showLoadingIcon: true,
        loading: true,
      }));
    }
  }

  function NexthistoryFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      history_list: data.results,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      loading: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: new_state.curr_page + 1
    }));
  }

  function NexthistoryFetchFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      loading: false,
      error: error,
      history_list: []
    }));
  }
  function fetchPrevHistory(data, tab_order, url) {
    if (url.split('/').pop() == 'null') {
    } else {
      if (!url) {
        url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
      }
      var requestInfo = {
        endPoint: url,
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
      }
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);
      setState(new_state => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        showLoadingIcon: true,
        loading: true,
      }));
    }
  }

  function PrevhistoryFetchSuccess(data) {
    setState(new_state => ({
      ...new_state,
      history_list: data.results,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      loading: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: new_state.curr_page - 1
    }));
  }

  function PrevhistoryFetchFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      history_info_status: "SUCCESS",
      showLoadingIcon: false,
      loading: false,
      error: error,
      history_list: []
    }));
  }


  // Deploy info

  function fetchDeployInfo(enteredPageNumber) {

    console.log("enterrrr", enteredPageNumber);


    // url = GenerateURL(
    //   { service_id: service_id, env_id: env_id },
    //   properties.api.env_deploy_request_url
    // );


    var requestInfo = {
      endPoint: GenerateURL(
        { service_id: service_id, env_id: env_id },
        properties.api.env_deploy_request_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };


    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        handleDeploySuccessApiHit(response, current_page);
      },
      handleDeployDataFailedApiHit
    );
  }

  function handleDeploySuccessApiHit(data, count) {
    console.log("enterrrr", data);
    setState((new_state) => ({
      ...new_state,
      history_list: data.results,
      show_loading_icon: false,
      loading: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: Number(count),
    }));
  }

  function handleDeployDataFailedApiHit(error, exception) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      loading: false,
      error: error,
      history_list: [],
    }));
  }


  function changeTab(newTabId) {
    setState(new_state => ({
      ...new_state,
      selected_tab_id: newTabId
    }))
  }
  function closeLogs() {
    setState(new_state => ({
      ...new_state,
      view_logs: false,
      logMetadata: {}
    }))
  }

  function closeTab(tabId) {
    var response = state.infiniteScrollInherits.closeTabFunction(tabId, state.logMetadata, closeLogs)
    if (response) {
      setState(new_state => ({
        ...new_state,
        logMetadata: response.new_tabs_data,
        selected_tab_id: response.selected_tab_id
      }))
    }
  }


  function onRevoke(task_id, cd_id) {
    var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id, id: task_id }, properties.api.env_deploy_revoke_url);
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);

  }


  function handleSuccessRevoke(respone) {
    setState(new_state => ({
      ...new_state,
      show_loading_icon: false,
      loading: false,
    }));
    refreshComponent();
  }

  function handleFailedRevoke(error) {
    setState(new_state => ({
      ...new_state,
      error: true,
      show_loading_icon: false,
      loading: false,
    }));
  }
  const refreshComponent = props => {
    setState(new_state => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
    }));
  }

  function getClassbyActivityStatus(activity_status, type) {
    if (type == "Revoke") {
      if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
        return "icon-btn-v1 icon-btn-outline-danger";
      else
        return "btn-sq-icon-primary-disabled d-flex align-center";
    } else {
      if (type === "Resume") {
        if ((activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING") || activity_status && activity_status == "SUCCESS")
          return "btn-sq-icon-primary-disabled d-flex align-center";
        else
          return "btn-sq-icon-primary d-flex align-center";
      } else {
        if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
          return "btn-sq-icon-primary-disabled d-flex align-center";
        else
          return "btn-sq-icon-primary d-flex align-center";
      }
    }
  }
  function onResume(id) {
    var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: id }, properties.api.deploy_resume);
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    PostData(url_temp, {}, handleSuccessResume, handleFailedResume);

  }


  function handleSuccessResume(respone) {
    setState(new_state => ({
      ...new_state,
      show_loading_icon: false
    }));
    refreshComponent();
  }

  function handleFailedResume(error) {
    setState(new_state => ({
      ...new_state,
      error: true,
      show_loading_icon: false
    }));
  }


  function callResumeFunctionbyStatus(activity_status, id) {
    if ((activity_status != "IN_QUEUE" || activity_status != "RUNNING") || activity_status != "SUCCESS")
      onResume(id)
  }

  function callFunctionbyStatus(activity_status, id, cd_id) {
    if (activity_status && activity_status == "IN_QUEUE" || activity_status && activity_status == "RUNNING")
      onRevoke(id, cd_id)
  }
  function removeExtraString(data) {
    var final_sting = data.worker_name ? data.worker_name.split('@')[1] : null
    final_sting = final_sting ? final_sting.toLowerCase() : null
    return final_sting;
  }
  function getEnvNamefromId() {
    var env_name = "";
    if (service_data && service_data.component_env) {
      service_data.component_env.map(item => {
        if (item.id == env_id) {
          env_name = item.project_env.name
        }
      })
    }
    return env_name;
  }
  var service_env_name = service_env ? service_env : getEnvNamefromId();
  function getActivityCurrentInfo(activity_id) {
    var requestInfo = {
      endPoint: GenerateURL({ activity_id: activity_id }, properties.api.get_current_activity_status),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setState((prev_state) => ({
      ...prev_state,
      selected_activity_id: activity_id,
      activity_details_loading: true,
    }))
    InvokeApi(requestInfo, getActivityCurrentInfoSuccess, getActivityCurrentInfoFailure);
    setState(new_state => ({
      ...new_state,
      step_loading: true
    }));
  }

  function getActivityCurrentInfoSuccess(response) {
    if (response.results.length != 0) {
      let fetchedData = response.results
      state.history_list.forEach(activity => {
        if (activity.activity_status.id == response.results[0].activity_status_id) {
          activity.activity_details = fetchedData;
          setState(new_state => ({
            ...new_state,
            history_list: [...state.history_list],
            activity_details_loading: false,
            activity_step_details: {
              open_div: state.history_list.length > 0 ? true : false
            },
            step_loading: false
          }));
        }
      })
    }

  }

  var url = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_deploy_request_url, true);
  const is_permitted = GetAuth().permission.POST.includes(url);
  function getActivityCurrentInfoFailure(error, exception) {
    setState(new_state => ({
      ...new_state,
      error: true,
      step_loading: false,
      activity_details_loading: false,
    }));
  }
  const emptyArr = (activity_id) => {
    state.history_list.forEach(activity => {
      if (activity.activity_details && activity.activity_details.length > 0) {
        // activity.activity_details.forEach((item, key) => {
        if (activity.id == activity_id) {
          activity.activity_details = null;
          setState(new_state => ({
            ...new_state,
            history_list: [...state.history_list],
            selected_activity_id: null,
            activity_details_loading: false,
            activity_step_details: {
              open_div: state.history_list.length > 0 ? true : false
            }
          }));
        }
        // })
      }
    })
  }

  console.log("deploy_history", state.history_list);


  function getDeployDurationForInfoMessage(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    console.log("diffInMs", diffInMs)
    return diffInMs;
  }

  const onChangeHandler = (e) => {
    var key = e.target.name;
    var value = e.target.value;
    fetchHistory(null, null, value);
    setSelectedDeploymentName(value);
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value,
      },
      error: {
        ...state.error,
        [key]: null,
      }
    });

  }

  const selectDeployment = (deployment) => {
    fetchHistory(null, null, deployment);
    setSelectedDeploymentName(deployment);
    setState({
      ...state,
      data: {
        ...state.data,
        deployment: deployment,
      },
      error: {
        ...state.error,
        deployment: null,
      }
    });
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickClosePopover = () => {
    setAnchorEl(null);
  };

  const OpenPopover = Boolean(anchorEl);
  const id = OpenPopover ? 'simple-popover' : undefined;

  console.log(state.data.deployment, "setSelectedDeploymentName")


  return (
    <>
      <Grid container className={classes.containerBox + " " + "align-center"}>
        <Grid item xs={5} className={classes.searchPosition}>
          <SearchBar
            search_data={state.search_data}
            default_filter={{ name: "deploy_tag", label: "Artifact" }}
            search_call_back={fetchHistory}
            clear_search_callback={fetchHistory}
          />
        </Grid>

        {/* {
                state.deployment_list && state.deployment_list.length > 0 &&
                  <div style={{width: "300px"}}><SelectWithInsideLabel list={state.deployment_list} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.data.deployment} optionsWidth={300} /></div>
              }
              {/* {
                state.deployment_list && state.deployment_list.length > 0 &&
                <Grid item xs={3}>
                  <Input
                    type="select"
                    name="deployment"
                    label="Select deployment"
                    data={state.data}
                    error={state.error}
                    list={state.deployment_list}
                    onChangeHandler={onChangeHandler}
                  />
                </Grid>
              } */}


      </Grid>
      <div className='d-flex align-center space-between'>
        {options_tabs}
        {
          state.deployment_list && state.deployment_list.length > 0 &&
          <div style={{ width: "300px" }}><SelectWithInsideLabel list={state.deployment_list} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.data.deployment} optionsWidth={300} /></div>
        }
      </div>

      {
        state.loading ?
          <div className={classes.historyCard} style={{ height: "300px" }}><Loading varient="light" /></div> :
          <>
            <div className="font-12 font-weight-600 color-key-78 mt-12 mb-12">Deploy History</div>
            <div className={classes.historyCard}>
              <div style={{ borderRadius: "6px", border: "1px solid #E6E6E6" }}>
                <div className="header-tab-build">
                  <p>Type</p>
                  <p>Status</p>
                  {/* <p>Duration</p> */}
                  <p>Date/Duration</p>
                  <p>Artifact</p>
                  <p>Deployed By</p>
                  {/* <p>Build/Deploy Params</p> */}
                  <p></p>
                </div>
                <div className="history-body">
                  {state.history_list.length > 0 ?
                    <>
                      {
                        state.history_list.map((history, key) => (
                          <>
                            <div className="body-tab-build align-center">

                              <Link
                                className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                                to={"/logs?global_task_id=" + history.global_task_id +
                                  "&tab_id=" + history.id +
                                  "&service_name=" + service_name +
                                  "&service_env=" + service_env +
                                  "&service_type=" + service_env_type +
                                  "&tab_name=DEPLOY" + "&num=" + history.deploy_number +
                                  "&service_id=" + service_id +
                                  "&env_id=" + env_id +
                                  "&cd_id=" + history.env_cd_detail_id
                                }
                                target="_blank"
                              >
                                Deploy #{history.deploy_number}
                              </Link>
                              <Tooltip title={history.activity_status && history.activity_status.status}>
                                <p className={history.activity_status &&
                                  history.activity_status.status == "FAILED"
                                  ? "new-status-chip new-failed"
                                  : history.activity_status &&
                                    history.activity_status.status == "RUNNING"
                                    ? "new-status-chip new-running"
                                    : history.activity_status &&
                                      history.activity_status.status == "REVOKED"
                                      ? "new-status-chip new-revoked"
                                      : history.activity_status &&
                                        history.activity_status.status ==
                                        "IN_QUEUE"
                                        ? "new-status-chip new-inqueue"
                                        : "new-status-chip new-success"} style={{ width: "fit-content" }} >{history.activity_status && history.activity_status.status ? history.activity_status.status : "N/A"}</p></Tooltip>
                              {/* <p>
                                {history.activity_status ?
                                  history.activity_status && (history.activity_status.status === "FAILED" || history.activity_status.status === "SUCCESS") ?
                                    getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) == 0 || getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.updated_at)) > 0 ?
                                      "0s" : getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) : getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) : "N/A"}

                              </p> */}
                              <div>
                                <p>
                                  {moment().diff(moment(history.activity_status &&
                                    history.activity_status.created_at
                                    ? history.activity_status.created_at
                                    : ""), 'hours') > 8 ?
                                    moment(
                                      history.activity_status &&
                                        history.activity_status.created_at
                                        ? history.activity_status.created_at
                                        : ""
                                    ).format('DD-MMM-YYYY')
                                    :
                                    moment(
                                      history.activity_status &&
                                        history.activity_status.created_at
                                        ? history.activity_status.created_at
                                        : ""
                                    ).fromNow()
                                  }
                                </p>
                                <p className="color-key-78">
                                  {history.activity_status ?
                                    history.activity_status && (history.activity_status.status === "FAILED" || history.activity_status.status === "SUCCESS") ?
                                      getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) == 0 || getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.updated_at)) > 0 ?
                                        "0s" : getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) : getDuration(new Date(history.activity_status.start_time), new Date(history.activity_status.end_time)) : "N/A"}

                                </p>
                              </div>

                              <div className="d-flex">
                                <Tooltip title={history.deploy_tag}><p className="color-icon-secondary font-weight-500">{history.deploy_tag}</p></Tooltip>
                                {history.deploy_tag ? (
                                  <CopyToClipboard data={history.deploy_tag} />
                                ) : null}
                              </div>
                              <p >{getDeployedByUser(history)}</p>

                              <div className="d-flex align-center justify-end" style={{ gap: "12px" }}>
                                <div>
                                  <ParameterComponent
                                    buildParameters={history?.build_metadata}
                                    deployParameters={history?.deploy_args_key_value}
                                  />
                                </div>
                                <div className="btn-group width-fitcontent" style={{ gap: "4px" }}>

                                  <Tooltip title="View Activity Details">
                                    <button className="btn-sq-icon-primary d-flex align-center"
                                      onClick={() => {
                                        getActivityCurrentInfo(history.activity_status &&
                                          history.activity_status.id ? history.activity_status.id : "")
                                      }} >
                                      {/* <span class="material-icons material-symbols-outlined" style={{ color: '#0086ff', fontSize: 20 }}>
                                          notes
                                        </span> */}
                                      <span className="ri-list-check-2" style={{ fontSize: 20 }}></span>
                                    </button>
                                  </Tooltip>

                                  {
                                    is_permitted ?
                                      <>
                                        {
                                          deploy_env_offline_flag && deploy_env_offline_flag.value && deploy_env_offline_flag.value === "true" ?
                                            is_env_down ?
                                              <Tooltip title="Redeploy">
                                                <button
                                                  className={getClassbyActivityStatus(history.activity_status && history.activity_status.status, "Redeploy")}
                                                  onClick={handleClickOpenPopover} >
                                                  {/* <span class="flaticon-upload-button"></span> */}
                                                  <span className='ri-upload-2-line font-20'></span>
                                                </button>
                                              </Tooltip>
                                              : <Tooltip title="Redeploy">
                                                <button
                                                  className={getClassbyActivityStatus(history.activity_status && history.activity_status.status, "Redeploy")}
                                                  onClick={() => { validateAndReTriggerDeploy(history) }} >
                                                  <span className='ri-upload-2-line font-20'></span>
                                                </button>
                                              </Tooltip>
                                            :
                                            <Tooltip title="Redeploy">
                                              <button
                                                className={getClassbyActivityStatus(history.activity_status && history.activity_status.status, "Redeploy")}
                                                onClick={() => { validateAndReTriggerDeploy(history) }} >
                                                <span className='ri-upload-2-line font-20'></span>
                                              </button>
                                            </Tooltip>
                                        }
                                      </> :

                                      <Tooltip title="Redeploy">
                                        <button
                                          className={"btn-sq-icon-primary-disabled d-flex align-center"}
                                        >
                                          <span className='ri-upload-2-line font-20'></span>
                                        </button>
                                      </Tooltip>

                                  }
                                  <button
                                    className={history.activity_status ? getClassbyActivityStatus(history.activity_status.status, "Resume") : "btn-sq-icon-primary-disabled d-flex align-center"}
                                    onClick={history.activity_status ? () => callResumeFunctionbyStatus(history.activity_status.status, history.id) : null}
                                    title="Resume Deploy">
                                    {/* <SkipNextIcon style={{ fontSize: 24, color: '#0086ff' }} /> */}
                                    <span className="ri-play-circle-line font-20"></span>
                                  </button>
                                  <button
                                    className={history.activity_status && (!history.deploy_by_pipeline) ? getClassbyActivityStatus(history.activity_status.status, "Revoke") : "btn-sq-icon-primary-disabled d-flex align-center"}
                                    onClick={history.activity_status && (!history.deploy_by_pipeline) ? () => callFunctionbyStatus(history.activity_status.status, history.id, history.env_cd_detail_id) : () => { }}
                                    title={history.deploy_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Deploy"}>
                                    {/* <span class="flaticon-stop-button"></span> */}
                                    <span className="ri-stop-circle-line font-20"></span>
                                  </button>

                                  <Tooltip title="workspace">
                                    <Link to={{
                                      pathname:
                                        "/application/" +
                                        params.application_id +
                                        "/service/" +
                                        service_id +
                                        "/deploy/" +
                                        history.id +
                                        "/workspace/?service_name=" +
                                        service_name +
                                        "&env_name=" +
                                        service_env +
                                        "&global_task_id=" +
                                        history.global_task_id +
                                        "&env_id=" +
                                        env_id +
                                        "&action_number=" +
                                        history.deploy_number +
                                        "&action_status=" +
                                        history?.activity_status?.status +
                                        "&action_by=" +
                                        history.deploy_by_user +
                                        "&image=" +
                                        history.image_name,
                                    }} target="_blank">
                                      <button className="btn-sq-icon-primary d-flex align-center">
                                        {/* <span
                                            class="material-icons"
                                            style={{
                                              color: "#B9B8B8",
                                              display: "flex",
                                            }}
                                          >
                                            <StorageIcon
                                              style={{
                                                color: "rgb(0, 134, 255)",
                                                fontSize: "24px",
                                              }}
                                            />
                                          </span> */}
                                        <span className="ri-artboard-2-line font-20"></span>
                                      </button>
                                    </Link>
                                  </Tooltip>

                                </div>
                              </div>
                            </div>
                            {/* {
                              history.activity_status && history.activity_status.status === "FAILED" ?
                                <div style={{ width: "100%", height: "100%", backgroundColor: "#fff1f1", height: "40px", paddingLeft: "10px", borderBottom: "1px solid #ececec" }} className="duration-info-div" >
                                  <img src='/images/sentiment_dissatisfied.png' style={{ marginRight: "10px" }} />
                                  <p>Deployment #{history.deploy_number} Failed <span style={{ color: "#ff5151" }}>Liveness Probe failed. HTTP probe failed with status code 500.</span><span> View the possible cause</span></p>
                                </div>
                                :
                                null
                            }
                            {
                              history.activity_status && history.activity_status.status === "RUNNING" &&
                                getDeployDurationForInfoMessage(new Date(history.activity_status && history.activity_status.start_time), new Date()) > 60000 ?
                                <div style={{ width: "100%", height: "100%", backgroundColor: "#fffae4", height: "40px", paddingLeft: "10px", borderBottom: "1px solid #ececec" }} className="duration-info-div" >
                                  <img src='/images/neutral_sentiment.png' style={{ marginRight: "10px" }} />
                                  <p>Seems like the Deployment is taking longer than expected...<span>View the possible cause</span></p>
                                </div>
                                :
                                null
                            } */}
                            {
                              key === 0 && state.curr_page === 1 &&
                              <div style={{ height: '45px', borderBottom: '1px solid #ebedee' }} className="d-flex align-center pl-10">
                                <MonitoringDialogue
                                  variant='deploy-activity'
                                  application_id={service_data.project_id}
                                  env_id={env_id}
                                  cd_id={history.env_cd_detail_id ? history.env_cd_detail_id : null}
                                  service_id={service_id}
                                  env_name={env_name}
                                  service_data={{ name: service_data.name, type: masterLabel }}
                                  env_details={env_details}
                                />
                              </div>
                            }
                            {(state.selected_activity_id == history.activity_status?.id) && state.activity_details_loading ?
                              <div className="d-flex justify-center align-center" style={{ height: "147px" }}>
                                <Loading varient="light" />
                              </div>
                              :

                              history.activity_details ?
                                <div className="" style={{ width: '100%' }}>
                                  <ActivityStepDetails
                                    activityDetail={history.activity_details}
                                    tableGird={false}
                                    key={key}
                                    prev_state={state.activity_step_details}
                                    removeArrData={() => { emptyArr(history.id) }}
                                    logUrl={"/logs?global_task_id=" + history.global_task_id +
                                      "&tab_id=" + history.id +
                                      "&service_name=" + service_name +
                                      "&service_env=" + service_env +
                                      "&service_type=" + service_env_type +
                                      "&tab_name=DEPLOY" + "&num=" + history.deploy_number
                                    }
                                  />
                                </div>
                                :
                                null

                            }

                          </>
                        ))

                      }
                    </> : <div className="blank-div">
                      <div className="blank-div-text">No Data Found</div>
                    </div>}
                </div>
              </div>

            </div>
            <div style={{ marginTop: "12px" }}>
              <Pagination
                current_page_count={state.history_list ? `${state.curr_page}` : 0}
                total_count={state.total_page}
                next={state.next}
                previous={state.previous}
                on_previous_click={() => { fetchPrevHistory(null, 0, state.previous) }}
                on_next_click={() => { fetchNextHistory(null, 0, state.next) }}
                on_pageNumber_click={(pageNumber) => {
                  fetchDeployInfo(pageNumber);
                }}
              />
            </div>
          </>}
      <Popover
        id={id}
        open={OpenPopover}
        anchorEl={anchorEl}
        onClose={handleClickClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="popover-content" style={{ padding: '20px', width: '450px', }}>

          <div className='' style={{ width: '100%', position: 'relative' }}>
            <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
            <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-20 text-center' />
            <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
            <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger the deployment as the envrionment is offline now.
              Please contact your team administrator to get the environment online.</p>
          </div>

        </div>
      </Popover>

    </>
  )
}

DeployHistory.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default DeployHistory;



export const ParameterComponent = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ? props.selectedTab : 0);
  const classes = useStylesParameters();
  const buildParameters = props.buildParameters;
  const deployParameters = props.deployParameters;
  const [viewErrorStatus, setviewErrorStatus] = useState(props.activity_status === "FAILED" ? true : false);
  const variant = props.variant ? props.variant : null;
  const activity_master_id = props.activity_master_id ? props.activity_master_id : null
  const onlyBuildInfo = props.onlyBuildInfo ? props.onlyBuildInfo : false;
  const buildInfo = props.buildInfo ? props.buildInfo : null;
  const onlyPromoteInfo = props.onlyPromoteInfo ? props.onlyPromoteInfo : false;
  const promoteInfo = props.promoteInfo ? props.promoteInfo : null;
  const buildBy = props.buildBy ? props.buildBy : null;
  console.log(buildParameters, "buildParameters", promoteInfo);
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const open = Boolean(anchorEl);


  return (
    <div className={classes.rooter} style={variant === "activity_page" ? { borderRight: '1px solid #0787e1' } : {}}>
      {
        variant === "activity_page" ?
          activity_master_id === 7000 || activity_master_id === 80000 ?
            <button className="btn btn-flaticon btn-overrider" style={{ borderRight: '1px solid #0787e1' }} onClick={handleButtonClick}>
              <svg style={{ verticalAlign: 'middle' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3327 14.9993V16.666H1.66602V14.9993H18.3327ZM1.66602 2.91602L8.33268 7.08268L1.66602 11.2493V2.91602ZM18.3327 9.16602V10.8327H9.99935V9.16602H18.3327ZM3.33268 5.92268V8.24268L5.18768 7.08268L3.33268 5.92268ZM18.3327 3.33268V4.99935H9.99935V3.33268H18.3327Z" fill="#0787e1" />
              </svg>
            </button>
            :
            <button className="btn btn-flaticon btn-overrider" style={{ borderRight: '1px solid #0787e1' }} onClick={() => { }}>
              <svg style={{ verticalAlign: 'middle' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3327 14.9993V16.666H1.66602V14.9993H18.3327ZM1.66602 2.91602L8.33268 7.08268L1.66602 11.2493V2.91602ZM18.3327 9.16602V10.8327H9.99935V9.16602H18.3327ZM3.33268 5.92268V8.24268L5.18768 7.08268L3.33268 5.92268ZM18.3327 3.33268V4.99935H9.99935V3.33268H18.3327Z" fill="#b9b8b8" />
              </svg>
            </button>
          :
          props.variant === "activity_page_new" ?
            activity_master_id === 7000 || activity_master_id === 80000 ?
              <>
                <div className='activity-details-btn' onClick={handleButtonClick}>
                  <span className='ri-play-list-2-line font-20'>

                  </span>
                </div>
              </>
              :
              <>
                <div className='activity-details-btn cursor-not-allowed' onClick={() => { }}>
                  <span className='ri-play-list-2-line font-20'>

                  </span>
                </div>
              </>
            :
            variant === "ongoingactivity" ?

              <button onClick={handleButtonClick} className={classes.activitybutton + " " + "d-flex align-center justify-center gap-4 font-family-main"}>
                {/* <span> Status</span>
                {props.activity_status === "FAILED" ? <span className='ri-error-warning-line warning-logo font-18'></span> : null} */}
                {props.activity_status === "FAILED" ? <span>View Error</span> : <span>View Parameters</span>}
                {props.activity_status === "FAILED" ? <span className='ri-error-warning-line warning-logo font-18'></span> : null}
              </button>
              :
              <button onClick={handleButtonClick} style={{ height: "36px", marginRight: "10px", fontFamily: "Montserrat" }} className={classes.primaryButton}>
                Parameters
              </button>
      }


      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        className={classes.popoverClass}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{ padding: props.variant === "ongoingactivity" ? '12px' : "10px", width: props.variant === "ongoingactivity" ? "250px" : "" }}>
          {
            onlyBuildInfo ?
              buildInfo ?
                <>
                  <div className='pd-10'>
                    <div className={classes.topHeader}>Build Parameters Details</div>
                    {
                      buildInfo && Object.keys(buildInfo.build_args_key_value).length > 0 ?
                        Object.keys(buildInfo.build_args_key_value).map((element, key) => (
                          <Tooltip title={element + " : " + JSON.stringify(buildInfo.build_args_key_value[element])}>
                            <p className='font-12'>
                              <span className='' style={{ color: '#787878' }}>{element}</span> : <span className='' style={{ color: '#2f2f2f' }}>{JSON.stringify(buildInfo.build_args_key_value[element])}</span>
                            </p>
                          </Tooltip>
                        ))
                        : <div class="text-color-grey blank-div-text text-center">Build Params Not Available</div>
                    }
                    {
                      <>
                        <div className='d-flex'>
                          {
                            buildInfo?.commit_sha ?

                              <>
                                <Tooltip title={buildInfo.commit_sha}>
                                  <p className='font-12 d-flex align-center'>
                                    <span className='' style={{ color: '#787878' }}>Commit Id</span>&nbsp;:&nbsp;
                                    <span className='d-flex align-center' style={{ color: '#2f2f2f' }}>
                                      <span className='text-ellipsis-150 d-block'>{buildInfo.commit_sha}</span><CopyToClipboard data={buildInfo?.commit_sha} /></span>
                                  </p>
                                </Tooltip>

                              </>
                              : <p className='font-12'>
                                <span className='' style={{ color: '#787878' }}>Commit Id</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                              </p>
                          }
                        </div>
                        <div className='d-flex'>
                          {
                            buildInfo?.commit_message ?

                              <>
                                <Tooltip title={buildInfo.commit_message}>
                                  <p className='font-12 d-flex align-center'>
                                    <span className='' style={{ color: '#787878' }}>Commit Msg</span>&nbsp;:&nbsp;
                                    <span className='d-flex align-center' style={{ color: '#2f2f2f' }}><span className='text-ellipsis-150' >{buildInfo.commit_message}</span><CopyToClipboard data={buildInfo?.commit_message} /></span>
                                  </p>
                                </Tooltip>

                              </>
                              : <p className='font-12'>
                                <span className='' style={{ color: '#787878' }}>Commit Msg</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                              </p>
                          }
                        </div>
                        <div className='d-flex'>
                          {
                            buildInfo?.activity_status?.task_executor ?

                              <>
                                <Tooltip title={buildInfo?.activity_status?.task_executor}>
                                  <p className='font-12 d-flex align-center'>
                                    <span className='' style={{ color: '#787878' }}>Agent Name </span>&nbsp;:&nbsp;
                                    <span className='d-flex align-center' style={{ color: '#2f2f2f' }}><span className='text-ellipsis-150' >{buildInfo?.activity_status?.task_executor}</span></span>
                                  </p>
                                </Tooltip>

                              </>
                              : <p className='font-12'>
                                <span className='' style={{ color: '#787878' }}>Agent Name</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                              </p>
                          }
                        </div>
                      </>
                    }
                  </div>
                </>
                :
                <div className='d-flex'> No Build Params</div>

              :
              onlyPromoteInfo ? promoteInfo ?
                <>
                  <div className='pd-10'>
                    <div className={classes.topHeader}>Promote Parameters Details</div>
                    <div className='d-flex'>
                      {
                        promoteInfo?.target_image_tag ?
                          <>
                            <Tooltip title={promoteInfo.target_image_tag}>
                              <p className='font-12 d-flex align-center'>
                                <span className='' style={{ color: '#787878' }}>Artifact</span>&nbsp;:&nbsp;
                                <span className='d-flex align-center' style={{ color: '#2f2f2f' }}><span className='text-ellipsis-150' style={{ width: "105px" }} >{promoteInfo.target_image_tag}</span><CopyToClipboard data={promoteInfo.target_image_tag} /></span>
                              </p>
                            </Tooltip>

                          </>
                          :
                          <p className='font-12'>
                            <span className='' style={{ color: '#787878' }}>Artifact</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                          </p>
                      }
                    </div>
                    <div className='d-flex'>
                      {
                        promoteInfo?.commit_sha ?

                          <>
                            <Tooltip title={promoteInfo.commit_sha}>
                              <p className='font-12 d-flex align-center'>
                                <span className='' style={{ color: '#787878' }}>Commit Id</span>&nbsp;:&nbsp;
                                <span className='d-flex align-center' style={{ color: '#2f2f2f' }}>
                                  <span className='text-ellipsis-150 d-block'>{promoteInfo.commit_sha}</span><CopyToClipboard data={promoteInfo?.commit_sha} /></span>
                              </p>
                            </Tooltip>

                          </>
                          : <p className='font-12'>
                            <span className='' style={{ color: '#787878' }}>Commit Id</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                          </p>
                      }
                    </div>
                    <div className='d-flex'>
                      {
                        promoteInfo?.commit_message ?

                          <>
                            <Tooltip title={promoteInfo.commit_message}>
                              <p className='font-12 d-flex align-center'>
                                <span className='' style={{ color: '#787878' }}>Commit Msg</span>&nbsp;:&nbsp;
                                <span className='d-flex align-center' style={{ color: '#2f2f2f' }}>
                                  <span className='text-ellipsis-150 d-block'>{promoteInfo.commit_message}</span><CopyToClipboard data={promoteInfo?.commit_message} /></span>
                              </p>
                            </Tooltip>

                          </>
                          : <p className='font-12'>
                            <span className='' style={{ color: '#787878' }}>Commit Msg</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                          </p>
                      }
                    </div>
                  </div>
                </> :
                <div className='d-flex'> No Promote Params</div>
                :
                <>
                  {variant === "ongoingactivity" ? props.activity_status === "FAILED" ?
                    <>

                      <div className='d-flex w-100 align-center space-between'>
                        <button className={classes.errorTabs + " " + "font-family-monetserrat"}
                          style={viewErrorStatus ? { backgroundColor: '#0086ff', color: "white" } : {}}
                          onClick={() => {
                            setviewErrorStatus(true)
                            setSelectedTab(10)
                          }}>Error</button>
                        <button className={classes.errorTabs + " " + "font-family-monetserrat"}
                          style={selectedTab === 2 ? { backgroundColor: '#0086ff', color: "white" } : {}}
                          onClick={() => {
                            setSelectedTab(2)
                            setviewErrorStatus(false)
                          }}>Status</button>

                      </div>
                      <div className='w-100 mt-10' style={{ border: '1px #E6E6E6 solid' }}></div>
                    </>
                    :
                    <>
                      <div className='w-100 d-flex align-center justify-left font-12 text-transform-uppercase word-wrap-break font-weight-bold' style={{ color: '#787878', fontWeight: "700 !important" }}>Activity Status Detail</div>
                      <div className='w-100 mt-5' style={{ border: '1px #E6E6E6 solid' }}></div>
                    </>
                    : <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab className={classes.tabs} label="Build Parameters" />
                      <Tab className={classes.tabs} label="Deploy Parameters" />
                    </Tabs>}

                  {selectedTab === 0 && (
                    <div className='pd-10'>
                      {buildParameters ?
                        buildParameters.build_parameters && Object.keys(buildParameters.build_parameters).length > 0 ?
                          Object.keys(buildParameters.build_parameters).map((element, key) => (
                            <Tooltip title={element + " : " + JSON.stringify(buildParameters.build_parameters[element])}>
                              <p className='font-12'>
                                <span className='' style={{ color: '#787878' }}>{element}</span> : <span className='' style={{ color: '#2f2f2f' }}>{JSON.stringify(buildParameters.build_parameters[element])}</span>
                              </p>
                            </Tooltip>
                          ))
                          : <div class="text-color-grey blank-div-text text-center">Build Params Not Available</div>
                        : <div class="text-color-grey blank-div-text text-center">Build Params Not Available</div>
                      }
                      {
                        buildParameters ?
                          <div className="">
                            <div className='d-flex'>
                              {
                                buildParameters?.commit_sha ?

                                  <>
                                    <Tooltip title={buildParameters.commit_sha}>
                                      <p className='font-12 d-flex align-center'>
                                        <span className='' style={{ color: '#787878' }}>Commit Id</span>&nbsp;:&nbsp;
                                        <span className='d-flex align-center' style={{ color: '#2f2f2f' }}>
                                          <span className='text-ellipsis-150 d-block'>{buildParameters.commit_sha}</span><CopyToClipboard data={buildParameters?.commit_sha} /></span>
                                      </p>
                                    </Tooltip>

                                  </>
                                  : <p className='font-12'>
                                    <span className='' style={{ color: '#787878' }}>Commit Id</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                                  </p>
                              }
                            </div>
                            <div className='d-flex'>
                              {
                                buildParameters?.commit_message ?

                                  <>
                                    <Tooltip title={buildParameters.commit_message}>
                                      <p className='font-12 d-flex align-center'>
                                        <span className='' style={{ color: '#787878' }}>Commit Msg</span>&nbsp;:&nbsp;
                                        <span className='d-flex align-center' style={{ color: '#2f2f2f' }}><span className='text-ellipsis-150' >{buildParameters.commit_message}</span><CopyToClipboard data={buildParameters?.commit_message} /></span>
                                      </p>
                                    </Tooltip>

                                  </>
                                  : <p className='font-12'>
                                    <span className='' style={{ color: '#787878' }}>Commit Msg</span> : <span className='text-ellipsis-110' style={{ color: '#2f2f2f' }}>N/A</span>
                                  </p>
                              }
                            </div>
                          </div>
                          : null
                      }
                    </div>
                  )}

                  {selectedTab === 1 && (
                    <div className='pd-10'>
                      {
                        deployParameters && Object.keys(deployParameters).length > 0 ?
                          Object.keys(deployParameters).map((element, key) => (
                            <Tooltip title={element + " : " + JSON.stringify(deployParameters[element])}>
                              <p className='font-12'>
                                <span className='' style={{ color: '#787878' }}>{element}</span> : <span className='' style={{ color: '#2f2f2f' }}>{JSON.stringify(deployParameters[element])}</span>
                              </p>
                            </Tooltip>
                          ))
                          : <div class="text-color-grey blank-div-text text-center">Deploy Params Not Available</div>
                      }
                    </div>
                  )}

                  {selectedTab === 2 && (
                    <div className='pd-10'>
                      {
                        props.allDetails && Object.keys(props.allDetails).length > 0 ?
                          Object.keys(props.allDetails).map((element, key) => (
                            <Tooltip title={element + " : " + JSON.stringify(props.allDetails[element])}>
                              <p className='font-12'>
                                <span className='text-transform-capitalize' style={{ color: '#787878' }}>{element}</span> &nbsp;: &nbsp;<span className='' style={{ color: '#2f2f2f' }}>{props.allDetails[element] ? JSON.stringify(props.allDetails[element]) : "N/A."}</span>
                              </p>
                            </Tooltip>
                          ))
                          : <div class="text-color-grey blank-div-text text-center">All Details Not Available</div>
                      }
                    </div>
                  )}
                  {selectedTab === 3 && !viewErrorStatus && (
                    <div className='pd-10'>
                      {
                        props.allDetails && Object.keys(props.allDetails).length > 0 ?
                          Object.keys(props.allDetails).map((element, key) => (
                            <Tooltip title={element + " : " + JSON.stringify(props.allDetails[element])}>
                              <p className='font-12'>
                                <span className='text-transform-capitalize' style={{ color: '#787878' }}>{element}</span> &nbsp;: &nbsp;<span className='' style={{ color: '#2f2f2f' }}>{props.allDetails[element] ? JSON.stringify(props.allDetails[element]) : "N/A."}</span>
                              </p>
                            </Tooltip>
                          ))
                          : <div class="text-color-grey blank-div-text text-center">All Details Not Available</div>
                      }
                    </div>
                  )}


                  {props.activity_status == "FAILED" && viewErrorStatus ? (
                    <div className='pd-10'>
                      <div >

                        <span className='color-bold-red font-12' >
                          {props.activity_description}
                        </span>

                      </div>
                    </div>
                  ) : null}

                </>
          }

        </Paper>
      </Popover>
    </div>
  );
};

ParameterComponent.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

const useStylesParameters = makeStyles((theme) => ({
  rooter: {
    fontFamily: "Montserrat",
    '& .btn-overrider': {
      borderRight: '1px solid rgb(7, 135, 225)!important',
      '&:last-child': {
        borderRight: '1px solid rgb(7, 135, 225)!important',
      }
    }
  },
  errorTabs: {
    height: "30px !important",
    width: "106px !important",
    background: "transparent",
    border: "1px solid #E6E6E6",
    borderRadius: "4px",

    "&.active": {
      background: "rgb(7, 135, 225)!important"
    }
  },
  tabs: {
    '& .MuiTab-wrapper': {
      width: 'auto!important',
      height: 'auto!important',
      borderRadius: '0!important',
      border: 'none!important',
      textTransform: 'capitalize!important'
    },
    '&.MuiTab-root': {
      backgroundColor: '#fff!important',
      marginLeft: '5px!important',
      marginRight: '5px!important',
      '&.Mui-selected': {
        backgroundColor: '#0086ff!important',
        color: '#fff!important',
        borderColor: '#0086ff!important',
        marginLeft: '5px!important',
        marginRight: '5px!important'
      },
    }
  },
  topHeader: {
    fontWeight: "bold",
    fontSize: "12px",
    borderBottom: "1px solid #E6E6E6",
    paddingBottom: "10px",
    textTransform: "uppercase",
    marginBottom: "10px",

  },
  popoverClass: {
    '& .MuiTabs-root': {
      borderBottom: '2px solid #e6e6e6',
    },
    '& .MuiTab-root': {
      border: '1px solid #949494!important',
      borderRadius: '4px!important',
      backgroundColor: 'transparent!important',
      fontSize: '13px!important',
      color: '#505050!important',
      padding: '6px 10px!important',
      minHeight: 'auto',
      marginTop: '0px!important',
      marginBottom: '0px!important',
      fontWeight: 400,

      '&:hover': {
        backgroundColor: '#0086ff!important',
        color: '#fff!important',
        borderColor: '#0086ff!important'
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none!important'
    },
    '& .MuiPaper-rounded': {
      borderRadius: "12px",
    }
  },
  primaryButton: {
    display: "flex",
    height: "36px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "5px",
    border: "1px solid  #E6E6E6",
    background: "#FFF",
    '&:hover': {
      backgroundColor: '#0086ff',
      color: '#fff',
      borderColor: '#0086ff'
    },
  },
  activitybutton: {
    border: '1px solid #E6E6E6',
    display: "flex",
    width: "fit-content",
    height: "36px",
    padding: "10px",
    borderRadius: '4px',
    backgroundColor: 'transparent',
    textWrap: "nowrap",
    gap: "10px",
    "& .warning-logo": {
      color: "#C11212"
    },
    '&:hover': {
      backgroundColor: '#0086ff',
      color: '#fff !important',
      borderColor: '#0086ff',
      "& .warning-logo": {
        color: "white"
      },
    },


  }

}));

const useStyles = makeStyles((theme) => ({
  containerBox: {
    //padding: '16px 0px',
    paddingTop: "0px",
    justifyContent: "space-between",
    '& button.btn': {
      '&:hover': {
        color: '#0096db!important'
      }
    },
    '& .search-bar': {
      border: '1px solid #ebedee',
      overflow: 'hidden',
      borderRadius: '8px',
      '& .input-component input:focus': {
        border: 'none!important'
      }
    },
  },
  searchPosition: {
    position: "absolute",
    top: "16.8px",
    right: "8%",
    "@media screen and (min-width: 1440px) and (max-width: 1920px)": {
      right: "6%",
    }
  },
  historyCard: {
    //height: 550,
    '& .header-tab-build': {
      backgroundColor: '#FAFAFA',
      borderBottom: '1px solid #ebedee',
      gap: 8,
      fontSize: 12,
      display: 'grid',
      padding: 12,
      borderTop: '1px solid #ebedee',
      gridTemplateColumns: "10% 10% 16% 16% 16% 28%",//'6% 6% 6% 8% 10% 10% 14% 16% 1fr',
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      color: "#787878",
    },
    '& .body-tab-build': {
      borderBottom: '1px solid #ebedee',
      gap: 8,
      fontSize: 12,
      display: 'grid',
      padding: '12px 8px',
      gridTemplateColumns: "10% 10% 16% 16% 16% 28%",
      '& p': {
        //padding: '0px 3px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      '& .btn-disabeld-flaticon': {
        '& .MuiSvgIcon-root': {
          color: '#b9b8b8 !important'
        }
      },
      '& .btn-mui-svg': {
        padding: '0px 0.5rem!important'
      },
    },
    '& .history-body': {
      // overflowY: 'scroll',
      // height: 515,
      '& > div': {
        backgroundColor: '#FFFFFF',
      },
    }
  }
}));


const useStyles1 = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
const MenuListComposition = props => {
  const classes = useStyles1();
  const [open, setOpen] = useState(false);
  const status = props.status;
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {
                      status == "RUNNING" || status == "IN_QUEUE" ?
                        <MenuItem onClick={props.onRevoke}>Revoke</MenuItem>
                        :
                        null
                    }

                    <MenuItem onClick={props.onRebuild}>Redeploy</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

MenuListComposition.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}