import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

import { styled } from '@material-ui/core/styles';

import CommonHorizontalTab from '../../../components/genericComponents/CommonHorizontalTab';
import { useParams } from 'react-router-dom';
import GenerateURL, { GenerateSearchURL } from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import { ClusterName } from '../../../util/util';
import Events from '../components/Events';
import Conditions from '../components/Conditions';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import { Cancel } from '@material-ui/icons';
import Editor from '@monaco-editor/react';
import Annotations from '../Ingress/list/components/Annotations';
import HealthStatusRoundMeter from '../../../components/genericComponents/HealthStatusRoundMeter';
import { Tooltip } from '@material-ui/core';
import { Loading } from '../../utils/Loading';
import ExandableKeyValues from '../../../components/hoc/ExpanadleKeyValues';

function NodeDetails(props) {
    const[showLoading,setShowLoading]=useState(false);
    const classes = useStyles();
    const [state, setState] = useState({ selectedTab: "MasterNodes", selectedTabOrder: 1, selectedTabTwo: "Conditions" ,nodeInfo:{},nodeHealthStatus:{}});
    const changeTab = (tab) => {
        setState({
            ...state,
            selectedTab: tab,
            selectedTabTwo: tab
        })
    }
    const changeTabTwo = (tab) => {
        setState({
            ...state,
            selectedTabTwo: tab
        })
    }
const {cluster_id,node_name}= useParams();
const nodeName=node_name;

    function getSelectedTabTwo() {
        switch (state.selectedTabTwo) {
            case "Events":
                return <Events api_url={GenerateURL({ cluster_id: cluster_id , node_name:nodeName}, properties.api.get_nodes_events)} />

            case "Conditions":
                if(state.nodeInfo.metadata)
                return <Conditions nodeInfo={state.nodeInfo.status.conditions} conditions_negative={true} /> 
                else
                return <Conditions nodeInfo={[]}/>
            default:
                return <h1>Hi</h1>
        }
    }
 
    const tablist = [
        {
            tabName: "Overview",
            order: 1
        },
        {
            tabName: "DESCRIBE NODES (YAML)",
            order: 2
        }
    ]
    const updateSelectedTab = (selectedTabOrder) => {
        setState({ ...state, selectedTabOrder: selectedTabOrder });
    }
    function fetchNodeInfo(data, url) {

    var requestInfo = {
        endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_nodes),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    var requestInfo2 = {
        endPoint: GenerateURL({ cluster_id:cluster_id,node_name:nodeName }, properties.api.node_health_status),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        console.log("search", requestInfo.endPoint)
    }


    if (url) {
        requestInfo.endPoint = url;
    }
    if (props.api_url) {
        requestInfo.endPoint = props.api_url;
        console.log("hello2")
    }
    setState(new_state => ({
        ...new_state,
        search_data: data,

        current: requestInfo.endPoint,

    }));
    setShowLoading(true);
    requestInfo.endPoint=requestInfo.endPoint+nodeName+"/";
    InvokeApi(requestInfo, NodeInfoFetchSuccess, NodeInfoFetchFailure);
    InvokeApi(requestInfo2, NodeInfoFetchSuccess2, NodeInfoFetchFailure2);

}
const invokeSearch = (data) => {
    console.log("invokeSearch", data);
    fetchNodeInfo(data);
}

function NodeInfoFetchSuccess(data) {
    console.log("data coming api ", data)
    setState(new_state => ({
        ...new_state,
        nodeInfo: data,
        yaml_namespace: YAML.stringify(data),
        count: data.count,
        next: data.next,
        previous: data.previous
    }));
    // setTimeout(() => {
    //     eventTypeCounter(data.items)
    // }, 300);
   
    setShowLoading(false);

}
console.log("data coming api 2", state.nodeInfo)

function NodeInfoFetchFailure(error, exception) {
    console.log("EventsFetchFailure", error);
    setState(new_state => ({
        ...new_state,
        error: true
    }));
    setShowLoading(false);
}
function NodeInfoFetchSuccess2(data) {
    console.log("data coming api 22222222 ", data)
    setState(new_state => ({
        ...new_state,
        nodeHealthStatus: data,
    }));

    setShowLoading(false);

}
console.log("data coming api 2", state.nodeInfo)

function NodeInfoFetchFailure2(error, exception) {
    console.log("EventsFetchFailure", error);
    setState(new_state => ({
        ...new_state,
        error: true
    }));
    setShowLoading(false);
}

useEffect(() => {
    fetchNodeInfo();
}, [])

function getColor(percentage) {
    if (percentage <= 40) {
        return "#69e09c"
    }
    if (percentage <= 60) {
        return "#fedf5c"
    }
    if (percentage <= 80) {
        return "#ff8969"
    }
    return "#008000"
}

 
function getNodeHealth(data){
   
    const array=data;
    const keys=Object.keys(array);
    let healthList=[];
    for (let index = 0; index < keys.length; index++) {
        const element = array[keys[index]];
        const health={
            label: keys[index],
            percentage: element.percentage,
            color: getColor(element.percentage)

        }
        healthList.push(health);
        
    }
    console.log("hello11",healthList)
    return healthList;
} 




const [isEditorReady, setIsEditorReady] = useState(false);
const valueGetter = useRef();

const YAML = require('json-to-pretty-yaml');

function handleEditorDidMount(_valueGetter) {
    setIsEditorReady(true);
    valueGetter.current = _valueGetter;
}
console.log("hello",state)

return (
    <div className={classes.root}>
        <div className="main-div d-flex-top">
            <MainHeader name={nodeName} cluster_id={cluster_id} created_at={state.nodeInfo.metadata?(new Date(state.nodeInfo.metadata.creationTimestamp)).toString().substring(4, 21) + " IST" :"NULL" }
            />
        </div>
        {
            showLoading?<Loading/>:null
        }
        <div className={classes.mainBodyDiv}>
            <CommonHorizontalTab
                style={{ borderBottom: '1px solid #dedede' }}
                tabList={tablist}
                selectedTabOrder={state.selectedTabOrder}
                updateSelectedTab={updateSelectedTab} />
            {state.selectedTabOrder == 1 ?

                <div className={classes.subBoby}>
                    <InnerBox>
                        <InnerBoxData>
                            <div>
                                <span className={classes.smallText}>Node Info</span>
                                {state.nodeInfo.metadata?<NodeData nodeInfo={state.nodeInfo}/>:null}
                                
                            </div>
                            <div>
                                <span className={classes.smallText}>Node Health</span>
{state.nodeHealthStatus?                              <TabTwo>
                                    <HealthStatusRoundMeter data={state.nodeHealthStatus}/>
                                </TabTwo>
                      :null}
        </div>
                            <div>
                                <span className={classes.smallText} style={{ paddingLeft: '15px' }}>Labels</span>
                                {state.nodeInfo.metadata?
                                  <UI_ExpandComponent data={state.nodeInfo.metadata.labels}  />
                                // <Labels labels={state.nodeInfo.metadata.labels}/>
                                :null}
                                
                            </div>
                        </InnerBoxData>
                    </InnerBox>
                    <div className="expensionPanel" style={{ margin: '10px', padding: '0px' }}>
                        <div className="expensionPanelHead">
                            <a href="@" className={state.selectedTabTwo == "Conditions" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onClick={(e) => {e.preventDefault();  changeTabTwo("Conditions")}} >Conditions
                            
                                </a>
                            <span style={{cursor: "pointer"}} role="button" tabIndex={0} className={state.selectedTabTwo == "Events" ? "expensionPanelTab width-fitcontent active" : "expensionPanelTab width-fitcontent"} onKeyDown={()=>{}} onClick={() => {changeTabTwo("Events")}} >Events</span>

                        </div>
                        <div className="expensionPanelBody">
                            {getSelectedTabTwo()}
                        </div>
                    </div>
                </div> :
                 <Editor
                 height="90vh"
                 language="javascript"
                 options={{
                    minimap: {
                      enabled: false,
                    },
                    fontSize: 18,
                    cursorStyle: "block",
                    wordWrap: "on",
                  }}
                  defineTheme={{
                    themeName: "my-theme",
                    themeData: {
                      colors: {
                        "editor.background": "#000000",
                      },
                    },
                  }}
                 value={state.yaml_namespace ? state.yaml_namespace : "//console.log(hello world)"}
                 editorDidMount={handleEditorDidMount}
             />
            }
        </div>
    </div>
)
}
NodeDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default NodeDetails;



function NodeData(props) {
    const nodeInfo=props.nodeInfo;
    function getNodeStatus(){
        const array=nodeInfo.status.conditions;
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element.type=="Ready"){
                return <span style={{display:'flex', alignItems:'center'}}> <CheckCircleIcon  style={{ fontSize: '18px', color: '#69e09c' }} />&nbsp;<span>Ready</span> </span>;
            }
            
        }
        return <span style={{display:'flex', alignItems:'center'}}> <Cancel style={{ fontSize: '18px', color: '#ffce25' }} />&nbsp;<span>Not Ready</span> </span>;
    }
    function getNodeType(){
        if(Object.keys(nodeInfo.metadata.labels).includes("node-role.kubernetes.io/master")){
            return <p>Master</p>
        }
        return <p>Worker</p>
    }
    return (
        <div className="nodes-data">
            <div className="data" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="margin-top-bottom-3px" style={{display:'flex', alignItems:'center'}}>
                    <span className="text-color-black">Node Type:</span>
                    <span className="text-color-grey"> {getNodeType()} </span>
                </div>
                <div className="margin-top-bottom-3px" style={{display:'flex', alignItems:'center'}}>
                    <span className="text-color-black">Node Status:</span>
                    <span className="text-color-blue">{getNodeStatus()}</span>
                </div>
                <div className="margin-top-bottom-3px d-flex">
                    <span className="text-color-black">Taints:</span>
                    {nodeInfo.spec.taints?
    <span className="text-color-blue"><Annotations taints={nodeInfo.spec.taints?nodeInfo.spec.taints:[]} ></Annotations></span>
    : <span className="text-color-grey">NA</span>}
    
                </div>
                <div className="margin-top-bottom-3px d-flex align-center">
                    <span className="text-color-black">Annotations: </span>
                    <span className="text-color-grey"> <Annotations annotations={nodeInfo.metadata?nodeInfo.metadata.annotations:null} ></Annotations>            </span>
                </div>
               
                {/* <div className="margin-top-bottom-3px">
                    <span className="text-color-black">Unschedulable: </span>
                    <span className="text-color-grey">None</span>
                </div> */}
                {/* <div className="margin-top-bottom-3px">
                    <span className="text-color-black">Role: </span>
                    <span className="text-color-grey">Master, Worker</span>
                </div> */}
            </div>
        </div>
    )
}
NodeData.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_ExpandComponent(props) {
    const data = props.data;
    const data_list = [];
    const iteration_count = 4;
    const data2 = props.data_ui ? props.data_ui : [];
    const list_2 = [];
  
    let labels_list = [];
  
    let labels_list2 = [];
  
  
    function get_list(labels, labels2, list_type) {
      labels_list = [];
      labels_list2 = [];
      if (labels) {
        Object.keys(labels).forEach(label => {
          labels_list.push(
            {
              key: label,
              value: labels[label]
            }
          );
          labels_list2.push(
            <Tooltip placement="bottom" title={label + "=" + labels[label]}>
              <div className="pod-lable">
                {label + "=" + labels[label]}
              </div>
            </Tooltip>
          );
        });
  
  
      }
  
      if (labels2) {
        labels2.forEach(label => {
          labels_list.push(
            {
              key: label.env_key,
              value: label.env_value
            }
          );
          labels_list2.push(
            <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
              <div className="pod-lable">
                {label.env_key + "=" + label.env_value}
              </div>
            </Tooltip>
          );
        });
  
  
  
      }
      if (list_type == "1") {
        return labels_list;
  
      }
      if (list_type == "2") {
        return labels_list2;
  
      }
      return [];
    }
  
  
    return (
      <>
        <ExandableKeyValues
          compoenent_list={get_list(data, data2, 2)}
          iteration_count={iteration_count}
          labels_list={get_list(data, data2, 1)}
          isLabel={true}
          expandable_component={
            <>
              +{labels_list.length - iteration_count}
            </>
          } />
      </>
    );
  }
  UI_ExpandComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function Labels(props) {
    const labels = props.labels?props.labels:null;
    return (

        <div className="label-box">
            { labels?
                Object.keys(labels).map(label => (
                    <Tooltip title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                        {label + "=" + labels[label]}           
                         </div>
                        </Tooltip>

                )):null
            }

        </div>
    )
}
Labels.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        '& .cluster-head-data': {
            "& .cluster-name": {
                color: '#000!important'
            }
        },
        '& .main-div': {
            padding: '16px 0px'
        }
    },
    mainBodyDiv: {
        border: ' 1px solid #dedede',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        '& .headerul': {
            borderBottom: '1px solid #dedede',
            padding: '15px 20px 0px'
        },
        '& .expensionPanel': {
            border: 'none',
            backgroundColor: 'transparent',
            margin: '0px!important',
            '& .expensionPanelHead': {
                display: 'flex'
            }
        },
        '& .expensionPanelBody': {
            height: '100%',
            '& .head': {
                padding: '10px'
            }
        },
        '& .expensionPanelTab': {
            position: 'relative',
            top: '2px'
        }
    },
    subBoby: {
        backgroundColor: '#fff',
    },
    smallText: {
        fontSize: '13px',
        color: '#878787',
        lineHeight: '2',
        textTransform: 'uppercase'
    }
}))

const MainContainer = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #dedede',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    marginBottom: '2rem',
    '& .head': {
        padding: '0px 10px'
    }
})
const InnerBoxData = styled('div')({
    display: 'grid',
    alignItems: '',
    gridTemplateColumns: '260px 350px 1fr',
    '& .label-box': {
        '& .pod-lable': {
            float: 'left'
        },
        clear: 'both'
    }
})
const TabTwo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .progress-container': {
        padding: '0px'
    },
    '& .CircularProgressbar': {
        '& .CircularProgressbar-trail': {
            strokeWidth: '8px'
        },
        '& .CircularProgressbar-path': {
            strokeWidth: '8px'
        }
    }
})
const InnerBox = styled('div')({
    backgroundColor: '#fcfcfc',
    borderBottom: '1px solid #dedede',
    padding: '15px',
    marginBottom: '30px',
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})

const SquareBox = styled('div')({
    borderRadius: '8px',
    padding: '10px',
    height: '50px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dedede',
    fontSize: '18px',
    fontWeight: '700',
    color: '#fff',
    marginRight: '5px'
})
const MainHeader = props => {
    console.log("props",props.created_at)
    return(
        <HeaderDiv>
                <SquareAvatar 
                varient="double"
                name = {props.name} />
                <div className="cluster-head-data">
                <div className="general-data">
                    <span className="cluster-name">{props.name}</span>
                                </div>
                    <div className="general-data">
                        <div className="inner-data">
                            <span className="text-color-black">Cluster: </span>
    <span className="text-color-grey">{ClusterName(props.cluster_id)} &nbsp;</span>
    <span className="text-color-black">Created on: </span>
    <span className="text-color-grey">{props.created_at}</span>
                        </div>
                    </div>
                </div>
        </HeaderDiv>
    )
}
MainHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const HeaderDiv = styled('div')({
    display:'flex',
    alignItems:'center',
    padding:'10px',
    '& .cluster-head-data':{
        marginLeft:'10px'
    },
    '& .cluster-name':{
        color: '#0086ff',
        fontSize: '18px',
        fontFamily: 'nunito',
        fontWeight: 600,
        lineHeight: '22px'
    },
    '& .general-data':{
        display:'flex',
        alignContent:'center',
        justifyItems:'space-between',
        fontSize:'12px',
        lineHeight:'18px',
        '& .inner-data':{
            marginRight:'6px'
        },
        '& .text-color-grey':{
            color:'#989898'
        }
    }

})
