import React from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

export const CircularMeterNew = (props)=>{
    const label = props.label;
    const percentage = props.percentage;
    const max = props.max;
    const color = props.color;
    const allocatedLabel = props.allocatedLabel;
    const allocatedValue = props.allocatedValue;
    console.log("itjdkdm",percentage);
    return (
        <>
        <div title={"max : "+max} className="progress d-flex f-direction-column" style={{width: 100,gap:"15px"}}>
            {/* <div className="text-progress">
                <p className="progress-per">{percentage ? `${percentage}%` : '--'}</p>
                <p className="progress-label">{label}</p>
            </div> */}
                    <CircularProgressbarWithChildren value={percentage} strokeWidth={10} className='CircularProgressbarNew' styles={buildStyles({
                    pathTransitionDuration: 0.5,
                    pathColor: `${color}`,
                    trailColor: '#F1F1F1',
                    backgroundColor: '#eee',
                    })} >
                        <div style={{marginTop: "-10px", display: "flex",flexDirection: "column",alignItems: "center"}}>
                            <span className="font-16 font-weight-600" style={percentage != 0 ?{color:`${color}`}: {}}>{percentage ? `${percentage}%` : '--'}</span>
                            <span className="font-12 font-weight-400 color-value avtar" style={{marginTop: "-4px"}}>{label}</span>
                        </div>
                        
                    </CircularProgressbarWithChildren>
                
                { allocatedLabel && allocatedValue ?  
                <div className="process-label-sec">
                    <div className="font-12 font-weight-500 color-key-78 progress-label">{allocatedLabel}</div>
                    <div className="progress-label font-12 font-weight-600 color-value mt-2" >{allocatedValue}</div>
                </div>
                : null
            }
        </div>
        
        </>
    );
}

export const CircularMeterListNew = (props)=>{
    const list = props.list;
    
    return (
        <div className="progress-container justify-evenly">
            {
                list.map((element,key)=>(
                    <>
                        <CircularMeterNew key={key} label={element.label} percentage={element.percentage} allocatedValue={element.allocatedValue} allocatedLabel={element.allocatedLabel} max={element.max} color={element.color} />
                        {key !== list.length -1 ? 
                            <span style={{width: "1px",height: "147px",backgroundColor: "#DFDFDF"}}></span>
                        :null}
                    </>
                    
                    
                ))
            }
           
        </div>
    );
}