import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import properties from "../../../../../properties/properties";
import GenerateURL, {
  GenerateSearchURL,
} from "../../../../../util/APIUrlProvider";
import InvokeApi, { PostData } from "../../../../../util/apiInvoker";
import { Loading } from "../../../../utils/Loading";
import { Tooltip, IconButton, Grid } from "@material-ui/core";
import moment from "moment";
import {
  CopyToClipboard,
  getDuration,
} from "../../../../../util/util";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchBar from "../../../../../components/SearchBar";
import Pagination from "../../../../../components/Pagination";
import { GetAuth } from "../../../../../util/security";

import ActivityStepDetails from "../../../../superAdmin/activityFramework/components/ActivityStepDetails";
import { Link } from "react-router-dom";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StorageIcon from "@material-ui/icons/Storage";
import { useParams, useLocation } from "react-router-dom";
import { ParameterComponent } from "./DeployHistory";
const BuildHistory = (props) => {
  const classes = useStyles();
  const service_id = props.service_id;
  const env_id = props.env_id;
  const refresh_count = props.refresh_count;
  const service_data = props.service_data;
  const workspace_base_url = properties.workspace_url;
  const selected_tab_id = props.selected_tab_id;
  const options_tabs = props.options_tabs;
  const refresh = props.refresh;
  const serviceEnv = props.serviceEnv;



  var service_env_type = service_data && service_data.type;
  console.log("service_env_type", service_env_type);
  service_env_type = service_env_type ? service_env_type.toLowerCase() : null;
  var service_env = service_data && service_data.service_env;
  service_env = service_env ? service_env.toLowerCase() : props.service_env_name ? props.service_env_name :null;
  var service_name = service_data && service_data.name;

  const params = useParams();

  const queryString = require("query-string");
  //   var parsedQueryString = queryString.parse(props.location.search);

  console.log("paramsdsdsssds ", params);

  const [state, setState] = useState({
    show_loading_icon: true,
    selected_activity_id: null,
    activity_details_loading: false,
    infiniteScrollInherits: {},
    curr_page: "",
    total_page: "",
    refresh_count: 1,
    activity_list: [],
  });
  const refreshComponent = (props) => {
    setState((new_state) => ({
      ...new_state,
      refresh_count: new_state.refresh_count + 1,
    }));
  };
  useEffect(() => {
    fetchHistory();
  }, [service_id, env_id, refresh_count, selected_tab_id, state.refresh_count]);

  function fetchHistory(data, url) {
    if (!url) {
      url = GenerateURL(
        { service_id: service_id, env_id: env_id },
        properties.api.env_build_request_url
      );
    }
    var requestInfo = {
      endPoint: url,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }
    InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);
    setState((new_state) => ({
      ...new_state,
      search_data: data,
      current: requestInfo.endPoint,
      show_loading_icon: true,
      curr_page: 1,
    }));
  }

  function historyFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      history_list: data.results,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Number.isInteger(Number(data.count) / 10)
        ? (Number(data.count) / 10).toFixed(0)
        : (Number(data.count) / 10 + 1).toFixed(0) > Number(data.count) / 10 + 1
          ? (Number(data.count) / 10 + 1).toFixed(0) - 1
          : (Number(data.count) / 10 + 1).toFixed(0),
      curr_page: new_state.curr_page === "" ? 1 : new_state.curr_page,
    }));
  }

  function historyFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      error: error,
      history_list: [],
    }));
  }

  function getBuildbyUser(history) {
    return (
      <p>
        {history.build_by_pipeline ? (
          <>
            <p>Pipeline: {history.build_by_pipeline.pipeline.name}</p>
            <p>Triggered by: {history.build_by_pipeline.trigger_by}</p>{" "}
          </>
        ) : (
          <p>{history.build_by_user}</p>
        )}
      </p>
    );
  }

  function validateAndReTriggerBuild(data) {
    var data = {
      custom_tag:
        data && data.build_args_key_value
          ? data.build_args_key_value.custom_tag
          : null,
      branch_name:
        data && data.build_args_key_value
          ? data.build_args_key_value.branch_name
          : null,
      no_cache:
        data && data.build_args_key_value
          ? data.build_args_key_value.no_cache
          : null,
    };
    if (!data.custom_tag) {
      delete data.custom_tag;
    }
    if (!data.branch_name) {
      delete data.branch_name;
    }
    var endPoint = GenerateURL(
      { service_id: props.service_id, env_id: props.env_id },
      properties.api.env_build_request_url
    );
    PostData(
      endPoint,
      data,
      successBuildTriggerCallBack,
      failedBuildTriggerCallBack
    );
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: true,
    }));
  }
  function successBuildTriggerCallBack(response) {
    setState({
      ...state,
      trigger: "success",
      tag: "",
      branch_name: "",
      no_cache: false,
      show_loading_icon: false,
    });
    fetchHistory();
  }

  function failedBuildTriggerCallBack(error) {
    setState({
      ...state,
      trigger: "failed",
      error_msg: error,
      show_loading_icon: false,
    });
  }
  function fetchNextHistory(data, tab_order, url) {
    console.log("urllll", url);
    if (url.split("/").pop() == "null") {
    } else {
      if (!url) {
        url = GenerateURL(
          { service_id: props.service_id, env_id: props.env_id },
          properties.api.env_build_request_url,
        );
      }
      var requestInfo = {
        endPoint: url,
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" },
      };
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
      setState((new_state) => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        show_loading_icon: true,
      }));
    }
  }

  function NexthistoryFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      history_list: data.results,
      show_loading_icon: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: new_state.curr_page + 1,
    }));
  }

  function NexthistoryFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      error: error,
      history_list: [],
    }));
  }
  function fetchPrevHistory(data, tab_order, url) {
    if (url.split("/").pop() == "null") {
    } else {
      if (!url) {
        url = GenerateURL(
          { service_id: service_id, env_id: env_id },
          properties.api.env_build_request_url
        );
      }
      var requestInfo = {
        endPoint: url,
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" },
      };
      if (data) {
        requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
      }
      InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);
      setState((new_state) => ({
        ...new_state,
        search_data: data,
        current: requestInfo.endPoint,
        show_loading_icon: true,
      }));
    }
  }

  function PrevhistoryFetchSuccess(data) {
    setState((new_state) => ({
      ...new_state,
      history_list: data.results,
      show_loading_icon: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: new_state.curr_page - 1,
    }));
  }

  function PrevhistoryFetchFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: false,
      error: error,
      history_list: [],
    }));
  }

  // History info

  function fetchHistoryInfo(enteredPageNumber) {

    console.log("enterrrr", enteredPageNumber);


    // url = GenerateURL(
    //   { service_id: service_id, env_id: env_id },
    //   properties.api.env_build_request_url
    // );


    var requestInfo = {
      endPoint: GenerateURL(
        { service_id: service_id, env_id: env_id },
        properties.api.env_build_request_url
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };


    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    InvokeApi(
      requestInfo,
      (response) => {
        handleHistorySuccessApiHit(response, current_page);
      },
      handleHistoryDataFailedApiHit
    );
  }

  function handleHistorySuccessApiHit(data, count) {
    console.log("enterrrr", data);
    setState((new_state) => ({
      ...new_state,
      history_list: data.results,
      show_loading_icon: false,
      count: data.count,
      next: data.next ? properties.api.baseURL + data.next : null,
      previous: data.previous ? properties.api.baseURL + data.previous : null,
      total_page: Math.ceil(Number(data.count / 10)),
      curr_page: Number(count),
    }));
  }

  function handleHistoryDataFailedApiHit(error, exception) {
    setState((new_state) => ({
      ...new_state,
      history_info_status: "SUCCESS",
      show_loading_icon: false,
      error: error,
      history_list: [],
    }));
  }





  function changeTab(newTabId) {
    setState((new_state) => ({
      ...new_state,
      selected_tab_id: newTabId,
    }));
  }
  function closeLogs() {
    setState((new_state) => ({
      ...new_state,
      view_logs: false,
      logMetadata: {},
    }));
  }

  function closeTab(tabId) {
    var response = state.infiniteScrollInherits.closeTabFunction(
      tabId,
      state.logMetadata,
      closeLogs
    );
    if (response) {
      setState((new_state) => ({
        ...new_state,
        logMetadata: response.new_tabs_data,
        selected_tab_id: response.selected_tab_id,
      }));
    }
  }
  function onRevoke(task_id) {
    var url_temp = GenerateURL(
      { service_id: service_id, env_id: env_id, id: task_id },
      properties.api.env_build_revoke_url
    );
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
  }

  function handleSuccessRevoke(respone) {
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: false,
    }));
    refreshComponent();
  }

  function handleFailedRevoke(error) {
    setState((new_state) => ({
      ...new_state,
      error: true,
      show_loading_icon: false,
    }));
  }
  function onResume(id) {
    var url_temp = GenerateURL(
      { service_id: service_id, env_id: env_id, id: id },
      properties.api.build_resume
    );
    var fetchOptions = {
      endPoint: url_temp,
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    PostData(url_temp, {}, handleSuccessResume, handleFailedResume);
  }

  function handleSuccessResume(respone) {
    setState((new_state) => ({
      ...new_state,
      show_loading_icon: false,
    }));
    refreshComponent();
  }

  function handleFailedResume(error) {
    setState((new_state) => ({
      ...new_state,
      error: true,
      show_loading_icon: false,
    }));
  }

  function callResumeFunctionbyStatus(activity_status, id) {
    if (
      activity_status != "IN_QUEUE" ||
      activity_status != "RUNNING" ||
      activity_status != "SUCCESS"
    )
      onResume(id);
  }

  function getClassbyActivityStatus(activity_status, type) {
    if (type === "Revoke") {
      if (
        (activity_status && activity_status == "IN_QUEUE") ||
        (activity_status && activity_status == "RUNNING")
      )
        return "icon-btn-v1 icon-btn-outline-danger";
      else return "btn-sq-icon-primary-disabled d-flex align-center";
    } else {
      if (type === "Resume") {
        if (
          (activity_status && activity_status == "IN_QUEUE") ||
          (activity_status && activity_status == "RUNNING") ||
          (activity_status && activity_status == "SUCCESS") ||
          (activity_status && activity_status == "REVOKED")
        )
          return "btn-sq-icon-primary-disabled d-flex align-center";
        else return "btn-sq-icon-primary d-flex align-center";
      } else {
        if (
          (activity_status && activity_status == "IN_QUEUE") ||
          (activity_status && activity_status == "RUNNING")
        )
          return "btn-sq-icon-primary-disabled d-flex align-center";
        else return "btn-sq-icon-primary d-flex align-center";
      }
    }
  }

  function callFunctionbyStatus(activity_status, id) {
    if (
      (activity_status && activity_status == "IN_QUEUE") ||
      (activity_status && activity_status == "RUNNING")
    )
      onRevoke(id);
  }

  function removeExtraString(data) {
    var final_sting = data.worker_name ? data.worker_name.split("@")[1] : null;
    final_sting = final_sting ? final_sting.toLowerCase() : null;
    return final_sting;
  }
  function getEnvNamefromId() {
    var env_name = "";
    if (service_data && service_data.component_env) {
      service_data.component_env.map((item) => {
        if (item.id == env_id) {
          env_name = item.project_env.name;
        }
      });
    }
    return env_name;
  }
  var service_env_name = service_env ? service_env : props.service_env_name? props.service_env_name : getEnvNamefromId();
  var url = GenerateURL(
    { service_id: props.service_id, env_id: props.env_id },
    properties.api.env_build_request_url,
    true
  );
  const is_permitted = GetAuth().permission.POST.includes(url);

  function getActivityCurrentInfo(activity_id) {
    var requestInfo = {
      endPoint: GenerateURL(
        { activity_id: activity_id },
        properties.api.get_current_activity_status
      ),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" },
    };
    setState((prev_state)=>({
      ...prev_state,
      selected_activity_id: activity_id,
      activity_details_loading: true,
    }))
    InvokeApi(
      requestInfo,
      getActivityCurrentInfoSuccess,
      getActivityCurrentInfoFailure
    );
  }

  function getActivityCurrentInfoSuccess(response) {
    if (response.results.length != 0) {
      let fetchedData = response.results;
      // fetchedData.reverse();
      // console.log('fetchedData>>>', fetchedData);

      state.history_list.forEach((activity) => {
        if (
          activity.activity_status.id == response.results[0].activity_status_id
        ) {
          activity.activity_details = fetchedData;
          setState((new_state) => ({
            ...new_state,
            activity_details_loading: false,
            history_list: [...state.history_list],
            activity_step_details: {
              open_div: state.history_list.length > 0 ? true : false,
            },
          }));
        }
      });
    }
    // console.log("history_list", state.history_list);
  }

  function getActivityCurrentInfoFailure(error, exception) {
    setState((new_state) => ({
      ...new_state,
      error: true,
      activity_details_loading: false,
    }));
  }
  const emptyArr = (activity_id) => {
    state.history_list.forEach((activity) => {
      if (activity.activity_details && activity.activity_details.length > 0) {
        // activity.activity_details.forEach((item, key) => {
        if (activity.id == activity_id) {
          activity.activity_details = null;
          setState((new_state) => ({
            ...new_state,
            history_list: [...state.history_list],
            selected_activity_id: null,
            activity_details_loading: false,
            activity_step_details: {
              open_div: state.history_list.length > 0 ? true : false,
            },
          }));
        }
        // })
      }
    });
  };

  // console.log("history_list_state", state.history);
  console.log('state.history_list>>', state.history_list);
  console.log(state, "tyfgcvbnm ===================>")
  return (
    <>
      <Grid
            container
            className={classes.containerBox + " " + "align-center"}
          >
            <Grid item xs={5} className={classes.searchPosition} style={{position: "absolute",top: "16.5px",left: "70%"}}>
              <SearchBar
                search_data={state.search_data}
                default_filter={{ name: "build_tag", label: "Artifact" }} // "deploy_tag" : "target_image_tag"
                search_call_back={fetchHistory}
                clear_search_callback={fetchHistory}
                small={true}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <Pagination
                current_page_count={
                  state.history_list ? `${state.curr_page}` : 0
                }
                total_count={state.total_page}
                next={state.next}
                previous={state.previous}
                on_previous_click={() => {
                  fetchPrevHistory(null, 0, state.previous);
                }}
                on_next_click={() => {
                  fetchNextHistory(null, 0, state.next);
                }}
                on_pageNumber_click={(pageNumber) => {
                  fetchHistoryInfo(pageNumber);
                }}
              />
            </Grid> */}
      </Grid>
      <span> {options_tabs} </span>
      {state.show_loading_icon ? (
        <div className={classes.historyCard} style={{height: "300px"}} >
          <Loading varient="light" />
        </div>
      ) : (
        <>
          <div className="font-12 font-weight-600 color-key-78 mt-12 mb-12">Build History</div>
          <div className={classes.historyCard}>
            <div style={{borderRadius: "6px", border:"1px solid #E6E6E6"}}>
              <div className="header-tab-build">
                <p>Type</p>
                <p>Status</p>
                {/* <p>Duration</p> */}
                <p>Date/Duration</p>
                <p>Artifact</p>
                <p>Build by</p>
                {/*<p>Commit ID</p>
                <p>Commit Msg</p> */}
                {/* <p>Build Parameters</p> */}
                <p></p>
              </div>
              <div className="history-body">
                {state.history_list.length > 0 ? (
                  <>
                    {state.history_list.map((history, key) => (
                      <>
                        <div className="body-tab-build align-center">
                          <Link
                            className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                            to={
                              "/logs?global_task_id=" +
                              history.global_task_id +
                              "&tab_id=" +
                              history.id +
                              "&service_name=" +
                              service_name +
                              "&service_env=" +
                              service_env +
                              "&service_type=" +
                              service_env_type +
                              "&tab_name=BUILD" +
                              "&num=" +
                              history.build_number
                            }
                            target="_blank"
                          >
                            <span>Build #{history.build_number}</span>
                          </Link>
                          {/* <p><span className="cursor-pointer text-anchor-blue" onClick={() => { onLog(history.id, history.global_task_id, "Build #" + history.build_number) }}>Build#{history.build_number}</span></p> */}
                          <Tooltip
                            title={
                              history.activity_status &&
                              history.activity_status.status
                            }
                          >
                            <span
                              className={
                                history.activity_status &&
                                  history.activity_status.status == "FAILED"
                                  ? "new-status-chip new-failed"
                                  : history.activity_status &&
                                    history.activity_status.status == "RUNNING"
                                    ? "new-status-chip new-running"
                                    : history.activity_status &&
                                      history.activity_status.status == "REVOKED"
                                      ? "new-status-chip new-revoked"
                                      : history.activity_status &&
                                        history.activity_status.status ==
                                        "IN_QUEUE"
                                        ? "new-status-chip new-inqueue"
                                        : "new-status-chip new-success"
                              }
                              style={{width: "fit-content"}}
                            >
                              {(history.activity_status && history.activity_status.status) === 'IN_QUEUE' ? 'IN QUEUE' : history.activity_status && history.activity_status.status ? history.activity_status.status : "N/A"}
                            </span>
                          </Tooltip>
                          {/* <p>
                            {history.activity_status
                              ? history.activity_status.status === "SUCCESS" ||
                                history.activity_status.status === "FAILED"
                                ? getDuration(
                                  new Date(
                                    history.activity_status.start_time
                                  ),
                                  new Date(history.activity_status.end_time)
                                ) == 0
                                  ? "0s"
                                  : getDuration(
                                    new Date(
                                      history.activity_status.start_time
                                    ),
                                    new Date(history.activity_status.end_time)
                                  )
                                : getDuration(
                                  new Date(
                                    history.activity_status &&
                                    history.activity_status.created_at
                                  ),
                                  new Date(history.activity_status.updated_at)
                                ) == 0
                                  ? "0s"
                                  : getDuration(
                                    new Date(
                                      history.activity_status &&
                                      history.activity_status.created_at
                                    ),
                                    new Date(history.activity_status.updated_at)
                                  )
                              : "--"}
                          </p> */}
                          <div>
                            <p>
                              { moment().diff(moment(history.activity_status &&
                                  history.activity_status.created_at
                                  ? history.activity_status.created_at
                                  : ""), 'hours') > 8 ? 
                                    moment(
                                      history.activity_status &&
                                        history.activity_status.created_at
                                        ? history.activity_status.created_at
                                        : ""
                                    ).format('DD-MMM-YYYY')
                                  :  
                                    moment(
                                      history.activity_status &&
                                        history.activity_status.created_at
                                        ? history.activity_status.created_at
                                        : ""
                                    ).fromNow()
                              }
                              
                            </p>
                            <p className="color-key-78">
                              {history.activity_status
                                ? history.activity_status.status === "SUCCESS" ||
                                  history.activity_status.status === "FAILED"
                                  ? getDuration(
                                    new Date(
                                      history.activity_status.start_time
                                    ),
                                    new Date(history.activity_status.end_time)
                                  ) == 0
                                    ? "0s"
                                    : getDuration(
                                      new Date(
                                        history.activity_status.start_time
                                      ),
                                      new Date(history.activity_status.end_time)
                                    )
                                  : getDuration(
                                    new Date(
                                      history.activity_status.created_at &&
                                      history.activity_status.created_at
                                    ),
                                    new Date(history.activity_status.updated_at)
                                  ) == 0
                                    ? "0s"
                                    : getDuration(
                                      new Date(
                                        history.activity_status.created_at &&
                                        history.activity_status.created_at
                                      ),
                                      new Date(history.activity_status.updated_at)
                                    )
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <Tooltip title={history.build_tag}>
                              <p className="color-icon-secondary font-weight-500">{history.build_tag}</p>
                            </Tooltip>
                            {history.build_tag ? (
                              <CopyToClipboard data={history.build_tag} />
                            ) : null}
                          </div>

                          <p>{getBuildbyUser(history)}</p>
                          {/* <div className="d-flex">
                            <Tooltip title={history.commit_sha}>
                              <p>
                                {history.commit_sha ? (
                                  history.commit_sha
                                ) : (
                                  <span className="text-center">-</span>
                                )}
                              </p>
                            </Tooltip>
                            {history.commit_sha ? (
                              <CopyToClipboard data={history.commit_sha} />
                            ) : null}
                          </div> */}
                          {/* <div className="d-flex">
                            <Tooltip title={history.commit_message}>
                              <p>
                                {history.commit_message ? (
                                  history.commit_message
                                ) : (
                                  <span className="text-center">-</span>
                                )}
                              </p>
                            </Tooltip>
                            {history.commit_message ? (
                              <CopyToClipboard data={history.commit_message} />
                            ) : null}
                          </div> */}
                          {/* <div>
                            {history.build_args_key_value ? (
                              // Object.keys(history.build_args_key_value).map(
                              //   (element, key) => (
                              //     <Tooltip
                              //       title={
                              //         element +
                              //         " : " +
                              //         JSON.stringify(
                              //           history.build_args_key_value[element]
                              //         )
                              //       }
                              //     >
                              //       <p>
                              //         {element} :{" "}
                              //         {JSON.stringify(
                              //           history.build_args_key_value[element]
                              //         )}
                              //       </p>
                              //     </Tooltip>
                              //   )
                              // )
                              <ParameterComponent buildInfo={history} buildBy={getBuildbyUser(history)} onlyBuildInfo={true}  />
                            ) : (
                              <div className="text-color-grey blank-div-text">
                                No Build Params
                              </div>
                            )}
                          </div> */}
                          {/* <div className="">

                                                                <button className="btn btn-transparent text-anchor-blue d-block m-auto"
                                                                    onClick={() => {
                                                                        getActivityCurrentInfo(history.activity_status && history.activity_status.id ?
                                                                            history.activity_status.id : "")
                                                                    }} >View Details</button>
                                                            </div> */}
                          <div className="d-flex align-center justify-end" style={{gap: "12px"}}>
                            <div className=""><ParameterComponent buildInfo={history} onlyBuildInfo={true} serviceEnv={serviceEnv} /></div>
                            <div className="btn-group width-fitcontent" style={{gap: "4px"}}>
                            
                                <Tooltip title="View Activity Details">
                                  <button
                                    className="btn-sq-icon-primary d-flex align-center"
                                    onClick={() => {
                                      getActivityCurrentInfo(
                                        history.activity_status &&
                                          history.activity_status.id
                                          ? history.activity_status.id
                                          : ""
                                      );
                                    }}
                                  >
                                    {/* <span
                                      class="material-icons material-symbols-outlined"
                                      style={{ color: "#0086ff", fontSize: 20 }}
                                    >
                                      notes
                                    </span> */}
                                    <span className="ri-list-check-2" style={{ fontSize: 20 }}></span>
                                  </button>
                                </Tooltip>
                              
                              {is_permitted ? (
                                
                                  <Tooltip title="Rebuild">
                                    <button
                                      className={
                                        history.activity_status
                                          ? getClassbyActivityStatus(
                                            history.activity_status.status,
                                            "Rebuild"
                                          )
                                          : "btn-sq-icon-primary-disabled d-flex align-center"
                                      }
                                      onClick={() => {
                                        validateAndReTriggerBuild(history);
                                      }}
                                    >
                                      {/* <span class="flaticon-flash-on-indicator"></span> */}
                                      <span className="ri-flashlight-line font-20"></span>
                                    </button>
                                  </Tooltip>
                                
                              ) : (
                                
                                  <Tooltip title="Rebuild">
                                    <button
                                      className={"btn-sq-icon-primary-disabled d-flex align-center"}
                                    >
                                      <span class="ri-flashlight-line font-20"></span>
                                    </button>
                                  </Tooltip>
                                
                              )}

                              <button
                                className={
                                  history.activity_status
                                    ? getClassbyActivityStatus(
                                      history.activity_status.status,
                                      "Resume"
                                    )
                                    : "btn-sq-icon-primary-disabled d-flex align-center"
                                }
                                onClick={
                                  history.activity_status
                                    ? () =>
                                      callResumeFunctionbyStatus(
                                        history.activity_status.status,
                                        history.id
                                      )
                                    : null
                                }
                                title="Resume Build"
                              >
                                {/* <SkipNextIcon
                                  style={{ fontSize: 24, color: "#0086ff" }}
                                /> */}
                                <span className="ri-play-circle-line font-20"></span>
                              </button>
                              <button
                                className={
                                  history.activity_status && (!history.build_by_pipeline)
                                    ? getClassbyActivityStatus(
                                      history.activity_status.status, 
                                      "Revoke"
                                    )
                                    : "btn-sq-icon-primary-disabled d-flex align-center"
                                }
                                onClick={
                                  history.activity_status && (!history.build_by_pipeline)
                                    ? () =>
                                      callFunctionbyStatus(
                                        history.activity_status.status,
                                        history.id
                                      )
                                    : null
                                }
                                title={history.build_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Build"}
                              >
                                {/* <span class="flaticon-stop-button"></span> */}
                                <span className="ri-stop-circle-line font-20"></span>
                              </button>
                              
                                <Tooltip title="workspace">
                                  <Link
                                    to={{

                                      pathname:
                                        "/application/" +
                                        params.application_id +
                                        "/service/" +
                                        service_id +
                                        "/build/" +
                                        history.id +
                                        "/workspace/?service_name=" +
                                        service_name +
                                        "&env_name=" +
                                        service_env +
                                        "&global_task_id=" +
                                        history.global_task_id +
                                        "&env_id=" +
                                        env_id +
                                        "&action_number=" +
                                        history.build_number +
                                        "&action_status=" +
                                        history?.activity_status?.status +
                                        "&action_by=" +
                                        history.build_by_user +
                                        "&image=" +
                                        history.image_name,
                                    }}

                                    target="_blank"
                                  >
                                    <button className="btn-sq-icon-primary d-flex align-center">
                                      {/* <span
                                        class="material-icons"
                                        style={{
                                          color: "#B9B8B8",
                                          display: "flex",
                                        }}
                                      >
                                        <StorageIcon
                                          style={{
                                            color: "rgb(0, 134, 255)",
                                            fontSize: "24px",
                                          }}
                                        />
                                      </span> */}
                                      <span className="ri-artboard-2-line font-20"></span>
                                    </button>
                                  </Link>
                                </Tooltip>
                              
                            </div>
                          </div>
                        </div>
                        {(state.selected_activity_id == history.activity_status.id) && state.activity_details_loading ? 
                            <div className="d-flex justify-center align-center" style={{height: "147px"}}>
                              <Loading varient="light" /> 
                            </div>
                            
                            :
                            history.activity_details ? (
                              <div className="" style={{ width: "100%" }}>
                                <ActivityStepDetails
                                  activityDetail={history.activity_details}
                                  tableGird={false}
                                  key={key}
                                  logUrl={
                                    "/logs?global_task_id=" +
                                    history.global_task_id +
                                    "&tab_id=" +
                                    history.id +
                                    "&service_name=" +
                                    service_name +
                                    "&service_env=" +
                                    service_env +
                                    "&service_type=" +
                                    service_env_type +
                                    "&tab_name=BUILD" +
                                    "&num=" +
                                    history.deploy_number
                                  }
                                  prev_state={state.activity_step_details}
                                  removeArrData={() => {
                                    emptyArr(history.id);
                                  }}
                                />
                              </div>
                            ) : null
                        
                        }
                        
                      </>
                    ))}
                  </>
                ) : (
                  <div className="blank-div">
                    <div className="blank-div-text">No Data Found</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="" style={{marginTop: "12px"}}>
              <Pagination
                current_page_count={
                  state.history_list ? `${state.curr_page}` : 0
                }
                total_count={state.total_page}
                next={state.next}
                previous={state.previous}
                on_previous_click={() => {
                  fetchPrevHistory(null, 0, state.previous);
                }}
                on_next_click={() => {
                  fetchNextHistory(null, 0, state.next);
                }}
                on_pageNumber_click={(pageNumber) => {
                  fetchHistoryInfo(pageNumber);
                }}
              />
          </div>
        </>
      )}
     
    </>
  );
};

BuildHistory.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default BuildHistory;

const useStyles = makeStyles((theme) => ({
  containerBox: {
    //padding: "16px 0px",
    paddingTop: "0px",
    justifyContent: "space-between",
    "& button.btn": {
      "&:hover": {
        color: "#0096db!important",
      },
    },
    "& .search-bar": {
      border: "1px solid #ebedee",
      overflow: "hidden",
      borderRadius: "8px",
      "& .input-component input:focus": {
        border: "none!important",
      },
    },
  },
  searchPosition: {
    position: "absolute",
    top: "16.8px",
    right: "8%",
    "@media screen and (min-width: 1440px) and (max-width: 1920px)":{
      right: "6%",
    }
  },
  historyCard: {
    //height: 550,
    "& .header-tab-build": {
      backgroundColor: "#FAFAFA",
      borderBottom: "1px solid #ebedee",
      gap: 8,
      fontSize: 12,
      display: "grid",
      padding: 12,
      borderTop: "1px solid #ebedee",
      gridTemplateColumns:  "10% 10% 16% 16% 16% 28%",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      color: "#787878",
    },
    "& .body-tab-build": {
      borderBottom: "1px solid #ebedee",
      gap: 8,
      fontSize: 12,
      display: "grid",
      padding: 8,
      gridTemplateColumns:  "10% 10% 16% 16% 16% 28%",//"7% 7% 7% 8% 8% 10% 10% 10% 12% 13%",
      "& p": {
        //padding: "0px 3px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
    "& .btn-disabeld-flaticon": {
      "& .MuiSvgIcon-root": {
        color: "#b9b8b8 !important",
      },
    },
    "& .btn-mui-svg": {
      padding: "0px 0.5rem!important",
    },
    "& .history-body": {
      //overflowY: "scroll",
      //height: 515,
      '& > div': {
        backgroundColor: '#FFFFFF',
      },
      
    },
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
const MenuListComposition = (props) => {
  const classes = useStyles1();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const service_id = props.service_id;
  const env_id = props.env_id;
  const task_id = props.task_id;
  const status = props.status;
  const [state, setState] = useState();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {status == "RUNNING" || status == "IN_QUEUE" ? (
                      <MenuItem onClick={props.onRevoke}>Revoke</MenuItem>
                    ) : null}
                    <MenuItem onClick={props.onRebuild}>Rebuild</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

MenuListComposition.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

