import { Grid, makeStyles, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import { UI_InfraDetails } from '../Detail';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { ErrorComponent } from '../../../utils/Error';
import CancelIcon from '@material-ui/icons/Cancel';
//import Delete from '../../../../components/genericComponents/Delete';
//import { ClusterName } from '../../../../util/util';
function ClusterList(props) {
    const classes = useStyles();
    const [state, setState] = useState({ loaded: false });
    useEffect(() => {
        fetchClusters();

    }, []);


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            clusters: data,
            loaded: true,
        }));

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
        }))
    }

    function fetchClusters() {

        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_all_clusters),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Grid container spacing={4}>
                        <Grid item lg={12} sm={12} xl={12} xs={12} className="d-flex" style={{ justifyContent: 'space-between' }}>
                            <div>
                                <h1 className={classes.MainHeading}>Kubernetes Clusters</h1>
                                <p className={classes.SubHeading}>Displaying overall summary of Infrastructure associated with your account</p>
                            </div>
                            {
                                state.loaded && state.clusters && state.clusters.length > 0 ?
                                    <BtnSection>
                                        <Link className="btn btn-submit" to="/cluster/new/onboard">Add Cluster</Link>
                                    </BtnSection>
                                    : null
                            }
                        </Grid>

                        <Grid item lg={12} sm={12} xl={12} xs={12}>

                            {
                                state.loaded ?
                                    <>
                                        {
                                            state.clusters ?
                                                <>
                                                    {
                                                        state.clusters.length > 0 ?
                                                            state.clusters.map(cluster => (
                                                                <UI_ClusterCard refresh={fetchClusters} cluster={cluster} />
                                                            ))
                                                            :
                                                            <>
                                                                <div className="main-container-error" style={{ height: '60vh' }}>
                                                                    <div className="svg">
                                                                        <div className="servicePanel">
                                                                            <div className="blank-div">
                                                                                <div className="blank-div-text">
                                                                                    No clusters Available
                                                                    </div>
                                                                                <BtnSection>
                                                                                    <Link className="btn btn-submit" to="/cluster/new">Add Cluster</Link>
                                                                                </BtnSection>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <ErrorComponent error={state.error} />
                                        }
                                    </>
                                    :
                                    <Loading />
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default ClusterList;


function UI_ClusterCard(props) {
    const classes = useStyles();
    const cluster = props.cluster;
    const [state, setState] = useState({
        open: false,
    });

    function toggleOpen() {
        setState(new_state => ({
            ...new_state,
            open: !new_state.open
        }))
    }

    return (
        <>
            <div className={classes.card}>
                <div className={classes.cardHeader}>
                    <UI_ClusterCardHeader refresh={props.refresh} data={cluster} />
                    <div style={{ alignSelf: 'center' }}>
                        <IconButton onClick={toggleOpen}>
                            {
                                state.open ?
                                    <ExpandLessIcon style={{ fontSize: '18px' }} /> :
                                    <ExpandMoreIcon style={{ fontSize: '18px' }} />
                            }
                        </IconButton>
                    </div>
                </div>
                <div className={classes.cardBody}>
                    {
                        state.open ?
                            <>
                                {/* <UI_ClusterCardStatus cluster_id={cluster.id} /> */}
                                <UI_ClusterCardDetail refresh={props.refresh} cluster_id={cluster.id} />
                            </>
                            :
                            null
                    }
                </div>
            </div>
        </>
    );
}
UI_ClusterCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_ClusterCardStatus(props) {
    const cluster_id = props.cluster_id;
    const [state, setState] = useState({
        loaded: false
    })

    useEffect(() => {
        fetchControlManagerHealth();
    }, []);

    function fetchControlManagerHealth() {
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_cluster_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            health: data.items,
            loaded: true

        }));
    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
        }));
    }

    return (
        <>
            {
                !state.loaded ?
                    <div className="loading-controller" style={{ backgroundColor: '#fcfcfc', borderTop: '1px solid #d5d5d5', }}>
                        <Loading varient="light" />
                    </div>

                    :
                    <>
                        <div style={{ backgroundColor: '#fcfcfc', borderTop: '1px solid #d5d5d5', }}>

                            <div className="d-flex " >
                                {state.health ?
                                    state.health.map(h => (

                                        <div className="inner-data d-flex align-center nowrap mr-10 border-right-d5" style={{ padding: '10px', }}>
                                            <span className="text-color-black">{(h.metadata.name).charAt(0).toUpperCase() + (h.metadata.name).slice(1)}:&nbsp;</span>

                                            {
                                                h.conditions.map(c => (
                                                    c.type == "Healthy" ?
                                                        <span className="text-color-grey d-flex align-center">
                                                            {
                                                                c.status == "True" ? <span className="text-color-grey d-flex align-center "><CheckCircleIcon style={{ color: 'rgb(105, 224, 156)', fontSize: '18px' }} />&nbsp;<span className="text-gray" style={{ color: '#9e9e9e' }}>{c.status == "True" ? "Healthy" : "Unhealthy"}</span></span> :
                                                                    <span className="text-color-grey d-flex align-center "><CancelIcon style={{ fontSize: '18px', color: c.status == "True" ? '#69e09c' : '#ff8969' }} />&nbsp;<span className="text-gray" style={{ color: '#9e9e9e' }}>{c.status == "True" ? "Healthy" : "Unhealthy"}</span></span>
                                                            }
                                                        </span>
                                                        : null
                                                ))
                                            }
                                        </div>

                                    )) : <div className="inner-data d-flex align-center nowrap mr-10 border-right-d5" style={{ padding: '10px', }}><ErrorComponent error={"Cluster Unstable"} /></div>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    );
}
UI_ClusterCardStatus.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_ClusterCardDetail(props) {
    const cluster_id = props.cluster_id;

    const [state, setState] = useState({
        loaded: false,
    });

    useEffect(() => {
        fetchClusterSummary();
    }, [cluster_id]);

    function fetchClusterSummary() {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_summary));
        InvokeApi(request_info, onFetchSuccess, () => { });
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function onFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            cluster_summary: data,
            loaded: true,
        }));
    }


    return (
        <>
            {
                state.loaded ?
                    <UI_InfraDetails refresh={props.refresh} cluster_id={cluster_id} data={state.cluster_summary} />
                    :
                    <InnerBox>
                        <Loading varient="light" />
                    </InnerBox>

            }
        </>
    );
}
UI_ClusterCardDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


function UI_ClusterCardHeader(props) {
    const data = props.data;
    return (
        <>
            <HeaderDiv>
                <SquareAvatar
                    varient="double"
                    name={data.name} />
                <div className="cluster-head-data">
                    <div className="general-data">
                        <Link to={"cluster/" + data.id + "/detail"} className="cluster-name">{data.name}</Link>
                        {
                            data.status == "READY" ?
                                <span className="text-color-grey d-flex align-center ">
                                    <CheckCircleIcon style={{ color: 'rgb(105, 224, 156)', fontSize: '18px' }} />&nbsp;
                                    <span className="text-gray" style={{ color: '#9e9e9e' }}>{data.status}</span>
                                </span>
                                :
                                <span className="text-color-grey d-flex align-center ">
                                    <CancelIcon style={{ color: '#ff8969', fontSize: '18px' }} />&nbsp;
                                    <span className="text-gray" style={{ color: '#9e9e9e' }}>{data.status}</span>
                                </span>
                        }
                    </div>
                    <div className="general-data">
                        <div className="inner-data">
                            <span className="text-color-black">
                                Kubernetes Version:&nbsp;
                                </span>
                            <span className="text-color-grey">{data.kubernetes_version}</span>
                            <span className="text-color-black ml-5">
                                Infra Provider:&nbsp;
                                </span>
                            <span className="text-color-grey">{data.infra_provider.name}</span>
                        </div>
                        {/* {props.refresh && <Delete
                            display_data_name={ClusterName(data.id)}
                            data={{ entity_id: data.id, name: "cluster" }}
                            refresh={props.refresh }
                            api_link={GenerateURL({ cluster_id: data.id }, properties.api.get_single_cluster)}
                        />} */}
                    </div>
                </div>
            </HeaderDiv>
        </>
    );
}
UI_ClusterCardHeader.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }



const InnerBox = styled('div')({
    margin: '0px 0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px 15px',
    height: '162px',
    backgroundColor: '#fcfcfc',
    borderTop: '1px solid #dedede',
    borderBottom: '1px solid #dedede',
    gridTemplateColumns: '33% 32% 32%',
    '& .inner-data': {
        fontSize: '12px'
    },
    '& .pd-top-20': {
        padding: '20px 0px'
    },
    '& .nodes-data': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        padding: '5px 0px',
        lineHeight: '14px',
        '& .width-fixed': {
            width: '92px',
            display: 'inline-block'
        },
        '& .text-color-grey': {
            color: '#989898',
            marginLeft: '3px'
        },
        '& .text-color-blue': {
            color: '#0086ff',
            marginLeft: '3px'
        }
    },
    '& .text-black': {
        fontSize: '12px'
    },
    '& .k8s-version': {
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0px',
        '& .flaticon-circle-with-check-symbol': {
            '&:before': {
                fontSize: '14px!important'
            },
            '&:after': {
                fontSize: '14px'
            }
        },
        '& img': {
            width: '36px',
            height: '36px',
            display: 'block',
        }
    }
})
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        // paddingTop: '32px',
    },
    MainHeading: {
        fontSize: '24px',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        padding: '15px 20px',
        borderTop: '1px solid#dedede',
    },
    card: {
        borderRadius: '8px',
        background: '#fff',
        overflow: 'hidden',
        marginBottom: '30px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        '& .checkbox-box': {
            border: '1px solid #dedede',
            borderRadius: '4px',
            padding: '15px'
        },
        '& .MuiTypography-root': {
            color: '#828282',
            fontSize: '12px',
            fontWeight: 400
        },
        '& .margin-divider': {
            margin: '10px -15px'
        },
        '& .grid-with-3': {
            display: 'grid',
            gridTemplateColumns: 'auto auto auto'
        }
    },
    cardHeader: {
        backgroundColor: ' #fff',
        borderRadius: ' 8px 8px 0px 0px',
        display: 'flex',
        justifyContent: ' space-between',
        color: '#666',
        fontSize: '12px',
        padding: '0px 10px',

        '& .text-color-grey': {
            color: '#989898',
            fontSize: '12px'
        },
        '& .text-color-black': {
            fontSize: '12px',
            color: '#000'
        }
    },
    cardBody: {
        backgroundColor: '#fff',

        '& .divider': {
            marginTop: '15px'
        },
        '& .input-component': {
            marginBottom: '0px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',
        },
        '& .general-data': {
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'space-between',
            fontSize: '12px',
            lineHeight: '18px',
            '& .inner-data': {
                marginRight: '6px'
            },
            '& .text-color-grey': {
                color: '#989898'
            }
        },
        '& .text-color-grey': {
            color: '#989898',
            fontSize: '12px'
        },
        '& .text-color-black': {
            fontSize: '12px'
        }
    },
    cardFooter: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '15px',
        display: 'grid',
        justifyContent: 'end',
        gridTemplateColumns: 'auto auto',
        borderTop: '1px solid rgb(204, 204, 204)',
        borderRadius: '0px 0px 8px 8px',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));


const BtnSection = styled('div')({
    display: "flex",
    alignItems: 'center',
    '& .anchor-blank': {
        border: 'none!important',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})

const HeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    '& .cluster-head-data': {
        marginLeft: '10px'
    },
    '& .cluster-name': {
        color: '#0086ff',
        fontSize: '18px',
        fontFamily: 'nunito',
        fontWeight: 600,
        lineHeight: '22px'
    },
    '& .general-data': {
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'space-between',
        fontSize: '12px',
        lineHeight: '18px',
        '& .inner-data': {
            marginRight: '6px'
        },
        '& .text-color-grey': {
            color: '#989898'
        }
    }

})
