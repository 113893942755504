import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';
import GenerateURL, { GenerateSearchURL, GET_Request_Info } from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import { Loading } from '../../utils/Loading';
import { Link } from 'react-router-dom';

import LoopIcon from '@material-ui/icons/Loop';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAnnotations from './components/EditAnnotations';
import SearchBar, { MULTIPLE } from '../../../components/SearchBar';
//import SearchBar from '../../../components/SearchBar';


import { Cancel } from '@material-ui/icons';
import ExandableKeyValues from '../../../components/hoc/ExpanadleKeyValues';

function NodesListing(props) {
    const { cluster_id } = useParams();
    const classes = useStyles();
    const [state, setState] = useState({
        loaded: false,
    });
    const search_filter_list = [
        {
            name: "node_type",
            label: "Node Type"
        }
    ]

    useEffect(() => {
        fetchNodesDetails();
    }, [cluster_id]);

    function fetchNodesDetails(load_more, params) {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_nodes));
        if (load_more) {
            request_info.endPoint = GenerateSearchURL({ pagination_token: state.nodes.metadata.continue }, request_info.endPoint);
        }
        if (params) {
            request_info.endPoint = GenerateSearchURL(params, request_info.endPoint);
        }
        InvokeApi(request_info, onFetchSuccess, onFetchFail);

        setState(new_state => ({
            ...new_state,
            loaded: load_more,
            load_more: load_more,
            nodes: !load_more ? null : new_state.nodes,
            search_filter: params,
        }));

    }

    // function onFetchSuccess(data) {
    //     // const filtered_data = filterNodeData(data);
    //     setState(new_state => ({
    //         ...new_state,
    //         loaded: true,
    //         nodes: data,
    //         // filtered_data: filtered_data
    //     }));
    // }

    function onFetchSuccess(data) {
        function getFomatedData(prev_data) {
            return {
                ...data,
                items: [...prev_data.items, ...data.items]
            }
        }
        setState(new_state => ({
            ...new_state,
            nodes: new_state.nodes ? getFomatedData(new_state.nodes) : data,
            loaded: true,
            error: null,
            load_more: false
        }));
    }



    function onFetchFail(error) {
        console.log("onFetchFail", error);
    }

    function searchCallback(filter) {
        fetchNodesDetails(false, filter);
    }


    return (

        <div className={classes.root}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <UI_Heading onClick={() => { fetchNodesDetails() }} />
            </div>
            <Grid conatiner>
                <Grid item lg={12}>
                    <Grid conatiner>
                        <Grid item lg={12} style={{ marginBottom: '32px' }}>
                            <SearchBar
                                varient={MULTIPLE}
                                default_filter={{ name: "type", label: "Node Type" }}
                                search_call_back={searchCallback}
                                clear_search_callback={() => { }}
                                params_list={search_filter_list}
                            />
                        </Grid>
                        {
                            !state.loaded ?
                                <Loading />
                                :

                                <Grid item lg={12}>
                                    <div className={classes.card}>

                                        <table className="table table-responsive table-striped">
                                            <UI_NodeListHeader />
                                            <>
                                                {
                                                    state.nodes ?
                                                        <>
                                                            <tbody className="tbody-with-scroll">
                                                                {
                                                                    state.nodes.items.map(node => (


                                                                        <UI_NodeListRow cluster_id={cluster_id} node={node} />

                                                                    ))
                                                                }
                                                            </tbody>
                                                            <div className={classes.cardFooter} style={state.nodes.metadata.continue ? { display: 'block', textAlign: 'center' } : { display: 'none', textAlign: 'center' }}>
                                                                {state.load_more ? <Loading varient="light" /> :
                                                                    state.nodes.metadata.continue ?
                                                                        <IconButton className="btn-grey-navi" onClick={() => { fetchNodesDetails(true) }}><LoopIcon style={{ fontSize: '2.5rem' }} />Load More</IconButton> : null
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        "No Nodes Availaible"
                                                }
                                            </>
                                        </table>

                                    </div>
                                </Grid>


                        }

                    </Grid>


                </Grid>
            </Grid>
        </div>
    );
}

function UI_NodeListRow(props) {
    const node = props.node;
    const cluster_id = props.cluster_id;
    const is_ready = isReady(node.status.conditions);
    const is_master = Object.keys(node.metadata.labels).includes("node-role.kubernetes.io/master");



    const iteration_count = 2;


    let labels_list = [];

    let labels_list2 = [];


    function get_list(labels, labels2, list_type) {
        labels_list = [];
        labels_list2 = [];
        if (labels) {
            Object.keys(labels).forEach(label => {
                labels_list.push(
                    {
                        key: label,
                        value: labels[label]
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                            {label + "=" + labels[label]}
                        </div>
                    </Tooltip>
                );
            });


        }

        if (labels2) {
            labels2.forEach(label => {
                labels_list.push(
                    {
                        key: label.env_key,
                        value: label.env_value
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
                        <div className="pod-lable">
                            {label.env_key + "=" + label.env_value}
                        </div>
                    </Tooltip>
                );
            });



        }
        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }




    return (

        <>
            <tr>
                <td>
                    <Tooltip placement="bottom" title={node.metadata.name}>
                        <Link to={"/cluster/" + cluster_id + "/node/" + node.metadata.name + "/detail"} >{node.metadata.name}</Link>
                    </Tooltip>
                </td>

                <td >
                    <span style={{ display: 'flex', alignItems: 'center' }}>{
                        is_ready ? <><CheckCircleIcon style={{ fontSize: '18px', color: !is_ready ? '#ffce25' : '#69e09c' }} />&nbsp;<span>{!is_ready ? "Pending" : "Ready"}</span>
                        </> : <><Cancel style={{ fontSize: '18px', color: !is_ready ? '#ffce25' : '#69e09c' }} />&nbsp;<span>{!is_ready ? "Pending" : "Ready"}</span>
                            </>
                    }
                    </span>
                </td>
                {/* <td>
                    {
                        node.status.conditions.map(condition => (
                            <div>
                                <span>
                                    {condition.type}
                                </span>
                            </div>
                        ))
                    }

                </td> */}
                <td>
                    {/* {
                        node.status.conditions.map(condition => (
                            <> <span className="status-point"><span className={condition.type == "Ready" ? condition.status == "True" ? "bullet green p-relative" : "bullet red p-relative" : condition.status == "True" ? "bullet red p-relative" : "bullet green p-relative"}></span><span className="msg">{condition.status == "True" ? "True" : "False"}</span></span></>
                        ))
                    } */}
                    <div className="d-grid" style={{ gridTemplateColumns: '45% 45%', gap: '5%', width: '130px' }}>
                        {
                            node.status.conditions.map(condition => (
                                condition.type == "Ready" ?
                                    condition.status == "True" ?
                                        <div className="d-flex align-center"><CheckCircleIcon style={{ fontSize: '15px', color: '#69e09c' }} />&nbsp;<span>{condition.type}</span></div> :
                                        <div className="d-flex align-center"><Cancel style={{ fontSize: '15px', color: '#ffce25' }} />&nbsp;<span>{condition.type}</span></div> :
                                    condition.status == "True" ?
                                        <div className="d-flex align-center"><Cancel style={{ fontSize: '15px', color: '#ffce25' }} />&nbsp;<span>{condition.type == "PIDPressure" ? "PID" : (condition.type).split(/(?=[A-Z])/)[0]}</span></div> :
                                        <div className="d-flex align-center"><CheckCircleIcon style={{ fontSize: '15px', color: '#69e09c' }} />&nbsp;<span>{condition.type == "PIDPressure" ? "PID" : (condition.type).split(/(?=[A-Z])/)[0]}</span></div>

                            ))
                        }
                    </div>

                </td>

                <td >
                    <div className={is_master ? "master pod-lable" : "worker pod-lable"} style={{ margin: 'auto' }}>
                        {is_master ? "Master" : "Worker"}
                    </div>
                </td>


                <td>

                    {/* <ExandableComponentList
                        compoenent_list={annotation_list}
                        iteration_count={iteration_count}
                        expandable_component={
                            <>
                                +{annotation_list.length - iteration_count}
                            </>
                        }
                    /> */}

                    <ExandableKeyValues
                        compoenent_list={get_list(node.metadata.annotations, node.annotations, 2)}
                        iteration_count={iteration_count}
                        isLabel={false}
                        labels_list={get_list(node.metadata.annotations, node.annotations, 1)}
                        expandable_component={
                            <>
                                +{labels_list.length - iteration_count}
                            </>
                        } />
                </td>

                <td >


                    <ExandableKeyValues
                        compoenent_list={get_list(node.metadata.labels, node.labels, 2)}
                        iteration_count={iteration_count}
                        isLabel={true}
                        labels_list={get_list(node.metadata.labels, node.labels, 1)}
                        expandable_component={
                            <>
                                +{labels_list.length - iteration_count}
                            </>
                        } />
                </td>
                {/* 
                <td >
                    <LongMenu />
                </td> */}
            </tr>

        </>

    );
}
UI_NodeListRow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


function UI_NodeListHeader(props) {
    return (
        <thead>
            <th>Node Name</th>
            <th>Status</th>
            <th>Condition Type</th>
            <th>Node Type</th>
            <th>Annotations</th>
            <th>Labels</th>

        </thead>
    );
}

function UI_Heading(props) {
    const count = props.count;
    const onClick = props.onClick;
    const classes = useStyles();
    return (
        <>
            <div className="main-div d-flex align-center justify-center">
                <p className="main-heading">Nodes Available</p>

            </div>
            <button onClick={props.onClick} className="btn-round border-navy bg-clear-btn" style={{ justifySelf: "flex-end" }}><span className="flaticon-refresh-button-1"></span></button>
        </>
    );
}
UI_Heading.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default NodesListing;

function isReady(conditions) {
    var ready = false;
    conditions.forEach(condition => {
        if (condition.type == "Ready") {
            ready = condition.status == "True";
        }
    });
    return ready;
}

const ITEM_HEIGHT = 48;
function LongMenu(props) {
    // const annotations = props.annotations;
    // const labels = props.labels;
    // const annotation_kv_list = [];
    // const labels_kv_list = [];
    const url = props.url;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // Object.keys(annotations).forEach(anno => {
    //     annotation_kv_list.push({
    //         key: anno,
    //         value: annotations[anno],
    //     });
    // });

    // Object.keys(labels).forEach(label => {
    //     labels_kv_list.push({
    //         key: label,
    //         value: labels[label],
    //     });
    // });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div className="m-auto text-center">
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                    <Link className="hover-content-link" to={url}><VisibilityIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;View Details</Link>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <EditIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp; <EditAnnotations />
                    </span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <EditIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp; Edit Labels
               </span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <Link to="/namespaces">
                        <span className="hover-content-link" ><DeleteIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;Delete</span>
                    </Link>
                </MenuItem>

            </Menu>
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px',
    },
    card: {
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#fff',
        overflowY: 'auto',
        overflowX: 'auto',
        position: 'relative',
        minHeight: '500px',
        '& .table-grid': {
            height: '452px'
        },
        '& .grid-temp-eight': {
            gridTemplateColumns: '15% 15% 10% 15% 20% 20% 5%'
        },
        '& .chip-default': {
            width: 'fit-content'
        },
        '& .pod-lable': {
            maxWidth: '200px'
        }

    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fff",
        fontSize: '13px',
        padding: '10px 15px',
        '& .input-component': {
            minWidth: '120px',
            marginBottom: '0px'
        }
    },
    cardBody: {
        padding: '20px 15px',
        backgroundColor: '#f6f8f8',
        '& .input-component': {
            marginBottom: '15px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',

        }
    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        borderTop: '1px solid #d5d5d5',
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '100%',
        bottom: '0px',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));
