import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';
import properties from '../../../properties/properties';
import InvokeApi from '../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL, GET_Request_Info } from '../../../util/APIUrlProvider';
import { Loading } from '../../utils/Loading';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoopIcon from '@material-ui/icons/Loop';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import EditAnnotations from './components/EditAnnotations';
import Taints from './components/Taints';

import SearchBar, { MULTIPLE } from '../../../components/SearchBar';
import { yellow } from '@material-ui/core/colors';

import { ExandableKeyValues } from '../../../components/hoc/ExpanadleKeyValues';
import { ErrorComponent } from '../../utils/Error';
//import KeyValues from '../../../../components/genericComponents/inputs/KeyValues';

import moment from 'moment';
const CLUSTER = "CLUSTER";
const NODE = "NODE";

function PodsListing(props) {
    const classes = useStyles();
    const { cluster_id } = useParams();
    const [state, setState] = useState({
        filter: CLUSTER,
        value: {
            cluster_id: Number(cluster_id),
            node: "",
        },
        loaded: false,
    });
    const search_filter_list = [
        {
            name:'name',
            label:"Name"
        },
        {
            name: "status",
            label: "Status"
        },
        {
            name: "node_name",
            label: "Node Name"
        },
        {
            name: "labels",
            label: "Label"
        }
    ]

    useEffect(() => {
        fetchNodes();
    }, [cluster_id]);

    function fetchNodes(load_more, params) {
        const request_info = GET_Request_Info(GenerateURL({ cluster_id: cluster_id }, properties.api.cluster_pods));
        if (load_more) {
            request_info.endPoint = GenerateSearchURL({ pagination_token: state.pods.metadata.continue }, request_info.endPoint);
        }
        if (params) {
            request_info.endPoint = GenerateSearchURL(params, request_info.endPoint);
        }
        InvokeApi(request_info, onFetchSuccess, onFetchFail);
        setState(new_state => ({
            ...new_state,
            loaded: load_more,
            load_more: load_more,
            pods: !load_more ? null : new_state.pods,
            search_filter: params,
        }));
    }

    function onSearchCallBack(filters) {
        fetchNodes(false, filters);
    }

    function onFetchSuccess(data) {
        function getFomatedData(prev_data) {
            return {
                ...data,
                items: [...prev_data.items, ...data.items]
            }
        }
        setState(new_state => ({
            ...new_state,
            pods: new_state.pods ? getFomatedData(new_state.pods) : data,
            loaded: true,
            error: null,
            load_more: false
        }));
    }

    function onFetchFail(error) {
        console.log("onFetchFail", error);
        setState(new_state => ({
            ...new_state,
            error: error,
            loaded: true,
            load_more: false
        }));
    }

    return (
        <>

            <>
                <div className={classes.root}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <UI_Heading onClick={() => { fetchNodes() }} />

                    </div>
                    <Grid conatiner>
                        <Grid conatiner>
                            <Grid item lg={12} style={{ marginBottom: '32px' }}>
                                <SearchBar
                                    varient={MULTIPLE}
                                    default_filter={{name: "namespace",
                                    label: "Namespace Name"}}
                                    search_call_back={onSearchCallBack}
                                    clear_search_callback={fetchNodes}
                                    params_list={search_filter_list}
                                    search_data={state.search_filter ? state.search_filter : null}
                                />
                            </Grid>

                        </Grid>

                        {
                            !state.loaded ?
                                <Loading />
                                :

                                <>
                                    {
                                        state.error ?
                                            <ErrorComponent error={state.error} />
                                            :
                                            <Grid item lg={12}>
                                                <div className={classes.card}>

                                                    <table className="table table-responsive">

                                                  
                                                        <UI_PodHeading />
                                                        
                                                        <tbody>

                                                            {
                                                                state.pods.items.map(item => (
                                                                    <UI_PodDetail pod={item} cluster_id={cluster_id} />
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                    <div className={classes.cardFooter} style={state.pods.metadata.continue ? { display: 'block', textAlign: 'center' } : { display: 'none' }}>
                                                        <>
                                                            {
                                                                state.load_more ?
                                                                    <Loading varient="light" />
                                                                    :
                                                                    <>
                                                                        {
                                                                            state.pods.metadata.continue ?
                                                                                <IconButton className="btn-grey-navi hover-state" onClick={() => { fetchNodes(true) }}><LoopIcon style={{ fontSize: '2.5rem' }} />Load More</IconButton> : null
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                    </div>
                                                </div>
                                            </Grid>
                                    }
                                </>

                        }
                    </Grid>
                </div>
            </>
        </>
    );
}

function UI_Heading(props) {
    const count = props.count;
    const onClick = props.onClick;
    return (
        <>
            <div className="main-div d-flex align-center justify-center">
                <p className="main-heading">Pods Available</p>
                {/* <span className="count-chip count-chip-danger count-chip-xl"> {count} </span> */}

            </div>
            <button onClick={props.onClick} className="btn-round border-navy bg-clear-btn" style={{ justifySelf: "flex-end" }}><span className="flaticon-refresh-button-1"></span></button>
        </>
    );

}
UI_Heading.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


function UI_PodHeading(props) {

    return (
        <>

            <thead>
                <tr>
                    <th>Pod Name</th>
                    <th>Execution Duration</th>
                    <th>Status</th>

                    <th>Namespace</th>
                    <th>Node Name</th>

                    <th>Labels</th>
                    {/* <th></th> */}
                </tr>
            </thead>
        </>
    );

}
UI_PodHeading.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

function UI_PodDetail(props) {
    const pod = props.pod;
    const cluster_id = props.cluster_id;
    const { is_ready, readiness } = isReady(pod.status.conditions);
    const [ready, setReady] = useState(is_ready);
    const [readiness1, setReadiness] = useState(readiness);
    function isReady(conditions) {

        var is_ready = false;
        var readiness = false;
        if(conditions)
        conditions.forEach(condition => {
            if (condition.type == "Ready") {
                is_ready = condition.status == "True";

            }
            if (condition.type == "ContainerREADY") {
                readiness = condition.status == "True";

            }
        });
        return is_ready, readiness;
    }

    const annotation_list = [];
    const labels_list = [];
    const iteration_count = 2;
    const labels_list2 = [];


    if(pod?.metadata?.labels){
        Object.keys(pod.metadata.labels).forEach(label => {
            labels_list.push(
                // <Tooltip placement="bottom" title={label + "=" + pod.metadata.labels[label]}>
                //     <div className="pod-lable">
                //         {label + "=" + pod.metadata.labels[label]}
                //     </div>
                // </Tooltip>
                {
                    key: label,
                    value: pod.metadata.labels[label]
                }
            );
        });
        Object.keys(pod.metadata.labels).forEach(label => {
            labels_list2.push(
                <Tooltip placement="bottom" title={label + "=" + pod.metadata.labels[label]}>
                    <div className="pod-lable">
                        {label + "=" + pod.metadata.labels[label]}
                    </div>
                </Tooltip>
                // {
                //     key:label,
                //     value:pod.metadata.labels[label]
                // }
            );
        });
    }
    

    return (
        <tr>

            <td>
                <Link to={"/cluster/" + props.cluster_id + "/namespace/" + pod.metadata.namespace + "/pod/" + pod.metadata.name}><Tooltip placement="bottom" title={pod.metadata.name}>
                    <span>{pod.metadata.name}</span>
                </Tooltip></Link>
            </td>

            <td>
                {
                    pod.status.phase =="Running" ? <span className="d-flex align-center">
                    <span>
                        {moment(pod.metadata.creationTimestamp).fromNow()}
                       </span>
                    </span>
                    :"NA"
                }
            </td>

            <td>
                {/* {
                    pod.status.conditions.map(condition => (
                        condition.type == "Ready" ? condition.status == "True" ?
                            <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#69e09c' }} /><span>Ready</span>
                            </span>
                            : <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#ffce25' }} /><span>Not Ready</span>
                            </span> : null
                    ))
                } */}
                {
                    pod.status.phase == "Running" ? 
                        <span className="d-flex align-center">
                            <CheckCircleIcon fontSize="large" style={{ color: '#69e09c' }} /><span>Running</span>
                        </span>
                        : pod.status.phase == "Terminating" ?
                            <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#ff8969' }} />
                                <yellow fontSize="large" style={{ color: '#ff8969' }} /><span>{pod.status.phase}</span>
                            </span>
                        :
                            <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#ffce25' }} />
                                <yellow fontSize="large" style={{ color: '#ffce25' }} /><span>{pod.status.phase}</span>
                            </span>
                }

            </td>
            {/* <td>
                {
                    pod.status.conditions.map(condition => (
                        condition.type == "ContainersReady" ? condition.status == "True" ?
                            <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#69e09c' }} /><span>True</span>
                            </span>
                            : <span className="d-flex align-center">
                                <CheckCircleIcon fontSize="large" style={{ color: '#ffce25' }} /><span>False</span>
                            </span> : null
                    ))
                }
              
            </td> */}



            <td>
                <Link to={"/cluster/" + cluster_id + "/namespace/" + pod.metadata.namespace + "/detail"}>{pod.metadata.namespace}</Link>
            </td>
            <td>
                <Link to={"/cluster/" + cluster_id + "/node/" + pod.spec.nodeName + "/detail"}>{pod.spec.nodeName ? pod.spec.nodeName : null}</Link>
            </td>


            {/* <td>{pod.spec.tolerations.length}</td> */}


            <td>

                <ExandableKeyValues
                    compoenent_list={labels_list2}
                    iteration_count={iteration_count}
                    labels_list={labels_list}
                    isLabel={true}
                    expandable_component={
                        <>
                            +{labels_list.length - iteration_count}
                        </>
                    } />

                {/* <KeyValues data={labels_list}/> */}
            </td>
            {/* <td>
                <LongMenu />
            </td> */}

        </tr>
    );

}
UI_PodDetail.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default PodsListing;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px',
    },
    card: {
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#fff',
        border: '1px solid #d5d5d5',
        overflow: 'hidden',
        overflowX: 'auto',
        position: 'relative',
        minHeight: '500px',
        '& .chip-default': {
            width: 'fit-content'
        },
        '& .table-grid': {
            height: '432px'
        }
    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fff",
        fontSize: '13px',
        alignItems: 'center',
        padding: '10px 15px',
        '& .input-component': {
            minWidth: '120px',
            marginBottom: '0px'
        },

    },
    cardBody: {
        padding: '20px 15px',
        backgroundColor: '#f6f8f8',
        '& .input-component': {
            marginBottom: '15px'
        },
        '& .with-dropdown': {
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            gap: '10px',
            alignItems: 'center',

        }
    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        justifyContent: 'flex-end',
        width: '100%',
        bottom: '0px',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));

const ITEM_HEIGHT = 48;
export function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const application_id = props.application_id;


    return (
        <div className="m-auto">
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                    <span style={{cursor: "pointer"}} className="hover-content-link"><VisibilityIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;View Details</span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <EditIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;<Taints />
                    </span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <EditIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;<EditAnnotations />
                    </span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <Link to="/namespaces">
                        <span className="hover-content-link" ><DeleteIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;Delete</span>
                    </Link>
                </MenuItem>

            </Menu>
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }