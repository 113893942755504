import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';

import Collapse from '@material-ui/core/Collapse';
import properties from '../../../../properties/properties';
import { useParams } from 'react-router-dom';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { getListableArray } from '../../../../util/util';


const Questionnaire = (props) => {
    const classes = useStyles();
    const prev_state = props.prev_state ? props.prev_state : null;
    const inherits = props.inherits ? props.inherits : {};
    const jira_ref_key_list = props.jira_ref_key_list ? props.jira_ref_key_list : []
    const jira_id_reference_keys_list = props.jira_id_reference_keys_list ? props.jira_id_reference_keys_list : [];
    const updated_jira_ref_key_and_id_list = [
        ...jira_id_reference_keys_list,
        ...jira_ref_key_list,
    ]
    const { application_id } = useParams();

    //     const filtered_arr = updated_jira_ref_key_and_id_list.reduce((acc,item)=>{

    //         if(item.backend_data ){
    //             console.log(item, "itemitemitem")
    //             // && item.label !== item.id
    //             if(!acc.some(obj => obj.label === item.label ) ){
    //                 acc.push(item);
    //               }
    //         }
    //       return acc;
    //   },[])

    const [state, setState] = useState(prev_state ? questionnaireDefaultState(prev_state) : questionnaireDefaultState())
    const [dropdownOptions, setDropdownOptions] = useState([])
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    useEffect(() => {
        if (prev_state) {
            if (prev_state.job_template) {
                var jobTemplate = Number(prev_state.job_template)
                // fetchJiraUserList(jobTemplate, prev_state.user);

            }
            if (prev_state.type === "select" || prev_state.type === "checkbox") {
                const array = prev_state.possible_value.split(',')

                const options = array && array.map(item => {

                    const updatedString = item.trim()
                    return {
                        id: updatedString,

                        label: updatedString

                    }

                })

                setDropdownOptions(options)
            }
            setState(new_state => ({
                ...new_state,
                data: {
                    ...prev_state,
                    ...new_state.data,

                }
            }))
        }

        fetchJobTemplatesList();

    }, [prev_state])


    const dropdownlist = [

        {
            id: "text",
            label: "Text box"
        },
        {
            id: "textarea",
            label: "Text Area"
        },

        {
            id: "toggle",
            label: "Toggle",

        },
        {

            id: 'select',
            label: 'Select'
        },
        {

            id: 'checkbox',
            label: 'Checkbox'
        },
        {

            id: 'people',
            label: 'People'
        }
    ]
    const handleChange = (e) => {

        const value = e.target.value
        setState(prevState => ({

            ...prevState,
            data: { ...prevState.data, does_default_active: value }
        }))
    }

    const defaultValueoption = [{
        id: "Y",
        label: "Yes"
    },

    {
        id: "N",
        label: "No"
    }

    ]

    const toggleForm = () => {
        setState(new_state => ({
            ...new_state,
            show_from_state: !new_state.show_from_state,
        }));
    }
    const resetState = () => {
        setState((prevState) => ({

            ...prevState,
            data: {
                question: "",
                type: "",
                possible_value: null,
                default_value: "",
                jira_update: null,
                field_name: "",
                jira_reference_id: ""
            }
        }))
    }
    const validateAndSave = () => {
        props.validateAndPushDataToTable()
        resetState();
    }
    const get_option_dropdown = (e) => {
        const k = e.target.name;
        const v = e.target.value;
        if (v) {
            const array = v.split(',')

            const options = array && array.map(item => {

                const updatedString = item.trim()
                return {
                    id: updatedString,

                    label: updatedString

                }

            })

            setDropdownOptions(options)

        }
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }));
    }
    function fetchJobTemplatesList() {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id }, properties.api.add_job_template),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchJobTemplatesListSuccess, fetchJobTemplatesListFailure);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function fetchJobTemplatesListSuccess(response) {
        setState(new_state => ({
            ...new_state,
            all_templates: response.results,
            loading_data: false
        }));

    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
            error: error_msg,
            loading_data: false
        }));
    }


    const onChangeHandlerSelect = (e) => {
        const k = e.target.name;
        const v = e.target.value;
        commonFunctions.onChangeHandler(e);
    }



    return (
        <div className='mt-20'>

            
               
                <div className={`card ${classes.cardBody}`}>
                    <div className="card-header">
                        <p className='font-13'>Add question for the approver</p>
                       
                    </div>

                    <div className="">
                        <Grid className='mb-5 pd-10' container alignItems="center" >
                            <Grid item lg={12}>
                                <Input
                                    type="text"
                                    name="question"
                                    mandatorySign
                                    placeholder="Question"
                                    label="Question"
                                    onChangeHandler={commonFunctions.onChangeHandler}
                                    data={state.data}
                                    error={state.error}
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <Input
                                    type="select"
                                    name="type"
                                    mandatorySign
                                    placeholder="Add Question"
                                    label="Response field type"
                                    select_default_value={"Select Input Type"}
                                    list={dropdownlist}
                                    onChangeHandler={onChangeHandlerSelect}
                                    data={state.data}
                                    error={state.error}
                                />
                            </Grid>

                            {
                                state.data.type === "people" &&
                                <>
                                    {
                                        state.all_templates && state.all_templates.length > 0 ?
                                            <Grid item lg={12}>
                                                <Input
                                                    type="select"
                                                    name="job_template"
                                                    list={getListableArray(state.all_templates)}
                                                    label="Select Job Template With Jira Credentails"
                                                    mandatorySign
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error} />
                                            </Grid>

                                            :
                                            null
                                    }
                                </>
                            }
                            {
                                state.data.type !== 'select' && (state.data.type !== 'checkbox' && state.data.type !== 'people') ?
                                    
                                        <Grid item lg={12}>
                                            <Input
                                                type="select"
                                                mandatorySign
                                                list={defaultValueoption}
                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                placeholder=""
                                                name="does_default_active"
                                                label="Provide Default Response? "
                                                data={state.data}
                                                error={state.error} />
                                        </Grid>

                                     :
                                    state.data.type === 'select' || state.data.type === 'checkbox' ?
                                        <>

                                            <Grid item lg={12}>
                                                <Input
                                                    type="text"
                                                    name="possible_value"
                                                    mandatorySign
                                                    placeholder="Write options seprated by commas"
                                                    label="Write options seprated by commas"
                                                    onChangeHandler={get_option_dropdown}
                                                    data={state.data}
                                                    error={state.error}
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Input
                                                    type="select"
                                                    name='default_value'
                                                    list={dropdownOptions}
                                                    label="Provide Default Value"
                                                    placeholder="Select Value"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error}
                                                    style={{ borderLeft: '0' }} />
                                            </Grid>
                                        </>
                                        :
                                        null
                            }
                            <Grid item lg={12} >
                                {

                                    state.data.does_default_active === 'Y' &&

                                    <>
                                        {
                                            state.data.type === 'toggle' ?
                                                <Input
                                                    type="select"
                                                    name='default_value'
                                                    list={[{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]}
                                                    label="Provide Default Value"
                                                    placeholder="Select Value"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error}
                                                    style={{ borderLeft: '0' }} />
                                                :
                                                state.data.type === 'textarea' ?
                                                    <Input
                                                        type="textarea"
                                                        name='default_value'
                                                        placeholder="Enter Value"
                                                        label="Provide Default Value"
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                        style={{ borderLeft: '0' }} />
                                                    :
                                                    state.data.type === 'text' ?
                                                        <Input
                                                            type="text"
                                                            name='default_value'
                                                            placeholder="Enter Value"
                                                            label="Provide Default Value"
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                            error={state.error}
                                                            style={{ borderLeft: '0' }} />
                                                        :
                                                        null
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <div className='inner-inner-card ml-10 mr-10 mb-10'>
                            <div className="full-width-switch">
                                <Input
                                    type="switch"
                                    label="Push the approver response to JIRA?"
                                    name="jira_update"
                                    data={state.data}
                                    error={state.error}
                                    onChangeHandler={commonFunctions.toggleState}
                                />
                            </div>
                            <div className={state.data.jira_update ? classes.formCard : "border-none"}>
                                {
                                    state.data.jira_update &&
                                    <Collapse in={state.data.jira_update} collapsedSize={0}>

                                        <Grid
                                            className='mb-5 pd-10'
                                            container
                                            alignItems="center"
                                            style={state.data.jira_update ? { heigh: "100%", display: 'inherit' } : { heigh: 0, display: 'none' }}>
                                            <Grid item lg={12}>
                                                <Input
                                                    type="select"
                                                    name="jira_reference"
                                                    mandatorySign
                                                    placeholder="Add Question"
                                                    label="Select Jira Refrenece ID"
                                                    list={updated_jira_ref_key_and_id_list}
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error}
                                                />
                                            </Grid>

                                            <Grid item lg={12}>
                                                <Input
                                                    type="text"
                                                    name="field_name"
                                                    mandatorySign
                                                    placeholder="Ticket Name"
                                                    label="Name of field to be updated in JIRA"
                                                    onChangeHandler={commonFunctions.onChangeHandler}
                                                    data={state.data}
                                                    error={state.error}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='pd-10 d-flex align-center justify-flexend' style={{borderTop:'1px solid #dedede', backgroundColor:'#fafafa', }}>
                    <div className='d-flex align-center'>
                      <button className="btn btn-with-icon btn-save"
                        onClick={validateAndSave}>
                        <span className="material-icons material-symbols-outlined">
                          done
                        </span>
                        <span>Save</span>
                      </button>
                      <button
                        className="btn btn-with-icon btn-cancel"
                        onClick={() => { props.toggleForm() }}
                      >
                        <span className="material-icons material-symbols-outlined">
                          close
                        </span>
                        <span>Cancel</span>
                      </button>
                    </div>
                    </div>
                </div>
            
        </div>
    )
}

Questionnaire.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };
  

export default Questionnaire;

const useStyles = makeStyles(() => ({
    searchBar: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 60px',
        border: '1px solid #b7b7b7',
        marginBottom: '15px',
        borderRadius: '4px',
        '& .input-component': {
            marginBottom: '0!important',
            '& input': {
                border: 'none',
                '&:focus': {
                    outline: 'none',
                    border: '0px!important'
                }
            }
        },
        '& .btn': {
            marginRight: 0,
            padding: '.3rem 1rem'
        }
    },
    formCard: {
        borderLeft: '1px solid #f5f5f5',
        borderRight: '1px solid #f5f5f5',
        borderBottom: '1px solid #f5f5f5',
        '& .pd-0': {
            '& .pd-10': {
                padding: '0px!important'
            }
        }

    },

    cardHeader: {
        // border: '1px solid #eaeaea',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    cardBody:{
        border: '1px solid #dedede!important',
        boxShadow: 'rgb(0 0 0 / 6%) 0.877px 1.798px 21px 0px',
        '& .card-header':{
            backgroundColor: '#fafafa'
        },
        // '& .input-css-controller':{
            '& .input-component':{
                '& input':{
                    border: '1px solid #f5f5f5 !important'
                },
                '& .select':{
                    border: '1px solid #f5f5f5 !important'
                }
            },
        // },
        '& .btn-with-icon': {
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            fontSize: '12px!important',
            borderRadius: '4px',
            padding: '5px 9px !important',
            minWidth: '85px !important',
            overflow: 'hidden',
            '& .material-icons': {
              fontSize: '15px',
              marginRight: '5px'
            }
          },
          '& .btn-save': {
            border: '2px solid #007EFF !important',
            backgroundColor: '#fff',
            color: '#007EFF'
          },
          '& .btn-cancel': {
            border: '2px solid #959595 !important',
            backgroundColor: '#fff',
            color: '#959595'
          },
        '& .inner-inner-card':{
            '& .full-width-switch':{
                '& .card-header':{
                    border:'1px solid #f5f5f5 !important',
                }
            }
        }
    }
    
}))


const questionnaireDefaultState = (prev_state) => {
    var arry_for_select_type_field = [];
    // if(prev_state && prev_state.type === "select" && prev_state.type ==="checkbox"){
    //     arry_for_select_type_field.push({id: prev_state.default_value, label: prev_state.default_value})
    // }
    return {
        data: prev_state ?
            {
                ...prev_state,
                jira_update: prev_state.jira_update,
                does_default_active: prev_state.default_value ? "Y" : "N"
                // default_value: prev_state.type ==="select" && prev_state.type ==="checkbox" ? arry_for_select_type_field : prev_state.default_value, 
            } : {
                jira_id_validation: false,
                type: undefined,
                jira_update: false,
                all_templates: [],
                jira_user_list: []
            },
        questionnaire_list: [],
        error: {},
        validations: {
            question: [VALIDATION_TYPE_REQUIRED],
            type: [VALIDATION_TYPE_REQUIRED],
            // possible_value: [VALIDATION_TYPE_REQUIRED]
        },
        show_from_state: true
    }

}