import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';


const ClickableStepper = props => {
    // var tabDataList = props.tabDataList;
    // var tabState = props.tabState;
    // var variant = props.variant;
    // var onClickHandler = props.onClickHandler;
    // var subtext
    return(
        <div className="md-stepper-horizontal">
                
              <div className="md-step active" >
                  <div onKeyDown={()=>{}} onClick={()=>{}} className="md-step-circle success" tabIndex={0} role='button'>
                  <CheckIcon style={{fontSize:'18px!important', color:'#62E187'}} />
                  </div>
                  <div className="md-step-title">Stage 1<br /><span>job 1</span></div>
                  <div className="md-step-bar-left success"></div>
                  <div className="md-step-bar-right success"></div>
              </div>  
              <div className="md-step active" >
                  <div className="md-step-circle fail">
                  <WarningIcon style={{fontSize:'18px!important', color:'#ff8969'}} />
                  </div>
                  <div className="md-step-title">Stage 1<br /><span>job 1</span></div>
                  <div className="md-step-bar-left fail"></div>
                  <div className="md-step-bar-right fail"></div>
              </div> 

              <div className="md-step active" >
                  <div className="md-step-circle yellow">
                  <WatchLaterOutlinedIcon style={{fontSize:'18px!important', color:'#fff'}} />
                  
                  </div>
                  <div className="md-step-title">Stage 1<br /><span>job 1</span></div>
                  <div className="md-step-bar-left yellow"></div>
                  <div className="md-step-bar-right yellow"></div>
              </div> 

              <div className="md-step active" >
                  <div className="md-step-circle purple">
                  <ThumbUpIcon style={{fontSize:'18px!important', color:'#c294f1'}} />
                  </div>
                  <div className="md-step-title">Stage 1<br /><span>job 1</span></div>
                  <div className="md-step-bar-left purple"></div>
                  <div className="md-step-bar-right purple"></div>
              </div> 

              <div className="md-step active" >
                  <div className="md-step-circle ">
                  <span style={{fontSize:'13px', color:'#000', textAlign:'center', position:'relative', bottom:'6px'}}> +3</span>
                  </div>
                  <div className="md-step-title">Stage 1<br /><span>job 1</span></div>
                  <div className="md-step-bar-left "></div>
                  <div className="md-step-bar-right "></div>
              </div> 
              
             
        </div>
    )
}

export default  ClickableStepper ;
