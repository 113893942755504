import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../components/genericComponents/Input';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import EditorKeyValues from '../../../../../../components/genericComponents/inputs/EditorKeyValues';
import InvokeApi from '../../../../../../util/apiInvoker';
import GenerateURL, { GET_Request_Info } from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { ErrorComponent } from '../../../../../utils/Error';
import { Loading } from '../../../../../utils/Loading';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../../../util/Validator';
function Nodes(props) {
    const classes = useStyles();
    const inherits = props.inherits;
    const label_inherits = {};
    const prev_state = props.prev_state;
    const [state, setState] = useState(prev_state ? prev_state : {
        add_worker_enabled: true,
        instances: {
            loaded: false
        },
        worker_node_data_list: [],
        data: {
            master_node_instance_type: "",
            master_node_count: "",
            master_node_volume: "",
            current_worker_node_data: {
                labels_enabled: false,
                spot_enabled: false,
            }
        },
        current_worker_node_error: {}
        , error: {}
    });

    useEffect(() => {
        if (!prev_state) {
            fetchAwsInstance();
        }
    }, []);

    function fetchAwsInstance() {
        const request_info = GET_Request_Info(GenerateURL({}, properties.api.get_aws_instances));
        InvokeApi(request_info, onInstanceFetchSuccess, onInstanceFetchFailure);
    }

    function onInstanceFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            instances: {
                loaded: true,
                data: data
            },
        }));
    }

    function onInstanceFetchFailure(error) {
        setState(new_state => ({
            ...new_state,
            instances: {
                loaded: true,
                error: error
            },
        }));
    }

    function onChangeHandlerWorkerNode(e) {
        const k = e.target.name;
        var v = e.target.value;

        if (["labels_enabled", "spot_enabled"].includes(k)) {
            v = !state.data.current_worker_node_data[k];
        }

        if (["worker_node_count", "worker_node_size"].includes(k)) {
            if (!(Number(v))) {
                v = state.data.current_worker_node_data[k];
            }
        }

        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                current_worker_node_data: {
                    ...new_state.data.current_worker_node_data,
                    [k]: v
                }
            },
            current_worker_node_error: {
                [k]: "",
            }
        }));

    }

    function toggleWorkerNode() {
        setState(new_state => ({
            ...new_state,
            add_worker_enabled: !new_state.add_worker_enabled
        }));
    }

    function onChangeHandler(e) {
        const k = e.target.name;
        var v = e.target.value;

        if (["master_node_count", "master_node_volume"].includes(k)) {
            if (!(Number(v))) {
                v = state.data[k];
            }
        }

        updateData(k, v);
    }

    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v,
            },
            error: {
                ...new_state.error,
                [k]: "",
            }
        }));
    }

    function saveWorkerNode() {

        var data = state.data.current_worker_node_data;
        const validation = {
            labels_enabled: [],
            spot_enabled: [],
            group_name: [VALIDATION_TYPE_REQUIRED],
            worker_node_instance_type: [VALIDATION_TYPE_REQUIRED],
            worker_node_count: [VALIDATION_TYPE_REQUIRED],
            worker_node_size: [VALIDATION_TYPE_REQUIRED],
            labels: data.labels_enabled ? [VALIDATION_TYPE_REQUIRED] : [],
        }


        var data_labels = {};
        const current_worker_node_data = state.data.current_worker_node_data;
        if (state.data.current_worker_node_data.labels_enabled) {
            data_labels = label_inherits.getData();
            current_worker_node_data.labels = data_labels;
        }

        data = current_worker_node_data;

        const result = ValidateDataSet(data, validation);

        if (result.valid) {
            const worker_node_data_list = state.worker_node_data_list;
            if (state.active_worker_node || state.active_worker_node == 0) {
                worker_node_data_list[state.active_worker_node] = { ...current_worker_node_data }
            } else {
                worker_node_data_list.push({
                    ...current_worker_node_data
                });
            }

            setState(new_state => ({
                ...new_state,
                add_worker_enabled: false,
                data: {
                    ...new_state.data,
                    current_worker_node_data: {
                        spot_enabled: false,
                        labels_enabled: false,
                        labels: data_labels,
                    }
                },
                worker_node_data_list: worker_node_data_list
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                current_worker_node_error: {
                    ...result.error,
                }
            }));
        }
    }


    function editWorkerNodeData(i) {
        setState(new_state => ({
            ...new_state,
            active_worker_node: i,
            add_worker_enabled: true,
            data: {
                ...new_state.data,
                current_worker_node_data: {
                    ...new_state.worker_node_data_list[i],
                }
            }
        }));
    }


    //Overridings
    inherits.getState = () => {
        return state;
    };


    inherits.validateForm = () => {
        const data = { ...state.data };
        const validations = {
            master_node_instance_type: [VALIDATION_TYPE_REQUIRED],
            master_node_count: [VALIDATION_TYPE_REQUIRED],
            master_node_volume: [VALIDATION_TYPE_REQUIRED],
        }

        delete data.current_worker_node_data;



        const result = ValidateDataSet(data, validations);

        console.log("Node==================>", result,data,validations);

        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...result.error
                }
            }));
        }

        return {
            valid: result.valid
        }
    }

    return (
        <div className={classes.formDiv}>
            <p className="panel-heading">Add Nodes</p>
            <Grid container>
                <Grid item lg={12}>
                    <div className="card" style={{ boxShadow: 'none', marginBottom: '10px' }}>
                        <div className="card-header">
                            <div className="heading">
                                Master Nodes
                                </div>
                        </div>
                        <div className="card-body" style={{ padding: '10px' }}>
                            <Grid container justify="center" spacing="2">
                                <Grid item lg={6}>
                                    {
                                        state.instances.loaded ?
                                            <>
                                                {
                                                    state.instances.error ?
                                                        <ErrorComponent error={state.instances.error} />
                                                        :
                                                        <Input
                                                            type="select"
                                                            label="Select Instance Type"
                                                            name="master_node_instance_type"
                                                            list={getInstanceList(state.instances.data)}
                                                            onChangeHandler={onChangeHandler}
                                                            data={state.data}
                                                            error={state.error}
                                                        />
                                                }
                                            </>
                                            :
                                            <Loading varient="light" />
                                    }


                                </Grid>
                                <Grid item lg={3}>
                                    <Input
                                        type="text"
                                        label="Node Count"
                                        placeholder="2"
                                        name="master_node_count"
                                        onChangeHandler={onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <Input
                                        type="text"
                                        label="Volume Size"
                                        placeholder="2"
                                        name="master_node_volume"
                                        onChangeHandler={onChangeHandler}
                                        data={state.data}
                                        error={state.error}
                                    />
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </Grid>
                <Grid lg={12}>

                    {
                        state.add_worker_enabled ?
                            <>
                                <div className="form-card">
                                    <div className="form-card-header">
                                        <p>Add Worker Node Group</p>
                                        <IconButton onClick={toggleWorkerNode}>
                                            <CloseIcon style={{ fontSize: '24px' }} />
                                        </IconButton>
                                    </div>
                                    <div className="form-card-body">
                                        <Grid container alignItems="center" spacing="2">
                                            <Grid item lg={8}>
                                                <Input
                                                    type="text"
                                                    label="Group Name"
                                                    placeholder="Please give a name to the group"
                                                    name="group_name"
                                                    onChangeHandler={onChangeHandlerWorkerNode}
                                                    data={state.data.current_worker_node_data}
                                                    error={state.current_worker_node_error}
                                                />
                                            </Grid>
                                            <Grid item lg={4}>
                                                <div className="input-switch">
                                                    <Input
                                                        type="switch"
                                                        name="spot_enabled"
                                                        label="Use Spot Instance?"
                                                        onChangeHandler={onChangeHandlerWorkerNode}
                                                        data={state.data.current_worker_node_data}
                                                        error={state.current_worker_node_error} />
                                                </div>

                                            </Grid>
                                            <Grid item lg={12} md={12} xs={12} >
                                                <div className="card" style={{ marginBottom: '15px', boxShadow: 'none' }}>

                                                    <Input
                                                        type="switch"
                                                        name="labels_enabled"
                                                        label="Add Labels"
                                                        onChangeHandler={onChangeHandlerWorkerNode}
                                                        data={state.data.current_worker_node_data}
                                                        error={state.current_worker_node_error} />

                                                    {
                                                        state.data.current_worker_node_data.labels_enabled ?
                                                            <>
                                                                {
                                                                    state.current_worker_node_error.labels ?
                                                                        <ErrorComponent error={state.current_worker_node_error.labels} />
                                                                        : null
                                                                }
                                                                <EditorKeyValues inherits={label_inherits}
                                                                    data={state.data.current_worker_node_data.labels ? state.data.current_worker_node_data.labels : []} update_data_callback={() => { }} field_name={"labels"} />
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item lg={8}>
                                                {
                                                    state.instances.loaded ?
                                                        <>
                                                            {
                                                                state.instances.error ?
                                                                    <ErrorComponent error={state.instances.error} />
                                                                    :
                                                                    <Input
                                                                        type="select"
                                                                        label="Select Instance Type"
                                                                        name="worker_node_instance_type"
                                                                        list={getInstanceList(state.instances.data)}
                                                                        onChangeHandler={onChangeHandlerWorkerNode}
                                                                        data={state.data.current_worker_node_data}
                                                                        error={state.current_worker_node_error}
                                                                    />
                                                            }
                                                        </>
                                                        :
                                                        <Loading varient="light" />
                                                }


                                            </Grid>
                                            <Grid item lg={2}>
                                                <Input
                                                    type="text"
                                                    label="Node Count"
                                                    placeholder="2"
                                                    name="worker_node_count"
                                                    onChangeHandler={onChangeHandlerWorkerNode}
                                                    data={state.data.current_worker_node_data}
                                                    error={state.current_worker_node_error}
                                                />
                                            </Grid>
                                            <Grid item lg={2}>
                                                <Input
                                                    type="text"
                                                    label="Volume Size"
                                                    placeholder="2"
                                                    name="worker_node_size"
                                                    onChangeHandler={onChangeHandlerWorkerNode}
                                                    data={state.data.current_worker_node_data}
                                                    error={state.current_worker_node_error}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="form-card-footer">
                                        <button onClick={saveWorkerNode} className="btn btn-primary">Save</button>
                                        <button onClick={toggleWorkerNode} className="btn btn-secondary-outline">Cancel</button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="card" style={{ boxShadow: 'none', marginBottom: '30px' }}>
                                    <div className="card-header">
                                        <div className="heading">
                                            Worker Nodes
                                </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-grid-box">
                                            <div className="header">
                                                <p>Node Groups</p>
                                                <button onClick={toggleWorkerNode} className="transparent-btn">
                                                    <AddIcon /> New Node Group
                                        </button>
                                            </div>
                                            <div className="table-grid-box-body">
                                                <table className="table table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>Group Name</th>
                                                            <th>Spot Instance</th>
                                                            <th>Count</th>
                                                            <th>Volume</th>
                                                            <th>Instance Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.worker_node_data_list.map((worker_node, key) => (
                                                                <tr>
                                                                    <td><span style={{cursor: "pointer"}} role='button' tabIndex={0} onKeyDown={()=>{}}
    onClick={() => {editWorkerNodeData(key); }} className="text-width-120"> {worker_node.group_name} </span></td>
                                                                    <td>{worker_node.spot_enabled ? "Yes" : "No"}</td>
                                                                    <td>{worker_node.worker_node_count}</td>
                                                                    <td>{worker_node.worker_node_size}</td>
                                                                    <td>{worker_node.worker_node_instance_type}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                    }
                </Grid>
            </Grid>

        </div>
    );
}
Nodes.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default Nodes;

function getInstanceList(instances) {
    const result = [];
    instances.forEach(instance => {
        result.push({
            label: instance,
            id: instance,
            value: instance
        });
    });
    return result;
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 32,
        paddingTop: '32px'
    },
    MainHeading: {
        fontSize: '24px',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: '#000',
        lineHeight: '24px'
    },
    SubHeading: {
        fontSize: '11px',
        color: '#9d9d9d',
        lineHeight: '15px'
    },
    mainBodyDiv: {
        backgroundColor: '#f6f8f8',
        padding: '15px 20px',
        borderTop: '1px solid#dedede',
    },
    subHeader: {
        backgroundColor: '#fcfcfc',
        fontSize: '12px',
        lineHeight: 2,
        padding: '5px 10px',
        borderBottom: '1px solid #dedede',
        color: '#9a9a9a'
    },
    formDiv: {
        padding: '30px 15px',

    }
}))