import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CommonHorizontalTab from '../../../../../components/genericComponents/CommonHorizontalTab';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import properties from '../../../../../properties/properties';
import GenerateURL from '../../../../../util/APIUrlProvider';
import InvokeApi from '../../../../../util/apiInvoker';
import { getPodStatus, getPodStatusColor, getReplicasetName } from '../../../../../util/util';
import PodDetailedCard from './podsData/PodDetailedCard';
import AllOutIcon from '@material-ui/icons/AllOut';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { Loading } from '../../../../utils/Loading';
import { ErrorComponent } from '../../../../utils/Error';
export default function PodsListingWithDetails(props) {
    const service_id = props.service_id
    const env_id = props.env_id
    const cd_id = props.cd_id
    console.log(cd_id,"___dededefff")
    const classes = useStyles();
    const refresh_count = props.refresh_count;
    const [state, setState] = useState({
        selectedPod: 0,
        pod_names_list: []
    })
    const updateSelectedPod = (selectedPod) => {
        setState(new_state => ({
            ...new_state,
            selectedPod: selectedPod,

        }));
    }

    useEffect(() => {
        loadAllPods();
    }, [refresh_count, cd_id]);

    function loadAllPods() {
        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.service_deployment_pods_details),
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.service_deployment_pods_details_widget + `?deployment_name=${cd_id}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleLoadAllPodsSuccess, handleLoadAllPodsError);
    }
    function handleLoadAllPodsSuccess(response) {
        console.log(response,"sdbhjsdbhjcbsdjvbjs")
        var pod_names_list = getPodNamesList(response);
        var replicaset_pod_names = getPodsGroupedByReplicasetname(response)
        var container_names = getMultipleContainerNames(response)
        setState(new_state => ({
            ...new_state,
            pods_data_loaded: true,
            pods_data_load_failure: false,
            pods_array: response.items,
            pod_error_message: null,
            pod_names_list: pod_names_list,
            replicaset_pod_names: replicaset_pod_names
        }));
       
        loadPodMatrix();
    }
    function handleLoadAllPodsError(error) {
        var error_message = "Pods do not exist or there is temporary connectivity issue. This is message we got: "
        error_message = error_message + error.toString()
        setState(new_state => ({
            ...new_state,
            pods_data_loaded: true,
            pods_data_load_failure: true,
            pod_error_message: error_message
        }));
    }

    function getMultipleContainerNames(data){
        
    }
    function loadPodMatrix() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_monitoring_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleLoadPodMatrixSuccess, handleLoadPodMatrixError);
    }
    function handleLoadPodMatrixSuccess(response) {
        //props.updateReplicaStatus(response);
        console.log(response,"fdsafjdshfhsa")
        setState(new_state => ({
            ...new_state,
            pod_matrix_data: response,
            pods_matrix_data_loaded: true
        }));
        
    }
    function handleLoadPodMatrixError(error) {
        var error_message = "Pods matrix not loaded"
        error_message = error_message + error.toString()
        setState(new_state => ({
            ...new_state,
            pods_matrix_data_loaded: true,
            pod_matrix_error: error_message
        }));
    }
    function getPodNamesList(response) {
        var pod_names_list = []
        if (response && response.items) {
            response.items.forEach((pod, index) => {
                getPodStatus(pod)
                var status_color = getPodStatusColor(pod)
                pod_names_list.push(
                    {
                        order: index,
                        tabName: status_color == 'green' ?
                            <span className=""><CheckIcon className="color-success mr-5 v-align-sub" />{pod.metadata.name}</span> :
                            status_color == 'grey' ?
                                <span className=""><ClearIcon className="text-grey-b6 mr-5 v-align-sub" />{pod.metadata.name}</span> :
                                status_color == 'red' ?
                                    <span className=""><ClearIcon className="color-danger mr-5 v-align-sub" />{pod.metadata.name}</span> :
                                    status_color == 'yellow' ?
                                        <span className=""><TimelapseIcon className="color-pending mr-5 v-align-sub" />{pod.metadata.name}</span> :
                                        status_color == 'blue' ?
                                            <span className=""><AllOutIcon className="color-terminating-blue mr-5 v-align-sub" />{pod.metadata.name}</span> : null,
                        status_color: status_color
                    }
                )
            })

        }
        return pod_names_list
    }
    function getPodsGroupedByReplicasetname(response) {
        var replicasets_name_grouped_pods = {}
        if (response && response.items) {
            response.items.forEach((pod,index) => {
                if (!replicasets_name_grouped_pods[getReplicasetName(pod)]) {
                    replicasets_name_grouped_pods[getReplicasetName(pod)] = []
                }
                replicasets_name_grouped_pods[getReplicasetName(pod)].push(getPodTabElement(pod,index))
            })
        }
        return replicasets_name_grouped_pods;
    }
    function getPodTabElement(pod,index){
        getPodStatus(pod)
        var status_color = getPodStatusColor(pod)
        var pod_tab_element= {
                order: index,
                tabName: status_color == 'green' ?
                    <span className=""><CheckIcon className="color-success mr-5 v-align-sub" />{pod.metadata.name}</span> :
                    status_color == 'grey' ?
                        <span className=""><ClearIcon className="text-grey-b6 mr-5 v-align-sub" />{pod.metadata.name}</span> :
                        status_color == 'red' ?
                            <span className=""><ClearIcon className="color-danger mr-5 v-align-sub" />{pod.metadata.name}</span> :
                            status_color == 'yellow' ?
                                <span className=""><TimelapseIcon className="color-pending mr-5 v-align-sub" />{pod.metadata.name}</span> :
                                status_color == 'blue' ?
                                    <span className=""><AllOutIcon className="color-terminating-blue mr-5 v-align-sub" />{pod.metadata.name}</span> : null,
                status_color: status_color
            }
        return pod_tab_element
    }


   
    return (
        <div className={classes.root}>

            {
                state.pods_data_loaded ?
                    state.pods_data_load_failure ?
                        <div className="m-auto pd-10 error-pods max-width-400 d-flex">
                            <ErrorComponent error={state.pod_error_message} />
                        </div>
                        :
                        <div className="card-body d-grid grid-template-single-pods" >
                            {
                                state.pods_array ?
                                    <>
                                        <div className="pods-list">
                                            <div className="pd-10 d-flex align-center border-bottom space-between">
                                                <p className="font-13">PODs Available</p>
                                                {/* <p className="font-11 text-grey-b6"><CheckCircleIcon className="color-success v-align-sub" /> 3 of 5 Ready</p> */}
                                            </div>
                                            {state.pods_array.length> 0 ?
                                            <>
                                            {state.replicaset_pod_names &&  Object.keys(state.replicaset_pod_names).map(replicaset_name =>
                                            (<>
                                               <div className="font-12 pd-5">Replicaset: <span className=" text-grey-b6 ">{replicaset_name}</span></div> 
                                                <div className="side-panel-pods">
                                                    <CommonHorizontalTab
                                                        tabList={state.replicaset_pod_names[replicaset_name]}
                                                        selectedTabOrder={state.selectedPod}
                                                        updateSelectedTab={updateSelectedPod}
                                                    />
                                                 </div>

                                            </>))}
                                            </>
                                            : <span className="font-12 pd-5">No Data Available</span> }
                                        </div>
                                        <div className="right-panel-pod-details ">

                                            

                                                <PodDetailedCard selected_pod={state.pods_array[state.selectedPod]} service_id={service_id} env_id={env_id} cd_id={cd_id} pod_matrix={state.pod_matrix_data} />
                                            

                                        </div>
                                    </> : "No Data Available"}
                        </div>
                    : <Loading varient="light" />
            }



        </div>
    )
}

PodsListingWithDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles((theme) => ({
    root: {
        '& .loading-gif-container-two': {
            margin: '100px auto'
        },
        '& .side-panel-pods': {
            '& .headerul': {
                display: 'block',
                '& li': {
                    borderBottom: '1px solid #dedede',
                    fontSize: '11px',
                    padding: 10,
                    textTransform: 'lowercase',
                    '&:first-child': {
                        borderTop: '1px solid #dedede',
                    }
                }
            },
        },
        '& .max-width-400': {
            maxWidth: 400
        },
        '& .error-pods': {
            height: 500
        },
        '& .right-panel-pod-details': {
            backgroundColor: '#fff',
            padding: 20
        },
        '& .pods-list': {
            backgroundColor: '#fcfcfc',
            borderRight: '1px solid #dedede',

        },
        '& .font-11': {
            fontSize: 11
        },
        '& .pod-card': {
            borderRadius: 0,
            boxShadow: 'none',
            '& .card-header': {
                height: 'auto',
                display: 'block'
            }
        },
        '& .chip-default': {
            borderRadius: '4px',
            backgroundColor: '#ececec',
            boxShadow: 'none',
            color: '#878787'
        },
        '& .bottom-panel': {
            '& .headerul': {
                padding: "10px 20px",
                paddingBottom: '0!important',
                borderBottom: '1px solid #dedede',
                '& li': {
                    '&.active': {
                        border: '1px solid #dedede',
                        borderBottom: 'none',
                        borderRadius: '5px 5px 0px 0px'
                    }
                }
            },
        }
    },
}));