import React, { useState, useEffect, useRef, useMemo } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Loading, LoadingText } from "../../utils/Loading";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import CommonHorizontalTab from '../../../components/genericComponents/CommonHorizontalTab';
import { CircularMeterList } from '../../../components/genericComponents/CircularMeter';
import { Input } from '../../../components/genericComponents/Input';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { SubscribeToApi } from '../../../util/apiInvoker';
import { useParams, Link } from 'react-router-dom';

import { EnvMonitoringDataParser } from '../../../util/APIParser';
import { IconLinkList } from '../../../components/genericComponents/IconLink';
import { HorizontalMeter } from '../../../components/genericComponents/HorizontalMeter';
import TriggerActionCard from './components/TriggerActionCard';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { ProgressContainer } from './components/CustomStackedProgress';
import ReplicationToolBar from './components/ReplicationToolBar';
import Tooltip from '@material-ui/core/Tooltip';
import HealthCheck from './HealthCheck';

import HealthStatusRoundMeter from '../../../components/genericComponents/HealthStatusRoundMeter';

// import Delete from '../../../components/genericComponents/Delete';
import moment from 'moment';
import { CopyToClipboard } from '../../../util/util';
import HistoryRevamp from './components/HistoryRevamp';

import { GetAuth } from '../../../util/security';
import JobListingForTemplates from '../detail/components/servicesTemplates/JobListingForTemplates';
import PromoteActionCard from '../detail/components/PromoteActionCard';
import DeployActionCard from '../detail/components/DeployActionCard';
import MonitoringDialogue from '../detail/components/MonitoringDialogue';
import { makeStyles, styled } from '@material-ui/core/styles';
import SendForApprovalConfirmationDialog from '../detail/components/auditHistory/SendForApprovalConfirmationDialog';
import { ErrorComponent } from '../../utils/Error';
import TriggerActionCardNew from './components/TriggerActionCardNew';
import DeployActionCardNew from '../detail/components/DeployActionCardNew';
import { Dialog, Popover, Slide, Zoom } from '@material-ui/core';
import theme from '../../../theme';
import SelectWithInsideLabel from '../../../components/genericComponents/inputs/SelectWithInsideLabel';
import EnvSelectorNew from '../../../components/genericComponents/EnvSelectorNew';
import { CircularMeterListNew } from '../../../components/genericComponents/CircularMeterNew';
import ReplicationToolBarNew from './components/ReplicationToolBarNew';
import JobCardServiceCardForDynamicJob from '../detail/components/servicesTemplates/jobCard/JobCardServiceCardForDynamicJob';


const ServiceCard = (props) => {
    const service_data = props.service_data;
    const open_by_default = props.open_by_default
    const refresh = props.refresh ? props.refresh : () => { };
    const pinned = props.pinned ? props.pinned : false;
    const pinEnabled = props.pinEnabled ? props.pinEnabled : false;
    // service_data.component_env.length == 0 ? false : true;
    // Filling master environments for a service with sub env
    var environments = {};
    var lowestEnv = 5;
    service_data.component_env.forEach(component_env_one => {
        const master_env_id = component_env_one.project_env.environment_master;
        lowestEnv = master_env_id < lowestEnv ? master_env_id : lowestEnv
        environments[master_env_id] = environments[master_env_id] ? environments[master_env_id] : {}
        environments[master_env_id].envs = environments[master_env_id].envs ? environments[master_env_id].envs : []
        environments[master_env_id].envs = [...environments[master_env_id].envs, component_env_one];
    }
    );

    console.log(environments, "environments =========>")
    const [state, setState] = useState({ sectionOpen: open_by_default, selected_env_id: lowestEnv });

    const all_envs = Object.keys(environments);
    const toggleSectionOpen = () => {
        setState({
            ...state,
            sectionOpen: !state.sectionOpen
        })
    }
    function onEnvUpdate(env_id) {
        setState({
            ...state,
            selected_env_id: env_id
        })
    }

    function pinService(service_id) {
        let pinnedServices = localStorage.getItem('pinned_services');
        if (!pinnedServices) {
            pinnedServices = [];
            localStorage.setItem('pinned_services', JSON.stringify(pinnedServices));
        } else {
            pinnedServices = JSON.parse(pinnedServices);
        }

        const index = pinnedServices.indexOf(service_id);
        if (index !== -1) {
            pinnedServices.splice(index, 1);
        } else {
            pinnedServices.push(service_id);
        }

        localStorage.setItem('pinned_services', JSON.stringify(pinnedServices));
        refresh()
    }

    console.log(state.selected_env_id, service_data, 'sse-rrrr_001');
    return (

        <div className="main-container">
            {/* <div className="main-container-head-card">
                    <div className="left-content">
                        <HeadContent service_id={service_data.id} env_id={state.selected_env_id ? state.selected_env_id : service_data.selected_env_id} application_id={service_data.project_id} service_name={service_data.name} environments={environments} />
                    </div>
                    {/* <Delete
                        display_data_name={service_data.name}
                        data={{ entity_id: service_data.id, name: "component" }}
                        refresh={props.refresh}
                        api_link={GenerateURL({  service_id: service_data.id }, properties.api.service_basic_details_update_url)}
                    /> */}
            {/* <div className="right-content" >
                        <IconButton>
                            {
                                state.sectionOpen ?

                                    <ExpandLessIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} />
                                    :
                                    all_envs.length > 0 ?
                                        <ExpandMoreIcon style={{ fontSize: '24px', color: '#666', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} /> : null
                            }
                        </IconButton>

                    </div> */}

            {/*</div> */}


            <Body service_data={service_data} environments={environments} selected_env_master_id={lowestEnv} update_env_callback={onEnvUpdate} sendForApproval={props.sendForApproval} confirmForApproval={props.confirmForApproval} envIdForHeader={state.selected_env_id ? state.selected_env_id : service_data.selected_env_id} open_by_default={open_by_default} pinned={pinned} pinEnabled={pinEnabled} pinService={pinService} />



        </div>


    )
}

ServiceCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


const Body = (props) => {
    const { application_id } = useParams();
    const service_data = props.service_data;
    const environments = props.environments;
    const classes = useStyles();
    const update_env_callback = props.update_env_callback;
    const selected_env_master_id = props.selected_env_master_id
    const setEnvSelector = props.setEnvSelector;
    const envIdForHeader = props.envIdForHeader;
    const open_by_default = props.open_by_default;
    const pinned = props.pinned ? props.pinned : false;
    const pinEnabled = props.pinEnabled ? props.pinEnabled : false;
    const pinService = props.pinService ? props.pinService : () => { };
    const [showPin, setShowPin] = useState(false);
    function handleMouseEnter() {
        setShowPin(true);
    }

    function handleMouseLeave() {
        setShowPin(false);
    }
    console.log(service_data, 'service_data_001')
    console.log(environments, 'available_envs')
    console.log("hsdbjsndje", props);
    const [state, setState] = useState({
        selected_env_master_id: selected_env_master_id,
        component_id: props.service_data ? props.service_data.id : null,
        selected_env_id: environments[selected_env_master_id] ? environments[selected_env_master_id].envs[0].id : 1,
        selectedServiceEnv: environments[selected_env_master_id] ? environments[selected_env_master_id].envs[0] : {},
        count: 0,
        selected_component_env_taborder: 1,
        job_template: null,
        configured_Jobs: {},
        jobTrigger: false,
        data: {},
        error: {},
        dynamicJobs: null
    });

    const version = useMemo(() => {
        if (state.job_template) {
            return state?.job_template?.version ? state?.job_template?.version : 'v2'
        }

    }, [state.job_template])

    useEffect(() => {

        if (state.component_id !== undefined && state.selected_env_id != undefined) {
            const placeholders = {
                application_id: application_id,
                service_id: state.component_id,
                env_id: state.selected_env_id
            }

            var requestInfo = {
                endPoint: GenerateURL(placeholders, properties.api.env_ci_cd_data_url),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            InvokeApi(requestInfo, (data) => {

                console.log(data, 'data_001');
                application_id != undefined && data &&
                    fetchJobTemplatesInfo(application_id, data?.project_job_template_id)

            },
                (error) => {

                    setState(prevState => ({
                        ...prevState,
                        fetchError: error
                    }))
                });
        }

    }, [state.component_id, state.selected_env_id]);


    useEffect(() => {

        state.job_template && getConfiguredJobs(state.component_id, state.selected_env_id, state.job_template.id)
        if (state.job_template?.job_template) {
            const dynamicJobs = state.job_template?.job_template?.jobs?.filter(job => {
                if (job.is_dynamic) {
                    return job
                }
            })
            if (dynamicJobs?.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    dynamicJobs: dynamicJobs
                }))
            }
        }
    }, [state.job_template])

    function fetchJobTemplatesInfo(applicationId, jobTempalteId) {

        let myEndPoint = GenerateURL({ application_id: applicationId }, properties.api.add_job_template)

        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, (data) => {

            console.log(data, jobTempalteId, 'console_for_jjsjsj');
            let selectedTemplateData = data?.results?.find(template => template.id === jobTempalteId)

            console.log(selectedTemplateData, 'seldldldlld')
            setState(prevState => ({
                ...prevState,
                job_template: selectedTemplateData
            }))

        }, error => {
            setState(prevState => ({
                ...prevState.error,
                error: error
            }))
        });

    }

    var service_master_env_indexes = Object.keys(environments);
    const env_tab_list = [];
    var moreThanOneSubEnv = false;
    const sub_env_list = [];
    if (service_master_env_indexes) {
        service_master_env_indexes.forEach((service_master_env_idex) => {
            const env_tab = {
                order: service_master_env_idex,
                tabName: properties.envs_map[service_master_env_idex].label,
                total_sub_env: environments[service_master_env_idex].envs.length
            }
            env_tab_list.push(env_tab);
        });
    }

    const getComponentEnvsTabForMasterEnv = () => {
        var component_env_tablist = []
        var order = 1
        var componentEnvs = environments[state.selected_env_master_id] ? environments[state.selected_env_master_id].envs : []
        componentEnvs.forEach(componentEnv => {
            const componentEnvTab = {
                order: order++,
                tabName: componentEnv.project_env.name,
            }
            component_env_tablist.push(componentEnvTab)
        })
        return component_env_tablist;
    }


    const updateSelectedMasterEnv = (selectedMasterEnvId) => {
        var component_envs = environments[selectedMasterEnvId].envs;
        let selectedServiceEnv = environments[selectedMasterEnvId].envs[0];
        console.log("ijtijidw", component_envs, selectedMasterEnvId, selectedServiceEnv);
        setState(
            {
                ...state,
                uptime: null,
                restart: null,
                selected_env_master_id: selectedMasterEnvId,
                selected_env_id: component_envs[0].id,
                selectedServiceEnv: selectedServiceEnv,
                selected_component_env_taborder: 1

            }
        );
        update_env_callback(component_envs[0].id);
    }


    const updateComponentEnv = (componentEnvTabOrder) => {

        var component_env_id = environments[state.selected_env_master_id].envs[componentEnvTabOrder - 1].id;

        let selectedServiceEnv = environments[state.selected_env_master_id].envs[componentEnvTabOrder - 1];

        setState({
            ...state,
            uptime: null,
            restart: null,
            selected_env_id: component_env_id,
            selectedServiceEnv: selectedServiceEnv,
            selected_component_env_taborder: componentEnvTabOrder
        })
        update_env_callback(component_env_id);
    }

    const updateCDDetails = (data) => {
        setState(new_state => ({
            ...new_state,
            uptime: data.uptime,
            restart: data.restart,
        }));
    }

    function refresh() {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1,
        }));
    }

    const ref = useRef();
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };


    const getConfiguredJobs = (service_id, env_id, component_env_id) => {

        var requestInfo = {
            endPoint: GenerateURL({ component_id: service_id, sub_env_id: env_id }, properties.api.configure_job_post_step_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, getConfiguredJobSuccess, getConfiguredJobFailure);
    }

    const getConfiguredJobSuccess = (data) => {

        console.log('data_c', data)
        let configureJobsObj = {}

        data && data.forEach(({ job_code, ...item }) => {
            configureJobsObj[job_code] = item
        })

        setState((prevstate) => ({
            ...prevstate,
            configured_Jobs: configureJobsObj
        }))
    }

    const getConfiguredJobFailure = (error) => {
        console.log(error, 'error_001')
    }


    console.log(state, 'body_state_0001')
    useEffect(() => {
        fetchCdData();
        widgetApiHit();
    }, [state.component_id, state.selected_env_id])

    function fetchCdData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: state.component_id, component_env_id: state.selected_env_id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true
        }));
    }

    function widgetApiHit() {
        let requestInfo = {
            endPoint: GenerateURL({ service_id: state.component_id, component_env_id: state.selected_env_id }, properties.api.widget_data + "?job_code=deploy_job&widget_code=DEPLOYMENT_ANALYTICS"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onWidgetSuccess, onWidgetFail);
        setState(prevState => ({
            ...prevState,
            cd_data_loading: true,
        }));
    }

    function onWidgetSuccess(response) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
            deployments_list: response.map(item => { return { label: item, id: item } }),
        }));
    }

    function onWidgetFail(error) {
        setState(prevState => ({
            ...prevState,
            cd_data_loading: false,
        }))
    }

    function onFetchCdDataSuccess(response) {
        if (response && response.length > 0) {
            var fetchApprovedCd = response.filter(item => item.status == "APPROVED")
            console.log("ddfjd", fetchApprovedCd);
            setState(new_state => ({
                ...new_state,
                cd_data_loading: false,
                all_deployment_list: fetchApprovedCd.map(item => { return { label: item.deployment_name, id: item.id } }),
                data: {
                    ...new_state.data,
                    deployment_name: fetchApprovedCd[0] && fetchApprovedCd[0].id ? fetchApprovedCd[0].id : null,
                    wideget_deployment_name: fetchApprovedCd[0] && fetchApprovedCd[0].deployment_name ? fetchApprovedCd[0].deployment_name : null,
                },
                fetchError: false
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                cd_data_loading: false
            }));
        }
    }

    console.log(state.all_deployment_list, "all_deployment_list__")
    const [showMonitoring, setShowMonitoring] = useState(false);
    const monitoringSectionBtn = <Tooltip title={"Service Health"}><button className='btn-sq-icon-primary d-flex align-center' onClick={() => setShowMonitoring(!showMonitoring)}><span className='ri-heart-pulse-line'></span></button></Tooltip>

    function onFetchCdDataFail(response) {
        setState(new_state => ({
            ...new_state,
            cd_response: null,
            cd_data_loading: false
        }));
    }
    function onChangeDropdownHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        console.log(key, value, "onChangeDropdownHandler");
        let selected_deployment_name = state.all_deployment_list.find(item => item.id == value);
        console.log("fjhdjs", selected_deployment_name);
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
                wideget_deployment_name: selected_deployment_name.label,
            },
            error: {
                ...state.error,
                [key]: null,
            },

        });
    }

    function selectDeployment(deployment) {
        let selected_deployment_name = state.all_deployment_list.find(item => item.id == deployment);
        setState(prev_state => ({
            ...prev_state,
            data: {
                ...prev_state.data,
                deployment_name: deployment,
                wideget_deployment_name: selected_deployment_name.label,
            },
            error: {
                ...prev_state.error,
                deployment_name: null,
            }
        }));
    }

    const deploymentSelector =
        state.all_deployment_list && state.all_deployment_list.length > 1 ?
            <div style={{ width: "214px" }}>
                <SelectWithInsideLabel list={state.all_deployment_list} onChangeHandler={selectDeployment} label={"Deployment: "} SelectedDeployment={state.data.deployment_name} optionsWidth={214} />
            </div>
            : null;
    {/* <div className="input-mb-0 mb-0">
                    <Input
                        type="select"
                        label=""
                        name="deployment_name"
                        list={state.all_deployment_list}
                        onChangeHandler={onChangeDropdownHandler}
                        data={state.data}
                        error={state.error}
                    />
    </div> */}
    // console.log(environments, "environments =========>")
    const [stateHead, setStateHead] = useState({ sectionOpen: open_by_default });

    const all_envs = Object.keys(environments);
    const toggleSectionOpen = () => {
        setStateHead({
            ...state,
            sectionOpen: !stateHead.sectionOpen
        })
    }

    console.log("jijitjirt", env_tab_list, getComponentEnvsTabForMasterEnv());

    console.log('state_00p_00p', state.dynamicJobs)
    console.log(state.configured_Jobs, 'xlm_001')
    return (
        <>
            <div className={stateHead.sectionOpen ? "main-container-head-card" : "main-container-head-card main-card-head-hover"} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                <div className="left-content">
                    <HeadContent service_id={service_data.id} env_id={envIdForHeader} application_id={service_data.project_id} service_name={service_data.name} environments={environments} service_data={service_data} />
                </div>

                <div className="right-content" style={{ gap: "12px" }}>
                    {pinEnabled &&
                        <div className={`pin-container mr-12 ${pinned ? 'display-inline-flex' : showPin ? 'display-inline-flex' : 'display-none'} `} onClick={() => pinService(service_data.id)}>
                            <span className={pinned ? 'ri-unpin-line font-16' : 'ri-pushpin-line font-16'}></span>
                            <span className='font-12 font-weight-600'>{pinned ? "Unpin" : "Pin"}</span>
                        </div>
                    }
                    {
                        all_envs.length > 0 && stateHead.sectionOpen ?
                            <>

                                <div style={{ width: "330px" }}>
                                    <EnvSelectorNew envTabList={env_tab_list} selectedEnvTab={state.selected_env_master_id} envChangeHandler={updateSelectedMasterEnv} componentEnvTabList={getComponentEnvsTabForMasterEnv()} selectedComponentEnv={state.selected_component_env_taborder} componentEnvChangeHandler={updateComponentEnv} />
                                </div>
                                <div className='sub-section'>

                                    <button class="btn-without-border d-flex align-center" style={{ justifySelf: 'flex-end' }} onClick={refresh} ><span class="ri-restart-line font-20"></span></button>
                                </div>
                            </>
                            : null
                    }
                    <IconButton>
                        {
                            stateHead.sectionOpen ?

                                <ExpandLessIcon className='up-down-icon' style={{ fontSize: '24px', color: '#2f2f2f', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} />
                                :
                                all_envs.length > 0 ?
                                    <ExpandMoreIcon className='up-down-icon' style={{ fontSize: '24px', color: '#2f2f2f', lineHeight: 1, cursor: 'pointer' }} onClick={toggleSectionOpen} /> : null
                        }
                    </IconButton>

                </div>

            </div>
            {stateHead.sectionOpen ?
                <>
                    {
                        service_master_env_indexes.length > 0 ?
                            <div className="main-container-body">
                                {/* <div style={{width:"300px",position: "absolute" , top: "-22%",left: "60%"}} >
                            <EnvSelectorNew envTabList={env_tab_list} selectedEnvTab={state.selected_env_master_id} envChangeHandler={updateSelectedMasterEnv} componentEnvTabList={getComponentEnvsTabForMasterEnv()} selectedComponentEnv={state.selected_component_env_taborder} componentEnvChangeHandler={updateComponentEnv} />
                        </div> */}

                                {/* <div className="mr-20 ml-20 service-listing font-family-v1 font-weight-500" >
                            <CommonHorizontalTab variant="env_tab" tabList={env_tab_list}
                                showStatusBullet={false}
                                selectedTabOrder={state.selected_env_master_id} updateSelectedTab={updateSelectedMasterEnv}
                            />
                        </div> */}
                                <div className="service-lising-detailed-card card" style={{ boxShadow: 'none', borderColor: '#ebedee' }}>
                                    <div className="details-tab">
                                        <div className=""> {/*details-label */}
                                            {/* <div className="d-flex align-center " style={{ paddingRight: 15, }}>
                                        <div className="env_tabs font-family-v1 font-weight-500 d-flex align-center">

                                            <CommonHorizontalTab
                                                tabList={getComponentEnvsTabForMasterEnv()}
                                                variant="scrollable_div"
                                                showStatusBullet={false}
                                                selectedTabOrder={state.selected_component_env_taborder}
                                                updateSelectedTab={updateComponentEnv}
                                            />
                                        </div>
                                        //  {
                                        //     service_data.status &&
                                        //     <div className={classes.rootCard}>
                                        //         <p>Status :
                                        //             <span className={service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? "font-10 chip chip-yellow ml-5" : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? "font-10 chip chip-dark-grey ml-5" : "font-10 chip chip-green ml-5"}>
                                        //                 {service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? "IN DRAFT" : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? "APPROVAL_REQUESTED" : "IN USE"}
                                        //             </span>
                                        //         </p>
                                        //         {service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={service_data} sendForApproval={props.sendForApproval} /> </div> : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={service_data} sendForApproval={props.sendForApproval} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} /> </div> : ""}
                                        //     </div>
                                        // } 
                                    </div> */}


                                        </div>
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={state.jobTrigger}
                                            onClose={() => {
                                                setState(prevState => ({ ...prevState, jobTrigger: false }))
                                            }}
                                            autoHideDuration={4000}>
                                            <Alert severity="success">
                                                {"SUCCESSFULLY JOB TRIGGERED!"}
                                            </Alert>
                                        </Snackbar>
                                        {
                                            version === 'v3' ?
                                                <JobListingForTemplates
                                                    variant='service-card'
                                                    setSnackBar={setState}
                                                    application_id={application_id}
                                                    selectedServiceEnv={state.selectedServiceEnv}
                                                    masterLabel={properties.envs[state.selected_env_master_id - 1].label}
                                                    wideget_deployment_name={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                    showOnlyConfigured={true}
                                                    component_id={state.component_id}
                                                    sub_env_id={state.selected_env_id}
                                                    jobListing={state.job_template?.job_template?.jobs}
                                                    configuredJobs={state.configured_Jobs}
                                                    selectedJobTemplate={{ data: state.job_template }}
                                                    service_id={service_data.id}
                                                    service_data={{
                                                        ...service_data,
                                                        service_env: state.selectedServiceEnv?.project_env?.name,
                                                        type: properties.envs[state.selected_env_master_id - 1].label
                                                    }}
                                                    env_id={state.selected_env_id}
                                                    version='v3'
                                                />

                                                :
                                                version == 'v2' ?
                                                    <>
                                                        {/* <div className="details-label-d-card bg-light-grey font-12 font-family-v1 font-weight-500">
                                                <span className="tab-label option-1">Build Status</span>
                                                <span className="tab-label option-2">Deploy Status &amp; Monitoring Status</span>
                                                {
                                                    state.all_deployment_list && state.all_deployment_list.length > 1 ?
                                                        <div className='d-grid' style={{
                                                            gridTemplateColumns: '1fr 300px',
                                                            alignItems: 'baseline'
                                                        }}>
                                                            <div>Select Deployment:</div>
                                                            <div className="input-mb-0 mb-0">
                                                                <Input
                                                                    type="select"
                                                                    label=""
                                                                    name="deployment_name"
                                                                    list={state.all_deployment_list}
                                                                    onChangeHandler={onChangeDropdownHandler}
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div> */}

                                                        {
                                                            state.fetchError ?
                                                                <div style={{ padding: '12px' }}>
                                                                    <ErrorComponent error={state.fetchError} />
                                                                </div>

                                                                :
                                                                <div className="deatils-of bg-white pb-10"> {/**/}
                                                                    {

                                                                        <>
                                                                            <CICDSection
                                                                                dynamicJobs={state.dynamicJobs}
                                                                                selectedTemplate={state.job_template}
                                                                                refresh={refresh}
                                                                                count={state.count}
                                                                                selectedServiceEnv={state.selectedServiceEnv}
                                                                                application_id={application_id}
                                                                                service_data={{
                                                                                    ...service_data,
                                                                                    type: properties.envs[state.selected_env_master_id - 1].label
                                                                                }}

                                                                                masterLabel={properties.envs[state.selected_env_master_id - 1].label}
                                                                                service_id={service_data.id}
                                                                                env_id={state.selected_env_id}
                                                                                deploy_id={state.data.deployment_name ? state.data.deployment_name : null}
                                                                                wideget_deployment_name={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                                                uptime={state.uptime}
                                                                                restart={state.restart}
                                                                                all_available_deployments_without_widget={state.all_deployment_list && state.all_deployment_list.length > 0 ? state.all_deployment_list : []}
                                                                                all_available_deployments={state.deployments_list && state.deployments_list.length > 0 ? state.deployments_list : []}
                                                                                env_list={getComponentEnvsTabForMasterEnv()}
                                                                                deploymentSelector={deploymentSelector}
                                                                                confirmForApproval={props.confirmForApproval}
                                                                                sendForApproval={props.sendForApproval}
                                                                                monitoringSectionBtn={monitoringSectionBtn}
                                                                                configuredJobs={state.configured_Jobs}

                                                                            />
                                                                            <MonitoringSection
                                                                                deploy_id={state.data.wideget_deployment_name ? state.data.wideget_deployment_name : null}
                                                                                count={state.count}
                                                                                application_id={application_id}
                                                                                service_id={service_data.id}
                                                                                env_id={state.selected_env_id}
                                                                                callback={updateCDDetails}
                                                                                showMonitoring={showMonitoring}
                                                                                handleCloseMonitoring={() => setShowMonitoring(false)}
                                                                            />

                                                                        </>
                                                                    }
                                                                </div>
                                                        }

                                                    </> :

                                                    <div style={{ width: '100%', height: '300px', background: "#fff" }}>
                                                    </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            : null
                    }
                </>
                : null}

        </>
    );
}

Body.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


const CICDSection = ({ dynamicJobs, selectedTemplate, configuredJobs, ...props }) => {
    const [state, setState] = useState({
        loaded: false,
        action_card: "",
        data_loading: false
    });
    const classes = useStyles();
    // const [openMonitoringDialog, setOpenMonitoringDialog] = useState(false);
    const extraProps = props.extraProps;
    const application_id = props.application_id;
    const service_id = props.service_id ? props.service_id : null;
    const env_id = props.env_id ? props.env_id : null;
    const service_data = props.service_data;
    const count = props.count;
    const refresh = props.refresh;
    const selectedServiceEnv = props.selectedServiceEnv
    const masterLabel = props.masterLabel;
    const env_list = props.env_list;
    const deploy_id = props.deploy_id;
    const wideget_deployment_name = props.wideget_deployment_name;
    const service_env_name = env_list[0].tabName;
    const all_available_deployments = props.all_available_deployments
    const all_available_deployments_without_widget = props.all_available_deployments_without_widget
    const monitoringSectionBtn = props.monitoringSectionBtn

    // Managing API Calls

    console.log(deploy_id, all_available_deployments, "service_env_name list ============>")

    useEffect(() => {
        console.log(deploy_id, "deplcdsacdsaoy_iddeploy_id")
        // if (deploy_id) {
        const placeholders = {
            application_id: application_id,
            service_id: service_id,
            env_id: env_id
        }
        var requestInfo = {
            endPoint: GenerateURL(placeholders, properties.api.env_ci_cd_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        // SubscribeToApi(GenerateURL(placeholders, properties.api.env_ci_cd_data_url));
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState(new_state => ({
            ...new_state,
            loaded: false,
            data_loading: true,
            action_card: ""
        }));
        // }


    }, [env_id, count, deploy_id]); //deploy_id

    useEffect(() => {
        console.log(state.multiple_build_enabled,"sdbvksdbvjhsdbvjsd")
        if (state.multiple_build_enabled) {
            fetchLastParentCiData()
        }
    }, [state.multiple_build_enabled])


    const handleSuccessApiHit = (response) => {
        console.log(response, deploy_id, "data____1")
        var find_selected_deployed_details = response.last_deploy && response.last_deploy.length > 0 ? response.last_deploy.find(item => item.env_cd_detail_id == deploy_id) : null
        console.log(find_selected_deployed_details, "data____1")
        fetchCIData()
        fetchCdData()
        setState(new_state => ({
            ...new_state,
            loaded: true,
            ci_cd_data: response,
            build_details: response.last_build && response.last_build[0],
            deploy_details: find_selected_deployed_details,
            promote_details: response.last_promote,
            cd_configured: response.env_cd_detail,
            ci_configured: response.env_ci_detail,
            manual_build: response.manual_build,
            manual_deploy: response.manual_deploy,
            data_loading: false
        }));
    }
    const handleFailedApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }

    // useEffect(()=>{
    //     fetchCdData()
    // },[deploy_id])
    console.log(deploy_id, "fdskjanfjksa")
    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        console.log(data, "sdbvchsdbvhjbvhr")
        var filter_cd_versioning_data = data.filter(item => item.key == "VERSIONING_CD_ENABLE");
        var deploy_env_offline = data.filter(item => item.key == "DISABLE_DEPLOY_IF_ENV_OFFLINE");
        var disable_parallel_build = data.find(item => item.key === "DISABLE_PARALLEL_BUILD");
        var multiple_build_enabled = data.find(item => item.key === "MULTIPLE_BUILD_CONFIGURATION")
        setState(new_state => ({
            ...new_state,
            available_settings: data,
            cd_settings: filter_cd_versioning_data,
            deploy_env_offline_flag: deploy_env_offline,
            disable_parallel_build: disable_parallel_build,
            multiple_build_enabled: multiple_build_enabled?.value == "true" ? true : false,
            data_loading: false
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }



    function fetchCIData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCIDataSuccess, onFetchCIDataFail)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: true
        }));
    }

    function onFetchCIDataSuccess(response) {
        fetchSystemSettingsData()
        setState(new_state => ({
            ...new_state,
            ci_response: response,
            ci_data_loading: false
        }));

    }
    function onFetchCIDataFail(response) {
        setState(new_state => ({
            ...new_state,
            ci_response: null,
            ci_data_loading: false
        }));
    }

    function fetchLastParentCiData() {
        var requestInfo = {
            endPoint: GenerateURL({ component_id: service_id, env_id: env_id }, properties.api.last_parent_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchParentCIDataSuccess, onFetchParentCIDataFail)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: true
        }));
    }

    function onFetchParentCIDataSuccess(response) {
        console.log("jxcbvcxh", response)
        setState(new_state => ({
            ...new_state,
            ci_data_loading: false,
            build_details: response,
        }));
    }

    function onFetchParentCIDataFail(error) {
        setState(new_state => ({
            ...new_state,
            ci_data_loading: false
        }));
    }

    function fetchCdData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_cd),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onFetchCdDataSuccess, onFetchCdDataFail)
        setState(new_state => ({
            ...new_state,
            cd_data_loading: true
        }));
    }

    function onFetchCdDataSuccess(response) {
        if (response && response.length > 0) {
            setState(new_state => ({
                ...new_state,
                cd_response: response[0],
                cd_data_loading: false,
                all_deployment_list: response.map(item => { return { label: item.deployment_name, id: item.id } }),
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                cd_response: null,
                cd_data_loading: false
            }));
        }
    }

    function onFetchCdDataFail(response) {
        setState(new_state => ({
            ...new_state,
            cd_response: null,
            cd_data_loading: false
        }));
    }

    function toggleTriggerActionCard() {

        setState(fresh_state => ({
            ...fresh_state,
            action_card: !fresh_state.action_card,
            deploy_action_card: false,
            promote_action_card: false
        }));
    }
    function toggleTriggerPromoteActionCard(type) {
        setState(fresh_state => ({
            ...fresh_state,
            promote_action_card: !fresh_state.promote_action_card,
            action_card: false,
            deploy_action_card: false,
        }));
    }
    function toggleTriggerDeployActionCard(type) {
        setState(fresh_state => ({
            ...fresh_state,
            deploy_action_card: !fresh_state.deploy_action_card,
            promote_action_card: false,
            action_card: false,
        }));
    }
    function closeBox() {
        console.log("print statement")
        setState(new_state => ({
            ...new_state,
            action_card: null,
            deploy_action_card: null,
            promote_action_card: null,
        }));
    }
    function handleClose() {
        setState(new_state => ({
            ...new_state,
            trigger: null
        }));
    }

    // function handleOpenMonitoringDialog() {
    //     console.log("triggered")
    //     setOpenMonitoringDialog(true);
    // }

    // function handleCloseMonitoringDialog() {
    //     setOpenMonitoringDialog(false);
    // }


    var url_ci = GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_ci, true);
    const is_ci_permited = GetAuth().permission.POST.includes(url_ci);
    var url_cd = GenerateURL({ service_id: service_id, component_env_id: env_id }, properties.api.save_cd, true);

    const is_cd_permited = GetAuth().permission.POST.includes(url_cd);
    console.log(state, "url_cd")
    const styleForConf = ConfigureDiv();
    console.log("kdsjkdjkes", state);
    console.log(dynamicJobs, 'xl_op')
    // const isDynamicJobsExist = dynamicJobs?.length>0
    const isDynamicJobsExist = state?.configuredJobs?.length > 0;
    console.log(configuredJobs, 'xlm_ppp')
    useEffect(() => {

        if (dynamicJobs && configuredJobs) {
            let configuredJobsList = dynamicJobs?.filter(job => {
                if (configuredJobs[job?.job_code] && job?.dependent_entity == "SERVICE") {
                    return job
                }
            })

            setState(prevState => ({
                ...prevState,
                configuredJobs: configuredJobsList
            }))

        }
    }, [dynamicJobs, configuredJobs])

    console.log(dynamicJobs, configuredJobs, 'dyno_00pmns')

    return (
        <>
            {
                state.loaded ?
                    <>
                        <div className={styleForConf.container}>
                            {
                                state.data_loading ?
                                    <div className="ci-overview m-auto">
                                        <LoadingText />
                                    </div>
                                    :
                                    state.ci_configured ?
                                        // <CISection
                                        //     refresh={refresh}
                                        //     count={count}
                                        //     build_details={state.build_details}
                                        //     promote_details={state.promote_details}
                                        //     service_id={service_id}
                                        //     is_ci_permited={is_ci_permited}
                                        //     env_id={env_id}
                                        //     manual_build_flag={state.manual_build}
                                        //     show_build_card={toggleTriggerActionCard}
                                        //     service_data={service_data} />
                                        <CISectionNew
                                            isDynamicJobsExist={isDynamicJobsExist}
                                            refresh={refresh}
                                            count={count}
                                            build_details={state.build_details}
                                            promote_details={state.promote_details}
                                            service_id={service_id}
                                            is_ci_permited={is_ci_permited}
                                            env_id={env_id}
                                            manual_build_flag={state.manual_build}
                                            show_build_card={toggleTriggerActionCard}
                                            service_data={service_data} />
                                        :
                                        is_ci_permited ?

                                            <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                                <div className="heading">
                                                    <span className='ri-tools-line font-20 d-flex align-center color-key-78'></span>
                                                    <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure BUILD details</div>
                                                </div>
                                                <div className={styleForConf.tiggerCase}>
                                                    <div className="real-card">
                                                        <Link className="text-anchor-blue d-block text-center" to={"/application/" + application_id + "/service/" + service_id + "/detail?build_details=true&env_id=" + env_id}>
                                                            <button className="btn-primary conf-btn">
                                                                <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                                <span className='avtar font-family-v1'>Configure</span>
                                                            </button>
                                                        </Link>
                                                        {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500" style={{width: "75%"}}>
                                                        Please configure Build Details for this environment of the service!!
                                                    </p> */}

                                                    </div>
                                                </div>
                                            </div>


                                            :
                                            <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                                <div className="heading">
                                                    <span className='ri-tools-line font-20 d-flex align-center color-key-78'></span>
                                                    <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure BUILD details</div>
                                                </div>
                                                <div className={styleForConf.tiggerCase}>
                                                    <div className="real-card">
                                                        <div className='className="text-anchor-blue mb-15 d-flex align-center justify-center text-center"'>
                                                            <button className="btn-disable btn-primary cursor-not-allowed conf-btn">
                                                                <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                                <span className='avtar font-family-v1'>Configure</span>
                                                            </button>
                                                        </div>

                                                        {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500">
                                                        Build Details are not configured for this service. Kindly contact to project admin as you dont have permission to configure build details
                                                    </p> */}

                                                    </div>
                                                </div>
                                            </div>

                            }
                            <> {state.data_loading ?
                                <div className="ci-overview m-auto">
                                    <LoadingText />
                                </div>
                                :
                                state.cd_configured && state.cd_configured.length > 0 ?

                                    // <DeploySection
                                    //     deploy_details={state.deploy_details}
                                    //     uptime={props.uptime}
                                    //     restart={props.restart}
                                    //     show_deploy_card={() => { toggleTriggerDeployActionCard("DEPLOY") }}
                                    //     promote_details={state.promote_details}
                                    //     service_id={service_id}
                                    //     env_id={env_id}
                                    //     deploy_id={Number(deploy_id)}
                                    //     manual_deploy={state.manual_deploy}
                                    //     service_data={service_data} />
                                    <DeploySectionNew
                                        isDynamicJobsExist={isDynamicJobsExist}
                                        deploy_details={state.deploy_details}
                                        uptime={props.uptime}
                                        restart={props.restart}
                                        show_deploy_card={() => { toggleTriggerDeployActionCard("DEPLOY") }}
                                        promote_details={state.promote_details}
                                        service_id={service_id}
                                        env_id={env_id}
                                        deploy_id={Number(deploy_id)}
                                        manual_deploy={state.manual_deploy}
                                        service_data={service_data}
                                        deploymentSelector={props.deploymentSelector} />
                                    :
                                    is_cd_permited ?
                                        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                            <div className="heading">
                                                <span className='ri-rocket-2-line font-20 d-flex align-center color-key-78'></span>
                                                <div className='font-12 font-weight-600 color-icon-secondary avtar'>Configure DEPLOY details</div>
                                            </div>
                                            <div className={styleForConf.tiggerCase} >
                                                <div className="real-card">
                                                    <Link className="text-anchor-blue d-block text-center" to={"/application/" + application_id + "/service/" + service_id + "/detail?deploy_details=true&env_id=" + env_id} >
                                                        <button className="btn-primary conf-btn">
                                                            <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                            <span className='avtar font-family-v1'>Configure</span>
                                                        </button>
                                                    </Link>

                                                    {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500" style={{width: "75%"}}>
                                                    Please configure Deploy Details for this environment of the service!!
                                                </p> */}

                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={styleForConf.root}>
                                            <div className="heading">
                                                <span className='ri-rocket-2-line font-20 d-flex align-center color-key-78'></span>
                                                <div className='font-12 font-weight-600 color-icon-secondary'>Configure DEPLOY details</div>
                                            </div>
                                            <div className={styleForConf.tiggerCase}>
                                                <div className="real-card">
                                                    <div className='className="text-anchor-blue d-flex align-center justify-center text-center"'>
                                                        <button className="btn-disable btn-primary  cursor-not-allowed conf-btn">
                                                            <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                                            <span className='avtar font-family-v1'>Configure</span>
                                                        </button>
                                                    </div>

                                                    {/* <p className="font-12 mb-10 mt-10 text-center color-key-78 font-family-v1 font-weight-500">
                                                    Deploy Details are not configured for this service. Kindly contact to project admin as you dont have permission to configure deploy details
                                                </p> */}

                                                </div>
                                            </div>
                                        </div>

                            }</>

                            {
                                state?.configuredJobs?.length > 0 && state?.configuredJobs?.map((job, index) => {
                                    let job_code = job.job_code
                                    let metaInfo = { configured: configuredJobs && configuredJobs[job_code] }
                                    let isLastCard = dynamicJobs?.length - 1 == index
                                    return (
                                        <>
                                            {
                                                <JobCardServiceCardForDynamicJob
                                                    isLastCardastCard={isLastCard}
                                                    service_data={service_data}
                                                    wholeJob={job}
                                                    index={index}
                                                    setSnackBar={setState}
                                                    application_id={application_id}
                                                    selectedServiceEnv={selectedServiceEnv}
                                                    wideget_deployment_name={wideget_deployment_name}
                                                    masterLabel={masterLabel}
                                                    configuredJobs={configuredJobs}
                                                    component_env_id={env_id}
                                                    component_id={service_id}
                                                    sub_env_id={env_id}
                                                    showOnlyConfigured={true}
                                                    service_id={service_id}
                                                    env_id={env_id}
                                                    data={{ ...job, templateName: selectedTemplate?.name, ...metaInfo }} />
                                            }

                                        </>
                                    )
                                })
                            }

                        </div>
                        <div>
                            {
                                (state.ci_configured || state.cd_configured) ?
                                    <>
                                        <span className="" style={{ marginTop: '10px', display: "100%", justifyContent: "flex-end", width: "100%", marginLeft: "99%" }}>
                                            <div className='' style={{ display: "flex" }}>
                                                {/* {
                                                service_data.status &&
                                                <div className={classes.rootCard}>
                                                    <p>Status :
                                                        <span className={service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? "font-10 chip chip-yellow ml-5" : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? "font-10 chip chip-dark-grey ml-5" : "font-10 chip chip-green ml-5"}>
                                                            {service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? "IN DRAFT" : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? "APPROVAL_REQUESTED" : "IN USE"}
                                                        </span>
                                                    </p>
                                                    {service_data.status == "DRAFT" || service_data.status == "DRAFT_PENDING" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={service_data} sendForApproval={props.sendForApproval} /> </div> : service_data.status == "APPROVAL_REQUESTED_PENDING" || service_data.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={service_data} sendForApproval={props.sendForApproval} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} /> </div> : ""}
                                                </div>
                                            } */}
                                                <span className="btn-group width-fitcontent ml-auto" style={{ gap: "4px" }}> {/* btn-icon-group */}

                                                    {
                                                        state.manual_build ?
                                                            state.ci_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button>
                                                                :

                                                                <Tooltip title="Build">
                                                                    {/* <button className="btn btn-flaticon" onClick={toggleTriggerActionCard}><span className="flaticon-flash-on-indicator"></span></button> */}
                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={toggleTriggerActionCard}><span className="ri-flashlight-line vertical-align-super"></span></button>
                                                                </Tooltip>
                                                            : null}
                                                    {
                                                        state.manual_deploy ?
                                                            state.cd_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button> :

                                                                <Tooltip title="Deploy">
                                                                    {/* <button className="btn btn-flaticon" onClick={() => { toggleTriggerDeployActionCard("DEPLOY") }}> <span className="flaticon-upload-button"></span></button> */}
                                                                    <button className="btn-sq-icon-primary d-flex align-center" onClick={() => { toggleTriggerDeployActionCard("DEPLOY") }}><span className="ri-upload-2-line vertical-align-super"></span></button>
                                                                </Tooltip>
                                                            : null}

                                                    {
                                                        state.manual_build ?
                                                            state.ci_data_loading ?
                                                                <button className="btn btn-flaticon" >
                                                                    <div className='small-loading'></div></button>
                                                                :
                                                                <>
                                                                    <Tooltip title="Promote">
                                                                        {/* <button className="btn btn-flaticon" title="Promote" onClick={() => { toggleTriggerPromoteActionCard("PROMOTE") }} ><span className="flaticon-forward-arrow"></span></button> */}
                                                                        <button className="btn-sq-icon-primary d-flex align-center" onClick={() => { toggleTriggerPromoteActionCard("PROMOTE") }}><span className="ri-share-forward-line vertical-align-super"></span></button>
                                                                    </Tooltip>
                                                                </>
                                                            : null
                                                    }

                                                    <HistoryRevamp
                                                        service_id={service_id}
                                                        env_id={env_id}
                                                        service_data={service_data}
                                                        is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                        deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                        env_name={selectedServiceEnv.project_env.name}
                                                        masterLabel={masterLabel}
                                                        env_details={selectedServiceEnv}
                                                    />
                                                    <MonitoringDialogue
                                                        application_id={service_data.project_id}
                                                        type={state.action_card}
                                                        disable_parallel_build={state.disable_parallel_build ? state.disable_parallel_build : null}
                                                        multiple_build_enabled={state.multiple_build_enabled?.value == "true" ? true : false}
                                                        service_id={service_id}
                                                        env_id={env_id}
                                                        cd_id={wideget_deployment_name}    //{deploy_id}
                                                        all_available_deployments={all_available_deployments.length > 0 ? all_available_deployments : all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : []}
                                                        env_name={selectedServiceEnv.project_env.name}
                                                        service_data={{ name: service_data.name, type: masterLabel }}
                                                        env_details={selectedServiceEnv}
                                                    />
                                                    
                                                        <Tooltip title="Service Health">
                                                            {monitoringSectionBtn}
                                                        </Tooltip>
                                                       
                                                    
                                                </span>
                                                {

                                                    state.action_card ?

                                                        <TriggerActionCardNew
                                                            service_data={service_data}
                                                            configuration_data={state.ci_cd_data}
                                                            closebox={closeBox}
                                                            variant={"ServiceCard"}
                                                            is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                            deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                            cd_data={state.cd_response ? [state.cd_response] : null}
                                                            button_state={{ build_button: true, deploy_button: true }}
                                                            application_id={service_data.project_id}
                                                            type={state.action_card}
                                                            disable_parallel_build={state.disable_parallel_build ? state.disable_parallel_build : null}
                                                            service_id={service_id}
                                                            env_id={env_id}
                                                            ci_data={state.ci_response}
                                                            all_available_deployments={state.all_deployment_list && state.all_deployment_list.length > 0 ? state.all_deployment_list : []}
                                                            service_env_name={service_env_name}
                                                            build_action_card={state.action_card}
                                                            handleCloseDialog={closeBox}
                                                            service_env={selectedServiceEnv}
                                                        />
                                                        : null
                                                }
                                                {

                                                    state.deploy_action_card ?
                                                        <>
                                                            <DeployActionCardNew
                                                                service_data={service_data}
                                                                configuration_data={state.ci_cd_data}
                                                                closebox={closeBox}
                                                                cd_data={state.cd_response ? [state.cd_response] : null}
                                                                button_state={{ build_button: true, deploy_button: true }}
                                                                application_id={service_data.project_id}
                                                                type={state.action_card}
                                                                service_id={service_id}
                                                                env_id={env_id}
                                                                ci_data={state.ci_response}
                                                                env_name={selectedServiceEnv.project_env.name}
                                                                is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                                deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                                masterLabel={masterLabel}
                                                                env_details={selectedServiceEnv}
                                                                service_env_name={service_env_name}
                                                                handleCloseDialog={closeBox}
                                                                deploy_action_card={state.deploy_action_card}
                                                                all_available_deployments={all_available_deployments_without_widget && all_available_deployments_without_widget.length > 0 ? all_available_deployments_without_widget : []}
                                                            />
                                                        </>
                                                        : null
                                                }
                                                {
                                                    state.promote_action_card ?
                                                        <PromoteActionCard
                                                            service_data={service_data}
                                                            configuration_data={state.ci_cd_data}
                                                            closebox={closeBox}
                                                            cd_data={state.cd_response ? [state.cd_response] : null}
                                                            button_state={{ build_button: true, deploy_button: true }}
                                                            application_id={service_data.project_id}
                                                            type={state.action_card}
                                                            service_id={service_id}
                                                            env_id={env_id}
                                                            is_env_down={selectedServiceEnv?.project_env?.is_env_down}
                                                            deploy_env_offline_flag={state.deploy_env_offline_flag && state.deploy_env_offline_flag[0] ? state.deploy_env_offline_flag[0] : null}
                                                            ci_data={state.ci_response}
                                                            handleCloseDialog={closeBox}
                                                            all_available_deployments={all_available_deployments && all_available_deployments.length > 0 ?
                                                                all_available_deployments : []}
                                                            promote_action_card={state.promote_action_card}
                                                        /> : null
                                                }
                                            </div>

                                        </span>

                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={state.trigger}
                                            onClose={handleClose}
                                            autoHideDuration={3000}>
                                            <Alert severity="success">
                                                {"SUCCESSFULLY SCHEDULED"}
                                            </Alert>
                                        </Snackbar>

                                    </> : null
                            }

                        </div>

                    </>

                    : state.error ?
                        <p className="alert alert-primary">Error...</p> :
                        <>
                            <div class="card-loading" style={{ width: '100%', height: '206px' }}> <Loading varient="light" /> </div>
                        </>


            }
        </>

    );

}

const ConfigureDiv = makeStyles((theme) => ({
    container: {

        display: 'flex',
        width: "100%",
        overflow: 'auto',
        gap: '10px',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0',
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'none',
        },
        '&::-webkit-scrollbar-track': {
            display: 'none', /* For hiding scrollbar track */
        },
    },
    root: {
        width: '520px',
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            height: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        height: "100%",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            height: "100%",
            //background: "#FAFAFA",
            paddingTop: "10px",
            justifyContent: "center",
            '& .conf-btn': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "#0086FF",
                textTransform: "uppercase",
            }
        }
    }
}))


CICDSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

const useStyles = makeStyles((theme) => ({
    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
}))

const CISection = (props) => {
    var build_details = props.build_details;
    var promote_details = props.promote_details;
    const service_id = props.service_id ? props.service_id : null;
    const env_id = props.env_id ? props.env_id : null;
    console.log("build_detailsdds", service_id, env_id)
    const service_data = props.service_data;
    console.log("build_detailsdds", service_data)
    const refresh = props.refresh;
    var build_number = "N/A";
    var artifact = "N/A";
    var commit_sha = "N/A";
    var commit_msg = "N/A";
    var status = "N/A";
    var build_on = "N/A";
    var build_by = "N/A";
    var promoted_on = "N/A";
    var promoted_by = "N/A";

    const [state, setState] = useState({
        action_card: "",
    });


    useEffect(() => {
        setState({
            ...state,
            action_card: "",
        })

    }, [env_id]);




    if (build_details) {
        build_number = build_details.build_number;
        artifact = build_details.build_tag;
        commit_msg = build_details.commit_message;
        commit_sha = build_details.commit_sha;
        status = build_details.activity_status && build_details.activity_status.status;
        build_on = (new Date(build_details.activity_status && build_details.activity_status.start_time)).toString();
        build_by = build_details.build_by_pipeline ? "Pipeline " + build_details.build_by_pipeline.pipeline.name + ", " +
            build_details.build_by_pipeline.trigger_type.name + " by " + (build_details.build_by_pipeline.trigger_by ? build_details.build_by_pipeline.trigger_by : "System") :
            build_details.build_by_user ? build_details.build_by_user : "User Name";
    }

    if (promote_details) {
        var show_promote = false
        if (build_details) {
            if (new Date(promote_details.created_at) > new Date(build_details.created_at)) {
                build_details = null;
                var build_number = "N/A";
                var artifact = promote_details.current_image_tag;
                var commit_sha = "N/A";
                var commit_msg = "N/A";
                var status = "N/A";
                var build_on = "N/A";
                var build_by = "N/A";
                show_promote = true;
            } else {
                promote_details = null;
            }
        } else {
            show_promote = true;
        }
        if (show_promote) {
            promoted_on = (new Date(promote_details.created_at)).toString();
            promoted_by = promote_details.promote_by_pipeline ? "Pipeline " + promote_details.promote_by_pipeline.pipeline.name + ", " +
                promote_details.promote_by_pipeline.trigger_type.name + " by " + (promote_details.promote_by_pipeline.trigger_by ? promote_details.promote_by_pipeline.trigger_by : "System") :
                promote_details ? promote_details.promote_by_user ? promote_details.promote_by_user.name : "User Name" : "";
        }
    }

    function toggleTriggerActionCard(type) {
        switch (type) {
            default:
                setState(fresh_state => ({
                    ...fresh_state,
                    action_card: !fresh_state.action_card ? type : fresh_state.action_card == type ? "" : type,
                }));
        }
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }


    var url_ci = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_build_permited = GetAuth().permission.POST.includes(url_ci);
    return (

        <div className={build_details || promote_details ? "build-section font-family-v1 font-weight-600" : "build-section pd-0 m-auto"}>
            {
                build_details ?
                    <>
                        <span className="tab-label-inner">Build #{build_number} {status == "N/A" ? "" :
                            status == "FAILED" ?
                                <span className="status-chip status-chip-danger"><span className="flaticon-cancel-button"></span> Failed</span> :
                                status == "IN_QUEUE" ?
                                    <div className="status-chip status-chip-info"><span className="flaticon-circle-with-check-symbol"></span>In queue</div> :
                                    status == "RUNNING" ?
                                        <div className="status-chip status-chip-running "><span className="flaticon-circle-with-check-symbol"></span>Running</div> :
                                        status == "REVOKED" ?
                                            <div className="status-chip status-chip-approval"><span className="flaticon-circle-with-check-symbol"></span>Revoked</div> :
                                            <div className="status-chip status-chip-success"><span className="flaticon-circle-with-check-symbol"></span>{status == 'IN_QUEUE' ? 'IN QUEUE' : status}</div>
                        }
                        </span>
                        <div className="chip-section">
                            <p className='font-family-v1 font-12 font-weight-500 color-key'>Artifact</p>
                            <Tooltip title={artifact} placement="bottom">
                                <span className=" badge badge-pill badge-sq badge-artifact badge-ellipsis" >{artifact}</span>
                            </Tooltip>
                        </div>
                        <div className="line-height-one mb-5" style={{ marginTop: '10px' }}>
                            <span className="font-family-v1 font-12 font-weight-500 color-key">Commit ID: </span>
                            <div className="d-flex align-center color-value">
                                <Tooltip title={commit_sha} placement="bottom">
                                    <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_sha && commit_sha.substring(0, 30) + "..."} </span>
                                </Tooltip>
                                {commit_sha && <CopyToClipboard data={commit_sha} />}
                            </div>
                        </div>
                        <div className="line-height-one mb-5">
                            <span className="font-family-v1 font-12 font-weight-500 color-key">Commit Msg: </span>
                            <div className="d-flex">
                                <Tooltip title={commit_msg} placement="bottom">
                                    <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_msg}</span>
                                </Tooltip>
                                {
                                    commit_msg && <CopyToClipboard data={commit_msg} />
                                }

                            </div>
                        </div>
                        <div className="line-height-one mb-5">
                            <span className="font-family-v1 font-12 font-weight-500 color-key">Build on: </span>
                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{moment(build_on).fromNow()}</span>

                        </div>
                        <div className="line-height-one">
                            <span className="font-family-v1 font-12 font-weight-500 color-key">Build By: </span>

                            <span className="font-family-v1 font-12 font-weight-500 color-value">{build_by}</span>

                        </div>
                    </> :
                    promote_details ?
                        <div className="text-section font-family-v1 font-weight-500">
                            <div>
                                <div className="mb-5">
                                    <span className="font-family-v1 font-12 font-weight-500 color-key">Promoted On: </span>
                                    <Tooltip title={moment(promoted_on).fromNow()} placement="bottom">
                                        <span className="font-family-v1 font-12 font-weight-500 color-value">{moment(promoted_on).fromNow()}</span>
                                    </Tooltip>
                                </div>
                                <div className="chip-section ">
                                    <div className="font-family-v1 font-12 font-weight-500 color-key" style={{ marginBottom: '3px' }}>Artifact </div>
                                    {
                                        promote_details && promote_details.target_image_tag ?
                                            <Tooltip title={promote_details.target_image_tag} placement="bottom">
                                                <span className=" badge badge-pill badge-sq badge-artifact badge-ellipsis" style={{ color: '#fff' }} >{promote_details.target_image_tag}</span>
                                            </Tooltip>
                                            : <span className=" badge badge-pill badge-sq badge-artifact badge-ellipsis" >N/A</span>
                                    }

                                </div>
                                <div className="line-height-one mb-5" style={{ marginTop: '10px' }}>
                                    <span className="font-family-v1 font-12 font-weight-500 color-key">Commit ID: </span>
                                    {
                                        promote_details.commit_sha ?
                                            <div className="d-flex align-center">
                                                <Tooltip title={promote_details.commit_sha} placement="bottom">
                                                    <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_sha} </span>
                                                </Tooltip>
                                                {promote_details.commit_sha && <CopyToClipboard data={promote_details.commit_sha} />}
                                            </div> : <p className="font-11">N/A</p>
                                    }

                                </div>
                                <div className="line-height-one mb-5">
                                    <span className="color-dark">Commit Msg: </span>
                                    {
                                        promote_details.commit_message ?
                                            <div className="d-flex">
                                                <Tooltip title={promote_details.commit_message} placement="bottom">
                                                    <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_message}</span>
                                                </Tooltip>
                                                {
                                                    promote_details.commit_message && <CopyToClipboard data={promote_details.commit_message} />
                                                }

                                            </div> : <p className="font-11">N/A</p>
                                    }

                                </div>
                                <div>
                                    <span className="font-family-v1 font-12 font-weight-500 color-key">Promoted By: </span>
                                    {
                                        promote_details && promote_details.promote_by_user ?
                                            <Tooltip title={promote_details.promote_by_user} placement="bottom">
                                                <span title={promote_details.promote_by_user} className="font-family-v1 font-12 font-weight-500 color-value">{promote_details.promote_by_user}</span>
                                            </Tooltip> : "N/A"

                                    }

                                </div>
                            </div>
                        </div> :
                        props.manual_build_flag && is_build_permited ?
                            <div className=" card-ser m-auto " style={{ border: '3px dashed #c4c4c4', backgroundColor: '#fbfbfb' }}>
                                <div className="card-body pd-20">
                                    <button className="btn btn-primary-v2 lh-1 mb-15 m-auto d-block text-center" onClick={props.show_build_card}><span className="flaticon-flash-on-indicator"></span>Trigger first Build</button>
                                    <p className="font-12 mb-10 text-center text-grey-72 font-family-v1 font-weight-500">
                                        Build Details are configured!! Go ahead and trigger build for this environment!!
                                    </p>

                                </div>
                            </div> :

                            <div className=" card-ser m-auto " style={{ border: '3px dashed #c4c4c4', backgroundColor: '#fbfbfb' }}>
                                <div className="card-body pd-20">
                                    <button className="btn btn-primary-v2 btn-disable  cursor-not-allowed lh-1 mb-15 m-auto d-block text-center">
                                        <span className="flaticon-flash-on-indicator"></span> Trigger first Build
                                    </button>
                                    <p className="font-12 mb-10 text-center text-grey-72 font-family-v1 font-weight-500">Build Details are configured!! but it seems you don't have permission to trigger build. please contact to your project admin.</p>

                                </div>
                            </div>
            }

        </div>

    );
}

const CISectionNew = ({ isDynamicJobsExist, ...props }) => {
    var build_details = props.build_details;
    var promote_details = props.promote_details;
    const service_id = props.service_id ? props.service_id : null;
    const env_id = props.env_id ? props.env_id : null;
    const service_data = props.service_data;
    console.log("build_detailsdds", service_data, env_id, build_details)
    const selectedEnvName = service_data.component_env.find((envObj) => envObj.id == env_id)
    console.log("build_detailsdds", build_details,promote_details)
    const refresh = props.refresh;
    var build_number = "N/A";
    var artifact = "N/A";
    var commit_sha = "N/A";
    var commit_msg = "N/A";
    var status = "N/A";
    var build_on = "N/A";
    var build_by = "N/A";
    var promoted_on = "N/A";
    var promoted_by = "N/A";

    console.log(build_details, "sdhbfhdsbhbvshd")

    const [state, setState] = useState({
        action_card: "",
    });
    const classes = useStylesForCI();

    useEffect(() => {
        setState({
            ...state,
            action_card: "",
        })

    }, [env_id]);




    if (build_details) {
        build_number = build_details.build_number;
        artifact = build_details.build_tag;
        commit_msg = build_details.commit_message;
        commit_sha = build_details.commit_sha;
        status = build_details.activity_status && build_details.activity_status.status;
        build_on = (new Date(build_details.activity_status && build_details.activity_status.start_time)).toString();
        build_by = build_details.build_by_pipeline ? "Pipeline " + build_details.build_by_pipeline.pipeline?.name + ", " +
            build_details.build_by_pipeline.trigger_type?.name + " by " + (build_details.build_by_pipeline.trigger_by ? build_details.build_by_pipeline.trigger_by : "System") :
            build_details.build_by_user ? build_details.build_by_user : "User Name";
    }

    if (promote_details) {
        var show_promote = false
        if (build_details) {
            if (new Date(promote_details.created_at) > new Date(build_details.created_at)) {
                build_details = null;
                var build_number = "N/A";
                var artifact = promote_details.current_image_tag;
                var commit_sha = "N/A";
                var commit_msg = "N/A";
                var status = "N/A";
                var build_on = "N/A";
                var build_by = "N/A";
                show_promote = true;
            } else {
                promote_details = null;
            }
        } else {
            show_promote = true;
        }
        if (show_promote) {
            promoted_on = (new Date(promote_details.created_at)).toString();
            promoted_by = promote_details.promote_by_pipeline ? "Pipeline " + promote_details.promote_by_pipeline.pipeline.name + ", " +
                promote_details.promote_by_pipeline.trigger_type.name + " by " + (promote_details.promote_by_pipeline.trigger_by ? promote_details.promote_by_pipeline.trigger_by : "System") :
                promote_details ? promote_details.promote_by_user ? promote_details.promote_by_user.name : "User Name" : "";
        }
    }

    function toggleTriggerActionCard(type) {
        switch (type) {
            default:
                setState(fresh_state => ({
                    ...fresh_state,
                    action_card: !fresh_state.action_card ? type : fresh_state.action_card == type ? "" : type,
                }));
        }
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }


    var url_ci = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_build_permited = GetAuth().permission.POST.includes(url_ci);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickForCI = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForCI = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    console.log("ndjndfjs", promote_details, build_details);
    return (
        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={classes.root}>
            <div className="heading">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.44153 2.72433C4.99739 2.52599 5.60035 2.50094 6.17075 2.6525C6.74115 2.80407 7.25214 3.12511 7.63623 3.57321C8.02033 4.02132 8.25944 4.57541 8.32198 5.16227C8.38452 5.74914 8.26755 6.34118 7.98653 6.86017L16.9107 15.7852L15.7324 16.9635L6.80736 8.0385C6.28825 8.31842 5.69652 8.43449 5.11014 8.37142C4.52375 8.30834 3.97024 8.06909 3.52254 7.68517C3.07483 7.30126 2.75396 6.79071 2.60219 6.22081C2.45042 5.6509 2.47489 5.04839 2.67236 4.49267L4.53653 6.35683C4.65183 6.47622 4.78977 6.57145 4.94227 6.63696C5.09478 6.70247 5.2588 6.73695 5.42477 6.73839C5.59075 6.73984 5.75535 6.70821 5.90897 6.64536C6.06259 6.58251 6.20215 6.48969 6.31952 6.37233C6.43689 6.25496 6.5297 6.1154 6.59255 5.96177C6.6554 5.80815 6.68703 5.64355 6.68559 5.47758C6.68415 5.31161 6.64966 5.14758 6.58415 4.99508C6.51864 4.84257 6.42341 4.70464 6.30403 4.58933L4.44069 2.7235L4.44153 2.72433ZM13.0807 4.29433L15.7324 2.821L16.9107 3.99933L15.4374 6.651L13.964 6.946L12.1974 8.7135L11.0182 7.53517L12.7857 5.76767L13.0807 4.29433ZM7.48236 11.071L8.66069 12.2493L4.24153 16.6685C4.09128 16.8192 3.88908 16.9067 3.67638 16.913C3.46369 16.9194 3.25662 16.8442 3.09762 16.7028C2.93862 16.5613 2.83975 16.3645 2.82126 16.1525C2.80277 15.9405 2.86608 15.7295 2.99819 15.5627L3.06319 15.4902L7.48236 11.071Z" fill="#787878" />
                </svg>
                <div className='font-12 font-weight-600 color-icon-secondary avtar'>{build_details ? "BUILD STATUS" : promote_details ? "BUILD STATUS" : "Trigger first build"}</div>
            </div>
            {/*build_details*/}
            {/* promote_details */}
            {build_details ?
                <>
                    <div className='info-box'>
                        <div className='top-part'>
                            <div className='d-flex align-center'>
                                <Link
                                    className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                                    to={
                                        "/logs?global_task_id=" +
                                        build_details.global_task_id +
                                        "&tab_id=" +
                                        build_details.env_ci_detail_id +
                                        "&service_name=" +
                                        service_data.name +
                                        "&service_env=" +
                                        selectedEnvName.project_env.name +
                                        "&tab_name=BUILD" +
                                        "&num=" +
                                        build_details.build_number
                                    }
                                    target="_blank"
                                >
                                    <span className="font-14 font-weight-600" style={{ color: "#0086FF" }}>Build #{build_number} </span>
                                </Link>

                                {status == "N/A" ? "" :
                                    status == "FAILED" ?
                                        <span className="status-chip-new font-12" style={{ background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }} >Failed</span> :
                                        status == "IN_QUEUE" ?
                                            <div className="status-chip-new font-12" style={{ background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }}>In queue</div> :
                                            status == "RUNNING" ?
                                                <div className="status-chip-new font-12" style={{ background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }}>Running</div> :
                                                status == "REVOKED" ?
                                                    <span className="status-chip-new font-12" style={{ background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }} >Revoked</span> :
                                                    <span className="status-chip-new font-12" style={{ background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }}>{status == 'IN_QUEUE' ? 'IN QUEUE' : status}</span>
                                }

                            </div>
                            <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878" />
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{moment(build_on).fromNow()}</span>
                            </div>
                        </div>
                        <div className='bottom-part'>
                            <div className='bottom-info'>
                                <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                <div className='d-flex align-center'>
                                    <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{artifact}</span>
                                    {artifact && <CopyToClipboard data={artifact} />}
                                </div>
                            </div>
                            <div>
                                <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCI}>more Details</span>
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseForCI}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { overflow: 'visible' } }}
                            >
                                <div className={classes.popCI}>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Commit msg: &nbsp;</span>
                                        <Tooltip title={commit_msg ? commit_msg : "NA"} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_msg ? commit_msg : "N/A"}</span>
                                        </Tooltip>
                                        {
                                            commit_msg && <CopyToClipboard data={commit_msg} />
                                        }
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Commit ID: &nbsp;</span>
                                        <Tooltip title={commit_sha ? commit_sha : "N/A"} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_sha ? commit_sha.substring(0, 30) + "..." : "N/A"} </span>
                                        </Tooltip>
                                        {commit_sha && <CopyToClipboard data={commit_sha} />}
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Build by: &nbsp;</span>
                                        <span className="font-family-v1 font-12 font-weight-500 color-value">{build_by ? build_by : "N/A"}</span>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </>

                :

                promote_details ?
                    <>
                        <div className='info-box'>
                            <div className='top-part'>
                                <div className='d-flex align-center'>
                                    <span className="font-14 font-weight-600 color-value">Promoted on: &nbsp;
                                        <span className="font-family-v1 font-12 font-weight-500 color-value">{moment(promoted_on).fromNow()}</span>
                                    </span>
                                </div>
                                {/* <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878"/>
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{moment(build_on).fromNow()}</span>
                            </div> */}
                            </div>
                            <div className='bottom-part'>
                                <div className='bottom-info'>
                                    <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                    <div className='d-flex align-center'>
                                        {
                                            promote_details && promote_details.target_image_tag ?
                                                <Tooltip title={promote_details.target_image_tag} placement="bottom">
                                                    <span className='d-flex align-center' style={{ gap: "4px" }}>
                                                        <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{promote_details.target_image_tag}</span>
                                                        <CopyToClipboard data={promote_details.target_image_tag} />
                                                    </span>

                                                </Tooltip>
                                                : <span className="font-12 font-weight-600 color-icon-secondary mr-4" >N/A</span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCI}>more Details</span>
                                </div>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseForCI}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <div className={classes.popCI}>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Commit msg: &nbsp;</span>

                                            {
                                                promote_details?.commit_message ?
                                                    <div className="d-flex">
                                                        <Tooltip title={promote_details?.commit_message} placement="bottom">
                                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_message}</span>
                                                        </Tooltip>
                                                        {
                                                            promote_details.commit_message && <CopyToClipboard data={promote_details.commit_message} />
                                                        }

                                                    </div> : <span className="font-11">N/A</span>
                                            }
                                        </div>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Commit ID: &nbsp;</span>
                                            {/* <Tooltip title={commit_sha} placement="bottom">
                                        <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_sha && commit_sha.substring(0, 30) + "..."} </span>
                                    </Tooltip>
                                    {commit_sha && <CopyToClipboard data={commit_sha} />} */}
                                            {
                                                promote_details?.commit_sha ?
                                                    <div className="d-flex align-center">
                                                        <Tooltip title={promote_details?.commit_sha} placement="bottom">
                                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_sha} </span>
                                                        </Tooltip>
                                                        {promote_details.commit_sha && <CopyToClipboard data={promote_details.commit_sha} />}
                                                    </div> : <span className="font-11">N/A</span>
                                            }
                                        </div>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Promoted By: &nbsp;</span>
                                            {
                                                promote_details && promote_details?.promote_by_user ?
                                                    <Tooltip title={promote_details?.promote_by_user} placement="bottom">
                                                        <span title={promote_details.promote_by_user} className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.promote_by_user}</span>
                                                    </Tooltip> : <span className="font-11">N/A</span>

                                            }
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </>
                    :
                    props.manual_build_flag && is_build_permited ?
                        <div className={classes.tiggerCase} >
                            <div className="real-card">
                                <button className="btn-primary" onClick={props.show_build_card}>
                                    <span className="ri-flashlight-line font-weight-500 font-20"></span>
                                    <span className='font-family-v1'> Trigger</span>
                                </button>

                                {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500">
                                            Build Details are configured!! Go ahead and trigger build for this environment!!
                                        </p> */}

                            </div>
                        </div>
                        :

                        <div className={classes.tiggerCase}>
                            <div className='real-card'>
                                <button className="btn-primary btn-disable  cursor-not-allowed">
                                    <span className="ri-flashlight-line font-weight-500 font-20"></span>
                                    <span className='font-family-v1'>Trigger</span>
                                </button>

                                {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500">
                                                Build Details are configured!! but it seems you don't have permission to trigger build. please contact to your project admin.
                                            </p> */}
                            </div>

                        </div>

            }

        </div>
    )

}

const useStylesForCI = makeStyles((theme) => ({
    root: {
        width: "520px",
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flexDirection: "column",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            minHeight: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
        '& .info-box': {
            display: "flex",
            width: "100%",
            padding: "8px 16px 16px 16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            '& .top-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                '& .status-chip-new': {
                    display: "flex",
                    padding: "4px 6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    fontWeight: "700",
                    borderRadius: "4px",
                    //fontSize: "12px",
                    marginLeft: "12px",
                    textTransform: "uppercase",
                }
            },
            '& .bottom-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                alignSelf: "stretch",
                '& .bottom-info': {
                    display: "flex",
                    flexDirection: "column",
                    width: "285px",
                    //alignItems: "center",
                    //gap: "16px",
                },
                '& .more-btn': {
                    textTransform: "uppercase",
                    color: "#0086FF",
                    cursor: "pointer",
                    '&:hover': {
                        textDecoration: "underline",
                    }
                }
            },
        }
    },
    popCI: {
        display: "inline-flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        height: "100%",
        alignSelf: "stretch",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            alignSelf: "stretch",
            height: "100%",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            //background: "#FAFAFA",
            paddingTop: "10px",
            '& button': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "##0086FF",
                textTransform: "uppercase",
            },
            '& p': {
                width: "85%",
            }
        }
    }
}))


const DeploySectionNew = ({ isDynamicJobsExist, ...props }) => {
    const deploy_details = props.deploy_details;
    var promote_details = props.promote_details;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const deploy_id = props.deploy_id
    console.log(deploy_id, service_data, deploy_details, env_id, "deploy_iddeploy_id")
    var number = "N/A";
    var artifact = "N/A";
    var status = "N/A";
    var rollout_status = "N/A";
    var manifest_applied = "N/A"
    var deploy_on = "N/A";
    var deploy_by = "N/A";
    var trigger_type = null;
    var trigger_by = null;
    var uptime = props.uptime ? moment(props.uptime).fromNow() : "N/A";
    var restart = props.restart ? props.restart : "N/A";
    let deploymentSelector = props.deploymentSelector ? props.deploymentSelector : null;
    const selectedEnvName = service_data.component_env.find((envObj) => envObj.id == env_id)
    const [state, setState] = useState({
        action_card: "",
    });
    const params = useParams();

    const classes = useStylesForCD();
    useEffect(() => {
        setState({
            ...state,
            action_card: "",
        })
    }, []);
    if (deploy_details) {
        number = deploy_details.deploy_number;
        artifact = deploy_details.deploy_tag;
        rollout_status = deploy_details.deployment_status ?
            deploy_details.deployment_status : "FAILED";
        status = deploy_details.activity_status && deploy_details.activity_status.status ?
            deploy_details.activity_status.status : "N/A";
        manifest_applied = deploy_details.activity_status && deploy_details.activity_status.status ?
            deploy_details.activity_status.status : null;
        // deployment_status = deploy_details.deployment_status?deploy_details.deployment_status: null
        deploy_on = deploy_details.activity_status && deploy_details.activity_status.start_time ? moment(deploy_details.activity_status.start_time).fromNow() : "N/A"
        // deploy_details.deploy_by_pipeline && deploy_details.deploy_by_pipeline.pipeline.name ? "Pipeline " + deploy_details.deploy_by_pipeline.pipeline.name + ", " +
        //     deploy_details.deploy_by_pipeline.trigger_type.name + " by " + (deploy_details.deploy_by_pipeline.trigger_by ? deploy_details.deploy_by_pipeline.trigger_by : "System") :
        deploy_by = deploy_details.deploy_by_user ? deploy_details.deploy_by_user : "User Name";
        if (deploy_details.deploy_by_pipeline) {
            trigger_type = deploy_details.deploy_by_pipeline && deploy_details.deploy_by_pipeline.pipeline.name ? "Pipeline - " + deploy_details.deploy_by_pipeline.pipeline.name + " / " + deploy_details.deploy_by_pipeline.trigger_type.name : "N/A";
            trigger_by = deploy_details.deploy_by_pipeline.trigger_by ? deploy_details.deploy_by_pipeline.trigger_by : "System";
        }

    }
    function getStyleForStatus(status) {
        switch (status) {
            case "RUNNING":
                return { background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }
            case "IN_QUEUE":
                return { background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }
            case "FAILED":
                return { background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }
            case "SUCCESS":
                return { background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }
            case "REVOKED":
                return { background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }
            default:
                return { background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }
        }
    }
    function getDeployStatus(deploy_details) {
        let statusObj = {};
        if (!deploy_details) {
            return null
        }
        if (deploy_details?.activity_status?.status == "REVOKED") {
            statusObj.status = "REVOKED";
            statusObj.style = getStyleForStatus(deploy_details.activity_status.status);
            return statusObj;
        }
        if (deploy_details?.activity_status?.status == "IN_QUEUE") {
            statusObj.status = "IN QUEUE";
            statusObj.style = getStyleForStatus(deploy_details.activity_status.status);
            return statusObj;
        }

        let manifest_applied = deploy_details?.activity_status?.sub_task_status ? deploy_details?.activity_status?.sub_task_status.find((obj) => obj.activity_sub_task_code == "K8S_DEPLOYMENT_MANIFEST_APPLY") : null;
        manifest_applied = manifest_applied?.status;
        if (manifest_applied) {
            if (manifest_applied == "RUNNING") {
                statusObj.status = "Applying Manifest";
                statusObj.style = getStyleForStatus(manifest_applied);
                return statusObj
            } else if (manifest_applied == "FAILED") {
                statusObj.status = "Manifest Apply Failed";
                statusObj.style = getStyleForStatus(manifest_applied);
                return statusObj
            } else {
                if (deploy_details.deployment_status) {
                    if (deploy_details.deployment_status == "RUNNING") {
                        statusObj.status = "Rollout In Progress";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    } else if (deploy_details.deployment_status == "FAILED") {
                        statusObj.status = "FAILED";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    } else {
                        statusObj.status = "SUCCESS";
                        statusObj.style = getStyleForStatus(deploy_details.deployment_status);
                        return statusObj
                    }
                } else {
                    statusObj.status = "Manifest Applied";
                    statusObj.style = getStyleForStatus(manifest_applied);
                    return statusObj
                }
            }
        } else {
            return null;
        }

    }
    function toggleTriggerActionCard(type) {
        switch (type) {
            default:
                setState(fresh_state => ({
                    ...fresh_state,
                    action_card: !fresh_state.action_card ? type : fresh_state.action_card == type ? "" : type,
                }));
        }
    }
    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }
    const no_deployment_triggered = false
    var url_cd = GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id: deploy_id }, properties.api.save_cd, true);
    const is_deploy_permited = GetAuth().permission.POST.includes(url_cd);
    console.log("ispermited-cd", url_cd, is_deploy_permited)
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickForCD = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForCD = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    let final_deployment_status = getDeployStatus(deploy_details);
    console.log("dkfnksnd", final_deployment_status);
    return (
        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={classes.root}>
            <div className="heading">

                <div className='font-12 font-weight-600 color-icon-secondary d-flex align-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.919 16.6651C12.3677 17.9837 11.3193 19.0321 10.0007 19.5834C8.68205 19.0321 7.63362 17.9837 7.08232 16.6651H8.96565C9.23732 17.0726 9.58815 17.4251 10.0007 17.7009C10.4132 17.4259 10.764 17.0726 11.0365 16.6651H12.919ZM15.0007 12.3359L16.6673 14.2259V15.8318H3.33398V14.2259L5.00065 12.3359L5.00065 7.49844C5.00065 4.59594 7.08732 2.12594 10.0007 1.21094C12.914 2.12594 15.0007 4.59594 15.0007 7.49844V12.3359ZM14.3923 14.1651L13.334 12.9651V7.49844C13.334 5.56677 12.0257 3.80677 10.0007 2.98177C7.97565 3.80677 6.66732 5.56594 6.66732 7.49844L6.66732 12.9651L5.60898 14.1651H14.3923ZM10.0007 9.1651C9.55862 9.1651 9.1347 8.98951 8.82214 8.67695C8.50958 8.36439 8.33398 7.94047 8.33398 7.49844C8.33398 7.05641 8.50958 6.63249 8.82214 6.31993C9.1347 6.00737 9.55862 5.83177 10.0007 5.83177C10.4427 5.83177 10.8666 6.00737 11.1792 6.31993C11.4917 6.63249 11.6673 7.05641 11.6673 7.49844C11.6673 7.94047 11.4917 8.36439 11.1792 8.67695C10.8666 8.98951 10.4427 9.1651 10.0007 9.1651Z" fill="#787878" />
                    </svg>
                    <span className='ml-4 avtar'>{deploy_details ? "DEPLOY STATUS" : "Trigger first deployment"}</span></div>
                {deploymentSelector}
            </div>
            {/*build_details*/}
            {/* promote_details */}
            {deploy_details ?

                <>
                    <div className='info-box'>
                        <div className='top-part'>
                            <div className='d-flex align-center'>
                                <Link
                                    to={"/logs?global_task_id=" + deploy_details.global_task_id +
                                        "&tab_id=" + deploy_id +
                                        "&service_name=" + service_data.name +
                                        "&service_env=" + selectedEnvName.project_env.name +
                                        "&tab_name=DEPLOY" + "&num=" + deploy_details.deploy_number +
                                        "&service_id=" + service_id +
                                        "&env_id=" + env_id +
                                        "&cd_id=" + deploy_id
                                    }

                                    target="_blank">
                                    <span className="font-14 font-weight-600" style={{ color: "#0086FF" }}>Deploy #{number} </span>
                                </Link>

                                {/* {manifest_applied == "N/A" ? "" :
                                            manifest_applied == "FAILED" ?
                                            <span className="status-chip-new font-12" style={{background: "#FFF3F3",color:"#BA0000",border: "1px solid #FBE6E6"}} >Failed</span> :
                                            manifest_applied == "IN_QUEUE" ?
                                                <div className="status-chip-new font-12" style={{background: "#FCF6E1",color:"#FEA111",border: "1px solid #FCF6E1"}}>In queue</div> :
                                                manifest_applied == "RUNNING" ?
                                                    <div className="status-chip-new font-12" style={{background: "#F5FAFF",color:"#0086FF",border: "1px solid #DFEDFF"}}>Running</div> :
                                                    manifest_applied == "REVOKED" ?
                                                        <span className="status-chip-new font-12" style={{background: "#F4F4F4",color:"#626262",border: "1px solid #E6E6E6"}} >Revoked</span> :
                                                        <span className="status-chip-new font-12" style={{background: "#EFFFF3",color:"#129E5B",border: "1px solid #DFEDFF"}}>{manifest_applied == 'IN_QUEUE' ? 'IN QUEUE' : manifest_applied}</span>
                                } */}
                                {final_deployment_status ?

                                    <span className="status-chip-new font-12" style={final_deployment_status.style} >{final_deployment_status.status}</span>

                                    :
                                    manifest_applied == "N/A" ? "" :
                                        manifest_applied == "FAILED" ?
                                            <span className="status-chip-new font-12" style={{ background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }} >Failed</span> :
                                            manifest_applied == "IN_QUEUE" ?
                                                <div className="status-chip-new font-12" style={{ background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }}>In queue</div> :
                                                manifest_applied == "RUNNING" ?
                                                    <div className="status-chip-new font-12" style={{ background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }}>Running</div> :
                                                    manifest_applied == "REVOKED" ?
                                                        <span className="status-chip-new font-12" style={{ background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }} >Revoked</span> :
                                                        <span className="status-chip-new font-12" style={{ background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }}>{manifest_applied == 'IN_QUEUE' ? 'IN QUEUE' : manifest_applied}</span>

                                }

                            </div>
                            <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878" />
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{deploy_on}</span>
                            </div>
                        </div>
                        <div className='bottom-part'>
                            <div className='bottom-info'>
                                <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                <div className='d-flex align-center'>
                                    <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{artifact}</span>
                                    {artifact && <CopyToClipboard data={artifact} />}
                                    {/* {manifest_applied == "N/A" ? "" :
                                            manifest_applied == "FAILED" ?
                                                <span className="status-chip-new" style={{background: "#FFF3F3",color:"#BA0000",border: "1px solid #FBE6E6"}}>{manifest_applied}</span> : manifest_applied == "IN_QUEUE" ?
                                                    <span className="status-chip-new" style={{background: "#FCF6E1",color:"#FEA111",border: "1px solid #FCF6E1"}}>{manifest_applied}</span> :
                                                    manifest_applied == "RUNNING" ?
                                                        <span className="status-chip-new" style={{background: "#F5FAFF",color:"#0086FF",border: "1px solid #DFEDFF"}}>{manifest_applied}</span> :
                                                        <span className="status-chip-new" style={{background: "#EFFFF3",color:"#129E5B",border: "1px solid #DFEDFF"}}>{manifest_applied}</span>
                                } */}
                                </div>
                            </div>
                            <div>
                                <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCD}>more Details</span>
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseForCD}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { overflow: 'visible' } }}
                            >
                                <div className={classes.popCI}>
                                    {/* <div className='d-flex align-center'>
                                    <span className='font-12 font-weight-500 color-key-78 '>Artifact: &nbsp;</span>
                                    <Tooltip title={artifact} placement="bottom">
                                        <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{artifact}</span>
                                    </Tooltip>
                                    {
                                        artifact && <CopyToClipboard data={artifact} />
                                    }
                                </div> */}
                                    {deploy_details.deploy_by_pipeline ?
                                        <>
                                            <div className='d-flex align-center'>
                                                <span className='font-12 font-weight-500 color-key-78 '>Trigger Type: &nbsp;</span>
                                                <span className="font-family-v1 font-12 font-weight-500 color-value">{trigger_type}</span>
                                            </div>
                                            <div className='d-flex align-center'>
                                                <span className='font-12 font-weight-500 color-key-78 '>Triggered By: &nbsp;</span>
                                                <span className="font-family-v1 font-12 font-weight-500 color-value">{trigger_by}</span>
                                            </div>
                                        </>
                                        :
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Deployed By: &nbsp;</span>
                                            <span className="font-family-v1 font-12 font-weight-500 color-value">{deploy_by}</span>
                                        </div>
                                    }

                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Restart: &nbsp;</span>
                                        <Tooltip title={restart} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{restart} </span>
                                        </Tooltip>
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Uptime: &nbsp;</span>
                                        <Tooltip title={restart} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{uptime} </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </>

                :
                props.manual_deploy && is_deploy_permited ?
                    <div className={classes.tiggerCase}>
                        <div className="real-card">
                            <button className="btn-primary" onClick={props.show_deploy_card}>
                                <span className="ri-upload-2-line font-weight-500 font-20"></span>
                                <span className='font-family-v1'>Trigger</span>
                            </button>
                            {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500">
                        Deploy Details are configured!! Go ahead and trigger deployment for this environment!!
                    </p> */}
                        </div>
                    </div> :
                    <div className={classes.tiggerCase} >
                        <div className="real-card">
                            <button className="btn-primary btn-disable  cursor-not-allowed" >
                                <span className="ri-upload-2-line font-weight-500 font-20"></span>
                                <span className='font-family-v1'>Trigger</span>
                            </button>
                            {/* <p className="font-12 mb-10 text-center color-key-78 font-family-v1 font-weight-500">
                        Deploy Details are configured!! but it seems you don't have permission to trigger deployment. please contact to project admin.
                    </p> */}
                        </div>
                    </div>
            }

        </div>
    )
}

const useStylesForCD = makeStyles((theme) => ({
    root: {
        width: "520px",
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            minHeight: "48px",
            padding: "12px 8px 12px 16px",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
        },
        '& .info-box': {
            display: "flex",
            width: "100%",
            padding: "8px 16px 16px 16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            '& .top-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                '& .status-chip-new': {
                    display: "flex",
                    fontSize: "12px",
                    padding: "4px 6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "4px",
                    background: "#EFFFF3",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    marginLeft: "12px",
                },

            },
            '& .bottom-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                alignSelf: "stretch",
                '& .bottom-info': {
                    display: "flex",
                    flexDirection: "column",
                    width: "285px",
                    //alignItems: "center",
                    //gap: "16px",
                },
                '& .more-btn': {
                    textTransform: "uppercase",
                    color: "#0086FF",
                    cursor: "pointer",
                    '&:hover': {
                        textDecoration: "underline",
                    }
                }
            },
        }
    },
    popCI: {
        display: "inline-flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        paddingTop: "0px",
        height: "100%",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            //background: "#FAFAFA",
            paddingTop: "10px",
            height: "100%",
            justifyContent: "center",
            '& button': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "##0086FF",
                textTransform: "uppercase",
            },
            '& p': {
                width: "85%",
            }
        }
    }
}))


const MonitoringSection = (props) => {
    const [state, setState] = useState({
        loaded: false,
        deployment_list: [],
    });
    const count = props.count;
    const deploy_id = props.deploy_id ? props.deploy_id : null
    console.log(deploy_id, "fdskajfjksaj")
    const cd_callback = props.callback;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const showMonitoring = props.showMonitoring
    const handleCloseMonitoring = props.handleCloseMonitoring;

    const classes = useStylesForMonitoringDialog();
    useEffect(() => {
        if (state.deployment_list.length > 0 && deploy_id) {
            let find_current_deployment = state.deployment_list.find(item => item.deployment_name == deploy_id)
            console.log(state.deployment_list, "fdskajfjksaj")
            console.log(find_current_deployment, "find_current_deployment")
            var monitoring = EnvMonitoringDataParser(find_current_deployment);

            if (monitoring) {
                cd_callback(monitoring.service_data);
            }
            setState({
                ...state,
                loaded: true,
                monitoring: monitoring,
                health: find_current_deployment ? find_current_deployment.monitoring && find_current_deployment.monitoring.infra ? find_current_deployment.monitoring.infra : null : null
            });
        }
        console.log("ncjncjds", state.monitoring);
    }, [deploy_id, state.deployment_list, env_id])

    // Managing API Calls
    const handleSuccessApiHit = (response) => {
        // let find_current_deployment = response.find(item => item.id == deploy_id)

        // var monitoring = EnvMonitoringDataParser(find_current_deployment);

        // if (monitoring) {
        //     cd_callback(monitoring.service_data);
        // }
        setState({
            ...state,
            loaded: true,
            deployment_list: response
            // monitoring: monitoring,
            // health: find_current_deployment.monitoring.infra ? find_current_deployment.monitoring.infra : null
        });
    }
    const handleFailedApiHit = (error) => {
        setState({
            ...state,
            error: error,
        })
    }


    useEffect(() => {
        const placeholders = {
            application_id: application_id,
            service_id: service_id,
            env_id: env_id
        }
        var requestInfo = {
            endPoint: GenerateURL(placeholders, properties.api.env_monitoring_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        SubscribeToApi(GenerateURL(placeholders, properties.api.env_monitoring_data_url));
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState({
            ...state,
            loaded: false
        });
    }, [env_id, count, deploy_id]);

    function resolveReplicationStatus(current, max) {
        if (!max) {
            return "disabled";
        }
        const result = Math.round((current / max) * 100);
        if (result > 80) {
            return "danger";
        }
        if (result > 60) {
            return "warning";
        }
        return "success";
    }

    function getSection(monitoring) {
        console.log("jijtiijew", monitoring, state);
        switch (monitoring.status) {
            case "NOT_WORKING":
                return (
                    <div className='width-full'>
                        <div>
                            <CircularMeterListNew list={empty_data_list} />
                            <div className={classes.notMontInfo}>
                                <p className="font-14 text-grey-72 font-family-v1 font-weight-600">Monitoring data not available</p>
                                <p className="font-12 text-grey-72 font-family-v1 font-weight-500">Either there is no deployment or
                                    there is some issue with<br />the deployment. <Tooltip title={monitoring.description}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                            </div>

                        </div>
                        <div className="replication_preview">
                            {/* <ProgressContainer>
                                <HorizontalMeter
                                    current={state.monitoring.replication_bar.current}
                                    max={state.monitoring.replication_bar.max ? state.monitoring.replication_bar.max : state.monitoring.replication_bar.current}
                                    status={resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max)}
                                />
                                {resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max) == "disabled" ? <p className="auto-scale-disabled">Auto Scaling Disabled</p> : null}
                                <ReplicationToolBar not_setup={true} service_id={service_id} env_id={env_id} cd_id={deploy_id} replication_bar={state.monitoring.replication_bar} />
                            </ProgressContainer> */}
                        </div>

                    </div>
                );
            case "NOT_SETUP":
                return (
                    <div className='width-full'>
                        <div>
                            <CircularMeterListNew list={empty_data_list} />
                            <div className={classes.notMontInfo}>
                                <p className="font-14 text-grey-72 font-family-v1 font-weight-600">Monitoring data not available</p>
                                <p className="font-12 text-grey-72 font-family-v1 font-weight-500">Either there is no deployment or
                                    there is some issue with<br />the deployment. <Tooltip title={"Service Monitoring is not Active. Kindly Contact to Super Admin"}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                            </div>

                        </div>



                        <div className="replication_preview">
                            {/* <ProgressContainer>
                                <HorizontalMeter
                                    current={state.monitoring.replication_bar.current}
                                    max={state.monitoring.replication_bar.max ? state.monitoring.replication_bar.max : state.monitoring.replication_bar.current}
                                    status={resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max)}
                                />
                                {resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max) == "disabled" ? <p className="auto-scale-disabled">Auto Scaling Disabled</p> : null}
                                <ReplicationToolBar not_setup={true} service_id={service_id} env_id={env_id} cd_id={deploy_id} replication_bar={state.monitoring.replication_bar} />
                            </ProgressContainer> */}
                            <div>

                            </div>
                        </div>

                    </div>
                );
            default:
                return (
                    <div className="hardware-usage width-full">

                        {
                            state.health ?
                                <>
                                    {/* <span className="tab-label-inner">Service Health</span> */}
                                    <HealthStatusRoundMeter data={state.health} useNewMeter={true} />
                                </>
                                : null
                        }

                        {
                            state.monitoring.urls ?

                                <div className="monitoring-box">
                                    <span className="tab-label-inner">Monitor Using</span>
                                    <IconLinkList list={state.monitoring.urls} />
                                </div>
                                : null
                        }

                        {
                            monitoring.replication_bar ?
                                <div className="replication_preview">
                                    <ProgressContainer>
                                        {/* <HorizontalMeter
                                            current={state.monitoring.replication_bar.current}
                                            max={state.monitoring.replication_bar.max ? state.monitoring.replication_bar.max : state.monitoring.replication_bar.current}
                                            status={resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max)}
                                        /> */}
                                        {/* {resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max) == "disabled" ? <p className="auto-scale-disabled">Auto Scaling Disabled</p> : null} */}
                                        <ReplicationToolBarNew service_id={service_id} env_id={env_id} cd_id={deploy_id} replication_bar={state.monitoring.replication_bar} />
                                    </ProgressContainer>

                                </div> : null
                        }
                    </div>
                );
        }
    }
    const empty_data_list = [
        {
            label: "CPU",
            allocatedLabel: "Utilized/Allocated",
            allocatedValue: '--'
        },
        {
            label: "RAM",
            allocatedLabel: "Utilized/Allocated",
            allocatedValue: "--"
        }
    ]


    return (
        <>
            <Dialog
                open={showMonitoring}
                onClose={handleCloseMonitoring}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog-for-monitoring action_card_dialog_width"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        margin: "0",
                        overflow: "visible"
                    },
                }}
                TransitionComponent={Slide} // Use Slide transition for the dialog
                transitionDuration={500} // Adjust the duration of the transition
                TransitionProps={{
                    direction: 'left', // Slide from right to left
                }}
            >
                <div className={classes.root}>
                    <div className='heading'>
                        <span className='font-18 font-weight-600'>Service Health</span>
                        <button onClick={handleCloseMonitoring}><span className='ri-close-fill font-24'></span></button>
                    </div>
                    <div className='d-flex mt-20 align-center justify-center' style={{ padding: "0px 16px" }}>
                        {
                            state.loaded ?
                                <>
                                    {
                                        state.monitoring ?

                                            <>

                                                {getSection(state.monitoring)}

                                            </>
                                            :
                                            //         <div className=" card-ser">
                                            //         <div className="card-body pd-10">
                                            //             <p className="card-title">
                                            //                 Deploy Details  not Configured
                                            // </p>
                                            //             <p className="font-12 mb-10">
                                            //             Please configure Deploy Details to setup monitoring.
                                            //         </p>

                                            //         </div>
                                            //     </div>
                                            <div >
                                                <div className={classes.noDeploy}>
                                                    <div className='iconCont'>
                                                        <span className='ri-alert-fill line-height-normal' style={{ fontSize: "32px", color: "#0086FF" }}></span>
                                                    </div>
                                                    <span className='font-14 font-weight-600 color-value' style={{ width: "70%", textAlign: "center" }}>Please configure Deploy Details to setup monitoring.</span>
                                                </div>
                                                <CircularMeterListNew list={empty_data_list} />
                                            </div>
                                    }

                                </>
                                :
                                state.error ?
                                    <div>
                                        <CircularMeterListNew list={empty_data_list} />
                                        <div>
                                            <p className="font-13">Monitoring data not available</p>
                                            <p className="font-12 text-grey-72">Either there is no deployment or
                                                there is some issue with<br />the deployment. <Tooltip title={state.error}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                                        </div>

                                    </div>
                                    : <LoadingText />
                        }
                    </div>

                </div>



            </Dialog>

        </>
    );
}

const useStylesForMonitoringDialog = makeStyles((theme) => ({
    root: {
        '& .heading': {
            display: "flex",
            padding: "16px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            background: "#0086FF",
            color: "#FFF",
            position: "relative",

            '& button': {
                display: "flex",
                width: "54px",
                //height: "54px",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: "0",
                borderRadius: "6px 0px 0px 6px",
                background: "#C11212",
                border: "none",
                color: "#fff",
                position: "absolute",
                top: "0",
                left: "-54px",
            }
        }
    },
    noDeploy: {
        padding: "12px",
        border: "1px solid #DFEDFF",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5FAFF",
        gap: "10px",
        "& .iconCont ": {
            padding: "12px",
            border: "1px solid #DFEDFF",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
        }
    },
    notMontInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        padding: "12px",
        width: "100%",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FAFAFA",
    }
}))



const HeadContent = (props) => {
    const service_data = props.service_data;
    const service_name = props.service_name;
    const environments = props.environments;
    const service_id = props.service_id
    const all_envs = Object.keys(environments);
    const env_id = props.env_id;
    const application_id = props.application_id;

    return (


        <>
            <SquareAvatar varient="double" name={service_name.toUpperCase()} />
            <div className="text-icon-section">
                <div className="service-name-card font-14" style={{ color: "#2f2f2f" }}>
                    <Link to={{
                        pathname: "/application/" + props.application_id + "/service/" + props.service_id + "/detail",
                        state: service_data
                    }}>
                        {/* {service_name.toUpperCase()} */}
                        {service_name.length > 30 ?
                            <Tooltip title={service_name.toString()} TransitionComponent={Zoom}>
                                <span className='font-14'>{service_name.substring(0, 30).toUpperCase() + "..."}</span>
                            </Tooltip>

                            :
                            service_name.toUpperCase()
                        }
                    </Link>
                </div>
                <div className="service-details mt-2">
                    <div className="owner-name">

                        {/* <HealthCheck service_id={service_id} env_id={env_id} /> */}
                        <>
                            {/* <span className="owner" style={{ marginLeft: '0rem', color: '#000' }}>Environments: &nbsp;</span> */}
                            {all_envs.length > 0 ?
                                <span>
                                    {
                                        Object.keys(environments).map((env_id) => (
                                            <span key={env_id} className="color-lite" style={{ color: '#666' }}>{environments[env_id].name} </span>
                                        ))
                                    }
                                </span> :
                                <div className="overview-no-env">
                                    <span>No Env Added</span>
                                    <a href={"/application/" + application_id + "/service/" + service_id + "/env/add"} className="anchor">Add Env</a>
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
        </>

    );
}
HeadContent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default ServiceCard;
