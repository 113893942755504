import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import { IconButton } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
import { StepWizard } from '../../../../../components/Step Wizard/StepWizard';
import StepEnvVariableForm from './StepEnvVariableForm';
import WidgetEnvVariableForm from './WidgetEnvVariableForm';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import properties from '../../../../../properties/properties';

const BuildJobStepWizard = ({ setComponentView, selectedJobData, component_id, selectedSubEnv, onSucces, configuredJobDataForEdit }) => {

    const [stepWizardJson, setStepWizardJson] = useState(null),
        [updatedSelectedJobData, setUpdatedSelectedJobData] = useState(null),
        [selectedTabOrder, setSelectedTabOrder] = useState(1),
        [envDataForSelectedStep, setEnvDataForSelectedStep] = useState({ step_code: null, envVariables: null, dataForEdit: null });

    const versioningStatus = configuredJobDataForEdit ? configuredJobDataForEdit['status'] : false
    console.log(updatedSelectedJobData, envDataForSelectedStep, "bhjxbvvhxjvbhjf")

    useEffect(() => {
        if (selectedJobData) {
            const updatedData = getUpdatedJobData(selectedJobData);
            setUpdatedSelectedJobData(updatedData);
        }
    }, [selectedJobData])

    useEffect(() => {
        setStepWizardJson(getDefaultState(updatedSelectedJobData))
    }, [updatedSelectedJobData])

    const getUpdatedJobData = (selectedJobData) => {
        if (selectedJobData) {
            const jobSteps = selectedJobData.selected_job_steps;
            const lastOrder = jobSteps.length > 0 ? jobSteps[jobSteps.length - 1].order : 0;
            const newStep = {
                order: lastOrder + 1,
                step_code: "WIDGET",
                step_name: "widget",
                is_conditional_step: false,
                environment_variables: null
            };
            const updatedJobSteps = [...jobSteps, newStep];
            const updatedSelectedJobData = {
                ...selectedJobData,
                selected_job_steps: updatedJobSteps
            };

            return updatedSelectedJobData;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (updatedSelectedJobData && selectedTabOrder) {
            let steps = updatedSelectedJobData.selected_job_steps
            console.log(configuredJobDataForEdit, 'cjd_e')
            let dataForEdit = null
            if (selectedTabOrder == steps?.length) {
                const widgetData = configuredJobDataForEdit?.widget_data
                setEnvDataForSelectedStep({ ...widgetData })
                getSteByStepCode({ step_code: "WIDGET" })
            }
            else {
                if (configuredJobDataForEdit) {
                    let { job_step_environment_variables } = configuredJobDataForEdit
                    if (job_step_environment_variables && job_step_environment_variables.length > 0) {
                        dataForEdit = job_step_environment_variables[selectedTabOrder - 1]
                    }
                }
                if (steps.length > 0) {
                    let step = steps[selectedTabOrder - 1]
                    setEnvDataForSelectedStep({ step_code: step, envVariables: step.environment_variables, dataForEdit: dataForEdit, apiFetchedStepEnvVariables: null })
                    getSteByStepCode(step)
                }
            }

        }
    }, [selectedTabOrder, updatedSelectedJobData])
    console.log("sdjhdsja", selectedTabOrder, updatedSelectedJobData);

    console.log('sjd', selectedJobData)

    function getSteByStepCode({ step_code }) {
        console.log(step_code, "jdvjhdvcgdvg")
        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        let requestInfo = {
            endPoint: GenerateSearchURL({ step_code: step_code, version: 'v3' }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (step_code == "WIDGET") {
            requestInfo.endPoint = GenerateURL({}, properties.api.widget_list_v3)
        }
        console.log(requestInfo, "avfgvagcavncnc")
        InvokeApi(requestInfo, (response) => onGetStepSuccess(response, step_code), onGetStepFailure);
    }

    function onGetStepSuccess(response, step_code) {
        console.log(response, step_code, "sknvhhxbfvxvbxbv")

        if (step_code == "WIDGET") {

            setEnvDataForSelectedStep(prevState => ({
                ...prevState,
                apiFetchedStepEnvVariables: response
            }))
        } else {
            const { results } = response

            const [step] = results
            // setStepDetails(step)

            console.log(step, 'api_fetched_step')

            setEnvDataForSelectedStep(prevState => ({
                ...prevState,
                apiFetchedStepEnvVariables: step && step.environment_variables
            }))
        }

        console.log(envDataForSelectedStep, "dbhcbdhbcdjvcsd")
    }

    console.log(envDataForSelectedStep, "skjkhsdvjhsbvhjbsv")

    function onGetStepFailure(error) {
        console.log('ee_e', error)
        // setState(new_state => ({
        //     ...new_state,
        //     error: error,
        // }))
    }

    const getTabChangeEvent = (tabOrder) => {
        console.log(tabOrder, "dnhdshbvbsvb")
        if (selectedJobData && tabOrder) {

            setSelectedTabOrder(tabOrder)
            // let steps = selectedJobData.selected_job_steps
            // if (steps.length > 0) {
            //     let step = steps[tabOrder - 1]
            //     console.log(step, 'changed_001')
            //     setEnvDataForSelectedStep({ step_code: step, envVariables: step.environment_variables })
            // }

        }
        console.log(tabOrder, 'step_order-1')
    }

    const postSuccess = (data) => {
        onSucces()
    }

    const postFailure = (error) => {

        console.log('errr-ss', error)
    }

    const getRedistributedListOfIntegrationVars = (listOfIntegrationVars) => {

        console.log(listOfIntegrationVars, 'input_data_232323')
        let integationvariableslist = []
        let integrationTypeIndex = { 'GIT_REPO': null, 'CREDENTIAL_MANAGEMENT': null, 'REGISTRY_INFORMATION': null }
        listOfIntegrationVars?.forEach(dataObj => {
            let obj = { 'integration_values': [] }
            let insideDataObject = {}
            const integrationType = dataObj['integration']
            obj['integration'] = integrationType
            if (integrationTypeIndex[integrationType] != null) {
                const postitionOfIntegration = integrationTypeIndex[integrationType]
                let targetObject = integationvariableslist[postitionOfIntegration]
                insideDataObject = { ...dataObj, key: dataObj['integration'] }
                delete insideDataObject.integration
                delete insideDataObject.integration_key
                const integrationKeyObject = {}
                integrationKeyObject[dataObj['integration_key']] = [insideDataObject]
                let integrationKeyObjectArray = targetObject['integration_values']
                integrationKeyObjectArray.push(integrationKeyObject)
                targetObject['integration_values'] = integrationKeyObjectArray
                integationvariableslist[postitionOfIntegration] = targetObject
            }
            else {
                integationvariableslist.push(obj)
                const postionOfIntegration = integationvariableslist.length - 1
                integrationTypeIndex[integrationType] = postionOfIntegration
                insideDataObject = { ...dataObj, key: dataObj['integration'] }
                delete insideDataObject.integration
                delete insideDataObject.integration_key
                const integrationKeyObject = {}
                integrationKeyObject[dataObj['integration_key']] = [insideDataObject]
                obj['integration_values'].push(integrationKeyObject)
            }
        })

        return integationvariableslist

    }


    const addGitBranchInsideGitRepo = (integrationList, gitBranchObj) => {

        console.log(gitBranchObj, 'gatawewe')
        if (gitBranchObj && Object.keys(gitBranchObj).length > 0) {
            integrationList?.forEach(integrationObj => {
                if (integrationObj['integration'] === 'GIT_REPO') {
                    integrationObj['integration_values']?.forEach(integrationObj => {
                        const integrationKey = Object.keys(integrationObj)[0]
                        const gitBranchObject = { value: gitBranchObj[integrationKey], key: 'GIT_BRANCH', is_trigger_time_modify: true }
                        integrationObj[integrationKey].push(gitBranchObject)
                    })

                }
            })
        }
    }

    // function getWidgetData(data) {
    //     console.log(data, "sdbchbjsdbsdcvsdvcsdjg")
    //     var key = Object.keys(data)[0]
    //     let value = data[key]
    //     let updated_data = {}
    //     if (data.data_type == "list") {
    //         updated_data[key] = createArrayFromString(value);
    //     }else{
    //         updated_data[key] = value;
    //     }
    //     return updated_data;
    // }

    // function createArrayFromString(str) {
    //     console.log(str, "acvghdvcghdsvhc")
    //     return str.split(",").map(word => word.trim());
    // }


    const onSubmit = (complete_panel) => {

        console.log('cmP-l_weeewew', complete_panel)
        let { component_env_job_template_id, job_code } = updatedSelectedJobData

        let { selected_job_steps: steps } = updatedSelectedJobData
        var widget_panel_data;
        let finalDataArray = complete_panel && Object.keys(complete_panel).map(key => {
            if (complete_panel[key]) {
                console.log(complete_panel[key], "sbvsgcgscbjhxbchj")
                let formData = complete_panel[key].type == "widget_panel" ? complete_panel[key].widget_data : complete_panel[key].data
                console.log(formData, "sbvsgcgscbjhxbchj");
                let onlyIntegrationData = complete_panel[key].onlyIntegrationVariables
                let gitBranchesData = complete_panel[key].git_branches_data
                console.log('o_t_d', onlyIntegrationData)
                let modify_variables_keys = complete_panel[key].modify_variables_keys
                let input_related_data_of_env_vars = complete_panel[key].input_related_data_of_env_vars
                console.log(input_related_data_of_env_vars, 'ot_data')
                let integrationVarList = []
                if (complete_panel[key].type == "widget_panel") {
                    return { widget_data: { ...complete_panel[key].widget_data }, panel_type: "widget" }
                } else {
                    if (Object.keys(formData).length !== 0) {
                        let envKeyValueArray = []
                        formData && Object.keys(formData).forEach(key => {
                            console.log(key, 'key_001')
                            if (onlyIntegrationData[key] != undefined) {
                                // return { integration: key, value: formData[key], is_trigger_time_modify: false }
                                const integrationObject = onlyIntegrationData[key]
                                const finalObject = { ...integrationObject, value: formData[key] }
                                integrationVarList.push(finalObject)
                                // return finalObject
                            }

                            else {
                                // if (key === 'GIT_BRANCH') {
                                //     const finalObject = { key: key, value: formData[key], is_trigger_time_modify: true, integration_key: 'integration_1' }
                                //     integrationVarList.push(finalObject)
                                //     return finalObject
                                // }
                                // else
                                envKeyValueArray.push({ ...input_related_data_of_env_vars[key], key: key, value: formData[key], is_trigger_time_modify: modify_variables_keys[key] ? true : false })
                            }
                        })

                        console.log(envKeyValueArray, 'integration_sdesew33232')
                        const integrationReducedVarList = getRedistributedListOfIntegrationVars(integrationVarList)
                        // adding gitBranch data inside git repo integration values
                        addGitBranchInsideGitRepo(integrationReducedVarList, gitBranchesData)
                        console.log(integrationReducedVarList, 'reduced_weew3232')
                        envKeyValueArray = [...envKeyValueArray, ...integrationReducedVarList]
                        return envKeyValueArray
                    }

                    else
                        return null
                }

            }
        })
        // mapping array of envVar to correct step code for final post 
        console.log(finalDataArray, "dghczhdcjhzvbczgdj")
        var widget_data;
        let job_step_environment_variables = []
        steps && steps.forEach((step, index) => {
            if (finalDataArray[index]?.panel_type != "widget") {
                job_step_environment_variables.push(
                    {
                        order: index,           //order added
                        step_code: step.step_code,
                        step_name: step.step_name,
                        environment_variables: finalDataArray[index] ? finalDataArray[index] : []
                    }
                )
            } else {
                // widget_data = getWidgetData(finalDataArray[index].widget_data)
                widget_data = finalDataArray[index].widget_data
            }
        })

        console.log(job_step_environment_variables, "dghczhdcjhzvbczgdj")

        let postData = {
            // component_env_job_template_id: component_env_job_template_id,
            job_code: job_code,
            job_step_environment_variables: job_step_environment_variables,
            component_env_id: selectedSubEnv,
            widget_data: widget_data
        }

        console.log(postData, 'post_data_esl')

        if (versioningStatus) {
            postData['status'] = versioningStatus
        }
        if (configuredJobDataForEdit) {
            let post_url = GenerateURL({ component_id: component_id, sub_env_id: selectedSubEnv, component_env_id: component_env_job_template_id, job_code: job_code }, properties.api.update_configure_job_data)
            PostData(post_url, postData, postSuccess, postFailure);
        }
        else {
            let post_url = GenerateURL({ component_id: component_id, sub_env_id: selectedSubEnv }, properties.api.create_configure_job_data)
            PostData(post_url, postData, postSuccess, postFailure);
        }
    }

    // configure_job_post_step_data

    console.log('evs_n', envDataForSelectedStep)

    console.log('c_fv', configuredJobDataForEdit)

    console.log(stepWizardJson, 's_w_j')

    return (
        <div style={{ marginTop: '35px', border: '1px solid #dddddd', backgroundColor: '#f4f4f4' }}>
            {/* <div style={{ display: 'flex' }} className="align-center space-between">
                <div className='align-center' style={{ display: 'flex' }}>
                    <label onClick={() => setComponentView('jobListing')} style={{ color: '#285ebf', cursor: 'pointer' }}
                        className='font-17 mr-5'>All Jobs</label>
                    <ArrowForwardIosIcon style={{ color: '#6b6565' }} className='mr-5 font-weight-300' />
                    <label className='font-17 font-weight-300'>Build Job Steps</label>
                </div>
                <IconButton className='mr-1' onClick={() => setComponentView('jobListing')}>
                    <CloseIcon style={{ fontSize: '20px', color: "#124d9b" }} />
                </IconButton>
            </div> */}

            {
                stepWizardJson ?
                    <StepWizard
                        style={{ backgroundColor: '#ffffff' }}
                        onSubmit={onSubmit}
                        sendTabChangeEvent={getTabChangeEvent}
                        extraProps={envDataForSelectedStep}
                        dataJson={stepWizardJson}
                        currentTab={selectedTabOrder} /> :
                    
                        <div className='justify-center align-center' style={{ marginTop: '35px', display: 'flex', backgroundColor: ' #80808024', border: '1px solid #f8f8f8', height: '100px' }}>
                            <span style={{ color: '#332e2e' }} className='font-14'>No Steps</span>
                        </div>
                    
            }
        </div>
    )
}


BuildJobStepWizard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default BuildJobStepWizard


const getDefaultState = (selectedJobData) => {
    console.log(selectedJobData,"dshbhbsdjcdvhsf")
    if (selectedJobData) {

        let wizardJson = selectedJobData.selected_job_steps && selectedJobData.selected_job_steps.map(({ step_name }, index) => {
            console.log(step_name, "shbcjhzdvcvgj")
            return { order: index + 1, mainText: step_name, body: step_name == "widget" ? WidgetEnvVariableForm : StepEnvVariableForm }
        })
        console.log('wzs', wizardJson)
        if (wizardJson && wizardJson.length === 0)
            return null
        else
            return wizardJson
    }
    else
        return null

}

