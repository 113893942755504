import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';

import SearchBar from '../../../../components/SearchBar';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useParams } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import EditLabels from '../components/EditLabels';
import properties from '../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';
import DeleteConfirmationDialogue from '../../../settings/credentials/components/DeleteConfirmationDialogue';
import ExandableKeyValues from '../../../../components/hoc/ExpanadleKeyValues';
import { Cancel, Loop } from '@material-ui/icons';
//import Delete from '../../../../components/genericComponents/Delete';

const NamespaceList = props => {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const { cluster_id } = useParams();
    const dropdwn = [
        {
            id: 1,
            label: "show all"
        },
        {
            id: 1,
            label: "kube-master-6c8d"
        },
        {
            id: 1,
            label: "kube-master-6c8d"
        },
    ]

    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({
        namespaces: [],
        namespace_resource_quota: [],
        namespace_info: {}

    })

    useEffect(() => {
        setShowLoading(true);
        fetchNamespaces();

    }, [])


    function handleSuccessApiHit(data) {
        setState(new_state => ({
            ...new_state,
            namespaces: new_state.namespaces.length > 0 ? [...new_state.namespaces, ...data.items] : data.items,
            namespace_info: data,
            load_more: false,
            loadingNamespaces: false, 
        }));
        setShowLoading(false);

    }

    function handleFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
            loadingNamespaces: false, 
        }))
        setShowLoading(false);
    }

    function fetchNamespaces(data, load_more) {

        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_namespaces_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (load_more) {
            requestInfo.endPoint = GenerateSearchURL({ pagination_token: state.namespace_info.metadata.continue }, requestInfo.endPoint);
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
            console.log("search", requestInfo.endPoint)
        }





        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);

        setState(new_state => ({
            ...new_state,
            load_more: load_more,
            namespaces: !load_more ? [] : new_state.namespaces,
            search_data: data,
            current: requestInfo,
            loadingNamespaces: true, 
        }))
    }


    const invokeSearch = (data) => {
        console.log("invokeSearch", data);
        fetchNamespaces(data);
    }
    console.log("hello", state)
    function fetchNamespaceResouceQuota() {
        var requestInfo = {
            endPoint: GenerateURL({ cluster_id: cluster_id }, properties.api.get_namespace_resource_quota),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, handleSuccessApiHit1, handleFailedApiHit1);
    }

    function handleSuccessApiHit1(data) {
        setState(new_state => ({
            ...new_state,
            namespace_resource_quota: data.items
        }));
        setShowLoading(false);

    }

    function handleFailedApiHit1(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
        setShowLoading(false);
    }

    let labels_list = [];
    const iteration_count = 2;
    let labels_list2 = [];


    function get_list(labels, labels2, list_type) {

        labels_list = [];
        //const iteration_count = 2;
        labels_list2 = [];
        if (labels) {
            Object.keys(labels).forEach(label => {
                labels_list.push(
                    {
                        key: label,
                        value: labels[label]
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label + "=" + labels[label]}>
                        <div className="pod-lable">
                            {label + "=" + labels[label]}
                        </div>
                    </Tooltip>
                );
            });


        }
        if (labels2) {
            labels2.forEach(label => {
                labels_list.push(
                    {
                        key: label.env_key,
                        value: label.env_value
                    }
                );
                labels_list2.push(
                    <Tooltip placement="bottom" title={label.env_key + "=" + label.env_value}>
                        <div className="pod-lable">
                            {label.env_key + "=" + label.env_value}
                        </div>
                    </Tooltip>
                );
            });



        }
        if (list_type == "1") {
            return labels_list;

        }
        if (list_type == "2") {
            return labels_list2;

        }
        return [];
    }


    return (
        <div className={classes.root}>
            {
                showLoading ? <Loading /> : null
            }
            <div className="main-div d-flex align-center" style={{ justifyContent: 'space-between' }}>
                <p className="main-heading">Manage Namespaces</p>
                {state.namespaces.length > 0 ?
                    <Link to={"/cluster/" + cluster_id + "/namespace/add"}>
                        <button className="btn btn-submit">
                            Add Namespace
                    </button>
                    </Link> : null}
            </div>
            <Grid container spacing={4} >
                <Grid item lg={12} >
                    <SearchBar
                        search_data={state.search_data}
                        default_filter={{ name: "name", label: "Name" }}
                        search_call_back={invokeSearch}
                        clear_search_callback={fetchNamespaces}
                    />
                </Grid>
                {/* Loading spinner shown when list is refreshed  */}
                {state.loadingNamespaces && !showLoading ? <div className='d-flex justify-center align-center width-full' style={{height:"40vh"}}><Loading varient="light" /> </div>: 

                    state.namespaces.length > 0 ?
                        <>
                            <Grid item lg={12} >
                                <div className={classes.card}>
                                    <div className={classes.cardHeader}>
                                        <div className="main-div" style={{ padding: '0px' }}>
                                            <p className="main-heading">Namespaces Available</p>
                                            <p className="sub-heading">Displaying overall summary of Namespaces associated with this Cluster</p>
                                        </div>
                                        <button className="btn-round border-navy bg-clear-btn" style={{ justifySelf: "flex-end" }} onClick={() => { fetchNamespaces(null, false) }}><span className="flaticon-refresh-button-1"></span></button>
                                    </div>

                                    <table className="table table-responsive table-stripped">
                                        <thead>
                                            <tr>


                                                <th>Name</th>
                                                <th>Status</th>

                                                <th>Annotations</th>
                                                <th>Labels</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                state.namespaces.map(namespace => (
                                                    <tr>
                                                        <td>
                                                            <Tooltip placement="bottom" title={namespace.metadata.name}>
                                                                <Link to={"/cluster/" + cluster_id + "/namespace/" + namespace.metadata.name + "/detail"}> <span >{namespace.metadata.name}</span></Link>
                                                            </Tooltip>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex align-center">
                                                                {namespace.status.phase == "Active" ? <CheckCircleIcon style={{ color: '#69e09c', fontSize: '14px' }} /> : <Cancel style={{ fontSize: '15px', color: '#ffce25' }} />}<span>&nbsp;{namespace.status.phase}</span>
                                                            </div>
                                                        </td>


                                                        <td>
                                                            {namespace.metadata.annotations || namespace.annotations ? <ExandableKeyValues
                                                                compoenent_list={get_list(namespace.metadata.annotations, namespace.annotations, 2)}
                                                                iteration_count={iteration_count}
                                                                isLabel={false}
                                                                labels_list={get_list(namespace.metadata.annotations, namespace.annotations, 1)}
                                                                expandable_component={
                                                                    <>
                                                                        +{labels_list.length - iteration_count}
                                                                    </>
                                                                } />
                                                                : <p>No Annotations</p>}

                                                        </td>
                                                        <td>
                                                            {/* {namespace.metadata.labels ? Object.keys(namespace.metadata.labels).map(label => (
                                                                <Tooltip title={label} placement="bottom">
                                                                    <div className="pod-lable">
                                                                        {label}
                                                                    </div>
                                                                </Tooltip>

                                                            )) : <p>No Labels</p>} */}
                                                            {namespace.metadata.labels || namespace.labels ? <ExandableKeyValues
                                                                compoenent_list={get_list(namespace.metadata.labels, namespace.labels, 2)}
                                                                iteration_count={iteration_count}
                                                                isLabel={true}
                                                                labels_list={get_list(namespace.metadata.labels, namespace.labels, 1)}
                                                                expandable_component={
                                                                    <>
                                                                        +{labels_list.length - iteration_count}
                                                                    </>
                                                                } />
                                                                : <p>No Labels</p>}


                                                        </td>
                                                        <td>
                                                            {/* <Delete
                                                                display_data_name={namespace.metadata.name}
                                                                data={{ entity_id: , name: "cluster" }}
                                                                api_link={GenerateURL({ cluster_id: cluster_id }, properties.api.get_single_cluster)}
                                                            /> */}
                                                        </td>
                                                    </tr>))}

                                        </tbody>


                                    </table>
                                    {
                                        state.namespace_info ? <div className={classes.cardFooter} style={state.namespace_info.metadata.continue ? { display: 'block', textAlign: 'center' } : { display: 'none' }}>
                                            {state.load_more ? <Loading varient="light" /> :
                                                state.namespace_info.metadata.continue ?
                                                    <IconButton className="btn-grey-navi hover-state" onClick={() => { fetchNamespaces(null, true) }}><Loop style={{ fontSize: '2.5rem' }} />Load More</IconButton> : null
                                            }
                                        </div> : null
                                    }

                                </div>
                            </Grid>

                        </>
                        :
                        <>
                            <div className="main-container-error" style={{ height: '60vh', margin: 'auto' }}>
                                <div className="svg">
                                    <div className="servicePanel">
                                        <div className="blank-div">
                                            <div className="blank-div-text">
                                                No Namespace associated with this cluster
                                    </div>
                                            <div>
                                                <Link to={"/cluster/" + cluster_id + "/namespace/add"} className="btn btn-submit">
                                                    <div >Add Namespace</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    
                
                }
                


            </Grid>

        </div >
    )
}

export default NamespaceList;


const ITEM_HEIGHT = 48;
export function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const application_id = props.application_id;
    const onDeleteRequest = () => {

        var saveUrl = GenerateURL({ namespace_id: props.id }, properties.api.get_namespace)
        console.log("sas", saveUrl)
        //DeleteData(saveUrl, {}, handleDeleteSuccess, handleSaveFailure, true)
        // setState({ ...state, error: {} })
        // setShowLoading(true);
        setTimeout(() => {
            handleCloseDeleteDialogue();
        }, 400);
    }
  
    const handleCloseDeleteDialogue = () => {
        props.refresh();
        setDeleteOpen(false);
        // setState({ ...state, error: {}, post_status: "POST_SUCCESS" })
    };

    const [deleteOpen, setDeleteOpen] = useState(false);

    function onDeleteHandler() {
        setAnchorEl(null);
        setDeleteOpen(true);
    }

    return (
        <div className="m-auto text-center" >
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>
                    <span className="hover-content-link"><VisibilityIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;View Details</span>
                </MenuItem>
                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>

                    <span className="hover-content-link">
                        <EditIcon style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;<EditLabels />
                    </span>
                </MenuItem>


                <div className="divider"></div>
                <MenuItem selected={Link === 'Dashboard'} onClick={handleClose} style={{ display: "grid" }}>


                    <DeleteIcon onClick={onDeleteHandler} style={{ color: '#0096db', fontSize: '16px' }} />&nbsp;Delete

                </MenuItem>

            </Menu>
            <DeleteConfirmationDialogue onDeleteRequest={onDeleteRequest} open={deleteOpen} handleClose={handleCloseDeleteDialogue}
                name={props.name} type="namespace" />
        </div>
    );
}
LongMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px',
    },
    card: {
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
        background: '#fff',
        overflowY: 'auto',
        minHeight: '500px',
        '& .pod-lable': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    cardHeader: {
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: "#fff",
        fontSize: '13px',
        alignItems: 'center',
        padding: '10px 15px',
        '& .input-component': {
            minWidth: '120px',
            marginBottom: '0px'
        },

    },
    cardFooter: {
        backgroundColor: '#fff',
        borderRadius: ' 0px 0px 8px 8px',
        display: 'flex',
        padding: '10px 15px',
        borderTop: '1px solid #d5d5d5',
        justifyContent: 'flex-end',
        '& .btn-submit': {
            backgroundColor: '#3696db',
            color: '#fff',
            marginLeft: 'auto'
        }
    }
}));
