import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom'

export default function JobConfigureInfo({jobName}) {

    // const {step_id, step_code} = props
    // const [stepDetails,setStepDetails] = useState({})
    // const [state,setState] = useState()
    // const images = properties.catalog_images[0];

    const classes = useStyles();

    // useEffect(() => {
    //     step_id && getSteById(step_id)
    //     step_code && getSteByStepCode(step_code)
    // },[step_id,step_code])

    // function getSteById(step_id) {

    //     let requestInfo = {
    //         endPoint: GenerateURL({ step_id: step_id }, properties.api.getStep),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" }
    //     }

    //     InvokeApi(requestInfo, onSuccess, onFailure);
    // }

    // function onSuccess(response) {

    //     response  && setStepDetails(response)
    // }

    // function onFailure(error) {

    //     setState(new_state => ({
    //         ...new_state,
    //         error: error,
    //     }))
    // }

    // console.log('step_d',stepDetails)

    // console.log('step_id',step_id)


    // function getSteByStepCode(step_code) {

    //     let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

    //     let requestInfo = {
    //         endPoint: GenerateSearchURL({ step_code: step_code },myendpoint),
    //         httpMethod: "GET",
    //         httpHeaders: { "Content-Type": "application/json" }
    //     }

    //     InvokeApi(requestInfo, onGetStepSuccess, onGetStepFailure);
    // }

    // function onGetStepSuccess(response) {

    //     const {results} = response
    //     const [step] = results
    //     step  && setStepDetails(step)
    // }

    // function onGetStepFailure(error) {
    //    console.log('ee_e',error)
    //     setState(new_state => ({
    //         ...new_state,
    //         error: error,
    //     }))
    // }

    let jobData = { name: 'Build Job' }

    return (
        <div className={classes.staticwrap} style={{ backgroundColor: '#051939', height: '100%' }}>
            <div className='staticwrap-inner'>
                <h1 className="h1">{`Configure ${jobName}`}</h1>
                <p style={{ color: '#fff', paddingTop: '25px' }}>A Environment aims to mitigate such scenarios by encouraging automation and documentation, there by increasing communication.
                    In this environment, every release is committed in an automated fashion, enabling the rapid building, testing and deployment of every project.
                    <br /> <br />
                    To learn more about how to setup a Microservice please read <span style={{ color: '#e89317' }}>Documentation </span></p>
            </div>
        </div>
    )

    // return (
    //     <>
    //         <div className={classes.staticwrap} style={{ backgroundColor: '#051939', borderRight: '1px solid #B7B7B7', height: '100%' }}>
    //             <div className='info-container'>
    //                 <p className='less-bright' style={{ color: '#ffffff', fontSize: '27px', marginBottom:'32px' }}>{`Configure ${jobData.name}`} </p>
    //                 <p className='less-bright' style={{color: '#ffffff', fontSize: '14px',lineHeight: '27px'}}>A Enviornment aims to mitigate such
    //                     scenarious by encouraging automation and
    //                     documentation, thereby increasing communication.</p>
    //                 <p className='less-bright' style={{color: '#ffffff', fontSize: '14px',lineHeight: '27px',marginBottom:'36px'}}>
    //                     In this enviornment, every release is commited in an automated fashion, enabling the rapid building, testing and deployment of every project.</p>

    //                 <p className='less-bright' style={{color: '#ffffff', fontSize: '14px',lineHeight: '27px'}}>To learn more about how to setup a
    //                     Microservice please read <Link style={{color:'#d1851d'}}>Documentation.</Link></p>
    //             </div>
    //         </div>
    //     </>
    // )
}

JobConfigureInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const useStyles = makeStyles({
    staticwrap: {
        display: 'flex',
        // alignItems: 'center',
        '& .staticwrap-inner': {
            padding: '215px 30px 0 40px',
            color: '#fff',
            '& p': {
                fontSize: '13px',
                lineHeight: '23px',
                marginTop: '30px',
            }
        },
        '& .h1': {
            fontSize: '25px',
            lineHeight: '36px',
            color: '#ffffff',
            fontWeight: '300',
            position: 'relative',

            '&:before': {
                content: '""',
                borderBottom: '1px solid #000',
                position: 'absolute',
                borderLeft: 'none',
                bottom: '0',
                left: 0,
                display: 'inline-block',
                width: '40px',
                height: '4px',
                background: '#2e2af3',
            }
        },
        '& .info-container': {
            paddingLeft: '45px',
            paddingRight: '45px'
        },

        '& .less-bright': {
            filter: 'brightness(0.89)'
        }
    }
})














