import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import { List, ListItem } from "@material-ui/core";
import "../layout-styles.css";
import { GenerateRouteFromParams } from "../../routes";
import { Link, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { IsSuperUser } from "../../util/security";
import AppBreadcrumbNew from "./AppBreadcrumbNew";
import properties from "../../properties/properties";

export function isSelectedUrl(match_url, generated_link, ignore_path) {
    console.log("iuitnknkddf",match_url, generated_link, ignore_path);
    if (ignore_path) {
        var generated_link_array = generated_link.split("/");
        var match_url_array = match_url.split("/");

        ignore_path.forEach((path) => {
            const index = generated_link_array.indexOf(path);
            generated_link_array[index] = "/";
            match_url_array[index] = "/";
        });

        return generated_link_array.toString() == match_url_array.toString();
    }
    return match_url == generated_link;
}

const NavigationNew = (props) => {
    const classes = useStyles();
    const routes = props.routes;
    const data = props.data;
    const match = useRouteMatch();
    const bread_crumb_list = [];
    const open = props.open;
    // const handleDrawerOpen = props.handleDrawerOpen;
    // const handleToggle = props.handleToggle;
    const react_version = properties.release_version;
    console.log("__r", routes);

    console.log("__data", data);
    if (data.application) {
        var applications = Object.keys(data.application);
        applications.forEach((app_id) => {
            data.application[app_id].service = data.application[app_id].components;
            data.application[app_id].pipeline = data.application[app_id].pipelines;
            data.application[app_id].ci = data.application[app_id].project_ci_scope;
        });
    } else {
        data.application = {};
    }

    if (!data.cluster) {
        data.cluster = {};
    }

    console.log("Navigation Data", data);

    

    function getNavForRoutes(route, params, parent_element) {
        var routes = route.routes;
        var routes_keys = Object.keys(routes);
        var nav = [];
        var selected = false;

        routes_keys.forEach((key) => {
            var route = routes[key];
            var result = {};
            if (
                route.type == "element-only-heading" ||
                route.type == "element-static-heading"
            ) {
                var ids = parent_element[route.key];
                var elements = data[route.key];
                result = getNavListForElementRoute(route, elements, ids, params);
            } else if (route.type == "non-element" || route.type == "none") {
                result = getNavForRoute(route, route.label, params);
            }
            nav.push(result.nav);
            selected = result.selected ? true : selected;
        });

        var link = route.path;
        if (params) {
            link = GenerateRouteFromParams(route.path, params);
        }

        selected = isSelectedUrl(match.url, link, route.ignore_sub_path)
            ? true
            : selected;

        const expandable_nav = (
            <ExpandableNavForRoute
                link={link}
                selected={selected}
                icon={route.icon}
                data={{ label: parent_element ? parent_element.name : route.label }}
                sub_links={nav}
            />
        );

        if (selected) {

            bread_crumb_list.push({
                label: parent_element ? parent_element.name : route.label,
                link: link,
            });
        }

        return {
            nav: expandable_nav,
            selected: selected,
        };
    }

    function getNavListForElementRoute(route, elements, ids, params) {
        console.log(route,elements,ids, "fkdjkdjfkd")
        var element_id_key = route.key + "_id";
        var element_nav_list = [];
        var selected = false;

        if (ids) {
            ids.forEach((id) => {
                const element = elements[id];

                var sub_params = {
                    ...params,
                    [element_id_key]: id,
                };

                if (route.routes) {
                    var result = getNavForRoutes(route, sub_params, element);
                    element_nav_list.push(result.nav);
                    selected = result.selected ? true : selected;
                } else {
                    var result = getNavForRoute(route, element.name, sub_params);
                    element_nav_list.push(result.nav);
                    selected = result.selected ? true : selected;
                }
            });
        }

        var link = route.parent_path ? route.parent_path : route.path;
        if (params) {
            link = GenerateRouteFromParams(link, params);
        }

        var always_open = route.type == "element-static-heading";
        var only_heading = route.type == "element-only-heading";

        selected = isSelectedUrl(match.url, link, route.ignore_sub_path)
            ? true
            : selected;
        const nav = only_heading ? (
            <NavForRoute
                link={link}
                icon={route.icon}
                selected={selected}
                data={{ label: route.label }}
                imgPath={route.imgPath}
                imgPathSelected={route.imgPathSelected}
            />
        ) : (
            <ExpandableNavForRoute
                link={link}
                selected={selected}
                icon={route.icon}
                data={{ label: route.label }}
                sub_links={element_nav_list}
                always_open={always_open}
            />
        );

        if (selected) {
            bread_crumb_list.push({
                label: route.label,
                link: link,
                type: route.type,
            });
        }

        console.log({ nav: nav, selected: selected }, "ksndksbhbssdkjk")

        return {
            nav: nav,
            selected: selected,
        };
    }

    function getNavForRoute(route, label, params) {
        var link = route.path;
        if (params) {
            link = GenerateRouteFromParams(route.path, params);
        }

        const selected = isSelectedUrl(match.url, link, route.ignore_sub_path);

        const nav =
            route.type == "none" ? null : (
                <NavForRoute
                    link={link}
                    icon={route.icon}
                    selected={selected}
                    data={{ label: label }}
                />
            );

        if (selected) {
            bread_crumb_list.push({
                label: label,
                link: route.type == "none" ? null : link,
            });
        }

        return {
            nav: nav,
            selected: selected,
        };
    }

    function checkPathForIntegration(pathList) {
      return pathList.includes(window.location.pathname.toString());
    }

    const [integrationMenu, setIntegrationMenu] = useState(false);
    //const [insightMenu, setInsightMenu] = useState(false);
    const [userMgmtMenu, setuserMgmtMenu] = useState(false);
    const [userPolicies, setuserPolicies] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const integrationUrls = [
     "/repo/list",
     "/secrets/list",
     "/eventlistener/list",
     "/integration/containerRegistries",
     "/tickets/list",
     "/helm/integration/list"
    ];

    return (
        <>
            {/* <AppBreadcrumb
        list={bread_crumb_list}
        routes_url={match}
        super_admin_flag={IsSuperUser()}
      /> */}
            <AppBreadcrumbNew
                list={bread_crumb_list}
                routes_url={match}
                super_admin_flag={IsSuperUser()}
            />
            <Drawer
                anchor="left"
                // classes={{ paper: classes.drawer }}
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <List className="root side-nav">
                    <Link
                        to={IsSuperUser() ? "/ClusterDashboard" : "/ApplicationDashboard"}
                    >
                        <div className="dashboard-button">
                            <img src="/images/HomeDashboard.png" style={{ height: "24px", width: "24px" }} alt='..' />
                            <p style={{ marginLeft: "6px", color: "#fff" }}>Home: Dashboard</p>
                        </div>
                    </Link>
                    <div>
                        {
                            getNavListForElementRoute(
                                IsSuperUser() ? routes.cluster : routes.application,
                                IsSuperUser() ? data.cluster : data.application,
                                Object.keys(IsSuperUser() ? data.cluster : data.application)
                            ).nav
                        }
                        {
                            IsSuperUser()
                                ? getNavListForElementRoute(
                                    routes.application,
                                    data.application,
                                    Object.keys(data.application)
                                ).nav
                                : null
                        }
                    </div>
                    {IsSuperUser() ? (
                        <>
                            <div className="divider-nav"></div>
                            <ListItem
                                className="item hover-effect"
                                disableGutters>
                                <Link to="/step/list" className="main-nav-1">
                                    {/* <span className="flaticon-shopping-basket-button"> </span> */}
                                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                    <img src="/images/step-catalog.svg" style={{marginLeft:"9px"}} alt="step-catalog"/>
                                    <span className="color-black font-family-v1 font-14 font-weight-500 ml-10">Step Catalogs</span>
                                    </div>
                                    
                                </Link>
                            </ListItem>

                            {/* <div className="divider-nav"></div> */}
                            <ListItem className="item hover-effect" disableGutters>
                                <a
                                    href="/project/listing"
                                    className="main-nav-1 d-flex align-center"
                                >
                                    {/* <span className="flaticon-screen-with-rounded-corners d-flex align-center ">
                                        
                                    </span> */}
                                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                    <img src="/images/manage-app.svg" style={{marginLeft:"9px"}} alt="manage-app" />
                                    <span className="color-black font-family-v1 font-14 font-weight-500 ml-10">Manage Application</span>
                                    </div>
                                    
                                </a>
                            </ListItem>
                            {/* <div className="divider-nav"></div> */}
                        </>
                    ) : null}
                    {!IsSuperUser() ? (
                        <div className="main_menu">
                            <Link to="/step/list" className="">
                                <button className="main-menu hover-effect" /* style={selected ? { backgroundColor: "#1E3347", borderLeft: "4px solid #0086FF" } : {}} */>
                                    <img src="/images/step-catalog.svg" className="main-menu-icon" style={{ marginLeft: "23px" }} alt='..' />
                                    <p className="main-menu-name font-family-v1 font-14 font-weight-500">Step Catalogs</p>
                                </button>
                            </Link>
                            <Link to="/event-listener/status" className="">
                                <button className="main-menu hover-effect" /* style={selected ? { backgroundColor: "#1E3347", borderLeft: "4px solid #0086FF" } : {}} */>
                                    {/* {
                                        selected ?
                                            <img src={imgPathSelected} className="main-menu-icon" style={{ marginLeft: "28px" }} /> :
                                            <img src={imgPath} className="main-menu-icon" style={{ marginLeft: "32px" }} />
                                    } */}
                                    <img src="/images/Webhook.svg" className="main-menu-icon" style={{ marginLeft: "23px" }} alt='..'/>
                                    <p className="main-menu-name font-family-v1 font-14 font-weight-500">Webhook Events</p>
                                </button>
                            </Link>
                            <Link to="/velocity-insights" className="">
                                <button className="main-menu hover-effect">
                                    <img src="/images/velocity-insight.svg" className="main-menu-icon" style={{ marginLeft: "23px" }} alt='..'/>
                                    <p style={{marginRight:'35px'}} className="main-menu-name font-family-v1 font-14 font-weight-500">Velocity Insights</p>
                                    <img src="/images/premium/premium_crown.png"  alt="premium" />
                                    {/* <img src='/images/integrations/padlock.png' /> */}

                                </button>
                            </Link>
                        </div>
                    ) : null}
                    {

                        IsSuperUser() && <>

                            <ListItem className="item user-item" disableGutters>
                                <button className="main-nav-1 user-btn" onClick={() => setuserMgmtMenu(!userMgmtMenu)} >
                                    {/* <span className="flaticon-shopping-basket-button">
                                        <span className="color-black font-family-v1 font-14 font-weight-500">User Management</span>
                                    </span> */}
                                    <img src="/images/user-manage.svg" style={{marginLeft:"20px"}} alt="user-manage"/>
                                    <span className="color-black font-family-v1 font-14 font-weight-500 ml-10">User Management</span>
                                    <span className={`${userMgmtMenu ? "flaticon-expand-arrow" : "flaticon-downwards-arrow-key"}`} ></span>

                                </button>
                                {
                                    userMgmtMenu && <div className="integrationItem">
                                        <ul>
                                            <li>
                                                <Link to="/role-groups" className="user-list-options">
                                                    {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                    <img src="/images/manage-external-grp.svg" alt="manage-external-grp"/>
                                                      <span className="color-black  font-family-v1 font-12 light-grey" >Manage External Groups</span>
                                                </Link>
                                            </li>

                                            <li><Link to='/user/group/list'className="user-list-options">{/* /role-groups */}
                                                <img src="/images/manage-user-grp.svg" alt="manage-user-grp"/>
                                                {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                    <span className="color-black  font-family-v1 font-12  light-grey">Manage User Groups</span>
                                            </Link></li>
                                            <li><Link to='/manage-users' className="user-list-options">
                                                <img src="/images/manage-users.svg" alt="manage-users"/>
                                                <span className="color-black  font-family-v1 font-12  light-grey">Manage Users</span>
                                            </Link></li>
                                            <li>
                                                <Link to='/external-group-mapping' className="user-list-options">
                                                     {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                     <img src="/images/external-grp-map.svg" alt="external-grp-map"/>
                                                    <span className="color-black  font-family-v1 font-12  light-grey">External Group Mapping</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </ListItem>
                            <ListItem className="item user-item" disableGutters>
                                <button className="main-nav-1 user-btn" onClick={() => setuserPolicies(!userPolicies)}>
                                    {/* <span className="flaticon-shopping-basket-button">
                                        <span className="color-black  font-family-v1 font-14 font-weight-500">User Policies</span>
                                    </span> */}
                                    <img src="/images/user-policies.svg" style={{marginLeft:"20px"}} alt="user-policies"/>
                                    <span className="color-black  font-family-v1 font-14 font-weight-500 ml-10">User Policies</span>
                                    <span className={`${userPolicies ? "flaticon-expand-arrow" : "flaticon-downwards-arrow-key"} `} ></span>
                                </button>

                                {
                                    userPolicies && <div className="integrationItem">
                                        <ul>
                                            <li>
                                                <Link to="/permissions/listing" className="user-list-options">
                                                    {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                    <img src="/images/permission-grp.svg" alt="external-grp-map"/>
                                                    <span className="color-black  font-family-v1 font-12 light-grey">Permission Groups</span>
                                                </Link>
                                            </li>

                                            <li><Link to='/policy/listing' className="user-list-options">{/* /role-groups */}
                                                {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                <img src="/images/policies.svg" alt="external-grp-map"/>
                                                <span className="color-black  font-family-v1 font-12 light-grey">Policies </span>
                                            </Link></li>
                                            <li><Link to='/policy/template/listing' className="user-list-options" >
                                                {/* <span className="flaticon-shopping-basket-button"></span> */}
                                                <img src="/images/policy-temp.svg" alt="external-grp-map"/>
                                                <span className="color-black  font-family-v1 font-12 light-grey">Policy Templates</span>
                                            </Link></li>
                                            <li>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </ListItem>
                            {/* <div className="divider-nav"></div> */}



                        </>

                    }
                    <div className="main_menu">
                        <ListItem className="item" style={{ paddingLeft: "0px", paddingRight: "0px" }} disableGutters>
                            <button className={`main-menu hover-effect ${integrationMenu ? "selected-border selected-shadow" : ""}`} onClick={() => setIntegrationMenu(!integrationMenu)}>
                                <img src="/images/service-overview.svg" className="main-menu-icon" style={{ marginLeft: "23px" }} alt='..'/>
                                <p className="main-menu-name  font-family-v1 font-14 font-weight-500">Integrations</p>
                                <span className={`${integrationMenu ? "flaticon-expand-arrow" : "flaticon-downwards-arrow-key"} `} style={{ marginLeft: "69px" }}></span>
                            </button>
                            {
                                integrationMenu && <div className="integrationItem">
                                    <ul>
                                        {
                                            IsSuperUser() &&
                                            (
                                              <>
                                                {/* <li>
                                                  <Link
                                                    to="/integration/containerRegistries"
                                                    className="d-flex align-center"
                                                  >
                                                    <span class="material-icons material-icons-outlined">
                                                      list_alt
                                                    </span>
                                                    <span className=" font-family-v1 font-12  light-grey">
                                                      Container Registries
                                                    </span>
                                                  </Link>
                                                </li> */}
                        
                                                <Link
                                                  to="/integration/containerRegistries"
                                                  className=""
                                                >
                                                  <div className={classes.subOption}>
                                                    <button
                                                      className="sub-menu"
                                                      onClick={() => {
                                                        setSelectedOption(1);
                                                        setIntegrationMenu(true);
                                                      }}
                                                      style={
                                                        selectedOption === 1 &&
                                                        checkPathForIntegration(integrationUrls)
                                                          ? {
                                                              backgroundColor: "#1E3347",
                                                              borderLeft: "4px solid #0086FF",
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                      {/* <img src="/images/repo-integration.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} /> */}
                                                      <span
                                                        className="material-icons material-icons-outlined sub-menu-icon"
                                                        style={{ marginLeft: "30px", color: "white" }}
                                                      >
                                                        list_alt
                                                      </span>
                                                      <p className="sub-menu-name font-family-v1 font-12 light-grey">
                                                        Container Registries
                                                      </p>
                                                    </button>
                                                  </div>
                                                </Link>
                                              </>
                                            )
                                        }
                                        <Link to="/repo/list" className="">
                                            <div className={classes.subOption}>
                                                <button className="sub-menu"
                                                  onClick={() => {
                                                    setSelectedOption(2);
                                                    setIntegrationMenu(true);
                                                  }}
                                                  style={
                                                    selectedOption === 2 &&
                                                    checkPathForIntegration(integrationUrls)
                                                      ? {
                                                          backgroundColor: "#1E3347",
                                                          borderLeft: "4px solid #0086FF",
                                                        }
                                                      : {}
                                                  }
                                                >
                                                    <img src="/images/repo-integration.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} alt='..' />
                                                    <p className="sub-menu-name font-family-v1 font-12 light-grey">Repo Integration</p>
                                                </button>
 
                                            </div>
                                        </Link>
                                        <Link to="/secrets/list" className="">
                                            <div className={classes.subOption}>
                                                <button className="sub-menu"
                                                  onClick={() => {
                                                    setSelectedOption(3);
                                                    setIntegrationMenu(true);
                                                  }}
                                                  style={
                                                    selectedOption === 3 &&
                                                    checkPathForIntegration(integrationUrls)
                                                      ? {
                                                          backgroundColor: "#1E3347",
                                                          borderLeft: "4px solid #0086FF",
                                                        }
                                                      : {}
                                                  }
                                                >
                                                    <img src="/images/secrets.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} alt='..' />
                                                    <p className="sub-menu-name font-family-v1 font-12 light-grey">Secrets</p>
                                                </button>

                                            </div>
                                        </Link>
                                        <Link to="/helm/integration/list" className="">
                                            <div className={classes.subOption}>
                                                <button className="sub-menu"
                                                  onClick={() => {
                                                    setSelectedOption(6);
                                                    setIntegrationMenu(true);
                                                  }}
                                                  style={
                                                    selectedOption === 6 &&
                                                    checkPathForIntegration(integrationUrls)
                                                      ? {
                                                          backgroundColor: "#1E3347",
                                                          borderLeft: "4px solid #0086FF",
                                                        }
                                                      : {}
                                                  }
                                                >
                                                    <img src="/images/secrets.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} />
                                                    <p className="sub-menu-name font-family-v1 font-12 light-grey">Helm Integration</p>
                                                </button>

                                            </div>
                                        </Link>
                                        {
                                            !IsSuperUser() &&
                                            <Link to="/eventlistener/list" className="">
                                                <div className={classes.subOption}>
                                                    <button className="sub-menu"
                                                      onClick={() => {
                                                        setSelectedOption(4);
                                                        setIntegrationMenu(true);
                                                      }}
                                                      style={
                                                        selectedOption === 4 &&
                                                        checkPathForIntegration(integrationUrls)
                                                          ? {
                                                              backgroundColor: "#1E3347",
                                                              borderLeft: "4px solid #0086FF",
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                        <img src="/images/git-events.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} alt='..' />
                                                        <p className="sub-menu-name font-family-v1 font-12 light-grey">Git Events</p>
                                                    </button>

                                                </div>
                                            </Link>
                                        }
                                        {
                                            IsSuperUser() &&
                                            (
                                              <>
                                                {/*
                                            
                                                    <li>
                                                <Link
                                                  to="/tickets/list"
                                                  className="d-flex align-center"
                                                >
                                                  <span class="material-icons material-icons-outlined">
                                                    confirmation_number
                                                  </span>
                                                  <span className="font-family-v1 font-12 light-grey">
                                                    Ticketing
                                                  </span>
                                                </Link>
                                              </li>
                                                */}
                                                <Link to="/tickets/list" className="">
                                                  <div className={classes.subOption}>
                                                    <button
                                                      className="sub-menu"
                                                      onClick={() => {
                                                        setSelectedOption(5);
                                                        setIntegrationMenu(true);
                                                      }}
                                                      style={
                                                        selectedOption === 5 &&
                                                        checkPathForIntegration(integrationUrls)
                                                          ? {
                                                              backgroundColor: "#1E3347",
                                                              borderLeft: "4px solid #0086FF",
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                      {/* <img src="/images/repo-integration.svg" className="sub-menu-icon" style={{ marginLeft: "32px" }} /> */}
                                                      <span
                                                        className="material-icons material-icons-outlined sub-menu-icon"
                                                        style={{ marginLeft: "30px", color: "white" }}
                                                      >
                                                        confirmation_number
                                                      </span>
                                                      <p className="sub-menu-name font-family-v1 font-12 light-grey">
                                                        Ticketing
                                                      </p>
                                                    </button>
                                                  </div>
                                                </Link>
                                              </>
                                            )
                                        }
                                    </ul>
                                </div>
                            }
                        </ListItem>

                    </div>
                    <div className="main_menu">
                        <Link to="" className="">
                            <button className="main-menu help-support-btn">
                                <img src="/images/customer-service-line.svg" className="main-menu-icon" style={{ marginLeft: "23px" }} alt='..' />
                                <p className="main-menu-name font-weight-500">Help &amp; Support</p>
                                <div className="new-feature-chip">New</div>
                            </button>
                        </Link>
                    </div>
                    <div className={classes.signOutButton}>
                        <a href="/initiateLogOut" className="sign-out" onClick={() => { }}>
                            <img src="/images/log-out-icon.svg" className="log-out-icon" alt='..'/>
                            <p className="button-text">Sign Out</p>
                        </a>
                    </div>
                    <p className="version-number">Build Version: {react_version ? react_version : "N/A"}</p>
                </List>
            </Drawer>
        </>
    );
};

NavigationNew.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const NavForRoute = (props) => {
    const link = props.link;
    const selected = props.selected;
    const data = props.data;
    const classes = useStyles();
    //const icon = props.icon;
    const imgPath = props.imgPath;
    const imgPathSelected = props.imgPathSelected
    console.log(imgPath, "sdbjhbsjsbdkfhdsf")

    return (
        <ul className={classes.subOption}>
            <Link
                to={link}
                className={""}
            >
                <button className="sub-menu" style={selected ? { backgroundColor: "#1E3347", borderLeft: "4px solid #0086FF" } : {}}>
                    {
                        selected ?
                            <img src={imgPathSelected} className="sub-menu-icon" style={{ marginLeft: "28px" }} alt='..' /> :
                            <img src={imgPath} className="sub-menu-icon" style={{ marginLeft: "32px" }} alt='..' />
                    }
                    <p className="sub-menu-name">{data.label}</p>
                </button>
            </Link>
        </ul>
    );
};

NavForRoute.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

const ExpandableNavForRoute = (props) => {
    console.log("e_p", props);
    const classes = useStyles();
    //const link = props.link;
    const selected = props.selected;
    const sub_links = props.sub_links;
    const data = props.data;
    const always_open = props.always_open;
    const [state, setState] = useState({ open: selected });
    //const icon = props.icon;

    console.log(props, "jdbfsdfjsfguefkj")

    return (
        
            <ListItem className={classes.SubItem} disableGutters>
                {always_open ?
                    (
                        <>
                            {/* <span className="main-nav-1 color-fa" style={{ cursor: "auto" }}>
                            <span className="fa-color-white">
                                <span className="color-black ">{data.label}</span>
                            </span>
                        </span> */}
                            <p className="dock-header" style={{ cursor: "auto" }}>{data.label.toUpperCase()}</p>
                            <div>{sub_links}</div>
                        </>
                    )
                    :
                    (
                        <>
                            <button
                                className={state.open ? "app-cluster-button selected-border selected-shadow" : "app-cluster-button"}
                                onClick={() => {
                                    setState({ ...state, open: !state.open });
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", width: "fit-content" }}>  {/* width: "80%" */}
                                    <img src="/images/side-panel-app-icon.svg" style={{ height: "24px", width: "24px", filter: "brightness(0) invert(1)", }} alt='..'/>
                                    <Tooltip title={data.label} placement="bottom">
                                        <span className="app-name">
                                            {data.label}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div style={{ width: "15%", position: "absolute", left: "200px" }}>
                                    <span
                                        className={
                                            state.open
                                                ? "flaticon-expand-arrow"
                                                : "flaticon-downwards-arrow-key"
                                        }
                                    ></span>
                                </div>
                            </button>
                            <div className={!state.open ? classes.hidden : ""}>{sub_links}</div>
                        </>
                    )}
            </ListItem>
        
    );
};

ExpandableNavForRoute.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default NavigationNew;

export const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        backgroundColor: "#234361",
        [theme.breakpoints.up("lg")]: {
            marginTop: 96,
            height: "100%",
            width: 240,
            backgroundColor: "#234361",
        },
    },
    subOption: {
        // listStyle: "none",
        // display: "grid",
        cursor: "pointor",

        "& .sub-menu": {
            display: "flex",
            alignItems: "center",
            height: "36px",
            backgroundColor: "#234361",
            border: "none",
            width: "100%",

            "&:hover": {
              backgroundColor: "#1C3854",
            },

            "& .sub-menu-name": {
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#DBDBDB",
                marginLeft: "12px",
            },

            "& .sub-menu-icon": {
                height: "20px",
                width: "20px",
            }

        }
    },



    rootTop: {
        backgroundColor: "#234361",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0),
        backgroundColor: "#000",
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
    item: {
        display: "inline-block",
        paddingTop: 0,
        paddingBottom: 0,
        width: "100%",
        paddingLeft: 12,
        paddingRight: 12,
        "& .main-nav-1 .flaticon-downwards-arrow-key": {
            color: "#8596a7 !important",
        },
        "& .main-nav-1 .flaticon-expand-arrow": {
            color: "#8596a7 !important",
        }
    },
    SubItem: {
        display: "inline-block",
        paddingTop: 0,
        paddingBottom: 0,
        width: "100%",
        // paddingLeft: "8px",
        // paddingRight: "8px",

        "& .dock-header": {
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "17px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#91A1B0",
          marginTop: "24px",
          marginLeft: "22px",
          marginBottom: "4px",
        },

        "& .app-cluster-button": {

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "52px",
            width: "100%",
            background: "none",
            border: "none",
            paddingLeft: "22px",
            paddingRight: "6px",
            position: "relative",
            transition: "background-color 0.3s ease-in-out",

            "&:hover": {
              backgroundColor: "#1C3854",
            },


            "& .app-name": {
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#FFFFFF",
                marginLeft: "12px",
                overflow: 'hidden',
                textWrap: 'nowrap',
                textOverflow: 'ellipsis'
            }

        },

        "& .selected-border": {
            borderLeft: "4px solid #0086FF",
        },

        "& .selected-shadow": {
            background :"linear-gradient(.25turn, #0086FF, 1%, #234361)"
        }
    },


    signOutButton: {

        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        position: "relative",

        "& .sign-out": {
          width: "224px",
          height: "42px",
          borderRadius: "8px",
          border: "1px solid #4F6981",
          backgroundColor: "#234361",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
          position: "absolute",
          left: "4%",
          marginLeft: "5px",
          transition: "background-color 0.3s ease-in-out",
    
          "&:hover": {
            backgroundColor: "#0086FF", // Change background color on hover
            border: "none",
          },
        },

        "& .button-text": {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#ffffff",
            marginLeft: "10px"

        },

        "& .log-out-icon": {

        }

    },

    itemOne: {
        display: "inline-block",
        paddingTop: 0,
        paddingBottom: 0,
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    hidden: {
        display: "none",
    },
    appList: {
        // height: '25rem',
        // overflowX: 'hidden',
        // overflowY: 'scroll'
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#234361",
        [theme.breakpoints.up("lg")]: {
            marginTop: 70,
            height: "100%",
            width: 256,
        },
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        "& .root": {
            backgroundColor: "#234361",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            //overflowY: "auto",

            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),


            "& .dashboard-button": {
                width: "210px",
                height: "52px",
                borderRadius: "12px",
                backgroundColor: "#0086FF",
                //marginTop: "21px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "15px",
                transition: "background-color 0.3 ease-in-out",

                "&:hover": {
                  backgroundColor: "#0068c7",
                },

                "& p": {
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                }
            },

            "& .item": {
                display: "inline-block",
                paddingTop: 0,
                paddingBottom: 0,
                width: "100%",
                paddingLeft: 12,
                paddingRight: 12,

                "& .user-btn": {
                  height: "52px",
                  justifyContent:"normal",
                  marginTop:0,
                  marginBottom: 0,
                  fontSize: "14px",
                  border: "1px solid white",
                  borderLeft: "0",
                  borderRight: "0",
                  borderImage: "linear-gradient(to right, transparent, #3c556d, transparent) 1",
                  borderImageSlice: "1",
                  "&:hover": {
                    backgroundColor: "#1C3854",
                  },

                },
                
                "& .main-nav-1 .flaticon-downwards-arrow-key": {
                    color: "#a7b5c2 !important",
                    position: "absolute",
                    
                    left: "204px",
                },
                "& .main-nav-1 .flaticon-expand-arrow": {
                    color: "#8596a7 !important",
                    position: "absolute",
                    
                    left: "204px",
                }
            },
            "& .hover-effect": {
              transition: "background-color 0.3s ease-in-out",
              paddingTop:"4px",
              paddingBottom: "4px",
              border: "1px solid white",
              borderLeft: "0",
              borderRight: "0",
              borderImage: "linear-gradient(to right, transparent, #3c556d, transparent) 1",
              borderImageSlice: "1",
              "&:hover": {
                backgroundColor: "#1C3854",
              },
            },
            "& .user-item": {
              paddingLeft: 0,
              paddingRight: 0,
              
              "& .user-list-options":{
                display: "flex",
                alignItems: "center",

                "& span":{
                  marginLeft:"8px",
                },
                "& img":{
                  marginLeft:"4px",
                  height: "20px",
                  width: "20px",
                },
              },
            },


            "& .main_menu": {

                "& .main-menu": {
                    display: "flex",
                    alignItems: "center",
                    height: "52px",
                    backgroundColor: "#234361",
                    border: "none",
                    width: "100%"
                },
                "& .hover-effect": {
                  border: "1px solid white",
                  borderLeft: "0",
                  borderRight: "0",
                  borderImage: "linear-gradient(to right, transparent, #3c556d, transparent) 1",
                  borderImageSlice: "1",
                  transition: "background-color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "#1C3854",
                  },
                },
                "& .main-menu>div": {
                  display: "flex",
                  alignItems: "center",
                },
        
                "& .help-support-btn": {
                  //justifyContent: "space-between",
                  paddingRight: "10px",
                  position: "relative",
                  transition: "background-color 0.3s ease-in-out",
        
                  "&:hover": {
                    backgroundColor: "#1C3854",
                  },
                },

                "& .main-menu-icon": {
                    width: "24px",
                    height: "24px",
                    marginLeft: "10px"
                },

                "& .main-menu-name": {
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    marginLeft: "10px",
                    color: "#ffffff"

                },

                "& .new-feature-chip": {
                    width: "43px",
                    height: "28px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "12px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    backgroundColor: "#395671",
                    //marginLeft: "41px",
                    position: "absolute",
                    left: "200px",
                },
                "& .selected-border": {
                  borderLeft: "4px solid #0086FF",
                },
        
                "& .selected-shadow": {
                  background: "linear-gradient(.25turn, #0086FF, 1%, #234361)",
                },


            },


            "& .integrationItem": {
                "& ul": {
                    listStyle: 'none',
                    "& li": {
                        padding: '6px 20px',
                        "& a": {
                            fontSize: "11px",
                            color: '#8598a7',
                            '& .material-icons': {
                                fontSize: '18px',
                                marginRight: '5px'
                            },
                            "& span": {
                                color: '#8598a7',
                            }
                        }
                    }
                }
            },

            "& .version-number": {
                marginTop: "20px",
                marginBottom: "80px",
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "300",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                marginLeft: "30px",
                color: "#ABABAB",
            },
        },
    },
    drawerPaperClose: {
        backgroundColor: "#234361",
        marginBottom: 96,
        overflowX: "hidden",
        "& span.color-black": {
            display: "none",
        },
        "& span.Y6 ": {
            display: "none",
        },
        "& .text-home": {
            display: "none",
        },
        "& .main-nav": {
            padding: "8px 4px",
            borderRadius: "24px",
            height: "30px",
            width: "30px",
            "& .flaticon-right-arrow-forward": {
                display: "none",
            },
        },

        "& .subOptionChild": {
            marginLeft: "0px",
            padding: "5px 0px!important",
        },
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    toolbarIcon: {
        paddingRight: "5px",
    },
}));
