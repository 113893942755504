import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../components/genericComponents/Input';
import Grid from '@material-ui/core/Grid';
import { getCommonFunctions, ResetComponent } from '../ci_flow/SourceDetails';
import {  VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import CanaryTemplate from '../../../service/detail/components/CanaryTemplate';
import AlertStrip from '../../../../components/AlertStrips';

const LivenessReadiness = (props) => {
  const classes = useStyles();
  const inherits = props.inherits ? props.inherits : {};
  const extraProps = props.extraProps ? props.extraProps : {}
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getLivenessReadinessDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  useEffect(() => {
    setState(new_state => ({ ...new_state, ...prev_state }))
  }, [prev_state])

  return (
    <div className={`${classes.root} ci-cd-edit-input-div`}>
      <Grid container className="card-add-service">
        <Grid item lg={12} direction="row" className="card-add-service-head">
          <div className="formTag">
            <h4 className='mainHeading'>Liveness and Readiness</h4>
          </div>
        </Grid>
        <div>
          <Grid container spacing="2">
            <Grid lg={12} item className="pd-10">
              <div className="card">
                <div className="card-header">
                  <div className="heading">
                    Deployment Strategy: Rolling Update
                  </div>
                </div>
                <div className="card-body">
                  <Grid container spacing="2" className="pd-10">

                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        label="Max Surge"
                        name="max_surge"
                        subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        error={state.error}
                        mandatorySign
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        name="max_unavailable"
                        label="Max Unavailable"
                        subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        mandatorySign
                        error={state.error}
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        name="revision_history_limit"
                        label="Revision History Limit"
                        // subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        mandatorySign
                        error={state.error}
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        name="termination_grace_period"
                        label="Termination Grace Period"
                        // subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        mandatorySign
                        error={state.error}
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        name="progress_deadline"
                        label="Progress Deadline"
                        // subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        error={state.error}
                        mandatorySign
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Input
                        type="text"
                        placeholder="1 or 25%"
                        name="minimum_ready"
                        label="Minimum Ready"
                        // subHeading="(User can provide value in number or in percentage 1 or 25%)"
                        data={state.data}
                        error={state.error}
                        mandatorySign
                        onChangeHandler={commonFunctions.onChangeHandler}
                      />
                    </Grid>

                    {
                      extraProps.istio_enabled ?
                        <Grid item lg={12}>
                          <div className='card' style={{ margin: 0 }}>
                            <div className='heading'>
                              <Input
                                type="switch"
                                name="canary_template_1"
                                label="Do you want to do Canary deployment also?"
                                onChangeHandler={commonFunctions.toggleState}
                                data={state.data}
                                error={state.error}
                                enabledComponent={<CanaryTemplate
                                  prev_state={state.canary_template}
                                  variant="list_only"
                                  inherits={state.child_inherits.canary_template} />}
                                disabledComponent={<ResetComponent inherits={state.child_inherits.canary_template} />}
                              />
                            </div>
                          </div>
                        </Grid>

                        : <Grid item lg={12}>
                          <AlertStrip
                            message="To enable canary deployment contact your admin to setup ISTIO in the namespace of this environment."
                            variant="info"
                          />
                        </Grid>
                    }

                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid lg={12} item className="pd-10">
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="liveliness"
                    label="Do you have liveness health check URL?"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<Liveness prev_state={state.Liveness_data} inherits={state.child_inherits.Liveness_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.Liveness_data} />}
                  />
                </div>

              </div>
            </Grid>
            <Grid lg={12} item className="pd-10">
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="readiness"
                    label="Do you have readiness health check URL?"
                    onChangeHandler={commonFunctions.toggleState}
                    data={state.data}
                    error={state.error}
                    enabledComponent={<Readiness prev_state={state.Readiness_data} inherits={state.child_inherits.Readiness_data} />}
                    disabledComponent={<ResetComponent inherits={state.child_inherits.Readiness_data} />}
                  />
                </div>
              </div>
            </Grid>
            <Grid item lg={12} className="pd-10">
              <div className='card'>
                <div className='heading'>
                  <Input
                    type="switch"
                    name="deployment_rollback_validation"
                    label="Validate Deployment Rollout"
                    // subHeading="(User can provide value in number or in percentage 1 or 25%)"
                    data={state.data}
                    error={state.error}
                    onChangeHandler={commonFunctions.toggleState}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>

  )
}

LivenessReadiness.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}


export default LivenessReadiness;


export function getLivenessReadinessDefaultState() {
  return {
    data: {
      liveliness: false,
      readiness: false,
      "minimum_ready": 0,
      "progress_deadline": 600,
      "termination_grace_period": 30,
      "revision_history_limit": 10,
      max_surge: '100%',
      max_unavailable: '5%',
      deployment_rollback_validation: true
    },
    error: {},
    validations: {
      max_surge: [VALIDATION_TYPE_REQUIRED],
      max_unavailable: [VALIDATION_TYPE_REQUIRED]
    },
    child_inherits: {
      Liveness_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      Readiness_data: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      },
      canary_template: {
        "validateForm": () => { return { valid: true }; },
        "getState": () => { return {}; },
        "getData": () => { return {}; }
      }
    }
  };
}

function Liveness(props) {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;

  const [state, setState] = useState(prev_state ? prev_state : getLivenessDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);

  const radio_list = [
    {
      label: "URL or Path",
      value: "HTTP"
    },
    {
      label: "Command",
      value: "EXEC"
    }
  ]
  return (
    <>
      <div className='card-body' style={{ padding: '10px 10px 25px' }}>
        <Grid container spacing={2} style={{ marginBottom: '15px' }}>
          <Grid lg={12} item direction="row">
            <Input
              type="radio"
              label="Execution Type"
              name="probe_type"
              list={radio_list}
              data={state.data}
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandler}
            />
          </Grid>
          {state.data.probe_type === "HTTP" ?
            <>
              <Grid lg={6} item direction="row">
                <Input
                  type="text"
                  name="path"
                  label="URL or Path"
                  placeholder="/demo-app/healthcheck"
                  data={state.data}
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandler}
                />
              </Grid>
              <Grid lg={6} item direction="row">
                <Input
                  type="text"
                  name="port"
                  label="Port Running on"
                  placeholder="8000"
                  onChangeHandler={commonFunctions.onChangeHandlerNumber}
                  data={state.data}
                  error={state.error} />
              </Grid>
            </>
            : null
          }
          {state.data.probe_type === "EXEC" ?
            <Grid lg={6} item direction="row">
              <Input
                type="text"
                name="exec_cmd"
                label="Execute Command"
                placeholder="Command"
                data={state.data}
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}

              />
            </Grid>
            : null
          }


        </Grid>
        <div>
          <span className="InputLabel">Set Liveness Time Settings</span>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridGap: "5px" }}>
            <Input
              type="text"
              name="initial_delay_seconds"
              label="Delay"
              placeholder="5"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              name="period_seconds"
              label="Period"
              placeholder="5"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              name="timeout_seconds"
              label="Timeouts"
              placeholder="25"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              placeholder="100"
              name="failure_threshold"
              label="Failure"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
          </div>
        </div>
      </div>
    </>
  )
}

Liveness.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getLivenessDefaultState() {
  return {
    data: {
      probe_type: 'HTTP'
    },
    error: {},

    validations: {
      initial_delay_seconds: [VALIDATION_TYPE_REQUIRED],
      period_seconds: [VALIDATION_TYPE_REQUIRED],
      timeout_seconds: [VALIDATION_TYPE_REQUIRED],
      failure_threshold: [VALIDATION_TYPE_REQUIRED]
    }
  };
}

function Readiness(props) {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : getReadinessDefaultState())
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const radio_list = [
    {
      label: "URL or Path",
      value: "HTTP"
    },
    {
      label: "Command",
      value: "EXEC"
    }
  ]
  return (
    <>
      <div className='card-body' style={{ padding: '10px 10px 25px' }}>
        <Grid container spacing={2} style={{ marginBottom: '15px' }}>
          <Grid lg={12} item direction="row">
            <Input
              type="radio"
              label="Execution Type"
              name="probe_type"
              list={radio_list}
              data={state.data}
              error={state.error}
              onChangeHandler={commonFunctions.onChangeHandler}
            />
          </Grid>
          {state.data.probe_type === "HTTP" ?
            <>
              <Grid lg={6} item direction="row">
                <Input
                  type="text"
                  name="path"
                  label="URL or Path"
                  placeholder="/demo-app/healthCheck"
                  error={state.error}
                  onChangeHandler={commonFunctions.onChangeHandler}
                  data={state.data}
                />
              </Grid>
              <Grid lg={6} item direction="row">
                <Input
                  type="text"
                  name="port"
                  label="Port Running on"
                  placeholder="8080"
                  onChangeHandler={commonFunctions.onChangeHandlerNumber}
                  data={state.data}
                  error={state.error} />
              </Grid>
            </>
            : null}
          {state.data.probe_type === "EXEC" ?
            <Grid lg={6} item direction="row">
              <Input
                type="text"
                name="exec_cmd"
                label="Execute Command "
                placeholder="Command"
                error={state.error}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
              />
            </Grid>
            : null}

        </Grid>

        <div>
          <span className="InputLabel">Set Readiness Time Settings</span>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridGap: "5px" }}>
            <Input
              type="text"
              name="initial_delay_seconds"
              label="Delay"
              placeholder="5"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              name="period_seconds"
              label="Period"
              placeholder="5"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              name="timeout_seconds"
              label="Timeouts"
              placeholder="20"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
            <Input
              type="text"
              name="failure_threshold"
              label="Failure"
              placeholder="100"
              onChangeHandler={commonFunctions.onChangeHandlerNumber}
              data={state.data}
              error={state.error} />
          </div>
        </div>
      </div></>

  )
}
Readiness.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export function getReadinessDefaultState() {
  return {
    data: { probe_type: 'HTTP' },
    error: {},

    validations: {
      initial_delay_seconds: [VALIDATION_TYPE_REQUIRED],
      period_seconds: [VALIDATION_TYPE_REQUIRED],
      timeout_seconds: [VALIDATION_TYPE_REQUIRED],
      failure_threshold: [VALIDATION_TYPE_REQUIRED]
    }
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    '& > .card-add-service': {
      borderRadius: '0px',
      border: '0px'
    },
    '& .card-add-service-head': {
      borderTop: '1px solid #e7e7eb',
      borderRadius: '0px',
    },
    '& > .card': {
      backgroundColor: '#f6f8f8',
      borderRadius: '0px',
      '& .card-footer': {
        backgroundColor: '#fff',
        justifyContent: 'flex-end'
      },
      '& .card ': {
        marginBottom: '15px',
        boxShadow: 'none',
        '& .input-component': {
          marginBottom: '0px'
        }
      }
    }
  }
}))
