import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import properties from '../../../properties/properties';
import InvokeApi, { PostData } from '../../../util/apiInvoker';
import GenerateURL from '../../../util/APIUrlProvider';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { Input } from '../Input';
import AddIcon from '@material-ui/icons/Add';
import { RemoveFromArray, showErrorHandlerUpdated } from '../../../util/util';
import DeleteIcon from '@material-ui/icons/Delete';
import { Loading } from '../../../views/utils/Loading';
import { ErrorComponent } from '../../../views/utils/Error';
import Grid from '@material-ui/core/Grid'
import AlertStrip from '../../AlertStrips';
function GitRepo(props) {
    const classes = useStyles();
    const single_path = props.single_path;
    const only_repo_and_branch_dropdown = props.only_repo_and_branch_dropdown ? props.only_repo_and_branch_dropdown : false
    const inherits = props.inherits;
    const fetchApiResponse = props.fetchApiResponse ? props.fetchApiResponse : false;
    const fetchApi = props.fetchApi ? props.fetchApi : () => { }
    const with_command_args = props.with_command_args
    const variant = props.variant ? props.variant : null;
   
    const [state, setState] = useState(props.state ? { ...getDefaultState(variant), ...props.state } : getDefaultState(variant));

    const [branches, setBranches] = useState([]);
    useEffect(() => {
        if (state.branch_data?.branches) {
            setState(new_state => ({
                ...props.state,
                ...new_state
            }))

        }
        else {
            setState(new_state => ({
                ...new_state,
                ...props.state
            }))
        }

    }, [props.state])
    useEffect(() => {
      if(variant && variant == "with_helm_support"){
        setState(new_state => ({
            ...new_state,
            form_data :{
                ...new_state.form_data,
                default_validations:{
                    ...new_state.form_data.default_validations,
                    helm_integration_id: [VALIDATION_TYPE_REQUIRED],
                    helm_template: [VALIDATION_TYPE_REQUIRED],
                    helm_version: [VALIDATION_TYPE_REQUIRED],
                }
            }
        }))
      }
               
    }, [variant])
    
    useEffect(() => {
        fetchRepoList();
    }, []);

    // useEffect(()=>{              // will sent in next release

    //     setState(prevState=>({
    //         ...prevState,
    //         form_data : {...prevState.form_data, data: {...prevState.form_data.data, branch_name:''}},
    //         branch_data : {...prevState.branch_data, branches: null }
    //     }))

    // },[state.form_data.data.git_url])


    function onChangeHandler(e) {
        const key = e.target.name;
        let value = e.target.value;

        if (key == "credential_id") {

            if (Number(value)) {
                value = Number(value);
            } else {
                value = null;
            }
        }
    
        if(key == "choose_one" && value ==2){
            setState(new_state => ({
                ...new_state,
                form_data :{
                    ...new_state.form_data,
                    default_validations:{
                        ...new_state.form_data.default_validations,
                        helm_integration_id: [VALIDATION_TYPE_REQUIRED],
                        helm_template: [VALIDATION_TYPE_REQUIRED],
                        helm_version: [VALIDATION_TYPE_REQUIRED],
                        git_url: [],
                        branch_name: [],
                        file_paths: [],
                    }
                }
            }))
        }
        if(key == "choose_one" && value ==1){
            setState(new_state => ({
                ...new_state,
                form_data :{
                    ...new_state.form_data,
                    default_validations:{
                        ...new_state.form_data.default_validations,
                        helm_integration_id: [],
                        helm_template: [],
                        helm_version: [],
                        git_url: [VALIDATION_TYPE_REQUIRED],
                        branch_name: [VALIDATION_TYPE_REQUIRED],
                        file_paths: [VALIDATION_TYPE_REQUIRED],
                    }
                }
            }))
        }
        updateData(key, value);
    }

    function updateData(key, value) {
        setState(new_state => ({
            ...new_state,
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    [key]: value
                },
                error: {
                    ...new_state.form_data.error,
                    [key]: ""
                }
            }
        }))
    }

    function addMoreFilePath() {
        updateData("file_paths", state.form_data.data.file_paths + ",");
    }

    function removeFilePath(index) {
        const file_path_array = state.form_data.data.file_paths.split(",");
        RemoveFromArray(file_path_array, file_path_array[index]);
        const file_paths = file_path_array.join();
        updateData("file_paths", file_paths);
    }

    function onFilePathChange(e, index) {
        const file_path_array = state.form_data.data.file_paths.split(",");
        file_path_array[index] = e.target.value;
        const file_paths = file_path_array.join();
        updateData("file_paths", file_paths);
    }


    const fetchRepoList = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.fetch_all_repo + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, FetchRepoSuccessResponse, FetchRepoFailedResponse);


    }

    const FetchRepoSuccessResponse = (response) => {
        fetchHemlRepoList();

        let available_repos = [];
        if (response.results) {
             available_repos = response.results.map(item => {
                return { id: item.id, label: item.name + " : " + item.git_url, url: item.git_url, cred_id: item.credential_id, git_provider_id: item.git_provider_id }
            })
            
            if (state.form_data && state.form_data.data) {
                if (state.form_data.data.branch_name && state.form_data.data.branch_name != "") {
                    testAndGetBranches(available_repos);
                } else {
                 
                    if (state.form_data.data.draft_branch && (state.form_data.data.draft_branch != null && state.form_data.data.draft_branch != "")) {
                   
                        testAndGetBranches(available_repos);
                    }
                }
            }
        } else {
             available_repos = [{ label: "No Repo Available", id: null }]
        }

        setState(new_state => ({
            ...new_state,
            available_repos: available_repos
        }));

    }
    const FetchRepoFailedResponse = (error_resposne) => {
        let response = error_resposne.detail;

        setState(new_state => ({
            ...new_state,
            available_repos: [{ id: null, label: response }]
        }));
    }


    //Overloadings
    inherits.validateForm = () => {
        const result = ValidateDataSet(state.form_data.data, state.form_data.default_validations);
        if (variant == "MANIFEST_BRANCH") {
            RemoveFromArray(state.form_data.default_validations.branch_name, VALIDATION_TYPE_REQUIRED);
        } else {
            RemoveFromArray(state.form_data.default_validations.draft_branch, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(state.form_data.default_validations.deployed_branch, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(state.form_data.default_validations.main_branch, VALIDATION_TYPE_REQUIRED);
            RemoveFromArray(state.form_data.default_validations.credential_id, VALIDATION_TYPE_REQUIRED);
        }
        if(variant == "with_helm_support"){
            if(state.form_data.data.choose_one == 1) {
                RemoveFromArray(state.form_data.default_validations.helm_integration_id, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.form_data.default_validations.helm_version, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.form_data.default_validations.helm_template, VALIDATION_TYPE_REQUIRED);
            }
            if(state.form_data.data.choose_one == 2) {
                RemoveFromArray(state.form_data.default_validations.git_url, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.form_data.default_validations.branch_name, VALIDATION_TYPE_REQUIRED);
                RemoveFromArray(state.form_data.default_validations.file_paths, VALIDATION_TYPE_REQUIRED);
            }
        }
       
        // if(!with_command_args){
        //     RemoveFromArray(state.form_data.default_validations.cmd_agrs, VALIDATION_TYPE_REQUIRED);
        // }
        if (!result.valid) {

            setState(new_state => ({
                ...new_state,
                form_data: {
                    ...new_state.form_data,
                    error: result.error
                }
            }));
        }
        return result;
    }
    inherits.getData = () => {
        let result = {
            branches: state.branch_data.branches
            , ...state.form_data.data
        }
        return result;
    }

    inherits.getState = () => {
        return state;
    }


    function testAndGetBranches(repo_list) {
        const result = ValidateDataSet({
            git_url: state.form_data.data.git_url,
        },
            {
                git_url: [VALIDATION_TYPE_REQUIRED]
            }
        );
        if (result.valid) {
            if (state.available_repos && state.available_repos.length > 0) {
                var filter_current_repo_with_id = state.available_repos.filter(item => item.id == state.form_data.data.git_url);

            } else {
                if (repo_list && repo_list.length > 0) {
                    var filter_current_repo_with_id = repo_list.filter(item => item.id == state.form_data.data.git_url);

                }
            }


            let cred_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].cred_id ? filter_current_repo_with_id[0].cred_id : null;
            let gir_repo_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].url ? filter_current_repo_with_id[0].url : null;

            const post_data = {
                git_url: gir_repo_id,
            };
            if (cred_id) {
                post_data.credential_id = cred_id
            }
            if (!post_data.credential_id) {
                delete post_data.credential_id;
            }
            PostData(GenerateURL({}, properties.api.git_test), post_data, repo_list && repo_list.length > 0 ? (data) => onFetchSuccess(data, repo_list) : onFetchSuccess, onFetchFail);
            setState(new_state => ({
                ...new_state,
                branch_data: {
                    loading: true,
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                form_data: {
                    ...new_state.form_data,
                    error: {
                        ...new_state.form_data.error,
                        ...result.error,
                    }
                }
            }));
        }
    }

    function onFetchSuccess(data, list) {

        if (fetchApiResponse) {
            setState(new_state => ({
                ...new_state,
                branch_data: {
                    ...new_state.branch_data,
                    loading: false,
                    ...data,
                }
            }));
            if (list && list.length > 0) {
                var current_repo_url = list.filter(item => item.id == state.form_data.data.git_url)
            } else {
                var current_repo_url = state.available_repos.filter(item => item.id == state.form_data.data.git_url)
            }


            let current_git_provider_id = current_repo_url[0] && current_repo_url[0].git_provider_id ? current_repo_url[0].git_provider_id : null
            if (current_git_provider_id) {
                fetchApi(current_git_provider_id)
            }
            setBranches(data.branches)
            setState(new_state => ({
                ...new_state,
                branch_data: {
                    ...new_state.branch_data,
                    loading: false,
                    ...data
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                branch_data: {
                    ...new_state.branch_data,
                    loading: false,
                    ...data
                }
            }));
        }

    }



    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_data: {
                ...new_state.branch_data,
                loading: false,
                error: error,
            }
        }));
    }

    function getBranchList(branches) {
        const result = [];

        branches.forEach(branch => {
            result.push({
                id: branch,
                label: branch
            })
        });
        return result;
    }

    function toggleState() {
        setState(new_state => ({
            ...new_state,
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    edit: true
                }
            }
        }));
    }
    const onKeyValueChangeHandler = (k, v) => {
        setState(new_state => ({
            ...new_state,
            form_data: {
                ...new_state.form_data,
                data: {
                    ...new_state.form_data.data,
                    [k]: v
                },
                error: { ...state.form_data.error, [k]: null }
            }
        }));

    }
    const onKeyValueHandler = (k, v) => {
        if(k ==="helm_template"){
            getVersions(v);
            setState({
                ...state,
                templates_data:{
                    ...state.templates_data,
                    loading_versions: true
                },
                form_data: {
                    ...state.form_data,
                    data: {
                        ...state.form_data.data,
                        [k]: v,

                    },
                    errors: { ...state.form_data.errors, [k]: "" },
                    error: { ...state.form_data.error, [k]: "" }
                }
            });
        }else{
            setState({
                ...state,
                form_data: {
                    ...state.form_data,
                    data: {
                        ...state.form_data.data,
                        [k]: v
                    },
                    error: { ...state.form_data.error, [k]: "" },
                    errors: { ...state.form_data.errors, [k]: "" }
                }
            });
        }
        
    }


    const fetchHemlRepoList = () => {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.get_post_helm_chart + "?all=true"),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, FetchHelmRepoSuccessResponse, FetchHelmRepoFailedResponse);
        
    }

    const FetchHelmRepoSuccessResponse = (response) => {


        if (response.results) {
            var available_helm_templates = response.results.map(item => {
                return { id: item.id, label: item.name + " : " + item.helm_url, url: item.helm_url, name: item.name }
            })
            if (state.form_data && state.form_data.data) {
                if (state.form_data.data.helm_template && state.form_data.data.helm_template != "") {
                    testAndGetHemlTemplates(available_helm_templates);
                }
            }
            
        } else {
            var available_helm_templates = [{ label: "No Repo Available", id: null }]
        }

        setState(new_state => ({
            ...new_state,
            available_helm_templates: available_helm_templates,

        }));

    }
    const FetchHelmRepoFailedResponse = (error_resposne) => {
        var response = showErrorHandlerUpdated(error_resposne);
        setState(new_state => ({
            ...new_state,
            available_helm_templates: [{ id: null, label: response }],
            error_in_loading_helm:  response
        }));
    }





    function testAndGetHemlTemplates(repo_list) {
       
        const result = ValidateDataSet({
            helm_url: state.form_data.data.helm_integration_id,
        },
            {
                helm_url: [VALIDATION_TYPE_REQUIRED]
            }
        );
        if (result.valid) {
            if (state.available_helm_templates && state.available_helm_templates.length > 0) {
                var filter_current_repo_with_id = state.available_helm_templates.filter(item => item.id == state.form_data.data.helm_integration_id);

            } else {
                if (repo_list && repo_list.length > 0) {
                    var filter_current_repo_with_id = repo_list.filter(item => item.id == state.form_data.data.helm_integration_id);

                }
            }

            var cred_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].cred_id ? filter_current_repo_with_id[0].cred_id : null;
            var gir_repo_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].url ? filter_current_repo_with_id[0].url : null;
            var git_repo_name = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].name ? filter_current_repo_with_id[0].name : null;
            const post_data = {
                helm_url: gir_repo_id,
                name: git_repo_name
            };
            if (cred_id) {
                post_data.credential_id = cred_id
            }
            if (!post_data.credential_id) {
                delete post_data.credential_id;
            }
            PostData(GenerateURL({}, properties.api.fetch_helm_charts), post_data, repo_list && repo_list.length > 0 ? (data) => onFetchSuccessHelmTemps(data, repo_list) : onFetchSuccessHelmTemps, onFetchFailHelmTemps);
            setState(new_state => ({
                ...new_state,
                templates_data:{
                    loading: true, 
                }
            }));
        } else {
            setState(new_state => ({
                ...new_state,
                form_data: {
                    ...new_state.form_data,
                    error: {
                        ...new_state.form_data.error,
                        ...result.error,
                    }
                }
            }));
        }
    }

    function onFetchSuccessHelmTemps(data, list) {

        
        var fetch_helm_templates = data && data.chart_names ? data.chart_names.map(item => { return item.chart_name }) : []
        if (state.form_data && state.form_data.data) {
            if (state.form_data.data.helm_template && state.form_data.data.helm_template != "") {
                getVersions(state.form_data.data.helm_template, list);
            }
        }
            setState(new_state => ({
                ...new_state,
                templates_data:{
                    ...new_state.templates_data,
                    loading: false,
                    branches: fetch_helm_templates
                }
            }));
        

    }



    function onFetchFailHelmTemps(error) {

        var error_msg = showErrorHandlerUpdated(error);

        setState(new_state => ({
            ...new_state,
            templates_data:{
                ...new_state.templates_data,
                loading: false,
                error: error_msg,
            }
        }));
    }

    function getBranchList(branches) {
        
        const result = [];

        branches.forEach(branch => {
            result.push({
                id: branch,
                label: branch
            })
        });
        return result;
    }
    const getVersions = (val, list) => {

       
        if(list && list.length>0){
          
                var filter_current_repo_with_id = list.filter(item => item.id == state.form_data.data.helm_integration_id);
        }else{
            if (state.available_helm_templates && state.available_helm_templates.length > 0) {
                
                var filter_current_repo_with_id = state.available_helm_templates.filter(item => item.id == state.form_data.data.helm_integration_id);
    
            }
        }
        

        var gir_repo_id = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].url ? filter_current_repo_with_id[0].url : null;
        var git_repo_name = filter_current_repo_with_id && filter_current_repo_with_id[0] && filter_current_repo_with_id[0].name ? filter_current_repo_with_id[0].name : null;
        const post_data = {
            helm_url: gir_repo_id,
            name: git_repo_name,
            chart_name: val
        };
        PostData(GenerateURL({}, properties.api.helm_chart_versions), post_data, onFetchSuccessVersions, onFetchFailVersions);
        setState(new_state => ({
            ...new_state,
            templates_data: {
                ...new_state.templates_data,
                loading_versions: true,
            }
        }));

    }

    function onFetchSuccessVersions(data) {

        var fetch_helm_version = data && data.chart_version ? data.chart_version.map(item => { return { label: item, id: item } }) : []
       
        setState(new_state => ({
            ...new_state,
            templates_data: {
                ...new_state.templates_data,
                loading_versions: false,
                versions_list: fetch_helm_version
            }
        }));


    }



    function onFetchFailVersions(error) {

        var error_msg = showErrorHandlerUpdated(error);

        setState(new_state => ({
            ...new_state,
            templates_data: {
                ...new_state.templates_data,
                loading_versions: false,
                chart_version_error: error_msg,
            }
        }));
    }

    return (
        
            <Grid container spacing="2" alignItems="center" className="pd-10">
                {
                    variant == "with_helm_support" &&
                    <Grid item lg={10}>
                        <div className='' style={{ padding: '0px 10px' }}>
                            <Input
                                type="radio"
                                list={[{ label: "Git Repo", value: 1 }, { label: "Helm Repo", value: 2 }]}
                                data={state.form_data.data}
                                name="choose_one"
                                error={state.form_data.error}
                                onChangeHandler={onChangeHandler}
                            />

                        </div>
                    </Grid>
                }

                <>
                    {
                        state.form_data.data.choose_one == 2 &&
                        <Grid container spacing="2" alignItems="center" className="pd-10">
                            <Grid item lg={10}>
                                {
                                    state.error_in_loading_helm ?
                                    <AlertStrip variant="error" message={state.error_in_loading_helm}  />
                                    :
                                    <div className="auto-complete-dropdown auto-complete-dropdown-42">
                                    <Input
                                        type="auto-complete-dropdown" // "select"
                                        label="Please Select the Helm Repo"
                                        mandatorySign
                                        id={"git_urls"}
                                        name="helm_integration_id"
                                        list={state.available_helm_templates&& state.available_helm_templates.length > 0 ?
                                            state.available_helm_templates : []}
                                        placeholder="Git URL"
                                        getOptionLabel={(option) => option.label}
                                        error={state.form_data.error}
                                        data={state.form_data.data}
                                        onChangeHandler={onKeyValueHandler}
                                    />
                                </div>
                                }
                               
                            </Grid>

                            <>
                                <Grid item lg={2}>
                                    {
                                        state.templates_data.loading ?
                                            <div style={{ marginTop: '10px' }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <button className="btn btn-submit" style={{ width: '100%' }} onClick={testAndGetHemlTemplates}>Load Templates</button>
                                    }
                                </Grid>

                                <Grid item lg={12}>
                                    {state.templates_data.error ?
                                        <Error error={state.templates_data.error} />
                                        : <div className="auto-complete-dropdown auto-complete-dropdown-42">
                                            <Input
                                                type="auto-complete-dropdown" // "select"
                                                label="Select Helm Charts"
                                                mandatorySign
                                                id={"helm_charts_selection"}
                                                name="helm_template"
                                                list={state.templates_data.branches ? getBranchList(state.templates_data.branches) :  []}
                                                placeholder="Helm Charts"
                                                getOptionLabel={(option) => option.label}
                                                error={state.form_data.error}
                                                data={state.form_data.data}
                                                onChangeHandler={
                                                    state.templates_data.branches ? onKeyValueHandler  : null
                                                }
                                            />

                                        </div>
                                    }
                                </Grid>
                                <Grid item lg={12}>
                                    {state.templates_data.loading_versions ?
                                        <div style={{ marginTop: '10px' }}>
                                            <Loading varient="light" />
                                        </div>
                                        : state.templates_data.chart_version_error ?
                                            <Error error={state.templates_data.chart_version_error} /> :
                                            <div className="auto-complete-dropdown auto-complete-dropdown-42">
                                                <Input
                                                    type="auto-complete-dropdown" // "select"
                                                    label="Select Version"
                                                    mandatorySign
                                                    id={"helm_charts_selection"}
                                                    name="helm_version"
                                                    list={state.templates_data.versions_list && state.templates_data.versions_list.length > 0 ? state.templates_data.versions_list : []}
                                                    placeholder="Helm Chart Version"
                                                    getOptionLabel={(option) => option.label}
                                                    error={state.form_data.error}
                                                    data={state.form_data.data}
                                                    onChangeHandler={
                                                        state.templates_data.versions_list ? onKeyValueHandler :  null
                                                    }
                                                />

                                            </div>
                                    }
                                </Grid>
                            </>

                        </Grid>
                    }
                </>

                {
                    state.form_data.data.choose_one == 1 || variant != "with_helm_support" ?
                    <>
                     <Grid item lg={10}>
                    <div className="auto-complete-dropdown auto-complete-dropdown-42">
                        <Input
                            type="auto-complete-dropdown" // "select"
                            label="Please Select the Git URL"
                            mandatorySign
                            id={"git_urls"}
                            name="git_url"
                            list={state.available_repos && state.available_repos.length > 0 ?
                                state.available_repos : []}
                            placeholder="Git URL"
                            getOptionLabel={(option) => option.label}
                            error={state.form_data.error}
                            data={state.form_data.data}
                            onChangeHandler={onKeyValueHandler}
                        />
                    </div>
                </Grid>
               
                {
                    variant == "AUTO_APPROVAL" ?
                        <></> :
                        variant == "MANIFEST_BRANCH" ?
                            <>
                                <Grid item lg={2}>
                                    {
                                        state.branch_data.loading ?
                                            <div style={{ marginTop: '10px' }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <button className="btn btn-submit" style={{ width: '100%' }} onClick={testAndGetBranches}>Load Branches</button>
                                    }
                                </Grid>
                                {state.branch_data.error ?
                                    <Grid item lg={12}><ErrorComponent error={state.branch_data.error} /></Grid>
                                    :
                                    <>
                                        <Grid item lg={12}>
                                            <Input
                                                type="select"
                                                label="Select Draft Branch"
                                                name="draft_branch"
                                                placeholder="Branch"
                                                mandatorySign
                                                list={state.branch_data.branches ? getBranchList(state.branch_data.branches) : []}
                                                error={state.form_data.error}
                                                data={state.form_data.data}
                                                onChangeHandler={state.branch_data.branches ? onChangeHandler : null}
                                            />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Input
                                                type="select"
                                                label="Select Deployed Branch"
                                                name="deployed_branch"
                                                placeholder="Branch"
                                                mandatorySign
                                                list={state.branch_data.branches ? getBranchList(state.branch_data.branches) : []}
                                                error={state.form_data.error}
                                                data={state.form_data.data}
                                                onChangeHandler={state.branch_data.branches ? onChangeHandler : null}
                                            />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Input
                                                type="select"
                                                label="Select Main Branch"
                                                name="main_branch"
                                                placeholder="Branch"
                                                mandatorySign
                                                list={state.branch_data.branches ? getBranchList(state.branch_data.branches) : []}
                                                error={state.form_data.error}
                                                data={state.form_data.data}
                                                onChangeHandler={state.branch_data.branches ? onChangeHandler : null}
                                            />
                                        </Grid>
                                    </>}
                            </>
                            :
                            <>
                                <Grid item lg={2}>
                                    {
                                        state.branch_data.loading ?
                                            <div style={{ marginTop: '10px' }}>
                                                <Loading varient="light" />
                                            </div>
                                            :
                                            <button className="btn btn-submit" style={{ width: '100%' }} onClick={testAndGetBranches}>Load Branches</button>
                                    }
                                </Grid>

                                <Grid item lg={12}>
                                    {state.branch_data.error ?
                                        <ErrorComponent error={state.branch_data.error} />
                                        :
                                        <Input
                                            type="select"
                                            label="Select Branch"
                                            name="branch_name"
                                            placeholder="Branch"
                                            mandatorySign
                                            list={state.branch_data.branches ? getBranchList(state.branch_data.branches) : branches && branches.length > 0 ? getBranchList(branches) : []}
                                            error={state.form_data.error}
                                            data={state.form_data.data}
                                            onChangeHandler={state.branch_data.branches ? onChangeHandler : branches && branches.length > 0 ? onChangeHandler : null}
                                        />

                                    }
                                </Grid>
                            </>
                }

                {
                    only_repo_and_branch_dropdown ?
                        null :
                        variant == "AUTO_APPROVAL" ?
                            <></> :
                            variant == "MANIFEST_BRANCH" ?
                                <>
                                    <Grid item lg={6}>
                                        <Input
                                            type="radio"
                                            label="Do you want to auto apply manifest?"
                                            name="auto_refresh"
                                            mandatorySign
                                            list={[{ label: "Yes", value: 1 }, { label: "No", value: 2 }]}
                                            error={state.form_data.error.versioning_path ? { versioning_path: "This is required" } : []}
                                            data={state.form_data.data}
                                            onChangeHandler={onChangeHandler}
                                        />

                                    </Grid>
                                    <Grid item lg={6}>
                                        <Input
                                            type="text"
                                            label="Please Enter File or Folder Path"
                                            name="versioning_path"
                                            placeholder="/xyz/abc.yaml"
                                            // mandatorySign
                                            error={state.form_data.error.versioning_path ? { versioning_path: "This is required" } : []}
                                            data={state.form_data.data}
                                            onChangeHandler={onChangeHandler}
                                        />

                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item lg={12}>
                                        {
                                            single_path ?
                                                props.id ?
                                                    <Grid container spacing="2">
                                                        <Grid item lg={6}>
                                                            <Input
                                                                type="text"
                                                                label="Build Context"
                                                                name="file_paths"
                                                                placeholder="/xyz/abc.yaml"
                                                                mandatorySign
                                                                error={state.form_data.error.file_paths ? { file_paths: "This is required" } : []}
                                                                data={state.form_data.data}
                                                                onChangeHandler={onChangeHandler}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6}>
                                                            <Input
                                                                type="text"
                                                                label="Please Enter Docker File Path with respect to build context"
                                                                name="file_paths_build_context"
                                                                placeholder="/xyz/abc.yaml"
                                                                mandatorySign
                                                                error={state.form_data.error.file_paths_build_context ? { file_paths_build_context: "This is required" } : []}
                                                                data={state.form_data.data}
                                                                onChangeHandler={onChangeHandler}
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    :
                                                    <Input
                                                        type="text"
                                                        label="Please Enter File or Folder Path"
                                                        name="file_paths"
                                                        placeholder="/xyz/abc.yaml"
                                                        mandatorySign
                                                        error={state.form_data.error.file_paths ? { file_paths: "This is required" } : []}
                                                        data={state.form_data.data}
                                                        onChangeHandler={onChangeHandler}
                                                    />

                                                :
                                                <>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f8f8f8', borderTop: '1px solid #dedede', borderBottom: '1px solid #dedede', padding: '5px' }}>
                                                        <p style={{ fontSize: '11px' }}>Click to add row</p>
                                                        <button onClick={addMoreFilePath} className="transparent-btn" >
                                                            <AddIcon style={{ fontSize: '1.5rem' }} /> Add Row
                                                        </button>
                                                    </div>
                                                    <div className="error-display">
                                                        <Input
                                                            type="hidden"
                                                            name="file_paths"
                                                            error={state.form_data.error}
                                                            onChangeHandler={() => { }}
                                                            data={{}}
                                                        />
                                                    </div>
                                                    {
                                                        state.form_data.data.file_paths.split(",").map((path, index) => (
                                                        
                                                                <div className={classes.multidiv}>
                                                                    <Input
                                                                        type="text"
                                                                        label="Please Enter File or Folder Path"
                                                                        name="file_path"
                                                                        placeholder="abc.yaml,xyz-folder"
                                                                        error={state.form_data.error}
                                                                        data={{ file_path: path }}
                                                                        onChangeHandler={(e) => { onFilePathChange(e, index) }}
                                                                    />
                                                                    <button onClick={() => { removeFilePath(index) }} className="bg-delete" ><DeleteIcon style={{ fontSize: '18px' }} /></button>
                                                                </div>
                                                            
                                                        ))
                                                    }
                                                </>
                                        }
                                    </Grid>
                                    <Grid item lg={12}>
                                        {
                                            with_command_args &&
                                            <Input
                                                type="tagged-input"

                                                placeholder="Command Args"
                                                label="Command Args "
                                                subHeading="(Enter command args and press Enter)"
                                                data={state.form_data.data}
                                                error={state.form_data.error}
                                                name="cmd_agrs"
                                                onChangeHandler={onKeyValueChangeHandler}
                                            />

                                        }
                                    </Grid>
                                </>
                }
                    </>
                    : null
                }
               
            </Grid>
        
    );
}

GitRepo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default GitRepo;


// Utils 
function getDefaultState(variant) {
    return {
        content: "",
        credentials: [],

        branch_data: {
            loading: false,
            validation_error: {}
        },
        templates_data:{
            loading: false,
            validation_error: {}
        },
        form_data: {
            default_validations: {
                git_url: [VALIDATION_TYPE_REQUIRED],
                branch_name: [VALIDATION_TYPE_REQUIRED],
                file_paths: [VALIDATION_TYPE_REQUIRED],
                main_branch: [],
                deployed_branch: [],
                draft_branch: [],
                // credential_id: [VALIDATION_TYPE_REQUIRED],
                versioning_path: [],
                credential_id: [],
                file_paths_build_context: [],
            },
            available_repos: [],
            available_helm_templates: [],
            error: {},
            data: {
                edit: true,
                git_url: "",
                branch_name: "",
                credential_id: null,
                file_paths: "",
                versioning_path: "",
                auto_refresh: 2,
                choose_one: 1
            }
        },
    }
}

export function GitRepoDefaultState() {
    return getDefaultState();
}

export function getCredentialsList(credentials) {
    const new_credential_list = [];
    if (credentials) {
        credentials.forEach(credential => {
            new_credential_list.push({
                id: credential.id,
                label: credential.name
            })
        });
    }
    return new_credential_list;
}


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        marginTop: '3rem'
    },
    cardHeader: {
        borderBottom: '1px solid #dedede',
        padding: '10px',
        backgroundColor: '#f4f4f4',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .input-component': {
            marginBottom: '0px'
        }
    },
    inputChoose: {
        '& .input-component': {
            padding: '10px'
        },
        '& .image-upload-wrap': {

            '& .file-upload-input': {
                zIndex: 99
            }
        },
        '& .drag-text': {
            textAlign: 'center',
            position: 'absolute',
            top: '42%',
            left: '44%',
            margin: '0px',
            zIndex: 0,
            '& h3': {
                padding: '0px!important'
            }
        }
    },
    gitInputChoose: {
        display: 'grid',
        gridTemplateColumns: 'auto auto 200px',
        gap: '2%',
        padding: '10px',
        paddingBottom: '15px',
        alignItems: 'center',
        '& button': {
            height: 'fit-content',
        }
    },
    creddiv: {
        display: 'grid',
        gridTemplateColumns: '85% 14%',
        gap: '1%',
        alignItems: 'center',
        padding: '0px 10px'
    },
    multidiv: {
        padding: '0px 10px',
        display: 'grid',
        gridTemplateColumns: '85% 14%',
        gap: '1%',
        alignItems: 'center',
        '& .input-component': {
            marginBottom: '0px'
        },
        '& button': {
            color: '#fff!important',
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '120px',
            padding: '12px 15px',
            fontSize: '11px',
            boxShadow: '0px 5px 16px 0px rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            borderRadius: '24px',
            backgroundImage: 'linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)',
        },
        '& .bg-delete': {
            backgroundColor: '#f6f6f6',
            backgroundImage: 'none',
            border: '1px solid #edcaca',
            color: '#ff4a4a!important',
            boxShadow: 'none',
            borderRadius: '4px',
            padding: '5px 12px',
            minWidth: 'fit-content'
        }
    }
}));