
import GenerateURL from './APIUrlProvider';
import properties from '../properties/properties';
import InvokeApi, { PostData } from './apiInvoker';
import initializeRoutes from '../routes';

var minutesInMillis = 60 * 1000;
var secondsInMillis = 1000;
var hoursInMillis = 60 * 60 * 1000;
export function PersistLoginCreds(userData) {

    userData.assumed_role_superadmin = userData.is_superuser
    var authData = {
        authenticated: true,
        userDetails: userData,
        accessTokenExpiresOn: (new Date()).getTime() + 5.5 * hoursInMillis,
        expiresOn: (new Date()).getTime() + 6.5 * hoursInMillis
    }
    localStorage.setItem("authData", JSON.stringify(authData));
    console.log(JSON.parse(localStorage.getItem("authData")), "opop1")
}


export function RemoveLoginCreds(handleLogoutResponse, handleLogoutFailure) {
    var logoutURL = GenerateURL({}, properties.api.logoutURL);
    var data = {
        token: GetAuth()?.refresh
    }
    let itemToSave = ["pinned_services"];
    const handleLogoutResponseLocal=(data)=>{
        localStorage.setItem("authData",null);
        localStorage.setItem("user_want_to_access_beta",null);
        handleLogoutResponse(data);
        // window.history.replaceState({}, document.title)
    }
    clearGlobalSseConnection(itemToSave);
    PostData(logoutURL,data, handleLogoutResponseLocal, handleLogoutFailure);

}

const clearGlobalSseConnection = (saveFromLocalStorage) => {
    let requestID= localStorage.getItem('global_sse_id')
    let localStorageValueObj = {};
    if(saveFromLocalStorage.length > 0){
        saveFromLocalStorage.forEach((value,index)=>{
            let valueFromStorage = JSON.parse(localStorage.getItem(value.toString()));
            localStorageValueObj[value] = valueFromStorage;
        });
    }
    if(requestID){
        let postData = { request_id: requestID, status: false };

        const endPoint = GenerateURL({}, properties.api.closed_sse_from_backend);
        PostData(endPoint, postData, (response) => {
            if (response) {
                localStorage.clear()
                Object.keys(localStorageValueObj).map((value,index)=>{
                    localStorage.setItem(value.toString(),JSON.stringify(localStorageValueObj[value.toString()]))
                });
            }
        },
        (error) => { 
            localStorage.clear() 
        });
    }
}


export function IsAuthenticated(){
    const authDataString = localStorage.getItem("authData");
    
    if(authDataString!=null && authDataString !="null"){ 
        var now = new Date(); 
        var authData = JSON.parse(authDataString);
        if ((authData.accessTokenExpiresOn - now.getTime()) > 20 * secondsInMillis) {
            return true;
        }
    }
    return false;
}

export function IsSuperUser(args) {
    console.log('args----->',args)
    const user_data = GetAuth();
    return user_data ? user_data.assumed_role_superadmin : null;
}

export function IsUserActualSuperUser() {
    const user_data = GetAuth();
    return user_data ? user_data.is_superuser : null;
}

export function GetAuth() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    return authData ? authData.userDetails : null;
}

export function RegenerateRefreshAndAccessToken(handleRefreshResponse, handleRefreshFailure) {
    var authData = JSON.parse(localStorage.getItem("authData"));
    console.log("refreshTOken", authData.userDetails.refresh)
    var data = {
        refresh: authData.userDetails.refresh
    }
    var url = GenerateURL({}, properties.api.refreshTokenURL);
    const handleRefreshResponseLocal = (data) => {
        console.log(data, "opop")
        authData.userDetails.refresh = data.refresh;
        authData.userDetails.access = data.access;
        authData.expiresOn = (new Date()).getTime() + 6.5 * hoursInMillis;
        authData.accessTokenExpiresOn = (new Date()).getTime() + 5.5 * hoursInMillis;
        localStorage.setItem("authData", JSON.stringify(authData));
        handleRefreshResponse(data);
    }

    const handleRefreshFailureLocal = (data) => {
        handleRefreshFailure(data);
    }

    PostData(url, data, handleRefreshResponseLocal, handleRefreshFailureLocal, true);
}


export function IsRefreshTokenValid() {
    var now = new Date();
    const authDataString = localStorage.getItem("authData");
    if (authDataString != null && authDataString != "null") {
        var authData = JSON.parse(authDataString);
        if (((authData.expiresOn - now.getTime()) > 20 * secondsInMillis)) {
            return true;
        }
    }

    return false;
}

export function UpdateAuthUserData(userData,reloadRoutes) {
    console.log("success updateauth")
    var authData = JSON.parse(localStorage.getItem("authData"));
    authData.userDetails = {
        ...authData.userDetails,
        ...userData,
    }
    localStorage.setItem("authData", JSON.stringify(authData));
    reloadRoutes && initializeRoutes()
}

export function GetPermitedURLs(handleSucess, handleFail) {
    var requestInfo = {
        endPoint: GenerateURL({}, properties.api.permission_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo, handleSucess ? handleSucess : (response) => { UpdateAuthUserData(response,false) }, handleFail ? handleFail : (error) => { console.log(error) });
}

// do not change false as true in the above UpdateAuthUserData function.

export function GetPermitedURLsAfterSwitch() {
    var requestInfo = {
        endPoint: GenerateURL({}, properties.api.permission_url),
        httpMethod: "GET",
        httpHeaders: { "Content-Type": "application/json" }
    }
    InvokeApi(requestInfo,  (response) => { UpdateAuthUserData(response,true) }, (error) => { console.log(error) });
}

export function SwitchToSuperAdminRole() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    if (authData) {
        var user_details = authData.userDetails;
        user_details.assumed_role_superadmin = user_details.is_superuser;
        authData.userDetails = user_details
        localStorage.setItem("authData", JSON.stringify(authData));
    }
    console.log(JSON.parse(localStorage.getItem("authData")), "opop")
    return authData.userDetails.is_superuser && authData.userDetails.assumed_role_superadmin;
}

export function SwitchToNormalUserRole() {
    var authData = JSON.parse(localStorage.getItem("authData"));
    if (authData) {
        var user_details = authData.userDetails;
        user_details.assumed_role_superadmin = false;
        authData.userDetails = user_details
        localStorage.setItem("authData", JSON.stringify(authData));
    }
    console.log(JSON.parse(localStorage.getItem("authData")),"opop___")
    return !authData.userDetails.is_superuser && !authData.userDetails.assumed_role_superadmin;
}
