import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Delete from '../../../components/genericComponents/Delete';
import GenerateURL from '../../../util/APIUrlProvider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { PostData } from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import { Loading } from '../../utils/Loading';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import AvatarSquare from '../../../components/genericComponents/AvatarSquare'

export default function HelmListCard(props) {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [showloading, setShowLoading] = useState(false)
    const prev_state = props.prev_state ? props.prev_state : null;
    const data = props.data?props.data:null
    const apiUrl = props.apiUrl ? props.apiUrl : "";
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const onClickOpenDialogAndSetData = props.onClickOpenDialogAndSetData

    useEffect(() => {
        setState(new_state => ({ ...new_state, ...prev_state, }))
    }, [prev_state])
    function TestConnection(id) {

        var post_url = GenerateURL({ edit_id: id }, properties.api.test_registry_connection);
        PostData(post_url, {}, postSuccess, postFail);
        setState(new_state => ({
            ...new_state,
            loading_status: true,
        }))
    }
    function postSuccess(response) {

        setState(new_state => ({
            ...new_state,
            status: response.status,
            loading_status: false,
            show_save_msg: "success",
        }))
        refreshFun()
    }
    function postFail(response) {
        var error_msg = response

        setState(new_state => ({
            ...new_state,
            loading_status: false,
            status: response.status,
            show_save_msg: "failed",
            error_in_save: error_msg.detail
        }))
        refreshFun()
    }
    const handleClose = () => {
        setState({ ...state, show_save_msg: null, });
    };
    return (
        <>
            <div className={classes.container_registry_card}>
                <div className="manage-body">
                    <div className='manage-list d-flex align-center' style={{ display: 'grid', gridTemplateColumns: '50px auto', gap: '5px' }}>
                        <div className='manage-img'>
                            <AvatarSquare shape="round"
                                name={data.name}
                                varient="double" />
                        </div>
                        <div className=''>
                            <div className='title'>Helm Name</div>
                            <div className={classes.valueDetails}>{data.name}</div>
                        </div>
                    </div>
                    <div className='manage-list'>
                        <div className='title'>Credential</div>
                        <div className={classes.valueDetails}>{data.credential && data.credential.name? data.credential.name :"N/A"}</div>
                    </div>
                    <div className='manage-list'>
                        <div className='title'>URL</div>
                        <div className={classes.valueDetails}>{data.helm_url}</div>
                    </div>
                    <div className='manage-update-icons'>
                        <div className='r-col7 repos-card-content'>
                            <OptionList
                                data={{ id: data.id }}
                                refreshFun={refreshFun}
                                apiUrl={apiUrl}
                                onClickOpenDialogAndSetData={onClickOpenDialogAndSetData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={state.show_save_msg == "success" || state.show_save_msg == "failed"}
                onClose={handleClose}
                autoHideDuration={6000}>
                {
                    state.show_save_msg == "success" ?
                        <>
                            <Alert severity="success">
                                {"Request hit successful"}
                            </Alert>
                        </> : state.show_save_msg == "failed" ?
                            <Alert severity="error">
                                {state.error_in_save}
                            </Alert>

                            : null
                }
            </Snackbar>
        </>
    )
}

const ITEM_HEIGHT = 48;

const OptionList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};
    const edit_id = data.id ? data.id : "";
    console.log(edit_id,"fdsafnsajnfajsdf")
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const apiUrl = props.apiUrl ? props.apiUrl : "";
    const onClickOpenDialogAndSetData = props.onClickOpenDialogAndSetData
    console.log(apiUrl,"fdsafnsajnfajsdf")
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#0096db', fontSize: '2rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem style={{ display: "grid" }}>
                    <Link to={"#"} onClick={()=>{onClickOpenDialogAndSetData(edit_id)}} className="d-flex align-center space-between">
                        <span className="hover-content-link"><BorderColorIcon />&nbsp;Edit</span>
                    </Link>

                </MenuItem>
                <MenuItem style={{ display: "grid", justifyContent: 'center' }}>
                    <Delete
                        data={{ entity_id: edit_id, name: 'helm_integration' }}
                        refresh={refreshFun}
                        is_edit_permited={true}
                        api_link={GenerateURL({id: edit_id}, properties.api.update_helm_chart)}
                    />
                </MenuItem>
            </Menu>
        </>
    )
}

const useStyles = makeStyles({
    container_registry_card: {
        '& .manage-body': {
            background: '#fff',
            padding: '20px 25px',
            width: '100%',
            borderRadius: '4px',
            marginTop: '20px',
            filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.04))',
            display: 'flex',
            gridTemplateColumns: '250px 150px 150px 150px 150px 120px 20px',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .manage-img': {
                width: '50px',
                height: '50px',
                background: '#f3f3f3',
                borderRadius: '50%',
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& img': {
                    display: 'block',
                    // paddingTop: '25px',
                    width: '35px',
                    margin: '0 auto',
                }
            },
            '& .manage-heading': {
                fontSize: '18px',
                color: '#000000',
                fontWeight: '300',
                fontFamily: 'Inter',
                paddingTop: '15px',
                textAlign: 'center',
            },
            '& .manage-list': {
                // borderBottom: '1px solid #eee',
                padding: '7px 0',
                fontSize: '13px',
                fontWeight: '300',
                display: 'block',
                '& .title': {
                    fontWeight: '400',
                    minWidth: '105px',
                    color: '#121212'
                },

            },
            '& .manage-update-icons': {
                textAlign: 'center',
                paddingTop: '10px',
                display: 'flex',
                justifyContent: 'center',
            },
            '& .text.connected': {
                display: 'flex',
                flexDirection: 'row',
                '& svg': {
                    fontSize: '1.5em',
                    marginRight: '6px',
                },
                '& .title-heading': {
                    fontWeight: '500',
                    textTransform: 'uppercase',
                    color: '#999999',
                }
            },
            '& .r-col7': {

                marginRight: '15px',
                '& .test-btn': {
                    border: '2px solid #3d73eb',
                    padding: '6px 15px',
                    borderRadius: '5px',
                    fontWeight: '500',
                    color: '#3d73eb',
                    background: '#fff'
                }
            }
        }
    },

    valueDetails: {
        width: 200,
        margin: '.25rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
})

