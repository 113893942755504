import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Input } from '../Input';
import { makeStyles } from '@material-ui/core';

function AddMoreValue(props) {
    console.log("propsppppppp",props);
    const classes = useStyles();
    const inherits = props.inherits;
    const values = props.values;
    const [state, setState] = useState({
        data: {
            values: values ? values : []
        },
        error: {},
    });
    const subHeaderHeading = props.subHeaderHeading ? props.subHeaderHeading : "";

    useEffect(() => {
        if(state.data.values.length==0)
        addMoreValues();
    }, [])

    function addMoreValues() {
        updateValues([...state.data.values, ""]);
    }

    function updateValues(values) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                values: values
            },
            error: {
            },
            error_indexes: []
        }));
    }

    function onValueChange(e, index) {
        const values = state.data.values;
        values[index] = e.target.value;
        updateValues(values);
    }

    function removeValue(index) {
        const new_values = [...state.data.values];
        new_values.splice(index, 1)
        updateValues(new_values);
    }

    inherits.validateForm = () => {
        let result = {
            valid: true,
        };
        if (state.data.values.length > 0) {
            state.data.values.forEach((value, index) => {
                if (!value) {
                    result.valid = false;
                    result.error_indexes = result.error_indexes ? result.error_indexes : [];
                    result.error_indexes.push(index);
                }
            });
        } else {
            result = {
                valid: true,
                error: {
                    values: "",
                    error_indexes: []
                }
            }
        }
        if (!result.valid) {
            setState(new_state => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                },
                error_indexes: result.error_indexes
            }));
        }
        return result;
    }

    inherits.getData = () => {
        return state.data.values;
    }


    return (
        <div className={classes.root}>
            <div className="card addmore-cont">
                <div className="card-header d-block">
                    <div className={ subHeaderHeading ? "class-control sub-heading-text d-flex align-center space-between" :"class-control d-flex align-center justify-flexend"} style={{ backgroundColor: '#f4f4f4', }}>
                       {subHeaderHeading}
                        <button onClick={addMoreValues} className="transparent-btn" >
                            <AddIcon style={{ fontSize: '1.5rem' }} /> Add More
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="error-display">
                            <Input
                                type="hidden"
                                name="values"
                                error={state.error}
                                onChangeHandler={() => { }}
                                data={{}}
                            />
                        </div>
                        

                    <div>
                        {
                            state.data.values.map((value, index) => (
                                <div className={classes.multidiv}>
                                    <Input
                                        type="text"
                                        name="value"
                                        placeholder="value"
                                        error={
                                            state.error_indexes ?
                                                state.error_indexes.includes(index) ? { value: "This is required" } : {}
                                                : {}
                                        }
                                        data={{ value: value }}
                                        onChangeHandler={(e) => { onValueChange(e, index) }}
                                    />
                                    <button onClick={() => { removeValue(index) }} className="bg-delete" ><DeleteIcon style={{ fontSize: '18px' }} /></button>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

AddMoreValue.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
  

export default AddMoreValue;

const useStyles = makeStyles(() => ({
    root:{
        '& .card':{
            '& .card-header':{
                justifyContent:'flex-end'
            }
        }
    },
    multidiv: {
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '94% 4%',
        gap: '1%',
        alignItems: 'center',
        '& .input-component': {
            margin:'0px!important'
        },
        '& button': {
            color: '#fff!important',
            width: 'fit-content',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            padding: '5px 12px',
            fontSize: '11px',
            boxShadow: '0px 5px 16px 0px rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            borderRadius: '4px',
            backgroundImage: 'linear-gradient( -90deg, rgb(0,150,219) 0%, rgb(20,107,236) 100%)',
        },
        '& .bg-delete': {
            backgroundColor: '#f6f6f6',
            backgroundImage: 'none',
            border: '1px solid #edcaca',
            color: '#ff4a4a!important',
            boxShadow: 'none'
        }
    }
}));