import React, { useState,useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { FormatTime } from "../../../../util/util";
import { Link } from 'react-router-dom';
import MonitoringDialogue from "../../../service/detail/components/MonitoringDialogue";
import { makeStyles } from '@material-ui/core/styles';


const MultipleDeploymentCard = ({ informationData, getSymbol, activityDetailsDuration, selectedInfoId, updateSelectedInformationId, component_name, env_name, activity_id, component_task_instance, component_id, env_master, manual_deploy_flag, open_by_default,component_env_id, task_type }) => {
    console.log(informationData,component_name,env_name,"")
    const [state, setState] = useState({
        open: false
    })
    const count = useRef(0);
    const open = informationData.id == selectedInfoId
    const toggleView = (id) => {
        updateSelectedInformationId(id)
    }
    const classes = usePopOverStyles()
    const start_time = new Date(informationData.activity_status && informationData.activity_status.start_time ? informationData.activity_status.start_time : "");
    const end_time = new Date(informationData.activity_status && informationData.activity_status.start_time ? informationData.activity_status.end_time : "");
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);
    open && console.log('recalling_state_everytime', selectedInfoId, informationData)

    console.log('recalling_state_everytime', task_type, informationData)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(true);
    };
    useEffect(() => {
        handleClick()
        if (open_by_default) {
            toggleView(informationData.id);
        }
    }, []);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getMasterLabel = (env_master_id) => {
        var env;
        if (env_master_id == 1)
            env = 'dev';
        if (env_master_id == 2)
            env = 'qa';
        if (env_master_id == 3)
            env = 'staging';
        if (env_master_id == 4)
            env = 'uat';
        if (env_master_id == 4)
            env = 'prod';
        return env;
    }
    const getDeployDurationForInfoMessage = (date1, date2) => {
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs", diffInMs)
        return diffInMs;
    }

    function getChildBuildLabel(label) {
        console.log(label, "sdbvhsbvjhfbhj")
        var data = label.split("-")
        return data[data.length - 1]
    }

    return (<>
        <SubHeader>
            <label className='deployment-name'>
                {
                    task_type == "Build" ?
                        informationData?.type_label ? `Child Build: ${getChildBuildLabel(informationData.type_label)}` : "Main Build"
                        :
                        task_type == "Promote" ?
                            informationData?.type_label ? `Child Promote: ${informationData.type_label}` : "Main Promote"
                            :
                            informationData?.deploy_args_key_value?.deployment_name ? informationData.deploy_args_key_value.deployment_name : null
                }<br />
                <span className="font-11 env-name ">Env : <span>{env_name ? env_name : "NA"}</span></span>
            </label>

            <Link
                to={"/logs?global_task_id=" + informationData.global_task_id +
                    "&service_name=" + component_name +
                    "&service_env=" + env_name +
                    "&status=" + informationData.status +
                    "&tab_id=" + informationData.id +
                    "&num=" + activity_id
                }
                title="view logs"
                target="_blank"
                // onClick={showLogs} 
                className="flaticon-google-drive-file"></Link>

            <div
                className={
                    informationData?.status || informationData?.activity_status && informationData?.activity_status?.status ?
                        informationData?.activity_status?.status == "SUCCESS" || informationData?.deployment_status == "SUCCESS" ?
                            "status-div-11 green-text" :
                            informationData?.activity_status?.status == "FAILED" || informationData?.deployment_status == "FAILED" ?
                                "status-div-11 red-text" :
                                informationData?.activity_status?.status == "IN_QUEUE" || informationData?.deployment_status == "IN_QUEUE" ?
                                    "status-div-11 inqueue-text" :
                                    informationData?.activity_status?.status == "STOPPED" || informationData?.deployment_status == "STOPPED" ?
                                        "status-div-11 red-text" :
                                        "status-div-11 yellow-text" : "status-div-11 primary-text"}>&nbsp;{informationData?.activity_status ? informationData?.activity_status?.status : informationData?.deployment_status}&nbsp;</div>

            <div className="duration">{informationData.status == "RUNNING" || informationData.status == "IN_QUEUE" ? '0s' : time_taken}</div>
            {
                open ?
                    <div className="flaticon-expand-arrow" onKeyDown={()=>{}} onClick={() => toggleView(null)} role="button" tabIndex={0}></div> :
                    <div className="flaticon-expand-button" onKeyDown={()=>{}} onClick={() => toggleView(informationData.id)} role="button" tabIndex={0}></div>
            }
        </SubHeader>



        {
            open &&
            <>
                {informationData ?
                    informationData.activity.map(activity => (
                        <div className="steps-list">

                            {getSymbol(activity)}
                            <div className="service">{activity.activity_sub_task}:</div>
                            <div
                                className={activity.status.toLowerCase() == "success" ?
                                    "status-div green-text" :
                                    activity.status.toLowerCase() == "failed" ?
                                        "status-div red-text d-flex align-center" :
                                        activity.status.toLowerCase() == "stopped" ? "status-div red-text d-flex align-center" : "status-div yellow-text"}>
                                &nbsp;{activity.status.toLowerCase()}&nbsp;

                            </div>
                            <div
                                style={{
                                    width: '100px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >

                                Duration: {activityDetailsDuration(activity)}
                            </div>

                            {
                                activity.status_file_path ?
                                    <a target="_blank" href={activity.status_file_path}><span className="flaticon-link-button" style={{ color: '#fff' }}></span></a> : null
                            }
                        </div>
                    )) : null
                }
                {informationData ?
                    informationData.env_integration_detail ?
                        <>
                            {
                                state.data.status && state.data.status === "RUNNING" || state.data.status && state.data.status === "STOPPED" ?
                                    <>
                                        {informationData.env_integration_detail.stopped && state.data.status === "STOPPED" ?
                                            <div className="steps-list">
                                                <p className='service-des'>
                                                    The pipeline will remain paused till we receive a response post integration testing
                                                </p>
                                            </div>
                                            : null
                                        }

                                    </>
                                    : null
                            }
                        </>
                        : null : null
                }
                { informationData.build_args_key_value &&
                    <Footer >
                        <span className="tag-image">Branch: : {informationData.build_args_key_value ? informationData.build_args_key_value?.branch_name : "NA"}</span>
                    </Footer>
                }
                
                <Footer >
                    <span className="tag-image">Artifact: : {informationData.build_tag ? informationData.build_tag : informationData.deploy_tag ? informationData.deploy_tag : informationData.rollback_tag ? informationData.rollback_tag : informationData.target_image_tag}</span>
                </Footer>
                
                {
                    anchorEl && informationData?.deploy_number &&
                    <div
                        className={classes.pop_over}
                        style={{}}
                    >
                        <div>

                            {
                                component_env_id &&
                                <MonitoringDialogue
                                    variant={component_task_instance.status == "RUNNING" &&
                                        getDeployDurationForInfoMessage(new Date(component_task_instance.activity_status.start_time), new Date()) > 100 ? "running_card_pipeline" :
                                        component_task_instance.status == "FAILED" || component_task_instance.status == "REVOKED" ? 'failed_card_pipeline' : "success_running"
                                    }
                                    application_id={component_task_instance.component.project}
                                    //env_id={task_details.project_env.id}
                                    env_id={component_env_id}
                                    cd_id={informationData?.deploy_args_key_value?.deployment_name}//{informationData?.env_cd_detail_id}
                                    service_id={component_id}
                                    env_name={env_name}
                                    service_data={{ name: component_task_instance.component.name, type: getMasterLabel(env_master) }}
                                    env_details={manual_deploy_flag}
                                    handleClosePopover={handleClose}
                                />
                            }
                        </div>
                    </div>
                }
            </>
        }

    </>)
}

MultipleDeploymentCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default MultipleDeploymentCard;

const SubHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    background: '#6b74a8',
    borderBottom: '1px solid #badcde',
    '& .deployment-name': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .env-name': {
        color: '#d8d7d7',
        lineHeight: '18px',
        fontSize: '12px',
        //color: '#fff',
        //margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '10px !important'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    },
    //
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px',
        fontSize: '11px !important'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div-11': {
        fontSize: '10px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})


const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    background: '#6b74a8',
    borderBottom: '1px solid #badcde',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})
const usePopOverStyles = makeStyles(() => ({
    pop_over: {
        position: 'absolute',
        left: '320px',
        top: '-48px',
        zIndex: 999
    }
}));