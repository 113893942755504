import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';

import Editor from "@monaco-editor/react";
import KeyValue from "./KeyValues";

// <EditorKeyValues data={} update_data_callback={} field_name={}/>

const EditorKeyValues = (props) => {
    const data = props.data;
    const error = props.error;
    const update_data_callback = props.update_data_callback;
    const inherits = props.inherits;
    const [value, setValue] = useState(`env_name =default 
branch_name = master
dockerfile_path =./ Dockerfile
expose_url = emp.buildpiper.in
expose_path = /`)

    let data_editor = value;

    console.log("error=", error);
    console.log("data=", data);

    const [isEditorReady, setIsEditorReady] = useState(false);
    console.log(isEditorReady)
    const [dataArray, setDataArray] = useState([]);
    const [state, setState] = useState({
        editor_enabled: false
    })
    const valueGetter = useRef();
    function handleEditorDidMount(_valueGetter) {
        setIsEditorReady(true);
        valueGetter.current = _valueGetter;
    }

    function handleShowValue(e) {
        data_editor = valueGetter.current()
        let s = data_editor.trim();
        let data_array = []
        s.split(/\r\n|\r|\n/).forEach(hi => (
            hi.length > 0 ?
                data_array.push({
                    key: hi.substring(0, hi.indexOf('=')).trim(),
                    value: hi.substring(hi.indexOf('=') + 1).trim(),
                    env_key: hi.substring(0, hi.indexOf('=')).trim(),
                    env_value: hi.substring(hi.indexOf('=') + 1).trim()
                }) : null

        ));
        console.log("hello1212", data_array)
        setDataArray(data_array)
        //   update_data_callback(props.field_name, data_array);
        if (e == "e") {
            return data_array
        }
    }

    useEffect(() => {
        setDataArray(getBuildKV(data));
        tabDatatoEditor(getBuildKV(data));

    }, [])

    function tabDatatoEditor(data) {
        let value_temp = ``;
        if (data.length > 0) {
            data.forEach(d => (
                value_temp += d.key + "=" + d.value + "\n"

            ))

        }
        setValue(value_temp);
    }


    function updateBuildKV(data) {

        if (data) {
            data.forEach(element => {
                element["env_key"] = element.key;
                element["env_value"] = element.value;
            });
        }
        tabDatatoEditor(data);
        setDataArray(data);
        update_data_callback(props.field_name, data);
    }



    function getBuildKV(data) {
        if (data.length > 0) {
            data.forEach(element => {
                element["key"] = element.env_key;
                element["value"] = element.env_value;
            });
        }
        return data;
    }

    inherits.switchStrategy = () => {


        if (state.editor_enabled) {
            handleShowValue();
        }
        // handleShowValue();
        setState(new_state => ({
            ...new_state,
            editor_enabled: !new_state.editor_enabled,
        }));

    }
    inherits.getData = () => {

        if (state.editor_enabled)
            return handleShowValue("e");
        return dataArray;
    }
    inherits.getState = () => {

        if (state.editor_enabled)
            return handleShowValue("e");
        return dataArray;
    }
    return (
        <>
            {
                <>
                    <div className="SubHeader d-flex align-center" style={{ backgroundColor: "#f8f8f8", padding: "5px 10px", borderBottom: "1px solid #dedede", justifyContent: "space-between ", width: '100%', margin: 'auto' }} >
                        <p className="sub-heading-text">{
                            state.editor_enabled ? "Editor can be used to manage the properties values easily. Switch to field view to easily manage the entered data." : "Use Field view to manage small number of variables. Use Editor view in case you want to copy paste or manage large number of variables"}</p>
                        <div role="button" tabIndex={0} className="sub-heading-text" onKeyDown={()=>{}} onClick={inherits.switchStrategy} ><button className="btn-save btn-save-primary btn-save-lg" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{
                            state.editor_enabled ? " Switch to Field View" : " Switch to Editor View"}</button></div>
                    </div>
                    {

                        !state.editor_enabled ?

                            <KeyValue field_name={props.field_name} isListing={props.isListing} disabled={props.disabled} data={dataArray} callback={updateBuildKV} />

                            :
                            <>
                                {/* <button onClick={handleShowValue} disabled={!isEditorReady}>
                  Update Data
                  
        </button> */}

                                <Editor
                                    height="200px"
                                    language="javascript"
                                    value={value}
                                    options={{
                                        minimap: {
                                            enabled: false,
                                        },
                                        fontSize: 18,
                                        cursorStyle: "block",
                                        wordWrap: "on",
                                    }}
                                    defineTheme={{
                                        themeName: "my-theme",
                                        themeData: {
                                            colors: {
                                                "editor.background": "#000000",
                                            },
                                        },
                                    }}
                                    editorDidMount={handleEditorDidMount}
                                />
                            </>}
                </>

            }

        </>

    )
}

EditorKeyValues.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default EditorKeyValues;