import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { styled } from '@material-ui/core/styles';
import { Input } from '../../../../components/genericComponents/Input';
import { PostData } from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


export default function ReplicationToolBarNew(props) {
  const classes = useStyles();
  const replication_bar = props.replication_bar;
  const varient = props.varient;
  const service_id = props.service_id;
  const open_by_default = props.open_by_default;
  const env_id = props.env_id;
  const cd_id = props.cd_id;
  console.log(cd_id,"cdcdcdcdd")
  const limits = resolveReplicationLimits();
  const callback = props.callback ? props.callback : () => { };
  const autoScalingEnable = props.autoScalingEnable ? true : false;
  const base_state_data = {
    desired_replication: replication_bar ? replication_bar.current : null,
    desired_replication_divison: replication_bar ? Math.round(replication_bar.current * 100 / limits.max) : null
  }
  const [state, setState] = useState({
    data: {
      ...base_state_data
    },
    error: {},
    sectionOpen: open_by_default,
  });

  function resolveReplicationLimits() {
    console.log(replication_bar);
    var limits = {};
    if (replication_bar) {
      if (replication_bar.min && replication_bar.max) {
        limits.min = replication_bar.min;
        limits.max = replication_bar.max;
      } else {
        limits.min = replication_bar.current > 5 ? replication_bar.current - 5 : 0;
        limits.max = replication_bar.current + 5;
      }
    }

    return limits;
  }

  function handleReplicationChange(e, value) {
    console.log("replication_bar", limits);
    value = Number(e.target.type === "text" ? e.target.value : value);
    if (e.target.type !== "text") {
      value = Math.round(value * limits.max / 100);
    }

    if (value <= limits.max && value >= limits.min) {
      setState({
        ...state,
        data: {
          ...state.data,
          desired_replication: value,
          desired_replication_divison: Math.round(value * 100 / limits.max)
        }
      });
      callback("open");
    }
  }

  function updateReplication() {
    const data = {
      desired_replication: state.data.desired_replication
    }

    PostData(GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id: cd_id }, properties.api.pod_update), data, handleSuccess, handleFailure);
    setState({
      ...state,
      post_requested: true,
    });
  }
  const toggleSectionOpen = () => {
    callback(state.sectionOpen ? "close" : "open");
    setState({
      ...state,
      data: {
        ...base_state_data
      },
      sectionOpen: !state.sectionOpen
    });
  }


  function handleSuccess(response) {
    setState({
      ...state,
      data: {
        ...base_state_data
      },
      open: true,
      sectionOpen: false,
      status: "success",
      post_requested: false,
      msg: "Request Success"
    })
    callback("close");
  }

  function handleFailure(error) {
    setState({
      ...state,
      open: true,
      status: "error",
      post_requested: false,
      msg: "Request Failed"
    })
  }

  function handleClose() {
    setState({
      ...state,
      data: {
        ...base_state_data
      },
      open: null
    });
    callback("close");
  }

  return (
    <>
      {/* <TextBox id="main-div"> */}
        {
          replication_bar &&

          <div className={classes.notMontInfo}>

            <p style={{color: "#7f8184"}}>Replication Status:&nbsp;</p>
            {
              replication_bar.min ?
                <p><span>Min: </span><span className="color-lite-grey"> {replication_bar.min} </span>&nbsp;|&nbsp;</p>
                : null
            }

            {
              replication_bar.max ?
                <p><span>Max: </span><span className="color-lite-grey"> {replication_bar.max} </span>&nbsp;|&nbsp;</p>
                : null
            }

            {
              replication_bar.min && replication_bar.max ?
                <p><span>Current: </span><span className="color-lite-grey"> {replication_bar.current} </span>&nbsp;</p>
                :
                <>
                  {
                    varient != "no-header" ?
                      <>
                        <p><span style={{color: "#7f8184"}}>Auto scaling:&nbsp;</span><span className=""> Disabled </span>&nbsp;</p>
                        <p><span style={{color: "#7f8184"}}>Pods: </span><span className=""> {replication_bar.current} </span>&nbsp;</p>
                      </> : null
                  }
                </>
            }

          </div>
        }
        {/* <>
          {
            props.not_setup ?
              <></> :
              <>
                {
                  !autoScalingEnable ?
                    <div className="btn-div">
                      <>
                        {
                          !state.sectionOpen ?
                            <button onClick={toggleSectionOpen} style={{ marginLeft: '15px' }}>Update</button>
                            : null
                        }
                      </>
                    </div>
                    : null
                }
              </>
          }
        </> */}
      {/* </TextBox> */}
      {/* {state.sectionOpen ?
        <>
          <div className="card-autoscaling">
            <div className="slider-with-input ">
              <Input
                type="slider"
                name="desired_replication"
                varient="divison"
                label="Change current replication?"
                onChangeHandler={handleReplicationChange}
                data={state.data}
                error={state.error}
              />
            </div>
          </div>
          <div className="btn-div">
            {
              !state.post_requested ?
                <button onClick={updateReplication} style={{ marginLeft: '0px' }}>Update</button>
                :
                <button onClick={() => { }} style={{ marginLeft: '0px' }}>Update</button>
            }
            <button onClick={toggleSectionOpen} >Cancel</button>
          </div>
        </> : null} */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={6000}>
        <Alert severity={state.status}>
          {state.msg}
        </Alert>
      </Snackbar>
    </>
  );
}
export const TextBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '.5rem',
  marginTop: '1rem',
  '& .data-div': {
    display: 'flex',
    alignItems: 'center',
  },
  '& p': {
    display: 'flex',
    fontSize: '11px',
    whiteSpace: 'nowrap',
    '& .color-lite-grey': {
      backgroundColor: 'transparent',
      color: '#666'
    }
  },
  '& button': {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#25a5e0'
  },
  '& .btn-div': {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-round'
  },
  margin: {
    height: theme.spacing(3),
  },
  input: {
    padding: '0.5rem',
    background: ' transparent',
    margin: '0px 20px',
    maxWidth: '65px',
    paddingRight: '25px',
    maxHeight: '30px',
  },
  notMontInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "12px",
    width: "100%",
    borderRadius: "6px",
    border: "1px solid #E6E6E6",
    background: "#FAFAFA",
 }
}));


const AirbnbSlider = withStyles({
  root: {
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);