import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { LoadingText } from '../../../../../utils/Loading';
import Tooltip from '@material-ui/core/Tooltip';

const ContainerSection = props => {
    const selected_pod_name = props.selected_pod_name;
    const selected_pod = props.selected_pod;
    const containers = getFormattedContainerInfo(selected_pod);
    const containers_keys = Object.keys(containers);
    const refreshTab = props.refreshTab;
    console.log("hello00",containers)
    return(
        <div style={{padding:'0px 10px 0px '}}>
            {
                containers ? 
                <>
                <HeadingSection>
                    <div className="text-setion">
                        <div className="main-heading"> 
                            Containers
                        </div>
                    </div>
                    <div className="count-chip-section">
                        <button style={{marginLeft:'auto', display:"block", marginTop:'10px' }} onClick={refreshTab} className="btn-round border-navy bg-clear-btn"><span className="flaticon-refresh-button-1"></span></button>
                    </div>
                    {/* <div className="count-chip-section">
                            <div className="chip-large red">
                                <p className="count-chip-large">12</p>
                                <p className="text-chip-large">Critical</p>
                            </div>
                            <div className="chip-large orange">
                                <p className="count-chip-large">5</p>
                                <p className="text-chip-large">Warning</p>
                            </div>
                            <div className="chip-large yellow">
                                <p className="count-chip-large">12</p>
                                <p className="text-chip-large">Critical</p>
                            </div>
                    </div> */}
                </HeadingSection>
                    <div className="table-section-8">
                        <div className="table-head">
                            <div>Name</div>
                            <div>Status</div>
                            <div>Liveness</div>
                            <div>Readiness</div>
                            <div>Image</div>
                            <div>Image pull policy</div>
                            <div>Mounted volume name</div>
                            <div>Mounted volume path</div>
                        </div>
                        <div className="scrollable-section">
                        {containers_keys ? containers_keys.map(name=>(
                            <div className="table-body">
                                <Tooltip title={name} placement="bottom">
                                <div>{name}</div>
                                </Tooltip>
                               
                                <div>{containers[name]?containers[name].state?containers[name].state.running ? "Running" : containers[name].state.terminated ? "Terminated" : "Waiting":"Waiting":"Waiting"}</div>
                                <div>{containers[name].liveness_probe?"Active":"Inactive"}</div>
                                <div>{containers[name].readiness_probe?"Active":"Inactive"}</div>
                                <Tooltip title={containers[name].image} placement="bottom"><div>{containers[name].image}</div></Tooltip>
                                <div>{containers[name].image_pull_policy}</div>

                                {containers[name].volume_mounts.map(volume=>(<Tooltip title={volume.name} placement="bottom"><div>{volume.name},</div></Tooltip>))}
                                {containers[name].volume_mounts.map(volume=>(<Tooltip title={volume.mount_path} placement="bottom"><div>{volume.mount_path},</div></Tooltip>))}
                                
                            </div>
                        )) : <div className="table-body-error">
                        <p>No Data Available</p>
                    </div>}
                        </div>
                    </div>
                </>
                :<LoadingText />
            }
        </div>
    )
}

ContainerSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default ContainerSection


const HeadingSection = styled('div')({
    margin:'10px 0px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& .main-heading':{
        fontSize:'18px',
        lineHeight:'24px',
        color:'#526174',
        fontFamily:'Nunito',
        fontWeight:300
    },
    '& .small-text':{
        fontSize:'11px',
        lineHeight:'13px',
        color:'#999'
    }, 
    '& .count-chip-section':{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    '& .chip-large':{
        borderRadius:'4px',
        padding:'5px',
        minWidth:'50px',
        margin:'5px',
        '& .count-chip-large':{
            fontSize:'14px',
            fontFamily:'nunito',
            fontWeight:300,
            color:'#fff',
            textAlign:'center'
        },
        '& .text-chip-large':{
            fontSize:'11px',
            fontFamily:'nunito',
            fontWeight:300,
            color:'#fff',
            textAlign:'center'
        },
    }

})

function getFormattedContainerInfo(pod) {
    const pods_data = {};
        if(pod.formatted_containers_info){
            return pod.formatted_containers_info
        }
        
        pod.formatted_containers_info = {};
        if (pod.status.container_statuses) {
            pod.status.container_statuses.forEach(container_status => {
                pod.formatted_containers_info[container_status.name] = { ...container_status }
        })
        }


        pod.spec.containers.forEach(container => {
            pod.formatted_containers_info[container.name] = {
                ...pod.formatted_containers_info[container.name],
                ...container
            }
        });


        
    return  pod.formatted_containers_info ;
}
