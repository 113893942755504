import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

import { FormatTime } from '../../../../util/util';
import { Link } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import { snowStatesList } from '../../add/components/UpdateSnowTicket';

export function getSnowTicketState(stateID) {
    switch (stateID) {
        case "-5":
            return "New";
        case "-4":
            return "Asses";
        case "-3":
            return "Authorize";
        case "-2":
            return "Scheduled";
        case "-1":
            return "Implement";
        case "0":
            return "Review";
        case "3":
            return "Closed";
        case "4":
            return "Canceled";
        default:
            return "State";
    }
}

const SnowJobExecutionCard = props => {
    const snow_task_instance = props.snow_task_instance;
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const selected_component_task_id = props.selected_component_task_id;
    const update_selected_component_task = props.update_selected_component_task;
    const open = selected_component_task_id == snow_task_instance.id;
    const showLogsCallback = props.showLogs;
    const stage = props.stage;

    const start_time = new Date(snow_task_instance.created_at);
    const end_time = new Date(snow_task_instance.updated_at);
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);
    const statesList = snowStatesList;
    console.log("dsbhbjhads", snow_task_instance);

    const toggleSectionOpen = () => {
        update_selected_component_task(!open ? snow_task_instance.id : null);
    }



    function getProgressStatusIcon() {
        switch (snow_task_instance.status) {
            case "SUCCESS":
                return (
                    <div className="circle-status circle-status-approved"><div className="flaticon-check-symbol"></div></div>
                );
            case "FAILED":
                return (
                    <div className="circle-status circle-status-failed"><div className="flaticon-round-info-button"></div></div>
                );
            case "RUNNING":
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
            default:
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
        }
    }

    function showLogs() {

        const path_arr = [stage ? stage.name : "", props.task.name ? props.task.name : "", snow_task_instance.jira_task_details.name,];
        showLogsCallback(snow_task_instance.id, path_arr, snow_task_instance.global_task_id);
    }
    return (
        <ServiceExecution>
            <div className="vertical-border-service-card" ></div>
            <div className="vertical-border-one" style={open ? { height: '11%' } : { height: '50%' }}></div>
            <span className="border-stage-card">
                {getProgressStatusIcon()}
            </span>
            <Header>
                {/* <div className="flaticon-check-symbol"></div> */}
                <a href={snow_task_instance.snow_url ? snow_task_instance.snow_url : "/"} target="_blank" className="service"> 
                    {snow_task_instance?.snow_issue_key ? snow_task_instance?.snow_issue_key : "NA"}
                <LinkIcon 
                style={{
                    color:'#fff',
                    verticalAlign: 'sub',
                    marginLeft: '5px',
                    transform: 'rotate(-40deg)'
                    }} /></a>
                {/* <a href="#viewFullLogs" title="view logs" onClick={showLogs} className="flaticon-google-drive-file"></a> */}
                <Link
                    to={"/logs?global_task_id=" + snow_task_instance.global_task_id +
                        "&status=" + snow_task_instance.status +
                        "&tab_id=" + snow_task_instance.id
                    }
                    title="view logs"
                    target="_blank"
                    // onClick={showLogs} 
                    className="flaticon-google-drive-file"></Link>
                <div className={snow_task_instance.status == "SUCCESS" ? "status-div green-text" : snow_task_instance.status == "FAILED" ? "status-div red-text" : "status-div yellow-text"}>&nbsp;{snow_task_instance.status}&nbsp;</div>
                <div className="duration"> {time_taken} </div>
                {
                    open ?
                        <div className="flaticon-expand-arrow" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div> :
                        <div className="flaticon-expand-button" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div>
                }
            </Header>

            {
                open ?
                    <Body>
                        {
                            snow_task_instance.snow_task_details.snow_operation == "snow_create" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">Ticket Type:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {snow_task_instance.snow_task_details.snow_ticket_type}</div>
                                </div>
                                {/* <div className="steps-list">
                    <div className="service">Status:</div>
                    <div className="status-div green-text text-ellipsis-200">&nbsp; {snow_task_instance.snow_task_details.status}</div>
                </div> */}
                            </>
                        }
                        {
                            snow_task_instance.snow_task_details.snow_operation == "snow_update" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">State:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {snow_task_instance.snow_task_details.change_state ? getSnowTicketState(snow_task_instance.snow_task_details.change_state) : "NA"}</div>
                                </div>

                            </>
                        }
                        {
                            snow_task_instance.snow_task_details.snow_operation == "snow_add_notes" &&
                            <>
                                <div className="steps-list">
                                    <div className="service">Note:</div>
                                    <div className="status-div green-text text-ellipsis-200">&nbsp; {snow_task_instance.snow_task_details.work_note ? snow_task_instance.snow_task_details.work_note : "NA"}</div>
                                </div>

                            </>
                        }
                    </Body> : null
            }
        </ServiceExecution>
    )
}


SnowJobExecutionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


export default SnowJobExecutionCard;



const ServiceExecution = styled('div')({
    width: '324px',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4652a2',
    marginLeft: '5rem',
    marginRight: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    '&:last-child > .vertical-border-service-card': {
        display: 'none'
    },
    '&:only-child .main-vertical-div-pipeline > .vertical-border-pipeline': {

    },
    '&:last-child > .vertical-border-service-card:last-child': {
        display: 'none'
    },
    '& .vertical-border-service-card': {
        top: '24px',
        left: '-30px',
        width: '3px',
        height: '104%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:only-child > .vertical-border-one': {
        display: 'block!important',
        height: '40%',
    },
    '& .vertical-border-one': {
        top: '-11px',
        left: '-30px',
        width: '3px',
        height: '50%',
        position: 'absolute',
        backgroundColor: '#666',
    },
    '& .border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '18px'
    },
    '& .circle-status': {
        height: '32px',
        width: '32px',
        border: '3px solid #666',
        position: 'relative',
        left: '-20px',
        bottom: '19px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-round-info-button:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
})
const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})

const Body = styled('div')({
    backgroundColor: '#5e6798',
    fontSize: '12px',
    color: '#fff',
    width: '100%',
    '& .steps-list': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #6b74a8'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .status-div': {
        fontSize: '12px'
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'


    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    '& .flaticon-expand-arrow:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})