import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { makeStyles } from '@material-ui/core/styles';
import { getCommonFunctions } from './SourceDetails';
import { Loading } from '../../../utils/Loading';
import AlertStrip from '../../../../components/AlertStrips';
const BuildX = props => {
  const prev_state = props.prev_state ? props.prev_state : null;
  const [state, setState] = useState(prev_state ? prev_state : buildxDefaultState());
  const inherits = props.inherits ? props.inherits : {};
  const commonFunction = getCommonFunctions(state, setState, inherits);
  const classes = useStyles();
  function onChangeHandlerForKeyValue(key, value) {
    commonFunction.updateDataKeyVal(key, value);
  }

  return (
    <div className={classes.root}>
      <div className="custom-card-header">
        <Input
          type="switch"
          name="buildx_platform_settings"
          label="Advance Configurations"
          data={state.data}
          error={state.error}
          onChangeHandler={commonFunction.toggleState} />

        {
          state.data.buildx_platform_settings ?
            <Input
              type="switch"
              name="buildkit_enabled"
              label="Default Image is generated for AMD 64 Platform/OS"
              data={state.data}
              error={state.error}
              onChangeHandler={commonFunction.toggleState} /> : null
        }
        {
          state.data.buildx_platform_settings && state.data.buildkit_enabled &&
          <div className="pd-10">
            <div className="mb-15">
              <p className="font-24">Select Platforms</p>
              <p className="font-11">Please note we use <span className="text-anchor-blue">BuildX</span>&nbsp; to generate images for different platform to learn more read our <span>Documentation</span></p>
            </div>
            <div className="">
              {
                state.buildx_platform_list ?
                  state.buildx_platform_list.length == 0 ?
                    <AlertStrip
                      variant="info"
                      dismissible={false}
                      message={"No Platforms Available"}
                      extraClasses="mt-5"
                    />
                    :
                    <>
                      <div className={"pd-10 input-component-mb-0"} style={{ backgroundColor: "#f6f6f6" }}>
                        <Input
                          type="checkbox-list"
                          name="selected_platforms"
                          list={state.buildx_platform_list}
                          data={state.data}
                          error={state.error}
                          onChangeHandler={onChangeHandlerForKeyValue}
                        />
                      </div>
                    </>
                  :
                  state.error ?
                    <AlertStrip
                      variant="info"
                      dismissible={false}
                      message={"Sorry This functionality is not available at the moment."}
                      extraClasses="mt-5"
                    />
                    :
                    <Loading varient="light" />
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
BuildX.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
export default BuildX;

export function buildxDefaultState() {
  return {
    data: {},
    error: {},
    buildx_platform_list: [
      {
        id: 'linux/amd64',
        label: 'linux/amd64'
      },
      {
        id: 'linux/arm64',
        label: 'linux/arm64'
      },
      {
        id: 'linux/386',
        label: 'linux/386'
      },
      {
        id: 'linux/riscv64',
        label: 'linux/riscv64'
      },
      {
        id: 'linux/ppc64le',
        label: 'linux/ppc64le'
      },
      {
        id: 'linux/s390x',
        label: 'linux/s390x'
      },
      {
        id: 'linux/arm/v7',
        label: 'linux/arm/v7'
      },
      {
        id: 'linux/arm/v6',
        label: 'linux/arm/v6'
      }
    ]
  }
}


const useStyles = makeStyles(item => ({
  root: {
    border: '1px solid #dedede'
  },
}));