import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components/genericComponents/Input';
import { makeStyles } from '@material-ui/core/styles';
import properties from '../../../../properties/properties';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet, VALIDATION_TYPE_YAML_FILE } from '../../../../util/Validator';
import ParseFile from '../../../../util/FileParser';
import YAML from 'yaml';
import Base64 from 'base-64';
import { GetInfraProviderId } from '../../../../util/util';
import { PostData } from '../../../../util/apiInvoker';
import { Loading } from '../../../utils/Loading';
import { ErrorComponent } from '../../../utils/Error';
import GenerateURL from '../../../../util/APIUrlProvider';

import { Link, Redirect } from 'react-router-dom';
import StepProgressDetailed from '../../../../components/genericComponents/StepProgressDetailed';
import { Box, Grid } from '@material-ui/core';
import { Heading } from '.';

const BasicInfo = (props) => {
    const classes = useStyles();
    const cluster_id = props.cluster_id;
    console.log(cluster_id, "checking cluster id")
    const aws_market_place_flag = properties.aws_market_place_flag;
    const cloud_provider = properties.infra_provider;
    const base_state = {
        validations: {
            name: [VALIDATION_TYPE_REQUIRED],
            kube_config: [VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_YAML_FILE],
            type: [VALIDATION_TYPE_REQUIRED]
        },
        data: {
            name: "",
            type: "",
            metricSetupe: false

        },
        error: {
            name: "",
            kube_config: "",
            type: ""

        },
        canRedirect: false
    }
    const [state, setState] = useState(base_state);

    const [loading, setLoading] = useState(false);
    function onChangeHandler(e) {
        const key = e.target.name;
        const value = e.target.value;

        updateData(key, value);
    }
    function onChangeHandlerName(e) {
        const key = e.target.name;
        var value = e.target.value;
        value = value.replace(/\W+(?!$)/g, '-');
        value = value.replace(/\W$/, '-');
        value = value.replace('_', '-').toLowerCase();
        updateData(key, value);
    }

    function updateData(key, value) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value,
            },
            error: {
                ...new_state.error,
                [key]: ""
            }
        }))
    }
    function onFileUpload(e) {
        ParseFile(e, handleSuccessFileParse, handleFailedFileParse);
    }
    function removeFile(e) {
        const key = e.target.name;
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                kube_config: null,
            }
        }))
    }
    function handleSuccessFileParse(file_data) {
        const key = file_data.event_name;
        const value = {
            name: file_data.name,
            content: file_data.content
        }
        updateData(key, value);
    }

    const parsingIntoYAML = (file_data) => {
        const yaml = Base64.decode(file_data);
        var result = YAML.parse(yaml);
        return result;
    }

    function handleFailedFileParse(error) {
        setState(new_state => ({
            ...new_state,
            error: {
                "kube_config": "Invalid File Format"
            }
        }))

    }
    function onSave() {
        var validation_result = ValidateDataSet(state.data, state.validations);
        if (validation_result.valid) {
            var file_valid = parsingIntoYAML(state.data.kube_config.content)
            var data = {
                name: state.data.name,
                infra_provider_id: GetInfraProviderId(state.data.type),
                kube_config: file_valid,
                metric_server_setup: state.data.metricSetupe
            }
            console.log("SUCCESS PLEASE PASTE LINK", data)
            if (cluster_id) {
                PostData(GenerateURL({ cluster_id: cluster_id }, properties.api.dr_cluster_onboard), data, onPostSuccess, onPostFail)
            } else {
                PostData(GenerateURL({}, properties.api.cluster_onboard), data, onPostSuccess, onPostFail)
            }
            // PostData(GenerateURL({}, properties.api.cluster_onboard), data, onPostSuccess, onPostFail)
            setLoading(true);
            setState(new_state => ({
                ...new_state,
                post_status: "REQUESTED",
            }));

        }
        else {
            setState(new_state => ({
                ...new_state,
                error: validation_result.error
            }))
        }

    }
    function onPostFail(error) {
        setLoading(false)
        var error_temp = JSON.stringify(error)
        setState(new_state => ({
            ...new_state,
            error_main: error_temp,
            error: error,
            post_status: "FAILED"

        }))
    }

    function onPostSuccess(response) {
        setLoading(false);
        console.log("state-id", response);
        setState(new_state => ({
            ...new_state,
            error_main: "",
            error: "",
            post_status: "SUCCESS",
            task_id: response.task_id,
            cluster_id: response.id,
        }))
    }



    function handleRefresh() {

        setState(new_state => ({
            ...new_state,
            canRedirect: true
        }));
    }

    const handleMetricCheckbox = (e) => {
        console.log(e)
        console.log(state.data.metricSetupe)
        setState(prevState => ({
            ...prevState,
            data: {
                ...state.data,
                metricSetupe: !state.data.metricSetupe
            }
        }))
    }
    console.log("bsjhdjsa", state)
    return (
        <>
            {state.post_status == "SUCCESS" ?
                <>
                    <StepProgressDetailed task_id={state.task_id} placeholders={{ cluster_id: state.cluster_id }} refresh={handleRefresh} variant="cluster_onboarding" type="new" />
                    {state.canRedirect ? <Redirect to={"/clusterdashboard"} /> : null}
                </>
                :
                <div className={classes.root}>
                    {
                        loading ?
                            <Loading /> : null
                    }
                    {/* {
                        state.error_main ?
                            <ErrorComponent error={state.error_main} /> : null
                    } */}

                    <Heading />
                    <div className=""> {/* detailedcard */}
                        <Grid container spacing={3} className="detailedcard-body" justifyContent='space-between' alignItems='center'>
                            <Grid container spacing={3} className="detailedcard-body" justifyContent='space-between' alignItems='center'>
                                <Grid item lg={6} className="form-controll">
                                    <Input
                                        type="text"
                                        placeholder="Cluster Name"
                                        mandatorySign
                                        name="name"
                                        label="Give a name to your cluster"
                                        subHeading="AlphaNumeric with hyphen"
                                        error={state.error}
                                        onChangeHandler={onChangeHandlerName}
                                        data={state.data} />
                                </Grid>
                                <Grid item lg={6} className="form-controll" style={state.error.kube_config ?{}:state.error.name?{marginBottom:"25px"}:{}}>

                                    <Input
                                        type="new-file"
                                        name="kube_config"
                                        mandatorySign
                                        label="Upload KubeConfig file"
                                        onChangeHandler={onFileUpload}
                                        data={state.data}
                                        error={state.error}
                                        onFileRemove={removeFile} />
                                </Grid>
                            </Grid>
                            
                            <Grid item className="form-controll">
                                <Input
                                    type="imgCheckbox"
                                    name="type"
                                    mandatorySign
                                    label="Choose Infra Provider"
                                    list={aws_market_place_flag ? [{
                                        id: 1,
                                        label: 'AWS',
                                        value: 1,
                                        src: '/images/logos/aws.png'
                                    }] : cloud_provider}
                                    onChangeHandler={onChangeHandler}
                                    data={state.data}
                                    error={state.error}
                                    variant="cluster" />

                            </Grid>
                        </Grid>
                        <Box padding={"0px 16px"} width={"100%"}>
                            <div className='display-inline-flex align-center space-between width-full' style={{ height: '44px', padding: "10px 12px", borderRadius: 6, border: '1px #D8D8D8 solid' }}>
                                <div className='display-flex align-center gap-8 justify-flexstart'>
                                    <div className='font-14 word-break font-weight-600 font-family-montserrat color-icon-secondary'>Setup metric server post cluster onboarding</div>
                                </div>

                                <label class="switch-new-box">
                                    <input type="checkbox" checked={state.data.metricSetupe} onChange={handleMetricCheckbox} />
                                    <span class="slider-new-box"></span>
                                </label>
                            </div>
                        </Box>

                        <div className="detailedcard-footer">
                            <Link to={"/ClusterDashboard"} className="cancel-btn font-12 font-weight-600 avtar">Cancel</Link>
                            <button className="btn btn-primary avtar font-weight-600" onClick={onSave}>Apply Manifest</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
BasicInfo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
export default BasicInfo;

const useStyles = makeStyles(theme => ({
    root: {
        //paddingBottom: theme.spacing(4),
        paddingRight: 0,
        paddingLeft: 0,
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
        '& .detailedcard': {
            overflow: 'hidden'
        },
        '& .detailedcard-body': {
            padding: '15px 20px',
            //backgroundColor: '#f6f8f8'
        },
        '& .detailedcard-footer': {
            backgroundColor: '#fff',
            padding: '16px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: "flex-end",
            gap: "12px",
            '& .cancel-btn': {
                display: "flex",
                height: "40px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #9DC0EE",
                background: "#FFF",
                color: "#124D9B",
                width: "fit-content",
                lineHeight: "1.4",
                '&:hover': {
                    background: "#124D9B",
                    color: "#FFF",
                }
            }
        },
        '& .image-control': {
            height: '30px'
        },
        '& .image-upload-wrap': {
            height: '44px'
        },
        // '& .btn-submit': {
        //     backgroundColor: '#3696db',
        //     color: '#fff',
        //     marginLeft: 'auto'
        // }
    },

}));