import React, { useState, useEffect, version } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormatTime } from '../../../../util/util';
import { Loading } from '../../../utils/Loading';
import { ErrorComponent } from '../../../utils/Error';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { SubscribeToApi, UnsubscribeToApi } from '../../../../util/apiInvoker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TimelapseIcon from '@material-ui/icons/Timelapse';

import StopIcon from '@material-ui/icons/Stop';
import LinkIcon from '@material-ui/icons/Link';

import MonitoringDialogue from '../../../service/detail/components/MonitoringDialogue';

import MultipleDeploymentCard from './MultipleDeploymentCard';
const ServiceExecutionCard = ({ isDynamicJob, ...props }) => {

    const component_task_instance = props.component_task_instance;
    const project_env_id = props.project_env_id;
    const component_env_id = props.component_env_id ? props.component_env_id : null;
    const component_id = component_task_instance && component_task_instance.component ? component_task_instance.component.id : null
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const selected_component_task_id = props.selected_component_task_id;
    console.log(selected_component_task_id, 'sttedss')
    const update_selected_component_task = props.update_selected_component_task;
    const open = selected_component_task_id == component_task_instance.id;
    const showLogsCallback = props.showLogs;
    const stage = props.stage;
    const start_time = new Date(component_task_instance.activity_status && component_task_instance.activity_status.start_time ? component_task_instance.activity_status.start_time : "");
    const end_time = new Date(component_task_instance.activity_status && component_task_instance.activity_status.start_time ? component_task_instance.activity_status.end_time : "");
    const diff = end_time - start_time;
    const time_taken = FormatTime(diff);
    const task_type_obj = props.task_type_obj ? props.task_type_obj : null;
    const task_details = props.task_details;
    console.log(task_details, 'task_001_0p_')
    const env_name = task_details?.project_env?.name;
    const handlePopOpen = props.handlePopOpen;
    const handleClosePopover = props.handleClosePopover;
    const stopPipelineExecution = props.stopPipelineExecution
    const startPipelineExecution = props.startPipelineExecution
    const start_stop_pipeline_flag = props.start_stop_pipeline_flag
    const version = props.version
    const updateSelectedInformationId = props.updateSelectedInformationId
    const selectedInfoId = props.selectedInfoId
    const toggleSectionOpen = () => update_selected_component_task(!open ? component_task_instance.id : null);


    function getProgressStatusIcon() {
        let statusForIcon = component_task_instance?.activity_status ? component_task_instance?.activity_status?.status : component_task_instance?.status;
        switch (statusForIcon) { //component_task_instance.status
            case "SUCCESS":
                return (
                    <div className="circle-status circle-status-approved"><div className="flaticon-check-symbol"></div></div>
                );
            case "FAILED":
                return (
                    <div className="circle-status circle-status-failed"><div className="flaticon-round-info-button"></div></div>
                );
            case "RUNNING":
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
            case "REVOKED":
                return (
                    <div className="circle-status circle-status-revoked"><TimelapseIcon fontSize="large" className="color-revoked" /></div>
                );
            case "STOPPED":
                return (
                    <div className="circle-status circle-status-stopped" ><StopIcon fontSize="large" className="color-stopped" /></div>
                );
            case "IN_QUEUE":
                return (
                    <div className="circle-status circle-status-inqueue"><div className="flaticon-clock-with-white-face"></div></div>
                );
            default:
                return (
                    <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                );
        }
    }

    function showLogs() {
        const path_arr = [stage.name, props.task.name, component_task_instance.component.name,];
        showLogsCallback(component_task_instance.id, path_arr, component_task_instance.global_task_id);
    }
    console.log("amdlmff", component_task_instance);

    return (

        <div className='ys-div'>
            <ServiceExecution>
                <div className="vertical-border-service-card" ></div>
                <div className="vertical-border-one" style={open ? { height: '105%' } : { height: '50%' }}></div>
                <span className="border-stage-card">
                    {getProgressStatusIcon()}
                </span>
                {/* {console.log("component_task_insta",component_task_instance)} */}
                <Header>
                    {/* <div className="flaticon-check-symbol"></div> */}
                    <div className="service"> {component_task_instance.component.name}</div>
                    {/* <a href="#viewFullLogs" title="view logs" onClick={showLogs} className="flaticon-google-drive-file"></a> */}
                    {
                        !Array.isArray(component_task_instance.information) &&
                        <Link
                            to={"/logs?global_task_id=" + component_task_instance.global_task_id +
                                "&service_name=" + component_task_instance.component.name +
                                "&service_env=" + env_name +
                                "&status=" + component_task_instance.status +
                                "&tab_id=" + component_task_instance.id
                            }
                            title="view logs"
                            target="_blank"
                            // onClick={showLogs} 
                            className="flaticon-google-drive-file"></Link>
                    }

                    <div
                        className={
                            component_task_instance?.status || component_task_instance?.activity_status && component_task_instance?.activity_status?.status ?
                                component_task_instance?.activity_status?.status == "SUCCESS" || component_task_instance?.status == "SUCCESS" ?
                                    "status-div green-text" :
                                    component_task_instance?.activity_status?.status == "FAILED" || component_task_instance?.status == "FAILED" ?
                                        "status-div red-text" :
                                        component_task_instance?.activity_status?.status == "IN_QUEUE" || component_task_instance?.status == "IN_QUEUE" ?
                                            "status-div inqueue-text" :
                                            component_task_instance?.activity_status?.status == "STOPPED" || component_task_instance?.status == "STOPPED" ?
                                                "status-div red-text" :
                                                "status-div yellow-text" : "status-div primary-text"}>&nbsp;{component_task_instance?.activity_status ? component_task_instance?.activity_status?.status : component_task_instance?.status}&nbsp;</div>

                        <div className="duration">{component_task_instance.status == "RUNNING" || component_task_instance.status == "IN_QUEUE" ? '0s' : time_taken}</div>
                    {
                        open ?
                            <div className="flaticon-expand-arrow" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div> :
                            <div className="flaticon-expand-button" onKeyDown={() => { }} onClick={toggleSectionOpen} role="button" tabIndex={0}></div>
                    }
                </Header>

                {
                    open ?
                        <ServiceDetails
                            {...props}
                            onResume={props.onResume}
                            task_type_obj={task_type_obj}
                            task_type={task_details.task_type && task_details.task_type.name ? task_details.task_type.name : null}
                            project_env_id={project_env_id}
                            component_id={component_id}
                            pop={props.pop}
                            start_stop_pipeline_flag={start_stop_pipeline_flag}
                            stopPipelineExecution={stopPipelineExecution}
                            startPipelineExecution={startPipelineExecution}
                            env_name={task_details.project_env.name}
                            component_task_instance={component_task_instance}
                            version={version}
                            isDynamicJob={isDynamicJob}
                            handlePopOpen={handlePopOpen}
                            manual_deploy_flag={task_details.project_env.manual_deploy}
                            handleClosePopover={handleClosePopover}
                            env_master={task_details.project_env.environment_master}
                            component_task_instance_id={component_task_instance.id}
                            updateSelectedInformationId={updateSelectedInformationId}
                            selectedInfoId={selectedInfoId}
                        /> : null
                }
            </ServiceExecution>
        </div>


    )
}

ServiceExecutionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default ServiceExecutionCard;

const ServiceDetails = ({ isDynamicJob, ...props }) => {
    const { pipeline_id, pipeline_instance_id, env_master, stage_instance_id, task_instance_id, component_task_instance_id, component_id, project_env_id, task_type_obj, task_type, component_task_instance, env_name, manual_deploy_flag } = props;
    console.log(task_type, task_type_obj, "sdbbjshbcjdsbc")
    const [state, setState] = useState({
        loaded: false,
        //component_env_id: ""
    });
    const classes = usePopOverStyles();
    const version = props.version;
    const updateSelectedInformationId = props.updateSelectedInformationId
    const selectedInfoId = props.selectedInfoId
    useEffect(() => {
        getServiceDetails();

        SubscribeToApi(GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id,
            stage_instance_id: stage_instance_id,
            task_instance_id: task_instance_id,
            component_task_instance_id: component_task_instance_id
        }, properties.api.component_task_details));

        return () => {
            UnsubscribeToApi(GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
                component_task_instance_id: component_task_instance_id
            }, properties.api.component_task_details));
        }
    }, [component_task_instance_id]);

    function getServiceDetails() {
        var requestInfo = {
            endPoint: GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
                component_task_instance_id: component_task_instance_id
            }, properties.api.component_task_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
    }

    function handleResponse(data) {
        if (data.status == "RUNNING" || data.status == "IN_QUEUE") {
            // console.log('line 199>>>');
            setTimeout(getServiceDetails, 2000);
        }


        if (component_id) {
            fetch_component_envs()
        }
        setState({
            ...state,
            loaded: true,
            data: data
        });
    }

    function handleError(error) {
        setState({
            ...state,
            loaded: true,
            error: error,
        });
    }

    function fetch_component_envs() {
        var requestInfo = {
            endPoint: GenerateURL({
                component_id: component_id,
            }, properties.api.fetch_component_envs),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessResponse, handleFailedError);
        setState({
            ...state,
            loaded: false
        });
    }

    function handleSuccessResponse(data) {
        console.log(data, project_env_id, "sdbhjvbshvbhfb")
        if (data && data.length > 0) {
            var find_component_env = data.find(item => item.project_env_id === project_env_id)

            setState(new_state => ({
                ...new_state,
                loaded: true,
                component_env_id: find_component_env.id ? find_component_env.id : null,
                env_master: find_component_env?.project_env?.environment_master?.id
            }));

        }

    }
    function handleFailedError(error) {
        setState(new_state => ({
            ...new_state,
            loaded: true,
            error: error,
        }));

    }

    function getSymbol(activity) {
        switch ((activity.status.toLowerCase())) {
            case "success":
                return (
                    <div className="flaticon-check-symbol"></div>
                );
            case "running":
                return (
                    <div className="flaticon-clock-with-white-face yellow-text"></div>
                );
            case "failed":
                return (
                    <div className="flaticon-close-button" style={{ color: '#ffa785' }}></div>
                );
            case "stopped":
                return (
                    <StopIcon fontSize="large" className="color-stopped" />
                );
            case "skipped":
                return (
                    <span className='ri-arrow-right-circle-line' style={{color :"#FFBF5C", fontSize :"22px"}}></span>
                );
        }

    }
    function activityDetailsDuration(activityDetail) {
        var created_at_date = moment(activityDetail.created_at);
        var updated_at_date = moment(activityDetail.updated_at);
        var totalDuration = moment.duration(updated_at_date.diff(created_at_date))
        var minutes = totalDuration.minutes();
        var seconds = totalDuration.seconds();
        var durationList = [minutes + "m" + " " + seconds + "" + "s"]
        // var listItems = []
        // for(var i in durationList){
        //     listItems.push(durationList[i])
        // }
        return durationList;
    }
    // const last_arr_element = state.data && state.data.information.activity[state.data.information.activity.length - 1]
    const getMasterLabel = (env_master_id) => {
        var env;
        if (env_master_id == 1)
            env = 'dev';
        if (env_master_id == 2)
            env = 'qa';
        if (env_master_id == 3)
            env = 'staging';
        if (env_master_id == 4)
            env = 'uat';
        if (env_master_id == 4)
            env = 'prod';
        return env;
    }


    const getDeployDurationForInfoMessage = (date1, date2) => {
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs", diffInMs)
        return diffInMs;
    }
    console.log("nbdjs", state);
    return (
        <div className='p-relative'>
            {
                component_task_instance && component_task_instance.information ?
                    component_task_instance.information.detail ?
                        <Body aria-describedby={'simple-popover'} className="hello-html">

                            <div className="steps-list">
                                <div className="service-des ">
                                    Details: <span className="red-text" style={{ color: '#fff' }}>{component_task_instance.information.detail ? component_task_instance.information.detail : "N/A"}</span>
                                </div>
                            </div>

                        </Body>
                        : null : null
            }
            {
                !state.loaded ?
                    <Loading /> : state.error ? <ErrorComponent /> :
                        <>
                            <Body aria-describedby={'simple-popover'} className="hello-html">

                                {state.data && state.data.information ?
                                    state.data.information.env_integration_detail ?
                                        <>
                                            {
                                                state.data.status && state.data.status === "RUNNING" || state.data.status && state.data.status === "STOPPED" ?
                                                    <>
                                                        <div className="steps-list">
                                                            <div className="service-des">
                                                                Integration testing has been initiated :
                                                            </div>
                                                        </div>
                                                        <div className="steps-list">
                                                            <LinkIcon fontSize="large" className="green-text" />
                                                            <div className="service-des ">
                                                                URL : <span className="green-text">{state.data.information.env_integration_detail.url ? state.data.information.env_integration_detail.url : "N/A"}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                        </>
                                        : null : null}
                                {
                                    state.data && state.data.information && Array.isArray(state.data.information) ?

                                        <> {/* for multiple deployment */}

                                            {

                                                state.data.information?.map((informationData, index) => {
                                                    console.log(informationData, 'dfvb')
                                                    return <MultipleDeploymentCard
                                                        selectedInfoId={selectedInfoId}
                                                        updateSelectedInformationId={updateSelectedInformationId}
                                                        component_name={component_task_instance?.component?.name}
                                                        // env_name={informationData.project_env ? informationData.project_env : "NA"}
                                                        activity_id={informationData.activity_status ? informationData.activity_status.id : "NA"}
                                                        key={informationData.id}
                                                        component_id={component_id}
                                                        component_task_instance={component_task_instance}
                                                        component_env_id={state.component_env_id ? state.component_env_id : null}
                                                        informationData={informationData}
                                                        getSymbol={getSymbol}
                                                        manual_deploy_flag={manual_deploy_flag}
                                                        activityDetailsDuration={activityDetailsDuration}
                                                        env_master={env_master}
                                                        open_by_default={index == 0 ? true : false}
                                                        task_type={task_type}
                                                        env_name={env_name}
                                                    />
                                                })
                                            }
                                        </>
                                        :
                                        <>
                                            {state.data && state.data.information ? state.data.information.activity ?
                                                state.data.information.activity.map(activity => (
                                                    <div className="steps-list">

                                                        {getSymbol(activity)}
                                                        <div className="service">{activity.activity_sub_task}:</div>
                                                        <div
                                                            className={activity.status.toLowerCase() == "success" ?
                                                                "status-div green-text" :
                                                                activity.status.toLowerCase() == "failed" ?
                                                                    "status-div red-text d-flex align-center" :
                                                                    activity.status.toLowerCase() == "stopped" ? "status-div red-text d-flex align-center" : "status-div yellow-text"}>
                                                            &nbsp;{activity.status.toLowerCase()}&nbsp;

                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '100px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >

                                                            Duration: {activityDetailsDuration(activity)}
                                                        </div>

                                                        {
                                                            activity.status_file_path ?
                                                                <a target="_blank" href={activity.status_file_path}><span className="flaticon-link-button" style={{ color: '#fff' }}></span></a> : null
                                                        }
                                                    </div>
                                                )) : null : null
                                            }
                                            {state.data && state.data.information ?
                                                state.data.information.env_integration_detail ?
                                                    <>
                                                        {
                                                            state.data.status && state.data.status === "RUNNING" || state.data.status && state.data.status === "STOPPED" ?
                                                                <>
                                                                    {state.data.information.env_integration_detail.stopped && state.data.status === "STOPPED" ?
                                                                        <div className="steps-list">
                                                                            <p className='service-des'>
                                                                                The pipeline will remain paused till we receive a response post integration testing
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                    }

                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    : null : null
                                            }
                                        </>
                                }
                            </Body>
                            {
                                Array.isArray(state?.data?.information) ?
                                    null
                                    :
                                    <>
                                        {
                                            state.data && state.data.information ?
                                                state.data.information.integration_args_key_value && (state.data.information.integration_args_key_value.activity_master_code && state.data.information.integration_args_key_value.activity_master_code == "INTEGRATION") ?
                                                    null :
                                                    <>
                                                        {
                                                            state.data.information.build_args_key_value && state.data.information.build_args_key_value.branch_name ?
                                                                <Footer className=' border-color-light-purple ' style={{ borderBottom: '1px solid #6b74a8', borderTop: '1px solid #6b74a8' }}>
                                                                    <span className="tag-image border-bottom">Branch Name:&nbsp;&nbsp;{state.data.information.build_args_key_value.branch_name}</span>
                                                                </Footer>
                                                                : null
                                                        }
                                                        <Footer >
                                                            <span className="tag-image">Artifact: : {state.data.information.build_tag ? state.data.information.build_tag : state.data.information.deploy_tag ? state.data.information.deploy_tag : state.data.information.rollback_tag ? state.data.information.rollback_tag : state.data.information.target_image_tag}</span>
                                                        </Footer>
                                                    </> : null
                                        }
                                    </>
                            }


                        </>
            }
        </div>
    );
}

ServiceDetails.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const usePopOverStyles = makeStyles(() => ({
    pop_over: {
        position: 'absolute',
        left: '320px',
        top: '-48px',
        zIndex: 999
    }
}));
const ServiceExecution = styled('div')({
    width: '324px',
    borderRadius: '4px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#4652a2',
    marginLeft: '5rem',
    marginRight: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    '&:last-child > .vertical-border-service-card': {
        display: 'none'
    },
    '&:only-child .main-vertical-div-pipeline > .vertical-border-pipeline': {

    },
    '&:last-child > .vertical-border-service-card:last-child': {
        // display: 'none'
    },
    '& .vertical-border-service-card': {
        top: '24px',
        left: '-30px',
        width: '3px',
        height: '104%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:only-child > .vertical-border-one': {
        display: 'block!important',
        height: '40%',
    },
    '& .vertical-border-one': {
        top: '-11px',
        left: '-30px',
        width: '3px',
        height: '50%',
        position: 'absolute',
        backgroundColor: '#666',
    },
    '& .border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '18px'
    },
    '& .circle-status': {
        height: '32px',
        width: '32px',
        border: '3px solid #666',
        position: 'relative',
        left: '-20px',
        bottom: '19px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '& .circle-status-inqueue': {
        border: '3px solid #b1cde3 !important'
    },
    '& .circle-status-inqueue .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#b1cde3',
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-stopped': {
        border: '3px solid #e9797e'
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-round-info-button:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
})
const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    "& .flaticon-google-drive-file": {
        color: '#fff',
        cursor: 'pointer',
        margin: '0px 8px'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px',
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'
    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})
const SubHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    background: 'blue',

    '& .deployment-name': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        width: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },

    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto',
        transition: 'all .3s ease',
    },
    '& .flaticon-expand-arrow:before, .flaticon-expand-button:before': {
        fontSize: '10px !important'
    },
    '& .flaticon-expand-button': {
        justifySelf: 'flex-end',
        cursor: 'pointer',
        marginLeft: 'auto',
        color: '#fff',
        transition: 'all .3s ease',
    }
})

const Body = styled('div')({
    backgroundColor: '#5e6798',
    fontSize: '12px',
    color: '#fff',
    width: '100%',
    '& .steps-list': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #6b74a8'
    },
    '& .flaticon-schedule-button': {
        color: '#929ac9'
    },
    '& .running': {
        color: "#ffc514!important"
    },
    '& .flaticon-schedule-button:before': {
        fontSize: '16px!important'
    },
    '& .service': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    '& .service-des': {
        lineHeight: '18px',
        fontSize: '12px',
        color: '#fff',
        margin: '0px 5px',
    },
    '& .status-div': {
        fontSize: '12px'
    },
    '& .status-div.green': {
        backgroundColor: 'inherit',
        color: '#79e599'
    },
    '& .duration': {
        color: '#fff',
        fontSize: '12px',
        margin: '0px 5px',
        whiteSpace: 'nowrap'


    },
    '& .flaticon-expand-arrow': {
        justifySelf: 'flex-end',
        color: '#fff!important',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    '& .flaticon-expand-arrow:before': {
        fontSize: '12px!important'
    },
    '& .flaticon-check-symbol': {
        color: '#79e599'
    }
})
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    fontSize: '12px',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    '& .artifact-name': {
        color: '#b5bbd9',
        display: 'none'
    }
})