import React, { useState } from 'react'
import PropTypes from 'prop-types';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import InvokeApi from '../../../util/apiInvoker';


function HealthCheck(props) {
    const service_id = props.service_id
    const env_id = props.env_id;
    const [healthState, setHealthState] = useState({
        message: "N/A"
    });

    function fetchHealthCheckData() {

        // const placeholders = {
        //     service_id: service_id,
        //     env_id: env_id
        // }
        // var requestInfo = {
        //     endPoint: GenerateURL(placeholders, properties.api.health_check),
        //     httpMethod: "GET",
        //     httpHeaders: { "Content-Type": "application/json" }
        // }
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.health_check_new),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, healthCheckFetchSuccess, healthCheckFetchFailure);

    }

    function healthCheckFetchSuccess(response) {
        // console.log(response,"response health check ==========>")
        if (response.status) {
            setHealthState({
                message: "SUCCESS"
            });
        }
        else {
            setHealthState({
                message: "FAILED"
            });
        }
    }

    function getStatus() {
        switch (healthState.message) {
            case "FAILED":
                return <span className="status-font round-chip bg-round-red" id="healthstatus" style={{ padding: '1px 8px 0px' }}>{healthState.message}</span>
            case "SUCCESS":
                return <span className="status-font round-chip bg-round-green" id="healthstatus" style={{ padding: '1px 8px 0px' }}>{healthState.message}</span>
            default:
                return <span style={{ color: '#666', margin: '0px 3px', display: 'inline-block' }} id="healthstatus" >N/A</span>
        }
    }

    function healthCheckFetchFailure(error, exception) {
        setHealthState({
            message: "N/A"
        });
    }

    return (
        <>
            {
                env_id ?
                    <>
                       

                        <span className="owner-new" style={{ color: '#000' }}>Health: </span>
                        {getStatus()}
                        <button style={{ color: '#0086ff', backgroundColor: 'transparent', border: 'none', margin: '0px 3px 0px 0px', display: 'inline-block',fontWeight: "600",fontSize: "12px" }} onClick={fetchHealthCheckData} >Check Status</button>
                        <FavoriteBorderIcon id="heart" style={{ fontSize: '14px', lineHeight: 1, color: "#666" }} />
                    </> : null
            }
        </>

    );

}

HealthCheck.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default HealthCheck;
