import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { VALIDATION_TYPE_REQUIRED } from '../../../util/Validator';
import { Grid } from '@material-ui/core';
import { Input } from '../../../components/genericComponents/Input';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Delete from '../../../components/genericComponents/Delete';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import AddMatrixDialog from './components/AddMatrixDialog';

const AddCanary = () => {
    const classes = useStyles();
    const inherits = {}
    const [open, setOpen] = useState(false)
    const [editedItem, setEditedItem] = useState(null);
    const [state, setState] = useState({
        data: {
        },
        matrix_analysis_list: [],
        error: {},
        validations: {
            group_name: [VALIDATION_TYPE_REQUIRED],
        },
        child_inherits: {
            add_more_matrix_child: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const validateAndSubmit = () => {
        var result = ValidateDataSet(state.data, state.validations);
        console.log(inherits, "child_state_validation____&&")
        var child_state_validation = inherits.validateForm && inherits.validateForm() ? inherits.validateForm() : { valid: true };
        var child_state = inherits.getState && inherits.getState() ? inherits.getState() : null;
        console.log(result, child_state_validation, "child_state_validation____&&", child_state)
        if (result.valid && child_state_validation.valid) {

        } else {
            setState((new_state) => ({
                ...new_state,
                error: {
                    ...new_state.error,
                    ...result.error
                }
            }));
        }
    }

    const handleClickClose = () => {
        setOpen(false)
    }
    const handleClickOpen = () => {
        setOpen(true)
    }

    // const getDataAndSetToState = (data) => {
    //     setState((new_state) => ({
    //         ...new_state,
    //         matrix_analysis_list: [
    //             ...new_state.matrix_analysis_list,
    //             { ...data },
    //         ]
    //     }));
    // }
    const getDataAndSetToState = (data) => {
        const { fail_on, resource, matrix } = data;

        // Check if an object with the same values for "fail_on," "resource," and "matrix" already exists
        const existingIndex = state.matrix_analysis_list.findIndex(
            (item) =>
                item.fail_on === fail_on &&
                item.resource === resource &&
                item.matrix === matrix
        );

        if (existingIndex !== -1) {
            // If exists, update the existing object
            setState((new_state) => ({
                ...new_state,
                matrix_analysis_list: new_state.matrix_analysis_list.map(
                    (item, index) =>
                        index === existingIndex ? { ...item, ...data } : item
                ),
            }));
        } else {
            // If doesn't exist, add a new entry
            setState((new_state) => ({
                ...new_state,
                matrix_analysis_list: [...new_state.matrix_analysis_list, { ...data }],
            }));
        }
    };

    const handleEditClick = (editedItem) => {
        // Open the dialog and pass the edited item data
        setOpen(true);
        setEditedItem(editedItem); // Assuming you have a state variable to store edited item data
    };

    console.log(state.matrix_analysis_list, "basic_detailsbasic_details___arr")
    return (
        <div className={classes.root}>
            <div className={classes.stabilityMatrix}>
                <h3>CANARY ANALYSIS TEMPLATE
                    <span className='' style={{
                        background: '#0086ff',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        padding: '3px 6px',
                        color: '#fff',
                        marginLeft: '5px',
                        borderRadius: '4px'
                    }}>TEMPLATE</span></h3>
                <div className={classes.formCard}>
                    <Grid item lg={12}>
                        <Input
                            type="text"
                            placeholder="Enter Name"
                            label={"Template Name"}
                            name="template_name"
                            style={{ height: '40px' }}
                            error={state.error}
                            mandatorySign
                            onChangeHandler={commonFunctions.onChangeHandler}
                            data={state.data}
                        />
                    </Grid>
                    <Grid item lg={12}>
                        <p className='font-14 font-weight-500 mb-15' style={{ color: '#2f2f2f' }}>Matrix for analysis</p>
                    </Grid>
                    <Grid item lg={12}>
                        <div className='' style={{ border: '1px solid #e6e6e6', borderRadius: '6px' }}>
                            <div
                                className='font-12 border-bottom'
                                style={{
                                    padding: '10px 20px',
                                    color: '#787878',
                                    display: 'grid',
                                    gridTemplateColumns: '250px 180px 300px 237px 1fr'
                                }}>
                                <p>Matrix</p>
                                <p>Fail on</p>
                                <p>Fail Criteria/Terminate Criteria</p>
                                <p>Matrix Group</p>
                                <p>Action</p>
                            </div>
                            {
                                state.matrix_analysis_list.length > 0 ?
                                    state.matrix_analysis_list.map(item => {
                                        return <>
                                            <div
                                                className='font-12 font-weight-500 border-bottom'
                                                style={{
                                                    padding: '10px 20px',
                                                    color: '#787878',
                                                    display: 'grid',
                                                    alignItems: 'center',
                                                    gridTemplateColumns: '250px 180px 300px 237px 1fr'
                                                }}>
                                                <p className="font-12 font-weight-500" style={{ color: '#2f2f2f' }}>{item.matrix}</p>
                                                <p>{item.fail_on}</p>
                                                <p>
                                                    {
                                                        item.fail_on && item.fail_on === "increase" ?
                                                            <>
                                                                <span className='ri-arrow-up-circle-line font-14' style={{ color: '#129E5B' }}></span>
                                                                {item.increase_percentage + "/" + item.critical_increase_percentage}
                                                            </>
                                                            :
                                                            item.fail_on === "decrease" ?
                                                                <>
                                                                    <span className='ri-arrow-down-circle-line font-14' style={{ color: '#C11212' }}></span>
                                                                    {item.decrease_percentage + "/" + item.critical_decrease_percentage}
                                                                </>
                                                                :
                                                                <div>
                                                                    <span className='ri-arrow-up-circle-line font-14' style={{ color: '#129E5B' }}></span>
                                                                    {item.increase_percentage + "/" + item.critical_increase_percentage}
                                                                    &nbsp;&nbsp;
                                                                    <span className='ri-arrow-down-circle-line font-14' style={{ color: '#C11212' }}></span>
                                                                    {item.decrease_percentage + "/" + item.critical_decrease_percentage}
                                                                </div>
                                                    }


                                                </p>
                                                <p className='btn-link cursor-pointer font-13' style={{ color: '#0086ff' }}><Link to={"#"}>{item.resource}</Link></p>
                                                <div>
                                                    <div className='btn-icon-group-div'>
                                                        <button className='btn btn-icon-outline btn-icon-outline-primary' onClick={() => handleEditClick(item)} >
                                                            <span className='font-20 ri-edit-line'></span>
                                                        </button>
                                                        <button className='btn btn-icon-outline btn-icon-outline-danger' onClick={handleClickOpen}>
                                                            <span className='font-20 ri-delete-bin-7-line '></span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })

                                    :
                                    <div
                                        className='font-12 font-weight-500 border-bottom'
                                        style={{
                                            padding: '10px 20px',
                                            color: '#787878',
                                            display: 'block',
                                            alignItems: 'center',
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                        <p>Matrix for analysis is not added yet!</p>
                                    </div>
                            }

                            <div
                                className='font-12 font-weight-500'
                                style={{
                                    padding: '10px 20px',
                                    color: '#787878',
                                }}>
                                <button
                                    className='variable-list-button mr-5'
                                    onClick={handleClickOpen}
                                    style={{}}
                                >Add Another Matrix</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12}>

                        <AddMatrixDialog
                            open={open}
                            handleClose={handleClickClose}
                            sendDataToParent={getDataAndSetToState}
                            editedItem={editedItem}
                        />
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default AddCanary


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 20,
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
        '& .with-header': {
            display: 'none'
        },
        '& .transparent-btn-add-more': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#0086FF',
            fontFamily: "'montserrat', sans-serif"
        }
    },
    stabilityMatrix: {
        padding: "20px",
        '&>h3': {

            fontWeight: "600",
            fontSize: "16px"
        }

    },
    formCard: {

        paddingTop: '24px',
        '& .MuiTextField-root': {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: '0px 14px!important'
            }

        },
        '& .label-controller': {
            display: 'inline-block',
            marginBottom: '8px'
        }

    },
    mainCard: {
        '&>p': {
            borderBottom: '1px solid #e6e6e6',
            padding: '10px 20px',
        },
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 120px',
        backgroundColor: '#fff',
        border: '1px solid #e6e6e6',
        borderBottom: '0px',
        borderRadius: '6px',
        overflow: 'hidden',
        '& .mainCardItem': {
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            borderBottom: '1px solid #e6e6e6',
            minHeight: '75px',
            '& .success-chip': {
                backgroundColor: '#E6FBEA',
                color: '#129E5B',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
            '& .not-success-chip': {
                backgroundColor: '#FCF6E1',
                color: '#E1941D',
                width: 'fit-content',
                padding: '4px 8px',
                borderRadius: '24px'
            },
        },
        '& .item1': {
            '& .thumbnail_outer': {
                boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '52px',
                width: '52px',
                borderRadius: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .logo_thumbnail': {
                // boxShadow: '0px 2.2px 2.2px 0px #00000014',
                height: '48px',
                width: '48px',
                borderRadius: 24,
                border: '2px solid #f4f4f4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }
        }
    }
}));