import Base64 from 'base-64';
import YAML from 'yaml';
export const VALIDATION_TYPE_REQUIRED = "VALIDATION_TYPE_REQUIRED";
export const VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY = "VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY";
export const VALIDATION_TYPE_KEY_VALUE_REQUIRED = "VALIDATION_TYPE_KEY_VALUE_REQUIRED";
export const VALIDATION_TYPE_START_WITH_SLASH = "VALIDATION_TYPE_START_WITH_SLASH";
export const VALIDATION_TYPE_TEN_CHARS = "VALIDATION_TYPE_TEN_CHARS";
export const VALIDATION_TYPE_PASSWORD = "VALIDATION_TYPE_PASSWORD";
export const VALIDATION_TYPE_EMAIL = "VALIDATION_TYPE_EMAIL";
export const VALIDATION_TYPE_COMMA_SEPRATED_STRING = "VALIDATION_TYPE_COMMA_SEPRATED_STRING";
export const VALIDATION_TYPE_YAML_FILE = "VALIDATION_TYPE_YAML_FILE";
export const VALIDATION_TYPE_POSITIVE_NUMBER = "VALIDATION_TYPE_POSITIVE_NUMBER"
export const VALIDATION_TYPE_IP_ADDRESS = "VALIDATION_TYPE_IP_ADDRESS";
export const VALIDATION_TYPE_MOBILE = "VALIDATION_TYPE_MOBILE";
export const VALIDATION_TYPE_CRON_PATTERN = "VALIDATION_TYPE_CRON_PATTERN";
export const VALIDATION_TYPE_SAME_NAME = "VALIDATION_TYPE_SAME_NAME"
const RequiredFieldValidator = (value) => {

    if (!value) {
        return "This is required";
    } else if (value.length == 0) {
        return "This is required";
    } else {
        return null;
    }
}

const RegexValidator = (value, regex) => {
    return regex.test(value);
}


const validate = (value, validation, list) => {
    var result = {
        valid: true
    };
    switch (validation) {
        case VALIDATION_TYPE_REQUIRED:
            var error_local = RequiredFieldValidator(value)
            if (error_local) {
                result = {
                    valid: false,
                    error: error_local
                }
            }
            break;
        case VALIDATION_TYPE_SAME_NAME:
            var valid = false;
            console.log(list, value, "VALIDATION_TYPE_SAME_NAME")
            if (list && list.length > 0) {
                let found_duplicate = list.find(item => item.label == value);
                if (found_duplicate) {
                    result = {
                        valid: false,
                        error: "This Deployment Name Already Exists"
                    }
                }
            }
            break;
        case VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY:
            //      old regex => /^([a-z][a-z0-9]*\-?[a-z0-9]+)*$/
            var regex = RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/);
            var valid = RegexValidator(value, regex)
            if (!valid) {
                result = {
                    valid: false,
                    error: "Only lowercase letters, numbers, and the dash symbol (-). characters should not end with (-) hyphen."
                }//"Minimum two characters. Please do not end with hyphen. No special characters allowed."
            }
            break;
        case VALIDATION_TYPE_IP_ADDRESS:
            var regex = RegExp(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/);
            if (value) {
                var valid = RegexValidator(value, regex)
                if (!valid) {
                    result = {
                        valid: false,
                        error: "Please enter valid IP Address"
                    }//"Minimum two characters. Please do not end with hyphen. No special characters allowed."
                }
            }
            break;
        case VALIDATION_TYPE_CRON_PATTERN:
            var regex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);;
            if (value) {
                var valid = RegexValidator(value, regex)
                if (!valid) {
                    result = {
                        valid: false,
                        error: "Invalid cron pattern"
                    }//"Minimum two characters. Please do not end with hyphen. No special characters allowed."
                }
            }
            break;
        case VALIDATION_TYPE_EMAIL:
            var regex = RegExp(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
            var valid = RegexValidator(value, regex)
            if (!valid) {
                result = {
                    valid: false,
                    error: "Invalid Email Address"
                }
            }
            break;
        case VALIDATION_TYPE_START_WITH_SLASH:
            var regex = RegExp(/^\//);
            var valid = RegexValidator(value, regex)
            if (!valid) {
                result = {
                    valid: false,
                    error: "This follows the patern \"^\/\""
                }
            }
            break;
        case VALIDATION_TYPE_POSITIVE_NUMBER:

            if (!Number(value) || Number(value) <= 0) {
                result = {
                    valid: false,
                    error: "This should be a no. greater than 0"
                }
                return result;
            }
            break;
        case VALIDATION_TYPE_KEY_VALUE_REQUIRED:
            if (value && value.length > 0) {
                value.forEach((obj) => {
                    if (!obj.env_key) {
                        result = {
                            valid: false,
                            error: "Key can not be empty"
                        }
                        return result;
                    }
                    if (!obj.env_value) {
                        result = {
                            valid: false,
                            error: "Value can not be empty"
                        }
                        return result;
                    }
                });
            }
            break;
        case VALIDATION_TYPE_TEN_CHARS:
            var valid = value.length == 10;
            if (!valid) {
                result = {
                    valid: false,
                    error: "This should contains 10 digits excactly"
                }
            }
            break;
        case VALIDATION_TYPE_PASSWORD:
            var passwordRegex = RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i);
            var valid = RegexValidator(value, passwordRegex)
            if (!valid) {
                result = {
                    valid: false,
                    error: "Min 8 Chars, at least one letter, one number and one special character"
                }
            }
            break;
        case VALIDATION_TYPE_COMMA_SEPRATED_STRING:
            console.log("888888888888888888888888");
            const arr = value ? value.split(",") : null;
            var valid = true;
            var err_index = 0;
            arr.forEach((val, index) => {
                if (!val) {
                    valid = false;
                    err_index = index + 1;
                }
            })
            if (!valid) {
                result = {
                    valid: false,
                    error: "No. " + err_index + " field is empty"
                }
            }
            break;
        case VALIDATION_TYPE_YAML_FILE:
            if (value) {
                const yaml = Base64.decode(value.content);

                try {
                    var convert = YAML.parse(yaml);
                    console.log("jjj",convert);

                } catch (error) {
                    result = {
                        valid: false,
                        error: "Invalid YAML File"
                    }

                }
            }
            break;


        default:
            break;
    }
    return result;
}


export const GenericValidator = (value, validations, list) => {
    console.log(list, "VALIDATION_TYPE_SAME_NAME")
    const errors = [];
    validations.forEach(validation => {
        const result = validate(value, validation, list);
        if (!result.valid) {
            errors.push(result.error)
        }
    });
    console.log("sdbskjbvjs", errors);
    return errors.length > 0 ? errors : null;
}

export const ValidateDataSet = (data, validations, list) => {
    console.log(data, validations, list, "testing___")
    var error_by_field = null;
    if (validations) {
        Object.keys(validations).forEach((key) => {
            const errors = GenericValidator(data[key], validations[key], list);
            if (errors) {
                error_by_field = error_by_field ? error_by_field : {};
                error_by_field[key] = errors;
            }
        });
    }
    if (error_by_field) {
        return {
            valid: false,
            error: error_by_field,
        };
    }
    return {
        valid: true,
    };
}