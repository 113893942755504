import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
    makeStyles,
} from "@material-ui/core/styles";
import { Loading } from "../../../views/utils/Loading";
import NumberComponent from "../AdvanceSearchFilter/NumberComponent";

const SearchDropdownComponent = ({ data,
    uniqueId,
    onSearch,
    name,
    label,
    searchName,
    autoClosedAfterSelection,
    list,
    changeParentStateFun,
    onUpdate,
    height,
    gap
}) => {
    console.log(list, data, "list and data")
    const [advCardVisible, setAdvCardVisible] = useState(false)
    const [state, setState] = useState({
        lengthOflistBeforeSearch: null,
    })
    const searchValue = data && data[searchName]
    const selectedCheckBoxes = data[name] ? data[name] : []


    const classes = useStyles();
    const handleClick = (event) => {
        const isInsideParentDiv = event.target.closest(`.${uniqueId}`);

        if (isInsideParentDiv)
            return false;
        else
            setAdvCardVisible(false)
    };

    useEffect(() => {
        if (list?.length > 0 && (searchValue == undefined || searchValue == ' ')) {
            setState(prevState => ({
                ...prevState,
                lengthOflistBeforeSearch: list.length
            }))
        }
    }, [list, searchValue])

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const popElementByName = (arr, option) => {
        const updatedArray = arr?.filter(item => option.id != item.id)
        return updatedArray
    }

    const handleChangeForCheckBox = (e, option) => {
        //const value = e.target.value
        const isChecked = e.target.checked
        let currentSelectedList = selectedCheckBoxes
        if (isChecked) {
            currentSelectedList.push(option);
            changeParentStateFun(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: currentSelectedList
                }
            }))
            onUpdate(currentSelectedList)
        }
        else {

            const updatedArray = popElementByName(currentSelectedList, option)
            console.log("jsdj", updatedArray);
            changeParentStateFun(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: updatedArray
                }
            }))
            onUpdate(updatedArray)
        }


        if (autoClosedAfterSelection)
            setTimeout(() => {
                setAdvCardVisible(false)
            }, 300)
    }

    // const handleClearSelection = () => {
    //     setState(prevState => ({
    //         ...prevState,
    //         selectedChecboxes: []
    //     }))
    //     setAdvCardVisible(false)
    // }

    const handleButtonClicked = () => {
        setAdvCardVisible(prevState => !prevState)
    }


    console.log(state, 'state+dst23323232')

    const checkForChecboxStatus = (id) => {
        if (selectedCheckBoxes) {
            const found = selectedCheckBoxes.find(item => item.id == id)
            if (found) {
                return true
            }
            return false
        }
    }

    const onHandleSearchChange = (e) => {
        const key = e.target.key
        const value = e.target.value
        changeParentStateFun(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [key]: value
            }
        }))
        onSearch(value)
    }

    const popElementForAll = (currentSelectedList, toRemoveList) => {

        console.log(currentSelectedList, toRemoveList, 'adewwe_23232323')
        let updatedList = []
        currentSelectedList?.forEach(item => {
            let isStillChecked = true
            toRemoveList?.forEach(removeObject => {
                if (item.id == removeObject.id) {
                    isStillChecked = false
                }
            })

            if (isStillChecked) {
                updatedList.push(item)
            }
        })

        return updatedList

    }

    const handleChangeForAll = (e) => {
        const isChecked = e.target.checked
        console.log(isChecked, 'is_checked_232323')
        let currentSelectedList = selectedCheckBoxes
        if (isChecked) {
            const updatedList = [...list] //
            changeParentStateFun(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: updatedList
                }
            }))
            onUpdate(updatedList)
        }
        else {

            const updatedArray = popElementForAll(currentSelectedList, list)
            changeParentStateFun(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [name]: updatedArray
                }
            }))
            onUpdate(updatedArray)
        }

        if (autoClosedAfterSelection)
            setTimeout(() => {
                setAdvCardVisible(false)
            }, 300)
    }
    console.log(selectedCheckBoxes, list, "asfdsdfsdf")
    const checkForCheckBoxStatusOfAll = () => {
        let currentSelectedList = selectedCheckBoxes
        // if (list?.length !== currentSelectedList?.length) {
        //     return false;
        // }
        // list?.forEach(item => {
        //     const isItemFound = currentSelectedList?.find(selectedChecbox => selectedChecbox.id == item.id)
        //     if (!isItemFound) {
        //         return false
        //     } 
        // });

        // return true


        if (list?.length !== currentSelectedList?.length) {
            return false;
        }
        list?.sort((a, b) => a.id - b.id);
        currentSelectedList?.sort((a, b) => a.id - b.id);
    
        for (let i = 0; i < list.length; i++) {
            if (list[i].id !== currentSelectedList[i].id) {
                return false;
            }
        }
        return true;
    }


    return (<div className={classes.searchFilterContainer + " " + `${uniqueId}`} style={{ gap: gap ? gap : "" }}>
        {
            <>
                <label style={{
                    'color': '#373737',
                    'fontSize': '14px',
                    'fontWeight': '500',
                }}>{label}</label>
                <div onKeyDown={() => { }} onClick={handleButtonClicked} className='dropdown-container-adv d-flex align-center space-between' tabIndex={0} role="button" style={{ width: "100%", height: height ? height : "48px" }}>

                    <div className="mr-12 d-flex align-center">
                        {
                            selectedCheckBoxes?.length > 0 ? <>
                                {


                                    <label style={{ color: '#1a1919' }} className="filter-name-style mr-5">{selectedCheckBoxes[0]?.label}</label>
                                }
                                {
                                    selectedCheckBoxes?.length > 1 ?
                                        <div className="mr-12">
                                            <NumberComponent number={selectedCheckBoxes?.length - 1} />
                                        </div>
                                        : null
                                }
                            </>
                                : <span className="filter-name-style mr-2">Please Select</span>
                        }
                    </div>

                    <KeyboardArrowDownIcon style={{ fontSize: '20px', marginTop: '2px', color: '#5a5a5a' }} />
                </div>
            </>
        }
        {
            advCardVisible ?
                <>
                    {
                        <div className="combo-box">

                            {
                                state.loadingData ?
                                    <Loading varient='light' />

                                    :
                                    <>
                                        <div >
                                            <input
                                                className='search-input-si'
                                                name={searchName}
                                                autoFocus={true}
                                                placeholder='Search'
                                                value={searchValue}
                                                onChange={onHandleSearchChange}
                                                disabled={false}
                                            />
                                        </div>
                                        <div style={{ height: "200px", overflow: 'auto', background: '#fff' }}>

                                            {
                                                searchValue != '' && searchValue != undefined ?
                                                    <>
                                                        {
                                                            list?.length > 0 ?
                                                                <>
                                                                    <div style={{ borderBottom: '1px solid #dedede' }} className="mr-12 ml-12 pb-8 mb-5 pl-18">
                                                                        <FormControlLabel control={<Checkbox checked={checkForCheckBoxStatusOfAll} onChange={(e) => handleChangeForAll(e)} name={'all_checkbox'} value={'0'} />} label={`Select All (${list.length}/${state.lengthOflistBeforeSearch})`} />
                                                                    </div>
                                                                    <div className='pt-12 pb-12 pl-15 pr-15'>
                                                                        {
                                                                            list?.map(option => {
                                                                                return (
                                                                                    <div className="pl-15">
                                                                                        <FormControlLabel control={<Checkbox checked={checkForChecboxStatus(option.id)} onChange={(e) => handleChangeForCheckBox(e, option)} name={option.id} value={option.id} />} label={option.label} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>

                                                                :

                                                                <div className="not-found-box d-flex align-center justify-center">
                                                                    <span className="font-14">No match found</span>
                                                                </div>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            list?.length > 0 ?
                                                                <>

                                                                    {
                                                                        list?.length > 0 &&
                                                                        <div style={{ borderBottom: '1px solid #dedede' }} className="mr-12 ml-12 pb-8 mb-5 pl-18">
                                                                            <FormControlLabel control={<Checkbox checked={checkForCheckBoxStatusOfAll("0")} onChange={(e) => handleChangeForAll(e)} name={'all_checkbox'} value={'0'} />} label={`Select All (${list.length}/${state.lengthOflistBeforeSearch})`} />
                                                                        </div>
                                                                    }

                                                                    <div className='pt-12 pb-12 pl-15 pr-15'>
                                                                        {
                                                                            list?.map(option => {
                                                                                return (
                                                                                    <div className="pl-15">
                                                                                        <FormControlLabel control={<Checkbox checked={checkForChecboxStatus(option.id)} onChange={(e) => handleChangeForCheckBox(e, option)} name={option.id} value={option.id} />} label={option.label} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                </> : <div className="not-found-box d-flex align-center justify-center">
                                                                    <span className="font-14">No Data</span>
                                                                </div>
                                                        }
                                                    </>
                                            }

                                        </div>


                                    </>
                            }
                            {/* <div className="selection-clear-container d-flex align-center justify-center cursor-pointer">
                                <label onClick={handleClearSelection} className="font-14 cursor-pointer">Clear Selection</label>
                            </div> */}
                        </div>
                    }
                </>
                :
                null
        }
    </div>

    )
}

SearchDropdownComponent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default SearchDropdownComponent;

const useStyles = makeStyles(() => ({

    searchFilterContainer: {
        position: 'relative',
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        '& .combo-box': {
            top: '80px',
            position: 'absolute',
            width: '100%',
            zIndex: 999,
            background: '#fff',
            boxShadow: '0px 2px 6px 0px #00000026',
            borderRadius: '5px'
        },
        '& .search-input-si': {
            border: '2px solid #dedede',
            background: '#f6f6f6'
        },
        '& .selection-clear-container': {
            height: '45px',
            width: '100%',
            borderTop: '1px solid #dedede',
            background: '#fff'
        },
        '& .dropdown-container-adv': {
            height: '48px',
            background: '#fff',
            border: '1px solid #b7b7b7',
            borderRadius: '5px',
            padding: '12px'
        },
        '& .filter-name-style': {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
        },

        '& .not-found-box': {
            height: '70%',
        },
        '& .selected-style': {
            background: '#CECECE'
        }

    },
}));
