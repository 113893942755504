import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleRounded, RadioButtonUnchecked } from '@material-ui/icons';



const ImageCheckbox = (props) => {
  const classes = useStyles();
  const newClasses = newStyles();
  const state = props.state;
  const name = props.name;
  const selectedImageCheckbox = state.selectedImageCheckboxLabel;
  const imageCheckboxOnClick = props.action;
  const variant = props.variant ? props.variant : ""

  function getRelevantClass(label) {
    switch (label) {
      case label == "DEV":
        return "dev-bg";
      case label == "QA":
        return "qa-bg";
      case label == "PROD":
        return "prod-bg";
      case label == "UAT":
        return "uat-bg";
      case label == "STAGING":
        return "staging-bg";
      case label == "DEVOPS":
        return "devops-bg";
      default:
        return "uat-bg";
    }
  }

  function getPaper(obj) {
    return (
      variant == "v-2" ?
        <span key={obj.label} className={obj.label === selectedImageCheckbox ? classes.currentImgCheckbox + " " + "image-cont" : classes.imgCheckbox + " " + "image-cont"} >
          <div style={{ height: '25px' }}>
            <Checkbox
              className={classes.Checkbox}
              name={name}
              value={obj.label}
              style={{ width: '20px', height: '20px', margin: '.5rem' }}
              checked={obj.label === selectedImageCheckbox}
              color="primary"
              onChange={imageCheckboxOnClick}
            />
          </div>
          <div className={classes.img_icon}>
            <img className={classes.image + " " + "image-control"} src={obj.src} alt='..'></img>
          </div>
          <div className={obj.label === selectedImageCheckbox ? classes.footerActive : classes.footer}>
            <Typography style={{ fontWeight: 500, fontSize: '10px' }} variant="body1">{obj.label}</Typography>
          </div>
        </span> :
        variant == "cluster" ?
        <>
        <label key={obj.label} className={obj.label === selectedImageCheckbox ? newClasses.checkedImgBox : newClasses.newImgCheckBox} >
          <div className={obj.label === selectedImageCheckbox ? newClasses.visible+ " "+ newClasses.checkboxContainer : newClasses.notVisible+ " "+ newClasses.checkboxContainer} style={{ height: '25px',marginTop: "3px",marginLeft: "3px" }}>
              <Checkbox
                className={variant === "no_image" ? classes.hideCheckbox : classes.Checkbox}
                name={name}
                value={obj.label}
                style={{ width: '20px', height: '20px', margin: '.5rem' }}
                checked={obj.label === selectedImageCheckbox}
                color="primary"
                onChange={imageCheckboxOnClick}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircleRounded />}
              />
            </div>
            <div className={newClasses.imgContiner}>
                <img className={"img"} src={obj.src}></img>
            </div>
            <div className={obj.label === selectedImageCheckbox ? classes.footerActive : 'textFooter'}>
              <Typography style={{ fontWeight: 500, fontSize: '14px',color: "#000" }} variant="body1">{obj.label}</Typography>
            </div>
        </label>
        </>
        :
        <label key={obj.label} className={obj.label === selectedImageCheckbox ? classes.currentImgCheckbox + " " + "image-cont" : classes.imgCheckbox + " " + "image-cont"} >
          <div style={{ height: '25px' }}>
            <Checkbox
              className={variant === "no_image" ? classes.hideCheckbox : classes.Checkbox}
              name={name}
              value={obj.label}
              style={{ width: '20px', height: '20px', margin: '.5rem' }}
              checked={obj.label === selectedImageCheckbox}
              color="primary"
              onChange={imageCheckboxOnClick}
            />
          </div>
          {
            variant === "no_image" ? 
            <>
              <div className='bg-color-contoroller'>
                {
                  obj.div
                }
              </div>
              <div style={{ height: '25px' }}></div>
              </>
                      :
              <div className={classes.img_icon}>
                <img className={classes.image + " " + "image-control"} src={obj.src} alt='..'></img>
              </div>
          }

          <div className={obj.label === selectedImageCheckbox ? classes.footerActive : classes.footer}>
            <Typography style={{ fontWeight: 500, fontSize: '10px' }} variant="body1">{obj.label}</Typography>
          </div>
        </label>
    );
  }

  function getPaperList(list) {
    let paperList = [];
    for (let i = 0; i < list.length; i++) {
      paperList.push(
        getPaper(list[i])
      );
    }
    return paperList;
  }

  return (
    <div className={ variant === "no_image" ?classes.root_without_checkbox :  classes.root}>
      {props.list ? getPaperList(props.list) : getPaper(props)}
    </div>
  );
}

ImageCheckbox.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default ImageCheckbox;


const useStyles = makeStyles(theme => ({
  Checkbox: {
    float: "right"
  },
  hideCheckbox: {
    display: 'none'
  },
  root_without_checkbox: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(18),
      height: '143px',
      overflow: 'hidden'
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(18),
      height: '168.5px',
      overflow: 'hidden'
    },
  },
  img_icon: {
    minHeight: '96px',
    width: '100%', display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgCheckbox: {
    border: '1px solid #dedede',
    backgroundColor: '#fff',
    borderRadius: "8px",
  },
  currentImgCheckbox: {
    border: '2px solid #0095da',
    backgroundColor: '#fff',
    borderRadius: "8px",
  },
  image: {
    marginLeft: 'auto',
    marginTop: theme.spacing(0),
    width: 'auto',
    height: '50px',
    border: "none",
    display: 'block',
    marginRight: 'auto'
  },
  footer: {
    textAlign: 'center',
    height: '41px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f0f3f5',
    padding: '8px',
    borderTop: '2px solid #dedede',
    borderRadius: '0px 0px 8px 8px',
  },
  footerActive: {
    display: 'flex',
    transition: 'all 500ms',
    margin: 'auto',
    background: '#f0f3f5',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '0px 0px 8px 8px',
    justifyContent: 'center',
    width: '99%',
    lineHeight: 1,
    height: '3.9rem',
  },
}));

const newStyles = makeStyles(theme => ({
  newImgCheckBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "6px",
    border: "1px solid #E8E8E8",
    justifyContent: "space-between",
    margin: "0 !important",
    width: "130px !important",
    height: "133px !important",
    overflow: 'hidden',
    position: "relative",
    marginRight: "12px !important",
    '&:hover': {
      borderColor: "#CACACA",
    },
    '& .textFooter': {
      display: "flex",
      padding: "8px 10px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      alignSelf: "stretch",
      background: "#FAFAFA",
    },
  },
  checkedImgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "6px",
    border: "2px solid #0086FF",
    justifyContent: "space-between",
    margin: "0 !important",
    width: "130px !important",
    height: "133px !important",
    overflow: 'hidden',
    position: "relative",
    marginRight: "12px !important",
    '&:hover': {
      borderColor: "#0086FF",
    },
    '& .textFooter': {
      display: "flex",
      padding: "8px 10px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      alignSelf: "stretch",
      background: "#EBF5FF",
    },
  },
  checkboxContainer: {
    position: 'absolute',
    top: "0",
    left: "0",
  },
  imgContiner: {
    display: "flex",
    width: "129.268px",
    height: "100px",
    padding: "30.894px 32.519px 30.894px 33.335px",
    justifyContent: "center",
    alignItems: "center",
    "& .img": {
      width: "auto",
      height: "38px",
    }
  },
  notVisible: {
    opacity: 0,
  },
  visible:{
    opacity: 1,
  },
}))