import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
// import properties from '../../../properties/properties';
import properties from '../../../../properties/properties';
// import { GetAuth } from '../../../util/security';
// import GenerateURL from '../../../util/APIUrlProvider';
import {Tooltip} from '@material-ui/core';
import GenerateURL from '../../../../util/APIUrlProvider';
import { GetAuth } from '../../../../util/security';
const HelmIntegrationHeader = (props) => {
    const classes = useStyles()
    
    const OpenDialogButton = props.OpenDialogButton ? props.OpenDialogButton : ()=>{}
    return (
        <>
            <div className={classes.integraded_header}>

                <Grid container>
                    <Grid item xs={10}>
                        <div>
                            <p className='main-heading'>Helm Integration</p>
                            <p className='sub-heading'>Displaying overall summary of helm integration with BuildPiper</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        {OpenDialogButton}
                    </Grid>


                </Grid>
            </div>
        </>
    )
}

const useStyles = makeStyles({
    integraded_header: {
        fontWeight: '300',
        fontFamily: 'Inter',
        lineHeight: '20px',
        marginBottom: '20px',

        '& .main-heading': {
            fontSize: '24px',
            fontWeight: '300',
        },
        '& .sub-heading-txt': {
            fontSize: '11px',
            color: '#999999',
        },
        '& .add-repos-btn': {
            background: '#3e73ec',
            filter: 'drop-shadow(2px 3.464px 5px rgba(0,0,0,0.13))',
            borderRadius: '4px',
            padding: '10px 7px',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
            textAlign: 'center',

        }
    }
});

export default HelmIntegrationHeader;