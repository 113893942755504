import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import JobConfigureInfo from './JobConfigureInfo';
import BuildJobStepWizard from './BuildJobStepWizard'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const JobConfigureDisplay = (props) => {
    
    let job_name = props.selectedJobData.job_name

    console.log(props,"bfhjvbfhvhjbv")

    return (
        
            <Dialog
                fullScreen
                maxWidth='lg'
                open={props.isOpen}
                onClose={() => { }}
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Transition}>
                <div className='d-grid build-input-div' style={{ gridTemplateColumns: '400px 1fr', height:'100%'}}>
                    <JobConfigureInfo jobName={job_name} />
                    <div style={{ padding: '60px 32px 60px 32px' }}>
                        <div style={{ display: 'flex' }} className="align-center space-between">
                            <div className='align-center' style={{ display: 'flex' }}>
                                <label className='font-18 font-weight-500'>{'Fill Up the Code details for '} </label> 
                                <label style={{color:'#3696db', marginLeft:'8px', marginRight:'8px'}} className='font-18 font-weight-700'>{` ${job_name} `}</label> 
                                <label className='font-18 font-weight-500'> {' Job Steps'}</label>
                            </div>
                            <IconButton className='mr-1' onClick={() => props.setComponentView('jobListing')}>
                                <CloseIcon style={{ fontSize: '20px', color: "#607086" }} />
                            </IconButton>
                        </div>
                        <BuildJobStepWizard  {...props} />
                    </div>
                </div>
            </Dialog>
        
    )
}

JobConfigureDisplay.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default JobConfigureDisplay