import React, { useState } from 'react';
import { SwitchToSuperAdminRole, SwitchToNormalUserRole, IsUserActualSuperUser, IsSuperUser, GetPermitedURLsAfterSwitch } from '../../util/security';
import { Redirect } from 'react-router-dom';

const SwitchUser = props => {
    const [state, setState] = useState({ redirect: false })
    function onClickSwitchToUserPortal() {
        console.log("dcssa")
        SwitchToNormalUserRole()
        GetPermitedURLsAfterSwitch()
        setState({ redirect: true })
    }

    function onClickSwitchToAdminPortal() {
        SwitchToSuperAdminRole()
        GetPermitedURLsAfterSwitch()
        setState({ redirect: true })
    }
    console.log(IsUserActualSuperUser(), IsSuperUser(), "ioio_____11")
    return (
        <div className='switch-bottom-profile-section font-family-v1 font-12 font-weight-500'>

            {state.redirect ? <Redirect to="/" />
                :
                <>
                    <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={onClickSwitchToUserPortal} className={IsUserActualSuperUser() && IsSuperUser() ? "inactive-portal cursor-pointer" : "active-portal cursor-pointer"}> {/*<SwapHorizIcon fontSize="large" style={{ verticalAlign: 'middle' }} /> */}USER PORTAL</span>
                    <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={onClickSwitchToAdminPortal} className={IsUserActualSuperUser() && !IsSuperUser() ? "inactive-portal cursor-pointer" : "active-portal cursor-pointer"}> {/*<SwapHorizIcon fontSize="large" style={{ verticalAlign: 'middle' }} />*/} ADMIN PORTAL</span>

                </>
            }

        </div>
    )
}

export default SwitchUser;