import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import properties from '../../properties/properties';
import GenerateURL from '../../util/APIUrlProvider';
import InvokeApi from '../../util/apiInvoker';
import NewReleaseFlag from '../../components/genericComponents/NewReleaseFlag';
import NewReleaseStrip from '../../components/genericComponents/NewReleaseStrip';
import { SimpleMenu } from './SimpleMenu';
import 'remixicon/fonts/remixicon.css'
import { Breadcrumbs } from '@material-ui/core';
const BreadcrumbsNew = props => {
    const classes = useStyles();
    const list = props.list.toReversed(); //.reverse()
    const userSettings = props.userSettings;
    const companyName = properties.company_name;
    const companyLogo = properties.company_logo;
    const company_logo_width = properties.logo_width;
    const company_logo_height = properties.logo_height;
    const routes_url = props.routes_url;
    const super_admin_flag = props.super_admin_flag;
    const additionalDrawer = props.additionalDrawer;
    var url_name = routes_url.path ? routes_url.path : "Dashboard";
    console.log(url_name, list, super_admin_flag, "Fdsajfjsadjs")


    const [state, setState] = useState();
    useEffect(() => {
        fetchIstioData();
    }, [])

    function fetchIstioData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.account_setting_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setState(new_state => ({
            ...new_state,
            show_loading_icon: true
        }));
        InvokeApi(requestInfo, handleIstioDataSuccessApiHit, handleIstioDataFailedApiHit);

    }
    function handleIstioDataSuccessApiHit(data) {
        console.log(data, "welocom")
        localStorage.setItem('super_admin', JSON.stringify(data));
        setState(new_state => ({
            ...new_state,
            istio_data: data.results,

            show_loading_icon: false
        }));

    }

    function handleIstioDataFailedApiHit(error) {
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }


    function get_current_url_label(url) {
        switch (url) {
            case "/health":
                return (
                    { label: "Health", url: url }
                );
            case "/activity":
                return (
                    { label: "Activity", url: url }
                );
            case "/credentials":
                return (
                    { label: "Credentails", url: url }
                );
            case "/integration/containerRegistries":
                return (
                    { label: "Container Registries", url: url }
                );
            case "/secrets/list":
                return (
                    { label: "Secrets List", url: url }
                );
            case "/tickets/list":
                return (
                    { label: "Tickets List", url: url }
                );
            case "/role-groups":
                return (
                    { label: "Roles Group", url: url }
                );
            case "/system-settings":
                return (
                    { label: "BP System Global Settings ", url: url }
                );
            default:
                return (
                    <div class="md-step-bar-left "></div>
                );
        }
    }
    return (
        <div className={classes.root}>
            <div className="d-flex align-center font-family-v1 font-weight-600 font-12 breadcrumb-navi" style={{ marginLeft: "15px" }}>
                <span className="ri-arrow-right-s-line font-24"></span>
                {
                    list.length > 0 ?
                        list.map((element, key) => (
                            <>
                                {console.log(element, "dsfjkajk")}
                                {
                                    element.disable_click ?
                                        <span className={classes.breadcrumbLink}>{element.label}</span> :
                                        <>
                                            {element.link ?

                                                <Link to={element.link} className={!(key + 1 == list.length) ? classes.breadcrumbLink + " " : classes.breadcrumbLink + " color-inactive-breadcrumb"}>
                                                    {element.label}
                                                </Link>
                                                :
                                                <span className={classes.breadcrumbLink}>{element.label}</span>
                                            }

                                        </>

                                }
                                {
                                    !(key + 1 == list.length) ?
                                        <>
                                            {console.log(key, "changes11111")}
                                            <span className={!(key + 1 == list.length) ? "ri-arrow-right-s-line font-24 ml-8" : "ri-arrow-right-s-line font-24 ml-8 color-inactive-breadcrumb"}></span>
                                        </> : null
                                }
                            </>
                        ))
                        : <>
                            <Link to={super_admin_flag ? "/ClusterDashboard" : "/ApplicationDashboard"} className={classes.breadcrumbLink}>{"Dashboard"}</Link>
                            {
                                get_current_url_label(url_name).label &&
                                <>
                                    <span className="ri-arrow-right-s-line font-24"></span>
                                    <Link to={get_current_url_label(url_name).url} className={classes.breadcrumbLink}>{get_current_url_label(url_name).label}</Link>
                                </>
                            }

                        </>
                }
            </div>
            {/* <SimpleMenu /> */}

            {/* {
                userSettings == true ?
                    <a className="d-flex align-center " style={{ color: '#124d9b', fontSize: '14px', justifySelf: 'flex-end' }} href="/ClusterDashboard"><span className="mr-5">Back to application</span> <ArrowForwardIcon style={{ color: '#124d9b', fontSize: '18px' }} /></a>
                    : null
            }
            {/* <NewReleaseFlag /> */}
            {/*<NewReleaseStrip /> */}

            {companyLogo ?
                <div className={additionalDrawer ? classes.additionalMarginRight + " " + classes.logoCover : classes.marginRight + " " + classes.logoCover}>
                    <div className={classes.companyLogo}>
                        <img src={companyLogo} style={company_logo_width && company_logo_height ? { height: company_logo_height, width: company_logo_width } : company_logo_width ? { height: "18px", width: company_logo_width } : {height: '18px' , width: '103px'}} />
                    </div>
                </div>
                : null}

        </div>
    )
}

export default BreadcrumbsNew;



const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.13)',
        width: '100%',
        display: 'flex',
        //position: 'fixed',
        zIndex: 1200,
        height: '70px',
        alignItems: 'center',
        padding: '0px 24px 0px 0px',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        //top: '0px',
        //left: '0px',
        zIndex: 999,
        paddingRight: "30px",
        '& .company-logo-breadcrumb': {
            width: '255px',
            color: '#FE9C11',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
                width: '150px'
            },
            '& .companyName': {
                fontSize: '12px',
                color: '#707780'
            },
            '& .flaticon-city-buildings-silhouette': {
                color: '#FE9C11',
                '&:before': {
                    fontSize: '24px!important',
                    color: '#FE9C11',
                }
            }
        },
        '& [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after': {
            color: '#000',
            backgroundColor: 'transparent'
        },
        '& .icon-settings': {

            '& #slide': {
                position: "absolute",
                left: "-100px",
                width: "100px",
                height: "100px",
                background: "blue",
                transition: '1s'
            }
        },

        "& .user-company-details": {
            width: "353px",
            height: "49px",
            padding: "0px 0px 0px 3px",
            borderRadius: "8px 60px 60px 8px",
            backgroundColor: "#F1F3F7",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",

            "& .company-logo": {

            },

            "& .user-details": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                paddingRight: "57px",


                "& .user-name": {
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#2F2F2F"
                },

                "& .user-type": {
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#2F2F2F",

                }
            }
        },

        "& .user-avatar": {

            position: "absolute",
            right: "0px",
            top: "0px",

            "& .MuiAvatar-root": {
                height: "47px",
                width: "47px",
            }

        },

        "& .notification": {
            width: "40px",
            height: "40px",
            backgroundColor: "#F1F3F7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "20px",
            borderRadius: "4px",
        }


    },
    logo: {
        height: '30px',
        paddingRight: '15px'
    },
    lineHeignt: {
        lineHeight: '0px'
    },
    breadcrumbLink: {
        fontSize: '13px',
        lineHeight: 1,
        color: '#2f2f2f',
        // '&:hover':{
        //     color:'#0086ff'
        // },
        '&:visited': {
            color: '#2f2f2f'
        }
    },
    serviceName: {
        display: 'flex',
        alignItems: 'center'
    },
    companyLogo: {
        display: "flex",
        width: "135px",
        height: "38px",
        justifyContent: "center",
        alignContent: "center",
        borderRadius: "6px",
        border: "1px solid #EDEDED",
        background: "#FFF",
        boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.04)",
        flexWrap: "wrap",
    },
    logoCover: {
        display: "flex",
        height: "49px",
        padding: "0px 6px",
        alignItems: "center",
        gap: "24px",
        borderRadius: "8px",
        background: "#F1F3F7",
        boxShadow: "3px 4px 2px 0px rgba(0, 0, 0, 0.04) inset",
    },
    marginRight: {
        marginRight: "82px",
    },
    additionalMarginRight: {
        marginRight: "252px",
        '@media screen and (max-width: 1440px)': {
            marginRight: "252px"
        },
        '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
            marginRight: "288px"
        },
    }
}))
