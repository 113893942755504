import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import Delete from '../../../components/genericComponents/Delete';
import { Tooltip } from '@material-ui/core';

import { ExandableComponentList } from '../../../components/hoc/expandableList';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';

import { GetAuth } from '../../../util/security';

const JobListingCard = props => {
    const classes = useStyles();
    const data = props.data;
    const { application_id } = useParams();
    var job_template_list = [];
    var expandable_component = null;
    const iteration_count = 2;
    if (data.job_template.jobs && data.job_template.jobs.length > 0) {
        data.job_template.jobs.forEach((job, key) => {
            job_template_list.push(
                <Tooltip title={job.job_name ? job.job_name : "N/A"}>
                    <div className={"chip-sq chip-sq-primary-outline"} key={job.job_name ? job.job_name : "N/A"}>
                        <Link >{job.job_name ? job.job_name : "N/A"}</Link>
                    </div>
                </Tooltip>

            )
        })
        expandable_component = (
            <>
                +{data.job_template && data.job_template.jobs ? data.job_template.jobs.length - iteration_count : null}
            </>
        );
    }

    var url = GenerateURL({ application_id: application_id }, properties.api.add_job_template, true);
    const is_permitted = GetAuth().permission.POST.includes(url);


    const is_delete_permitted = GetAuth().permission.DELETE.includes(url);

    console.log(is_permitted, "cfcgfvhnbmhnbmn")


    return (
        <div className={classes.root + " " + "card"}>
            {data.default ? <div className='default-template-chip'>Default Template</div> : null}

            <div className="card-body pd-10">
                <div className="d-grid grid-temp">
                    <div className="mt-5">
                        <SquareAvatar shape="lg-box" varient="double" name={data.name} />
                    </div>
                    <p className="font-18 pd-5 pt-0">
                        <Tooltip title={data.name ? data.name : 'N/A'}>
                            <div className={data.default ? 'template-name-ellipsis-default' : 'template-name-ellipsis'}>{data.name}</div>
                        </Tooltip>
                        <Tooltip title={data.description ? data.description : 'N/A'}>
                            <div className="job-des lh-12 font-11 text-grey-83 mb-15 text-ellipsis-twoline">
                                {data.description}
                            </div>
                        </Tooltip>
                        <ExandableComponentList compoenent_list={job_template_list} iteration_count={iteration_count} expandable_component={expandable_component} />
                    </p>
                </div>
            </div>
            <div className="card-footer bg-white space-between pd-10">
                <div className="">
                    {data.user ?
                        <span className="chip-default " style={{ backgroundColor: "#788695" }}>{data.user.name}</span> : <span className="chip-default chip-chip-blue" >bp-official</span>
                    }
                    {/* <div className="d-flex align-center">
                     <span className="chip-default" style={{backgroundColor:"#788695"}}>{data.job_template && data.job_template.jobs ?data.job_template.jobs.length : 'NA'}</span><span className="font-11 text-grey-83">JOBS</span>
                </div> */}
                    {/* {
                        data.status &&
                        <div className={classes.rootCard}>
                            <p>Status :
                                <span className={data.status == "DRAFT" || data.status == "DRAFT_PENDING" ? "font-10 chip chip-yellow ml-5" : data.status == "APPROVAL_REQUESTED_PENDING" || data.status == "APPROVAL_REQUESTED" ? "font-10 chip chip-dark-grey ml-5" : "font-10 chip chip-green ml-5"}>
                                    {data.status == "DRAFT" || data.status == "DRAFT_PENDING" ? "IN DRAFT" : data.status == "APPROVAL_REQUESTED_PENDING" || data.status == "APPROVAL_REQUESTED" ? "APPROVAL_REQUESTED" : "IN USE"}
                                </span>
                            </p>
                            {data.status == "DRAFT" || data.status == "DRAFT_PENDING" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={data} sendForApproval={props.sendForApproval} /> </div> : data.status == "APPROVAL_REQUESTED_PENDING" || data.status == "APPROVAL_REQUESTED" ? <div style={{ marginLeft: "10px" }}><SendForApprovalConfirmationDialog audit_card_data={data} sendForApproval={props.sendForApproval} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} /> </div> : ""}
                        </div>
                    } */}
                </div>
                <div className="d-flex align-center" style={{ gap: '8px' }}>
                    <Delete
                        varient="new_button"
                        display_data_name={data.name}
                        data={{ entity_id: data.id, name: "project_job_templates" }}
                        refresh={props.refreshFn}
                        api_link={GenerateURL({ project_id: application_id, temp_id: data.id }, properties.api.remove_template)}
                        is_edit_permited={is_delete_permitted}
                    />
                    {
                        is_permitted ?
                            <Link to={`/application/${application_id}/job-template/${data.id}/edit`} className="d-flex text-anchor-blue">
                                {/* <BorderColorIcon className="ml-10 vertical-bottom" /> */}
                                <button className='btn-sq-icon-primary mr-5'>
                                    <span className='ri-edit-line vertical-align-super'></span>
                                </button>
                            </Link>
                            :
                            <Tooltip title="You are not allowed to perform this action" >
                                {/* <BorderColorIcon className="ml-10 vertical-bottom" style={{ color: '#818078' }} /> */}
                                <button className='btn-sq-icon-primary mr-5'>
                                    <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                </button>
                            </Tooltip>
                    }

                </div>
            </div>
        </div>
    )
}


JobListingCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({
    root: {
        position: 'relative',
        overflow: 'visible',
        '& .default-template-chip': {
            backgroundColor: '#ff9000',
            fontSize: '13px',
            color: '#fff',
            padding: '10px',
            position: 'absolute',
            right: '0px',
            top: '-10px',
            borderRadius: '0px 8px 0px 8px',
            zIndex: '999',
            // "&:before":{
            //     content: '""',
            //     borderTop: '8px solid #93672e',
            //     borderBottom: '8px solid #93672e',
            //     borderLeft: '8px solid #93672e',
            //     borderRight: '8px solid #93672e',
            //     backgroundColor:'#93672e',
            //     position: 'absolute',
            //     top: '-24px',
            //     zIndex:'99',
            // }
        },
        '& .card-body': {
            height: '120px'
        },
        '& .grid-temp': {
            gridTemplateColumns: '60px 1fr'
        },
        '& .chip-sq': {
            fontSize: '11px',
            padding: '3px',
            border: '2px solid #0086ff',
            maxWidth: 90,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            marginRight: 2,
            marginLeft: 2,
            '&:first-child': {
                marginLeft: 0
            },
            '&:last-child': {
                marginRight: 0
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& .chip-sq-primary-outline': {
            borderColor: '#0086ff',
            color: '#0086ff',
            backgroundColor: 'transparent'
        },
        '& .chip-default': {
            backgroundColor: '#48a3e3',
            color: '#fff!important',
            borderRadius: '4px!important'
        },
        '& .chip-default-job': {
            fontSize: 11,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-block',
            lineHeight: 2,
            borderRadius: 25,
            padding: '2px 8px 0px',
            margin: 2,
            textAlign: 'center',
            maxWidth: 160,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.13)'
        },
        '& .template-name-ellipsis': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '240px',
            overflow: 'hidden',
        },
        '& .template-name-ellipsis-default': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '180px',
            overflow: 'hidden',
        }
    },

    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
});

export default JobListingCard;