import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/style.css'
import ImageCheckBox from './ImageCheckBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from "@material-ui/core/Slider";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import { RemoveFromArray } from '../../util/util';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckboxList from './inputs/Checkbox';
import TagInput from "./inputs/TagInput";
import { Checkbox, Typography } from "@material-ui/core";
import AutoCompleteDropdown from "./inputs/AutoCompleteDropDown";
import ChipCheckboxList from "./inputs/ChipCheckboxList";
import Tooltip from '@material-ui/core/Tooltip';
// import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MultiSelectInput from "./MultiSelectInput";
import VersionLogo from "./VersionLogo";
import MultipleSelectDropdown from "./MultiSelectDropdown";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Zoom from '@material-ui/core/Zoom';
import SelectWithDropdown from "./SelectWithDropdown";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '3rem',
    '&.select-value': {
      backgroundColor: '#ffffff',
      padding: '16px',
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mainDivSelected: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 0px 0px 30px',
    margin: '20px 0px 20px 20px'
  },
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '160px',
    backgroundColor: '#fff',
    padding: '.5rem 1.25rem',
    borderRadius: '30px 30px 30px 30px',
    margin: '20px'
  },
  count: {
    width: '30px',
    height: '30px',
    border: '1px solid #ccc',
    borderRadius: '50%',
    textAlign: 'center',
    paddingTop: '2px'
  },
  text: {
    fontSize: '11px',
    fontWeight: 400,
  },
  mainBodyDiv: {
    backgroundColor: '#fff',
    padding: '15px 20px',
    borderRadius: '8px 8px 0px 0px',
    borderTop: '1px solid#dedede',
    borderLeft: '1px solid#dedede',
    borderRight: '1px solid#dedede',
  },
  countBody: {
    backgroundColor: '#1d5b81',
    color: '#fff',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 800
  },
  tabBodyDivHeading: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    borderBottom: '1px solid #ccc'
  },
  textBody: {
    fontSize: '16px',
    fontWeight: 300,
    color: '#000'
  },
  formDiv: {
    padding: '8px 0px',
    margin: '10px 0px'
  },
  InputLabel: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#2f2f2f',
    fontFamily: "'Montserrat', sans-serif"
  },
  AlignLabel: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hindText: {
    fontSize: '10px',
    color: '#888',
    fontWeight: 400,
    marginLeft: '.5rem'
  },
  stepper: {
    margin: '1rem 0rem'
  },
  subHeading: {
    fontSize: '12px',
    color: '#666',
    fontWeight: 400,
    marginBottom: '1rem'
  },
  impMakr: {
    color: 'red'
  },
  multipleSelect: {
    '& .show-selected-value': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '14px',
      margin: '0',
      width: '100%',
      lineHeight: '2',
      borderRadius: '4px',
      border: '1px solid #b7b7b7',
      backgroundColor: '#ffffff',
      color: '#5e5e5e;',
      fontSize: '12px',
      fontWeight: '400',
      minHeight: '40px',
      height: 'auto',
      maxHeight: '50px',
      cursor: 'pointer',
      position: 'relative',
      '& svg': {
        fontSize: '21px',
        color: '#7a7a7a',
        position: 'absolute',
        right: '8px',
        top: '8px'
      },
      '& .selected-values': {
        display: 'flex',
        height: '100%',
        overflowY: 'auto',
        flexWrap: 'wrap',
        width: '94%',
        '& .selected-values-design': {
          margin: '0 6px !important',
          // flex: '0 0 auto',
          marginRight: '4px !important',
          backgroundColor: '#124D9B',
          borderRadius: '25px',
          color: '#ffffff',
          width: '20',
          padding: '0px 6px',
          display: "flex",
          alignItems: 'center',
          '& span': {
            marginLeft: '12px',
            cursor: 'pointer',
            whiteSpace: 'nowrap'
          }
        }
      }
    },
    '& .select-value': {
      backgroundColor: '#ffffff',
      padding: '3px 0px 3px 0',
      border: '1px solid #999999',
      position: 'absolute',
      top: '65px',
      width: '100%',
      flexWrap: 'wrap',
      zIndex: '1',
      '& .item': {
        paddingLeft: '14px',
        color: '#666666',
        fontSize: '12px',
        cursor: 'pointer',
        transition: '0.1s',
      },
      '& .item:hover': {
        backgroundColor: 'orange',
        color: '#ffffff',
        fontSize: '12px',
      },
    }
  }
}));



export const Input = (props) => {
  const classes = useStyles();
  const type = props.type;
  const style = props.style ? props.style : {}
  const label = props.label;
  const subHeading = props.subHeading;
  const paramsHeading = props.paramsHeading;
  const name = props.name;
  const error = props.error;
  const onChangeHandler = props.onChangeHandler;
  const onTextClick = props.onTextClick ? props.onTextClick : () => { }
  const data = props.data
  const value = data[name];
  const placeholder = props.placeholder;
  const list = props.list;
  const radioGroup = props.radioGroup;
  const min = props.min;
  const max = props.max;
  const onFileRemove = props.onFileRemove;
  const varient = props.varient;
  const mandatorySign = props.mandatorySign;
  const onFileSelect = props.onFileSelect ? props.onFileSelect : () => { };
  //const disabled = props.disabled;
  const valueLabel = props.valueLabel;
  const default_css = props.default_css;
  const isHooksCommandsVisible = props.isHooksCommandsVisible
  const [state, setState] = useState({ showPassword: false, ishookCommandsVisible: false });
  const isFirstTimeDropdown = props.isFirstTimeDropdown
  const [isDuelInputDropdown, setIsDuelInputDropdown] = useState(false);
  const rows = props.rows;
  //const validations = props.validations;
  const enabledComponent = props.enabledComponent;
  const disabledComponent = props.disabledComponent;
  const onKeyDown = props.onKeyDown ? props.onKeyDown : null;
  const borderBottom = props.borderBottom ? props.borderBottom : null;
  const maxLength = props.maxLength;
  const select_default_value = props.select_default_value;
  const remove_default_value = props.remove_default_value
  // const selectedValues = props.selectedValues;
  const inputCase = props.inputCase;
  const jiraOperation = props.jiraOperation;
  const info = props.info;
  const jiraOperationInfo = props.jiraOperationInfo;
  const listError = props.listError ? props.listError : {}
  const onBlurFun = props?.onBlurFun ? props?.onBlurFun : () => { }
  const jira_status = props.jira_status?props.jira_status:false;


  if (type == "select-with-checkbox") {
    console.log("ip_0_0_0", props);
  }
  const [show, setShow] = useState({
    showDropDown: false,
  });

  const [selectedValues, setSelectedValues] = useState(props.selectedValues);

  const [multipleSelectList, setMultipleSelectList] = useState(props.multipleSelectList);

  const selectValue = (item) => {
    console.log(item);
    setSelectedValues([...selectedValues, item]);
    const filterSelecttion = multipleSelectList.filter((items) => {
      return items != item;
    });
    setMultipleSelectList(filterSelecttion);
    setShow(prevState => ({
      ...prevState,
      showDropDown: false
    }))
    // setShow({ ...show, showDropDown: false });
    onChangeHandler([...selectedValues, item]);
  }

  const removeSelectedValue = (item) => {
    const filterSelecttion = selectedValues.filter((items) => {
      return items !== item;
    });
    setSelectedValues(filterSelecttion);

    setMultipleSelectList(prevState => ([...prevState, item]))

    onChangeHandler([...filterSelecttion]);
    // setMultipleSelectList([...multipleSelectList, item]);
    setShow({ ...show, showDropDown: false, });
  }

  const toggleDropDown = () => {
    setShow({ ...show, showDropDown: !show.showDropDown })
  }

  useEffect(() => {
    if (isFirstTimeDropdown) {
      setIsDuelInputDropdown(true)
    }
  }, [isFirstTimeDropdown])

  useEffect(() => {
    setState(prevState => ({ ...prevState, ishookCommandsVisible: isHooksCommandsVisible }))
  }, [isHooksCommandsVisible])

  function togglePassword() {
    setState({
      ...state,
      showPassword: !state.showPassword
    })
  }

  const toggleDualInput = () => {
    setIsDuelInputDropdown(!isDuelInputDropdown)
  }

  function getFieldBasedOnType() {
    switch (type) {
      case ("text"):
        return (
          <input
            onBlur={(e) => {
              console.log(e,'blured_001');
              onBlurFun(e)
            }
            }
            style={props.style}
            className={!error[name] || error[name].length == 0 ? "" : "error"}
            name={name}
            placeholder={placeholder}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChangeHandler}
            disabled={onChangeHandler ? false : true}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
            maxLength={maxLength}
          />
        );
      case ("text-new"):
        return (
          <input
            style={{ height: props.height ? props.height : "45px" }}
            className={!error[name] || error[name].length == 0 ? "" : "error"}
            name={name}
            style={props.style ? props.style : {}}
            placeholder={placeholder}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChangeHandler}
            disabled={onChangeHandler ? false : true}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
            maxLength={maxLength}
          />
        );
      case ("textarea"):
        return (
          <textarea
            className={!error[name] || error[name].length == 0 ? "textarea" : "textarea error"}
            name={name}
            placeholder={placeholder}
            value={value}
            rows={rows ? rows : 1}
            onChange={onChangeHandler}
            disabled={onChangeHandler ? false : true}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
          />
        );

      case ("imgCheckbox"):
        if (varient == "multicheck") {
          let return_list = data[name];
          function handleChange(e) {
            if (e.target.checked) {
              return_list.push(e.target.value);
              onChangeHandler(name, return_list);
            } else {
              return_list = RemoveFromArray(return_list, e.target.value);
              onChangeHandler(name, return_list)
            }
          }
          return (
            <div style={{ display: 'flex' }}>
              {
                list.map((element) => (

                  <ImageCheckBox
                    name={element.name}
                    label={element.label}
                    src={element.src}
                    variant={props.variant}
                    state={{ selectedImageCheckboxLabel: data[name].includes(element.label) ? element.label : "" }}
                    action={handleChange}
                  />

                ))
              }
            </div>
          );
        } else {
          return (
            <div className={classes.card}>
              <ImageCheckBox
                name={name}
                list={list}
                variant={props.variant}
                state={{ selectedImageCheckboxLabel: data[name] }}
                action={onChangeHandler} />
            </div>
          );
        }
      case ("radio"):
        return (<>

          {
            props.variant !== 'version-options' ? <RadioGroup row className="form-class">
              {
                list.map((radio) => (

                  <FormControlLabel lg={12 / list.length} control={<Radio className="radio-controller" name={name} radioGroup={radioGroup} checked={data[name] == radio.value} value={radio.value} onChange={onChangeHandler} disabled={onChangeHandler ? false : true} />} label={radio.label} />

                ))
              }
            </RadioGroup> :
              <RadioGroup row className="form-class">
                {
                  list.map((radio) => (

                    <FormControlLabel lg={12 / list.length} control={<Radio className="radio-controller" name={name} radioGroup={radioGroup} checked={data[name] == radio.value} value={radio.value} onChange={onChangeHandler} disabled={onChangeHandler ? false : true} />}
                      label={<div className="d-flex align-center">
                        <label className="mr-8">{radio.label}</label>
                        <VersionLogo version={radio.value}>{radio.value}</VersionLogo>
                      </div>
                      }
                    />

                  ))
                }
              </RadioGroup>
          }
        </>


        );
      case ("select-multiple"):
        return (
          <select name={name} value={data[name]} className='select' onChange={onChangeHandler} multiple>
            <option value="">Please Select</option>
            {list.map((option) => (
              <option value={option.id} selected={option.selected == "selected"}>{option.label}</option>
            ))}
          </select>
        );
      case ("select-multiple-with-display"):
        return (
          <div className={classes.multipleSelect} /* style={{ overflowX: 'auto' }} */>

            <div className='show-selected-value' onKeyDown={()=>{}} onClick={toggleDropDown} tabIndex={0} role="button">
              {
                selectedValues?.length <= 0 && <span>Please Select Events</span>
              }

              <div className="selected-values">
                {
                  selectedValues?.length > 0 && selectedValues?.map((item) => {
                    console.log('line 396', item);
                    return (
                      <div className='selected-values-design' key={item.id}>
                        <span>{item}</span>&nbsp;&nbsp;<span onKeyDown={()=>{}} onClick={() => {
                          removeSelectedValue(item);
                        }} tabIndex={0} role="button">X&nbsp;</span>
                      </div>
                    )
                  })
                }
              </div>
              <KeyboardArrowDownIcon />
            </div>
            {/* <input type="text" className="multiple-select" /> */}
            {
              show.showDropDown && <div className='select-value'>
                {multipleSelectList && multipleSelectList.map((option) => (
                  <div className="item" key={option.id} onKeyDown={()=>{}} onClick={() => { selectValue(option) }} selected={option.selected == "selected"} tabIndex={0} role="button">{option}</div>
                ))}
              </div>
            }

          </div>
        );
      case ("select-with-checkbox"):
        return (
          <MultiSelectInput
            list={list}
            id={props.id}
            value={data[name] ? data[name] : []}
            selected_checkboxes={data[name]}
            placeholder={placeholder}
            label={label}
            getOptionLabel={props.getOptionLabel}
            name={name}
            className={!error[name] || error[name].length == 0 ? "textarea" : "textarea error"}
            onChangeHandler={onChangeHandler}
          />
        );
      case ("select"):
        return (
          <select  style={style} name={name} value={data[name]} className={!error[name] || error[name].length == 0 ? "select" : "select error"} onChange={onChangeHandler} disabled={onChangeHandler ? false : true}>
            {remove_default_value ? null : <option className="selectoptions" style={{ fontFamily: "Montserrat, sans-serif",}} value="">{select_default_value ? select_default_value : "Please Select"}</option>}
            {list && list.map((option) => (
              <option className="selectoptions" style={{ fontFamily: "Montserrat, sans-serif",}} value={option.id} selected={option.selected == "selected"}>{option.label}</option>
            ))}
          </select>
        );
      case ("select-with-thumbnail"):
        return (
          <SelectWithDropdown
          name={name}
          remove_default_value={remove_default_value}
          select_default_value={select_default_value}
          mandatorySign
          data={data}
          list={list}
          error={error}
          onChangeHandler={onChangeHandler}
        />);
      case ("select-for-multideployment"):
        return (
          <Tooltip title={data[name]} arrow>
            <select style={{
              //maxWidth: '150px', // Adjust the max-width as needed
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              backgroundPosition: `right -8px center`,
            }} name={name} value={data[name]} className={!error[name] || error[name].length == 0 ? "select" : "select error"} onChange={onChangeHandler} disabled={onChangeHandler ? false : true}>
              {remove_default_value ? null : <option value="">{select_default_value ? select_default_value : "Please Select"}</option>}
              {list && list.map((option) => (
                <option value={option.id} selected={option.selected == "selected"}>
                  {option.label}
                  {/* {option.label.length > 20 ? `${option.label.substring(0, 20)}...` : option.label} */}
                </option>
              ))}
            </select>
          </Tooltip>
        );
      case ("file-upload-name"):
        return (
          <div className="file-upload">
            <div className="image-upload-wrap" style={{ height: '43px' }}>
              <input
                className="file-upload-input"
                type="file"
                name={name}
                onChange={onChangeHandler}
              />
              <div className="drag-text">

              </div>
              {data[name] ?
                <>
                  {data[name].name ?
                    <div onKeyDown={()=>{}} onClick={props.onFileSelect ? props.onFileSelect : () => { }} className="image-upload-chip-parent" tabIndex={0} role="button">
                      <div className="image-upload-chip">
                        <p>{data[name].name}</p>
                      </div>
                    </div>
                    :
                  
                      <div className="image-upload-chip-parent">
                        {(data[name]).map((element, i) => (
                          <div className="image-upload-chip">
                            <span role='button' tabIndex={0} onKeyDown={()=>{}} onClick={() => { onFileSelect(i) }}>{element.name} </span><button className="transparent-btn" onClick={() => onFileRemove(i)}><CloseIcon /></button>
                          </div>
                        ))}
                      </div>
                    
                  }
                </> : null
              }
            </div>
          </div>
        );
      case ("file"):
        return (
          <div className="file-upload">
            <div className="image-upload-wrap">
              <input
                className="file-upload-input"
                type="file"
                name={name}
                onChange={onChangeHandler}
              />
              <div className="drag-text">

              </div>
              {data[name] ?
                <>
                  {data[name].name ?
                    <div onKeyDown={()=>{}} onClick={props.onFileSelect ? props.onFileSelect : () => { }} className="image-upload-chip-parent" tabIndex={0} role="button">
                      <div className="image-upload-chip">
                        <p>{data[name].name}</p>
                      </div>
                    </div>
                    :
                  
                      <div className="image-upload-chip-parent">
                        {(data[name]).map((element, i) => (
                          <div className="image-upload-chip">
                            <span role='button' className="text-ellipsis" tabIndex={0} onKeyDown={()=>{}}  onClick={() => { onFileSelect(i) }}>{element.name} </span><button className="transparent-btn" onClick={() => onFileRemove(i)}><CloseIcon /></button>
                          </div>
                        ))}
                      </div>
                    
                  }
                </> : null
              }
            </div>
          </div>
        );
      case ("new-file"):
        function fileUploadClick(e){
            let fileInput = document.getElementById("file-upload");
            fileInput.click();
        }
        return (
          <div class="file-upload-container" style={{width : "100%"}}>
            <div class="input-file-wraper" onClick={data[name] && data[name].name ? ()=>{} : fileUploadClick}>
              {data[name] && data[name].name ? 
                
                  <div className="d-flex space-between align-center width-full">
                    <div className="d-flex align-center" style={{gap: "10px"}}>
                      <span className="ri-file-2-line font-20 color-icon-secondary"></span>
                      <span className="font-14 color-icon-secondary font-weight-500">{data[name].name}</span>
                    </div>
                    <div>
                      <span className="ri-delete-bin-7-line font-20" style={{color: "#C11212"}} onClick={onFileRemove} ></span>
                    </div>
                  </div>
                
              
              :
                <div className="d-flex align-center" style={{gap: "10px"}}>
                  <span className="ri-upload-2-line btn-lite-blue font-20"></span>
                  <span className="font-14 font-weight-500 btn-lite-blue">Choose Kubeconfig File</span>
                </div>
              }

              
              <input
                class="main-file-input"
                id="file-upload"
                type="file"
                name={name}
                onChange={onChangeHandler}
              />
              <div class="drag-text">

              </div>
              {/* {data[name] ?
                <>
                  {data[name].name ?
                    <div onClick={props.onFileSelect ? props.onFileSelect : () => { }} className="image-upload-chip-parent">
                      <div className="image-upload-chip">
                        <p>{data[name].name}</p>
                      </div>
                    </div>
                    :
                    <>
                      <div className="image-upload-chip-parent">
                        {(data[name]).map((element, i) => (
                          <div className="image-upload-chip">
                            <p onClick={() => { onFileSelect(i) }}>{element.name} </p><button className="transparent-btn" onClick={() => onFileRemove(i)}><CloseIcon /></button>
                          </div>
                        ))}
                      </div>
                    </>
                  }
                </> : null
              } */}
            </div>
          </div>
        );
      case ("multi-file-upload"):
        return (
          <div className="multi-file-upload">
            <div className="image-upload-wrap">
              <input
                className="file-upload-input"
                type="file"
                name={name}
                onChange={onChangeHandler}
              />
              {data[name] ?
                <>
                  {data[name].name ?
                    <div onKeyDown={()=>{}} onClick={props.onFileSelect ? props.onFileSelect : () => { }} className="image-upload-chip-parent" tabIndex={0} role="button">
                      <div className="image-upload-chip">
                        <p>{data[name].name}</p>
                      </div>
                    </div>
                    :
                  
                      <div className="image-upload-chip-parent">
                        {(data[name]).map((element, i) => (
                          <div className="image-upload-chip">
                            <span role='button' className="text-ellipsis" tabIndex={0} onKeyDown={()=>{}}  onClick={() => { onFileSelect(i) }}>{element.name} </span><button className="transparent-btn" onClick={() => onFileRemove(i)}><CloseIcon /></button>
                          </div>
                        ))}
                      </div>
                    
                  }
                </> : null
              }

            </div>
          </div>
        );
      case ("number"):
        return (
          <input
            type="number"
            value={data[name]}
            name={name}
            placeholder={placeholder}
            min={min}
            max={max}
            disabled={onChangeHandler ? false : true}
            onChange={onChangeHandler}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
            maxLength={maxLength}
          />
        );
      case ("password"):
        return (
          <>
            <input
              type={state.showPassword ? "text" : "password"}
              className={!error[name] || error[name].length == 0 ? "" : "error"}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChangeHandler}
              disabled={onChangeHandler ? false : true}
            />
            <span className="field-icon" onKeyDown={()=>{}} onClick={togglePassword} tabIndex={0} role="button">{state.showPassword ?

              <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
              <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />

            }

            </span>
          </>
        );
      case ("hook-commands"):
        return (
          <div style={{ height: '60px', display: 'flex' }}>
            <input
              type={state.ishookCommandsVisible ? "text" : "password"}
              className={!error[name] || error[name].length == 0 ? "" : "error"}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChangeHandler}
              disabled={onChangeHandler ? false : true}
            />
            <span style={{ position: 'relative', left: '-45px', top: '19px' }} onKeyDown={()=>{}} onClick={() => { setState((prevState => ({ ...prevState, ishookCommandsVisible: !prevState.ishookCommandsVisible }))) }} tabIndex={0} role="button">

              {state.ishookCommandsVisible ?

                <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />

              }
            </span>
          </div>
        );
      case ("switch"):
        return (
          <>
            <div className={borderBottom ? 'card-header border-bottom' : 'card-header'} style={ varient == "child-build"  ?  
            {
              background: "transparent",
              display: "flex",
              flexDirection : "column",
              alignItems: "flex-start",
              gap: "10px",
              borderBottom: "none",
              padding: props.padding ? props.padding : "10px"

            } : 
            {
              background: "transparent",
              display: "flex",
              allignItems: "center",
              gap: "10px",
              borderBottom: "none",
              padding: props.padding ? props.padding : "10px"

            }}>
              <div className='heading font-family-v1 font-weight-500 font-14 color-value'>
                {label}<span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
              </div>

              <label className="switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox" />
                <span className="switch-label" data-on="Yes" data-off="No"></span>
                <span className="switch-handle"></span>
              </label>
            </div>
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }

          </>

        );

      case ("enabled-disabled-switch"):
        return (
          <>
            <div className={borderBottom ? 'card-header border-bottom' : 'card-header'} style={inputCase ? { width: "220px" } : {}}>
              <div className='heading'>
                {label}<span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
              </div>

              <label className="switch ed-switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox"/>
                <span className="switch-label width-fix" data-on="Enabled" data-off="Disabled"></span>
                <span className="switch-handle switch-handle-fix"></span>
              </label>
            </div>
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }

          </>

        );
      case ("only-switch"):
        console.log(name, data[name], "sdnbshdbchjsdbc")
        return (
          <>
            <>
              {/* <div className='heading'>
                {label}<span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
              </div> */}

              <label className="switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox" />
                <span className="switch-label" data-on="Yes" data-off="No"></span>
                <span className="switch-handle"></span>
              </label>
            </>
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }

          </>

        );
      case ("only-switch-without-label"):
        return (
          <>
            <>
              {/* <div className='heading'>
                  {label}<span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
                </div> */}

              <label className="switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox" />
                <span className="switch-label" data-on="Yes" data-off="No"></span>
                <span className="switch-handle"></span>
              </label>
            </>
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }

          </>

        );
      case ("true-false-switch"):
        return (
          <>
          
              <label className="switch true-false-switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox" />
                <span className="switch-label" data-on="True" data-off="False"></span>
                <span className="switch-handle"></span>
              </label>
            
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }

          </>

        );
      case ("active-inactive-switch"):
        // console.log('data:', data, name, data[name]);
        return (
          <>
          
              <label className="switch">
                <input className="switch-input" name={name} checked={data[name]} onChange={onChangeHandler} type="checkbox" />
                <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                <span className="switch-handle"></span>
              </label>
            
            {
              error[name] && error[name].length > 0 ?
                <div className="error-message">{error[name]}</div>
                : null
            }
            {
              enabledComponent ?
                <>
                  {data[name] ? enabledComponent :
                    disabledComponent ? disabledComponent : null}
                </> : null
            }
          </>
        );
      case ("slider"):
        function changeValue(type) {
          onChangeHandler({
            target: {
              type: "text",
              name: name,
              value: type == "increase" ? data[name] + 1 : data[name] - 1,
            }
          });
        }
        return (
          <div className="d-flex align-center space-between slider-controller" >
            {
              varient == "divison" ?
                <Slider
                  name={name}
                  style={{ width: '80%!important' }}
                  value={data[(name + "_divison")]}
                  onChange={onChangeHandler}
                  valueLabelDisplay={valueLabel == "on" ? "on" : "off"}
                  max={max}
                  min={min}
                />
                :
                <Slider
                  name={name}
                  style={{ width: '80%!important' }}
                  value={data[name]}
                  onChange={onChangeHandler}
                  valueLabelDisplay={valueLabel == "on" ? "on" : valueLabel == "auto" ? "auto" : "off"}
                  max={max}
                  min={min}
                />
            }
            <div className="input-with-btn">
              <div className="change-value-btn">
                <span onKeyDown={()=>{}} onClick={() => changeValue("increase")} className="flaticon-expand-arrow" tabIndex={0} role="button"></span>
                <span onKeyDown={()=>{}} onClick={changeValue} className="flaticon-expand-button" tabIndex={0} role="button"></span>
              </div>
              <input
                type="text"
                name={name}
                className="range-slider-input"
                placeholder={placeholder}
                value={data[name]}
                onChange={onChangeHandler}
                max={max}
                min={min}

              />
            </div>

          </div>
        );
      case ("checkbox"):
        return <CheckboxList listError={listError} name={name} onTextClick={onTextClick} list={list} selected_checkboxes={name == "BP_VERSIONING_APPROVAL_BP_KIND_LIST" || name == "SNOW_MANDATORY_FIELDS" ? getBpSelectedList(data[name]) : data[name]} varient={props.varient} label={props.label} onChangeHandler={onChangeHandler} />
      case ("checkbox-list"):
        return <ChipCheckboxList name={name} list={list} selected_checkboxes={data[name]} variant={props.variant} label={props.label} onChangeHandler={onChangeHandler} />
      case ("tagged-input"):
        return <TagInput name={name} placeholder={placeholder} tag_list={data[name]} onChangeHandler={onChangeHandler} />
      case "auto-complete-dropdown":
        return (<AutoCompleteDropdown
          style={props.style}
          label={label}
          height={props.height}
          name={name}
          id={props.id}
          default_css={default_css}
          getOptionLabel={props.getOptionLabel}
          value={data[name]}
          placeholder={placeholder}
          className={!error[name] || error[name].length == 0 ? "textarea" : "textarea error"}
          list={list}
          disabled={onChangeHandler ? false : true}
          onChangeHandler={onChangeHandler} />)
      case "multiple-selected-dropdown":
        return (<MultipleSelectDropdown
          label={label}
          name={name}
          value={data[name]}
          id={props.id}
          placeholder={placeholder}
          options={list}
          selectedOptions={data[name]}
          onChangeHandler={onChangeHandler}
        />)
      case "round-checkbox":
        function returnCheckboxValue(e) {
          onChangeHandler({
            target: {
              name: name,
              value: e.target.checked,
            }
          });
        }
        return (
          <div className='round-checkbox d-flex align-center justify-center'>

            <input type="checkbox" onChange={returnCheckboxValue} checked={data[name]} name={name} id={name} />
            <label htmlFor={name}> {props.label}</label>
          </div>

        );
      case "simple-checkbox":
        function returnValue(e) {
          onChangeHandler({
            target: {
              name: name,
              value: e.target.checked,
            }
          });
        }
        return (
          <Checkbox
            checked={data[name]}
            onChange={returnValue}
            // for="simple-checkbox"
            name={name} />
        );

      case ("dual-input"):
        return (<>
          {
            !isDuelInputDropdown ?
              <input
                className={!error[name] || error[name].length == 0 ? "" : "error"}
                name={name}
                placeholder={placeholder}
                value={value}
                onKeyDown={onKeyDown}
                onChange={onChangeHandler}
                disabled={onChangeHandler ? false : true}
                onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
                maxLength={maxLength}
              /> :

              <select name={name} value={data[name]} className={!error[name] || error[name].length == 0 ? "select" : "select error"} onChange={onChangeHandler} disabled={onChangeHandler ? false : true}>
                <option value="">Please Select</option>
                {list && list.map((option) => (
                  <option value={option.id} selected={option.selected == "selected"}>{option.label}</option>
                ))}
              </select>
          }

        </>

        );
      case ("date"):
        return (
          <input
            type="date"
            className={!error[name] || error[name].length == 0 ? "" : "error"}
            name={name}
            placeholder={placeholder}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChangeHandler}
            disabled={onChangeHandler ? false : true}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
            min={min}
            max={max}
          />
        );
      case ("time"):
        return (
          <input
            type="time"
            className={!error[name] || error[name].length == 0 ? "" : "error"}
            name={name}
            placeholder={placeholder}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChangeHandler}
            disabled={onChangeHandler ? false : true}
            onKeyPress={varient == "handle-keys" ? props.onKeyPress : () => { }}
          />
        );
      default:
        return null;
    }
  }

  function getBpSelectedList(data) {
    let selected_data = data;
    if (selected_data && typeof (selected_data) == "string") {
      let updatedData = JSON.parse(selected_data);
      return updatedData;
    }
    if (selected_data && typeof (selected_data) == "object") {
      return data;
    }
  }

  return (
    <>

      {type === 'dual-input' ?

        <>
          {
            label &&
          
              <div className="d-flex space-between align-center" >
                <span>
                  <label className={"input-label"}>
                    {label}
                    <span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
                  </label>
                  <span className={classes.hindText + " input-hint-label"}>{subHeading}</span>
                </span>
                <button className="btn btn-clear mr-0 btn-link  text-anchor-blue pr-0" onClick={toggleDualInput}>{isDuelInputDropdown ? "Provide Custom Value" : "Choose Value"}</button>
              </div>
            
          }
          {getFieldBasedOnType()}

        </> :
        type === "switch" ?
          <>
            {getFieldBasedOnType()}
          </>

          :
          type === "simple-checkbox" ?
            <div className="input-component d-flex align-center">
              {getFieldBasedOnType()}
              {
                label && type !== "switch" ?
                
                    <div className="d-flex space-between">
                      <span className="d-flex space-between align-center width-100">
                        <label className={classes.InputLabel}>
                          {label}
                          <span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
                        </label>
                        <span className={classes.hindText}>{subHeading}</span>
                      </span>
                      {props.extraDiv ? props.extraDiv : null}

                    </div>


                   : null
              }
              {/* {getFieldBasedOnType()} */}
              {
                error[name] && error[name].length > 0 ?
                  typeof (error[name]) == "string" ?
                    <div className="error-message">{error[name]}</div> :
                    <div className="error-message">
                      {
                        error[name].map((error2, key) => (
                          error2 + (key + 1 != error[name].length ? ", " : "")
                        ))
                      }
                    </div>
                  : null
              }
            </div>
            :
            <div className="input-component d-flex f-direction-column" style={{ gap: props.gap ? props.gap : "5px" }}>
              {
                label && type !== "switch" && inputCase != "cache_switch" ?
                  
                    <div className="d-flex space-between ">
                      {
                        type !== "round-checkbox" &&
                        <span className={"d-flex space-between align-center"} style={{ color: "#2F2F2F", fontWeight: "500", fontSize: "14px", lineHeight: "17.07px", gap: "5px" }}>
                          <label className={jiraOperation || info ? classes.InputLabel + " " + classes.AlignLabel + " " + " label-controller" : classes.InputLabel + " " + " label-controller"} >
                            {label}
                            <span style={mandatorySign ? { color: 'red' } : { display: 'none' }}>*</span>
                            {jiraOperation || info ?
                              <>
                              <Tooltip TransitionComponent={Zoom} title={info ? <Typography variant="body2" style={{ whiteSpace: 'pre-line',color:"white" }}>{info}</Typography> :jiraOperationInfo } style={{ marginLeft: "4px"}} placement="right">
                                <InfoOutlinedIcon />
                              </Tooltip>
                              </>
                              : ""}
                              {jira_status ? 
                              <Tooltip TransitionComponent={Zoom} title={"This is the list of valid Jira statuses"} style={{marginLeft: "4px"}} placement="right">
                                <InfoOutlinedIcon />
                              </Tooltip>
                            : ""}
                          </label>
                        </span>
                      }

                      {props.extraDiv ? props.extraDiv : null}

                    </div>


                   : null
              }
              {getFieldBasedOnType()}
              {
                paramsHeading &&
                <span className={"subheading-input-label" + " " + " sub-heading-controller"}>{paramsHeading}</span>
              }
              {
                error[name] && error[name].length > 0 ?
                  typeof (error[name]) == "string" ?
                    <Tooltip title={error[name]}>
                      <p className="text-ellipsis-300 error-message">{error[name]}</p>
                    </Tooltip>
                    :
                    <div className="error-message">
                      {
                        error[name].map((error2, key) => (
                          error2 + (key + 1 != error[name].length ? ", " : "")
                        ))
                      }
                    </div>
                  : null
              }
            </div>

      }
    </>
  );
}

Input.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}