import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { createStyles, withStyles } from '@material-ui/core/styles';
import GenerateURL from '../../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import properties from '../../../../../properties/properties';

import AlertStrip from '../../../../../components/AlertStrips';
import { Loading } from '../../../../utils/Loading';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
const CanaryDeploymentView = (props) => {
    const application_id = props.application_id;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const deploy_id = props.deploy_id;
    const refresh_count = props.refresh_count;
    const [state, setState] = useState({
        progress_value: 50,
        count: 0,
        current_template_id: "",
        loading: false,
        show_abort_msg: ""
    })
    useEffect(() => {
        fetchCanaryProcessingData();
    }, [env_id, deploy_id, refresh_count, state.count])
    const refreshFn = () => {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1,
        }));
    }
    function fetchCanaryProcessingData() {
        var requestInfo = {
            endPoint: GenerateURL({ component_id: service_id, component_env_id: env_id, deployment_id: deploy_id }, properties.api.canary_progress),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, onfetchCanaryProcessingDataSuccess, onfetchCanaryProcessingDataFail);
        setState(new_state => ({
            ...new_state,
            loading: true
        }));
    }

    function onfetchCanaryProcessingDataSuccess(response) {
        var current_template_id = getCurrentId(response.canary_phase_deploy_history);
        if (current_template_id) {
            fetchCanaryTemplatesInfo(current_template_id);
        }

        setState(new_state => ({
            ...new_state,
            last_deploy_data: response,
            current_template_id: current_template_id,
            loading: false
        }));

    }
    function onfetchCanaryProcessingDataFail(response) {
        setState(new_state => ({
            ...new_state,
            error: response,
            loading: false
        }));
    }

    function getStatus(data) {
        switch (data) {
            case ('SUCCESS'):
                return ('bg-success');
            case ('FAILED'):
                return ('bg-failed');
            case ('RUNNING'):
                return ('bg-processing');
            case ('ABORT'):
                return ('bg-failed');
            case ('IN_QUEUE'):
                return ('bg-initiated');
            default:
                return ('bg-pending')
        }
    }
    function getCapitalizeText(data) {
        switch (data) {
            case ('SUCCESS'):
                return ('Success');
            case ('FAILED'):
                return ('Failed');
            case ('RUNNING'):
                return ('Running');
            case ('ABORT'):
                return ('Aborted');
            case ('IN_QUEUE'):
                return ('Inqueue');
            default:
                return ('')
        }
    }
    //  color-pending
    function getClassName(data) {
        switch (data) {
            case ('SUCCESS'):
                return ('color-success');
            case ('FAILED'):
                return ('color-danger');
            case ('RUNNING'):
                return ('color-pending');
            default:
                return ('text-grey-83')

        }

    }
    function fetchCanaryTemplatesInfo(id) {
        var requestInfo = {
            endPoint: GenerateURL({ application_id: application_id, template_id: id }, properties.api.edit_canary_template_listing),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, canaryTemplatesInfoFetchSuccess, canaryTemplatesInfoFetchFailure);
        setState(new_state => ({
            ...new_state,
            loading: true,
        }));
    }
    function canaryTemplatesInfoFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            loading: false,
            current_template_data: data
        }));
    }
    function canaryTemplatesInfoFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            loading: false,
            error_msg: error,
        }));
    }
    function getLastTrafficShiftData(data) {
        if (data.canary_phase_deploy_history) {
            if (data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].phase_release_template) {
                if (data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].phase_release_template.traffic_shift_to_new_deployment_in_pct) {
                    var last_traffic_percentage = data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].phase_release_template.traffic_shift_to_new_deployment_in_pct;
                    return last_traffic_percentage;
                }
            }
        }
    }
    function showTriggerButtonFlag(data) {
        var show_trigger_button = false
        if (data.canary_phase_deploy_history) {
            if (data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].status) {
                if (data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].status == "SUCCESS") {
                    show_trigger_button = false
                }
                if (data.canary_phase_deploy_history[data.canary_phase_deploy_history.length - 1].status == "FAILED") {
                    show_trigger_button = false
                }
            }
        }
        return show_trigger_button;
    }
    function checkIfBothArrayAreEqual(data, dataProcessing) {
        var reached_to_full_lenght = ""
        if (data.phase_release_template.length == dataProcessing.canary_phase_deploy_history.length) {
            return reached_to_full_lenght = true;
        }
    }
    function ConvertDataAccordingToUI() {
        var template_data = state.current_template_data;
        var template_data_processing = state.last_deploy_data;
        console.log("template_data_processing", template_data_processing, template_data)
        var final_obj = {};
        if (template_data && template_data_processing) {
            final_obj.status = template_data_processing.activity_status.status;
            final_obj.last_template_traffic_shift =
                final_obj.deploy_number = '#' + template_data_processing.deploy_number;
            final_obj.template_name = template_data.name;
            final_obj.total_traffic_shifted = getLastTrafficShiftData(template_data_processing);
            final_obj.get_total_phase_arr = phaseArrWithStatus(template_data, template_data_processing);
            final_obj.message = template_data_processing.description;
            final_obj.last_node_check = showTriggerButtonFlag(template_data_processing);
            final_obj.reached_to_full_length = checkIfBothArrayAreEqual(template_data, template_data_processing);
            final_obj.final_status = template_data_processing.status
        } else {
            state.loading = true;
        }
        return final_obj;
    }
    function phaseArrWithStatus(data, processing_data) {
        var final_arr = []
        if (data && processing_data) {
            if (data.phase_release_template && data.phase_release_template.length > 0) {
                data.phase_release_template.forEach(element => {
                    final_arr.push(element)
                });
            }
            if (processing_data.canary_phase_deploy_history && processing_data.canary_phase_deploy_history.length > 0) {
                processing_data.canary_phase_deploy_history.forEach((item, key) => {
                    final_arr[key]["status"] = final_arr[key].name == item.phase_release_template.name ? item.status : "WAITING"
                })
            }
        }
        return final_arr;
    }

    var final_data_object = ConvertDataAccordingToUI();


    function TriggerNextPhase() {
        var post_url = GenerateURL({ component_id: service_id, component_env_id: env_id, deployment_id: deploy_id }, properties.api.trigger_next_phase);
        PostData(post_url, {}, TriggerNextPhaseSuccess, TriggerNextPhaseFail);
        setState(new_state => ({
            ...new_state,
            loading: true,
        }));
    }

    function TriggerNextPhaseSuccess(data) {
        refreshFn()
        setState(new_state => ({
            ...new_state,
            loading: false,
            success_msg: "Next Phase Triggered Successfully"
        }));
    }
    function TriggerNextPhaseFail(data) {
        setState(new_state => ({
            ...new_state,
            loading: false,
            failed_msg: "Next Phase Trigger Failed"

        }));
    }
    function AbortPhase() {
        var post_url = GenerateURL({ component_id: service_id, component_env_id: env_id, deployment_id: deploy_id }, properties.api.abort_phases);
        PostData(post_url, {}, AbortPhaseSuccess, AbortPhaseFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
        }));
    }
    function AbortPhaseSuccess(data) {
        refreshFn()
        setState(new_state => ({
            ...new_state,
            loading: false,
            show_abort_msg: "success",

        }));
    }
    function AbortPhaseFailed(data) {
        setState(new_state => ({
            ...new_state,
            loading: false,
            show_abort_msg: "failed",
            error_msg: data
        }));
    }
    function handleClose() {
        setState({
            ...state,
            show_abort_msg: null
        });
    }
    function handleCloseTriggerPhase() {
        setState({
            ...state,
            failed_msg: null,
            success_msg: null
        });
    }
    return (
        <>{
            state.loading ?
                <div className="d-flex align-center justify-center" style={{ height: 500 }}>
                    <Loading varient="light" />
                </div>
                :
                <div className="bg-white ">
                    <div className="font-14 pd-10 border-bottom font-family-nunito text-center">
                        <span className={getClassName(final_data_object.status)}>{final_data_object.status}... </span>
                        <span className="">Canary deployment: </span>
                        <span className="text-grey-af">{final_data_object.deploy_number} </span>
                        <span>Template: </span>
                        <span className="text-grey-af">{final_data_object.template_name} </span>
                    </div>
                    {
                        final_data_object.status == "FAILED" ?
                            <div className="font-14 pd-10 border-bottom font-family-nunito text-center">
                                <AlertStrip message={final_data_object.message} variant={"error"} />
                            </div>
                            : null
                    }

                    <div className="">
                        <div className=" align-center header__section">
                            <div className="progress-bar-section">
                                <p className="slider-with-value-heading">Traffic Shifted to new deployment <span>(%)</span></p>
                                <div className="slider-with-value">
                                    <span className="slider-value">{final_data_object.total_traffic_shifted}%</span><BorderLinearProgress variant="determinate" value={final_data_object.total_traffic_shifted} />
                                </div>
                            </div>
                            <div className="button-section">
                                {
                                    final_data_object.final_status == "FAILED" || final_data_object.final_status == "ABORT" ?
                                        null :
                                        final_data_object.reached_to_full_length ?
                                            <></> :
                                            <button className="btn btn-danger" onClick={() => { AbortPhase() }} style={{ backgroundColor: '#fa5c5c' }}>ABORT</button>

                                }
                                {
                                    final_data_object.final_status == "FAILED" || final_data_object.final_status == "ABORT" ?
                                        null :
                                        final_data_object.final_status == "IN_QUEUE" || final_data_object.final_status == "RUNNING" ?
                                            null :
                                            final_data_object.reached_to_full_length ?
                                                <></> :
                                                <button className="btn btn-primary" onClick={() => { TriggerNextPhase() }} style={{ backgroundColor: '#0984e3' }}>TRIGGER NEXT PHASE</button>

                                }
                            </div>
                        </div>
                        <div className="canary_template_row sm-card-sec">
                            {
                                final_data_object.get_total_phase_arr.map(item => (
                                    <span className="row_canary">
                                        <div className="bar-grid sm-card">
                                            <div className="sm-card-header" style={{ backgroundColor: '#e3e6e8' }}>
                                                <p className="font-12">{item.name}</p>
                                            </div>
                                            <div className="sm-card-body">
                                                <p className="" style={{ fontSize: 40, color: '#adaeae' }}>{item.traffic_shift_to_new_deployment_in_pct}%</p>
                                            </div>
                                            <div className={getStatus(item.status) + " " + "sm-card-footer"}>
                                                <p style={!item.status ? { color: '#3668aa' } : {}}>{item.status ? getCapitalizeText(item.status) : "Waiting"}</p>
                                            </div>
                                        </div>
                                        <div className="arrow_icon">
                                            <ArrowForwardIcon style={{ color: "#fea010", fontSize: 24 }} />
                                        </div>
                                    </span>
                                ))
                            }
                        </div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={state.show_abort_msg == "success" || state.show_abort_msg == "failed"}
                            onClose={handleClose}
                            autoHideDuration={3000}>
                            {
                                state.show_abort_msg == "success" ?
                                    
                                        <Alert severity="success">
                                            {"Aborting Deployment"}
                                        </Alert>
                                     : state.show_abort_msg == "failed" ?
                                        <Alert severity="error">
                                            {"Deployment Aborting Operation Failed"}
                                            <div className="error-div">
                                                {state.error_msg}
                                            </div>
                                        </Alert>

                                        : null
                            }
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={state.success_msg || state.failed_msg}
                            onClose={handleCloseTriggerPhase}
                            autoHideDuration={3000}>
                            {
                                state.success_msg ?
                                    
                                        <Alert severity="success">
                                            {state.success_msg}
                                        </Alert>
                                     : state.failed_msg == "failed" ?
                                        <Alert severity="error">
                                            {state.failed_msg}
                                            <div className="error-div">
                                                {state.error_msg}
                                            </div>
                                        </Alert>

                                        : null
                            }
                        </Snackbar>
                    </div>
                </div>

        }
        </>
    )
}

CanaryDeploymentView.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default CanaryDeploymentView;

function getCurrentId(data) {
    var current_template_id = ""
    data.forEach(item => {
        if (item.phase_release_template) {
            if (item.phase_release_template.release_template) {
                current_template_id = item.phase_release_template.release_template
            }
        }
    });
    return current_template_id
}

function getDataForRespectiveTemplate(current_template_id, canary_template_list) {
    var data_for_current_template = {};

    if (canary_template_list && current_template_id) {

        canary_template_list.forEach(element => {

            if (element.id == current_template_id) {
                data_for_current_template = element;
            }
        });
    }
    return data_for_current_template;
}
const BorderLinearProgress = withStyles(() =>
    createStyles({
        root: {
            height: 20,
            borderRadius: 10,
        },
        colorPrimary: {
            backgroundColor: '#e3e6e8',
        },
        bar: {
            borderRadius: 10,
            backgroundColor: '#0984e3',
        },
    }),
)(LinearProgress);