import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import properties from '../../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../../util/apiInvoker';
import { Loading } from '../../../../utils/Loading';
import { Tooltip, IconButton, Grid } from '@material-ui/core';
import moment from 'moment';
import { CopyToClipboard } from '../../../../../util/util';
import { getDuration } from '../../../../../util/util';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchBar from '../../../../../components/SearchBar';
import { VALIDATION_TYPE_REQUIRED, GenericValidator } from '../../../../../util/Validator';
import Pagination from '../../../../../components/Pagination';
import { Link } from 'react-router-dom';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import StorageIcon from "@material-ui/icons/Storage";
import { useParams, useLocation } from "react-router-dom";
import { ParameterComponent } from './DeployHistory';
const PromoteHistory = props => {
    const classes = useStyles();
    const service_id = props.service_id;
    const env_id = props.env_id;
    const refresh_count = props.refresh_count;
    const service_data = props.service_data;
    const workspace_base_url = properties.workspace_url;
    const selected_tab_id = props.selected_tab_id;
    const options_tabs = props.options_tabs;
    var service_env_type = service_data.type;
    service_env_type = service_env_type ? service_env_type.toLowerCase() : null
    var service_env = service_data.service_env;
    service_env = service_env ? service_env.toLowerCase() : null
    var service_name = service_data.name;
    const [state, setState] = useState({
        show_loading_icon: true,
        infiniteScrollInherits: {},
        curr_page: '',
        total_page: '',
        refresh_count: 1,
    });

    const params = useParams();

    useEffect(() => {
        fetchHistory();
    }, [service_id, env_id, refresh_count,state.refresh_count]);

    function fetchHistory(data, url) {
        if (!url) {
            url = GenerateURL({ service_id: service_id, env_id: env_id , deployment_name : service_data && service_data.deployment_name }, properties.api.env_promote_request_url);
        }
        var requestInfo = {
            endPoint: url,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }
        InvokeApi(requestInfo, historyFetchSuccess, historyFetchFailure);
        setState(new_state => ({
            ...new_state,
            search_data: data,
            current: requestInfo.endPoint,
            show_loading_icon: true,
            curr_page: 1
        }));
    }

    function historyFetchSuccess(data) {
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Number.isInteger(Number(data.count) / 10) ? (Number(data.count) / 10).toFixed(0) : (Number(data.count) / 10 + 1).toFixed(0) > (Number(data.count) / 10 + 1) ? (Number(data.count) / 10 + 1).toFixed(0) - 1 : (Number(data.count) / 10 + 1).toFixed(0),
            curr_page: new_state.curr_page === '' ? 1 : new_state.curr_page
        }));
    }

    function historyFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: []
        }));
    }
    function getPromotedByUser(history) {
        return (
            <p>{history.promote_by_pipeline ?
                <>
                    <p>Pipeline: {history.promote_by_pipeline.pipeline.name}</p>
                    <p>Triggered by: {history.promote_by_pipeline.trigger_by}</p> </>
                : <p style={{padding: "0px"}}>{history.promote_by_user}</p>
            }</p>
        )

    }
    function validateAndReTriggerPromote(data) {
        console.log('propmoet')
        var promote_env_error = GenericValidator(data.promote_env, [VALIDATION_TYPE_REQUIRED]);
        var promote_tag_errors = GenericValidator(data.promote_tag, [VALIDATION_TYPE_REQUIRED]);
        if (promote_tag_errors) {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    promote_env: promote_env_error,
                    promote_tag: promote_tag_errors,
                }
            });
        } else {
            var data = {
                component_env_id: data.promote_env,
                current_image: { tag: data.promote_tag },
                target_image: { tag: data.promote_target_tag }
            }
            var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_promote_request_url);
            PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
            setState(new_state => ({
                ...new_state,
                show_loading_icon: true
            }));
        }
    }
    function successBuildTriggerCallBack(response) {
        setState({
            ...state,
            show_loading_icon: false
        });
        fetchHistory();
    }

    function failedBuildTriggerCallBack(error) {
        setState({
            ...state,
            trigger: "failed",
            error_msg: error,
            show_loading_icon: false
        });
    }
    function fetchNextHistory(data, tab_order, url) {
        if (url.split('/').pop() == 'null') {
            console.log("")
        } else {
            if (!url) {
                url = GenerateURL({ service_id: props.service_id, env_id: props.env_id }, properties.api.env_promote_request_url);
            }
            var requestInfo = {
                endPoint: url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            if (data) {
                requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
            }
            InvokeApi(requestInfo, NexthistoryFetchSuccess, NexthistoryFetchFailure);
            console.log(state)
            setState(new_state => ({
                ...new_state,
                search_data: data,
                current: requestInfo.endPoint,
                showLoadingIcon: true
            }));
        }
    }

    function NexthistoryFetchSuccess(data) {
        console.log(data, state)
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            history_info_status: "SUCCESS",
            showLoadingIcon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,

            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: new_state.curr_page + 1
        }));
    }

    function NexthistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            showLoadingIcon: false,
            error: error,
            history_list: []
        }));
    }
    function fetchPrevHistory(data, tab_order, url) {
        if (url.split('/').pop() == 'null') {
            console.log("")
        } else {
            if (!url) {
                url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_promote_request_url);
            }
            var requestInfo = {
                endPoint: url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            if (data) {
                requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
            }
            InvokeApi(requestInfo, PrevhistoryFetchSuccess, PrevhistoryFetchFailure);
            console.log(state)
            setState(new_state => ({
                ...new_state,
                search_data: data,
                current: requestInfo.endPoint,
                showLoadingIcon: true
            }));
        }
    }

    function PrevhistoryFetchSuccess(data) {
        console.log(data, state)
        setState(new_state => ({
            ...new_state,
            history_list: data.results,
            history_info_status: "SUCCESS",
            showLoadingIcon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,

            total_page: Math.ceil(Number(data.count / 10)),
            curr_page: new_state.curr_page - 1
        }));
    }

    function PrevhistoryFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            history_info_status: "SUCCESS",
            showLoadingIcon: false,
            error: error,
            history_list: []
        }));
    }

    // Promote info

    function fetchPromoteInfo(enteredPageNumber) {

        console.log("enterrrr", enteredPageNumber);


        //   url = GenerateURL(
        //     { service_id: service_id, env_id: env_id },
        //     properties.api.env_deploy_request_url
        //   );


        var requestInfo = {
            endPoint: GenerateURL(
                { service_id: service_id, env_id: env_id },
                properties.api.env_promote_request_url,
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };


        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;

        InvokeApi(
            requestInfo,
            (response) => {
                handlePromoteSuccessApiHit(response, current_page);
            },
            handlePromoteDataFailedApiHit
        );
    }

    function handlePromoteSuccessApiHit(data, count) {
        console.log("enterrrr", data);
        setState((new_state) => ({
            ...new_state,
            history_list: data.results,
            show_loading_icon: false,
            count: data.count,
            next: data.next ? properties.api.baseURL + data.next : null,
            previous: data.previous ? properties.api.baseURL + data.previous : null,
            total_page: Math.ceil(Number(data.count / 10)),

            curr_page: Number(count),
        }));
    }

    function handlePromoteDataFailedApiHit(error, exception) {
        setState((new_state) => ({
            ...new_state,
            history_info_status: "SUCCESS",
            show_loading_icon: false,
            error: error,
            history_list: [],
        }));
    }



    function onLog(logTabId, task_id, tabName) {
        var new_tab_data = getNewLogTab(logTabId, task_id, tabName, false, [])
        setState(new_state => ({
            ...new_state,
            selected_tab_id: logTabId,
            view_logs: true,
            logMetadata: {
                ...state.logMetadata,
                ...new_tab_data,
            }
        }));
    }

    function changeTab(newTabId) {
        setState(new_state => ({
            ...new_state,
            selected_tab_id: newTabId
        }))
    }
    function closeLogs() {
        setState(new_state => ({
            ...new_state,
            view_logs: false,
            logMetadata: {}
        }))
    }

    function closeTab(tabId) {
        var response = state.infiniteScrollInherits.closeTabFunction(tabId, state.logMetadata, closeLogs)
        if (response) {
            setState(new_state => ({
                ...new_state,
                logMetadata: response.new_tabs_data,
                selected_tab_id: response.selected_tab_id
            }))
        }
    }
    function onRevoke(task_id) {
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: task_id }, properties.api.env_promote_revoke_url);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);

    }


    function handleSuccessRevoke(respone) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false
        }));
        refreshComponent();
    }

    function handleFailedRevoke(error) {
        setState(new_state => ({
            ...new_state,
            error: true,
            show_loading_icon: false
        }));
    }
    const refreshComponent = props => {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));
    }
    function onResume(id) {
        var url_temp = GenerateURL({ service_id: service_id, env_id: env_id, id: id }, properties.api.deploy_resume);
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);

    }


    function handleSuccessResume(respone) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false
        }));
        refreshComponent();
    }

    function handleFailedResume(error) {
        setState(new_state => ({
            ...new_state,
            error: true,
            show_loading_icon: false
        }));
    }
    function callResumeFunctionbyStatus(activity_status, id) {
        if ((activity_status != "INITIATED" || activity_status != "RUNNING") || activity_status != "SUCCESS")
            onResume(id)
    }

    function getClassbyActivityStatus(activity_status, type) {
        if (type == "Revoke") {
            if (activity_status && activity_status == "INITIATED" || activity_status && activity_status == "RUNNING")
                return "icon-btn-v1 icon-btn-outline-danger";
            else
                return "btn-sq-icon-primary-disabled d-flex align-center";
        } else {
            if (type === "Resume") {
                if ((activity_status && activity_status == "INITIATED" || activity_status && activity_status == "RUNNING") || activity_status && activity_status == "SUCCESS")
                    return "btn-sq-icon-primary-disabled d-flex align-center";
                else
                    return "btn-sq-icon-primary d-flex align-center";
            } else {
                if (activity_status && activity_status == "INITIATED" || activity_status && activity_status == "RUNNING")
                    return "btn-sq-icon-primary-disabled d-flex align-center";
                else
                    return "btn-sq-icon-primary d-flex align-center";
            }
        }
    }

    function callFunctionbyStatus(activity_status, id) {
        if (activity_status && activity_status == "INITIATED" || activity_status && activity_status == "RUNNING")
            onRevoke(id)
    }
    function removeExtraString(data) {
        var final_sting = data.worker_name ? data.worker_name.split('@')[1] : null
        final_sting = final_sting ? final_sting.toLowerCase() : null
        return final_sting;
    }
    function getEnvNamefromId() {
        var env_name = "";
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_name = item.project_env.name
                }
            })
        }
        return env_name;
    }
    var service_env_name = service_env ? service_env : getEnvNamefromId()

    return (
        <>
            <Grid container className={classes.containerBox + " " + "align-center"}>
                            <Grid item xs={5} className={classes.searchPosition}>
                                <SearchBar
                                    search_data={state.search_data}
                                    default_filter={{ name: "target_image_tag", label: "Artifact" }}
                                    search_call_back={fetchHistory}
                                    clear_search_callback={fetchHistory}
                                />
                            </Grid>
                        </Grid>
            {options_tabs}
            {
                state.show_loading_icon ?
                    <div className={classes.historyCard} style={{height: "300px"}}> <Loading varient="light" /></div> :
                    <>
                        <div className="font-12 font-weight-600 color-key-78 mt-12 mb-12">Promote History</div>
                        <div className={classes.historyCard}>
                            <div style={{borderRadius: "6px", border:"1px solid #E6E6E6"}}>
                                <div className="header-tab-build">
                                    <p>Type</p>
                                    <p>Status</p>
                                    {/* <p>Duration</p> */}
                                    <p>Date/Duration</p>
                                    {/* <p>Artifact</p>
                                    <p>Commit Id</p>
                                    <p>Commit msg</p> */}
                                    <p>Promoted to/Promoted by</p>
                                    <p>Promoted from</p>
                                    {/* <p>Promoted to</p> */}
                                    <p></p>
                                </div>
                                <div className="history-body">
                                    {state.history_list.length > 0 ?
                                        <>
                                            {
                                                state.history_list.map((history, key) => (

                                                    <div key={key} className="body-tab-build align-center">
                                                        <Link
                                                            to={"/logs?global_task_id=" + history.global_task_id +
                                                                "&tab_id=" + history.id +
                                                                "&service_name=" + service_name +
                                                                "&service_env=" + service_env +
                                                                "&service_type=" + service_env_type +
                                                                "&tab_name=PROMOTE" + "&num=" + history.promote_number
                                                            }
                                                            target="_blank"
                                                            className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                                                        // onClick={() => {onLog(history.id,history.global_task_id,"Promote #"+history.promote_number)}}
                                                        >Promote #{history.promote_number}</Link>
                                                        <Tooltip title={history.activity_status && history.activity_status.status ? history.activity_status.status : 'Not found'}>
                                                            {
                                                                !history.activity_status ?
                                                                    <p style={{ fontSize: '12px' }}>Not found</p> :
                                                                    <p className={ history.activity_status &&
                                                                        history.activity_status.status == "FAILED"
                                                                        ? "new-status-chip new-failed"
                                                                        : history.activity_status &&
                                                                            history.activity_status == "RUNNING"
                                                                          ? "new-status-chip new-running"
                                                                          : history.activity_status &&
                                                                            history.activity_status.status == "REVOKED"
                                                                            ? "new-status-chip new-revoked"
                                                                            : history.activity_status &&
                                                                              history.activity_status.status ==
                                                                              "IN_QUEUE"
                                                                              ? "new-status-chip new-inqueue"
                                                                              : "new-status-chip new-success"} style={{width: "fit-content"}}>{history.activity_status && history.activity_status.status ? history.activity_status.status : "N/A"}</p>

                                                            }
                                                        </Tooltip>
                                                        {/* {
                                                            !history.activity_status ?

                                                                <p style={{ fontSize: '12px' }}>Not found</p> :
                                                                <p>

                                                                    {history.activity_status && history.activity_status.status != "RUNNING" ? getDuration(new Date(history.activity_status && history.activity_status.end_time), new Date(history.activity_status && history.activity_status.start_time)) == 0 ? "--" : getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) :
                                                                        getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date())}
                                                                </p>
                                                        } */}

                                                        {
                                                            !history.activity_status ?

                                                                <p style={{ fontSize: '12px' }}>Not found</p> :
                                                                <div>
                                                                    <p>
                                                                    { moment().diff(moment(history.activity_status &&
                                                                                history.activity_status.created_at
                                                                                ? history.activity_status.created_at
                                                                                : ""), 'hours') > 8 ? 
                                                                                moment(
                                                                                    history.activity_status &&
                                                                                    history.activity_status.created_at
                                                                                    ? history.activity_status.created_at
                                                                                    : ""
                                                                                ).format('DD-MMM-YYYY')
                                                                                :  
                                                                                moment(
                                                                                    history.activity_status &&
                                                                                    history.activity_status.created_at
                                                                                    ? history.activity_status.created_at
                                                                                    : ""
                                                                                ).fromNow()
                                                                    }
                                                                    </p>
                                                                    <p className="color-key-78">
                                                                        {history.activity_status && history.activity_status.status != "RUNNING" ? getDuration(new Date(history.activity_status && history.activity_status.end_time), new Date(history.activity_status && history.activity_status.start_time)) == 0 ? "--" : getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date(history.activity_status && history.activity_status.end_time)) :
                                                                            getDuration(new Date(history.activity_status && history.activity_status.start_time), new Date())}
                                                                    </p>
                                                                </div>

                                                        }
                                                        {/* <div className="d-flex">
                                                            <p>{history.target_image_tag}</p>
                                                            {history.target_image_tag ? (
                                                                <CopyToClipboard data={history.target_image_tag} />
                                                            ) : null}
                                                        </div>
                                                        <Tooltip title={history.commit_sha ? history.commit_sha : "N/A"}><p >{history.commit_sha ? history.commit_sha : "--"}</p></Tooltip>
                                                        <Tooltip title={history.commit_message ? history.commit_message : "N/A"}><p >{history.commit_message ? history.commit_message : "--"}</p></Tooltip> */}
                                                        <div>
                                                            <Tooltip title={history.target_env_cd_detail.deployment_name ? history.target_env_cd_detail.deployment_name : null}><p>{history.target_env_cd_detail.deployment_name ? history.target_env_cd_detail.deployment_name : null}</p></Tooltip>
                                                            <p>{getPromotedByUser(history)}</p>
                                                        </div>
                                                        
                                                        <Tooltip title={history.env_cd_detail.deployment_name ? history.env_cd_detail.deployment_name : null}><p >{history.env_cd_detail.deployment_name ? history.env_cd_detail.deployment_name : null}</p></Tooltip>
                                                        {/* <Tooltip title={history.target_env_cd_detail.deployment_name ? history.target_env_cd_detail.deployment_name : null}><p>{history.target_env_cd_detail.deployment_name ? history.target_env_cd_detail.deployment_name : null}</p></Tooltip> */}

                                                        <div className="d-flex align-center justify-end">
                                                            <div><ParameterComponent onlyPromoteInfo={true} promoteInfo={history} /></div>
                                                            <div className="btn-group width-fitcontent ml-15" style={{gap: "4px"}}>
                                                                
                                                                    <Tooltip title="Resume Promote">
                                                                        <button
                                                                            className={history.activity_status ? getClassbyActivityStatus(history.activity_status.status, "Resume") : "btn-sq-icon-primary-disabled d-flex align-center"}
                                                                            onClick={history.activity_status ? () => callResumeFunctionbyStatus(history.activity_status.status, history.id) : null}
                                                                        >
                                                                            {/* <SkipNextIcon style={{ fontSize: 24, color: '#0086ff' }} /> */}
                                                                            <span className="ri-play-circle-line font-20"></span>
                                                                        </button>
                                                                    </Tooltip>
                                                                
                                                                
                                                                    <Tooltip title={history.promote_by_pipeline ? "This task has been triggered by a pipeline, please revoke the pipeline in order to revoke the task" : "Revoke Promote"}>
                                                                        <button
                                                                            className={(!history.promote_by_pipeline) ? getClassbyActivityStatus(history?.activity_status?.status, "Revoke") : "btn btn-disabeld-flaticon"}
                                                                            onClick={(!history.promote_by_pipeline) ? () => callFunctionbyStatus(history?.activity_status?.status, history.id) : null}
                                                                        >
                                                                            {/* <span class="flaticon-stop-button"></span> */}
                                                                            <span className="ri-stop-circle-line font-20"></span>
                                                                        </button>
                                                                    </Tooltip>

                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </> : <div className="blank-div">
                                            <div className="blank-div-text">No Data Found</div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: "12px"}}>
                                <Pagination
                                    current_count={state.history_list ? `Page ${state.curr_page}` : 0}
                                    total_count={state.total_page}
                                    next={state.next}
                                    previous={state.previous}
                                    on_previous_click={() => { fetchPrevHistory(null, 0, state.previous) }}
                                    on_next_click={() => { fetchNextHistory(null, 0, state.next) }}
                                />
                        </div>
                    </>}
           
        </>
    )
}

PromoteHistory.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default PromoteHistory;

const useStyles = makeStyles((theme) => ({
    containerBox: {
        //padding: '16px 0px',
        paddingTop: "0px",
        '& button.btn': {
            '&:hover': {
                color: '#0096db!important'
            }
        },
        '& .search-bar': {
            border: '1px solid #ebedee',
            overflow: 'hidden',
            borderRadius: '8px',
            '& .input-component input:focus': {
                border: 'none!important'
            }
        },
    },
    searchPosition: {
        position: "absolute",
        top: "16.8px",
        right: "8%",
        "@media screen and (min-width: 1440px) and (max-width: 1920px)":{
          right: "6%",
        }
    },
    historyCard: {
        //height: 550,
        '& .header-tab-build': {
            backgroundColor: '#FAFAFA',
            borderBottom: '1px solid #ebedee',
            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: 12,
            borderTop: '1px solid #ebedee',
            gridTemplateColumns: "10% 10% 16% 16% 16% 28%",//'8% 8% 8% 8% 9% 12% 11% 11% 11% 1fr',
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            color: "#787878",
        },
        '& .body-tab-build': {
            borderBottom: '1px solid #ebedee',
            gap: 8,
            fontSize: 12,
            display: 'grid',
            padding: '12px 8px',
            gridTemplateColumns: '10% 10% 16% 16% 16% 28%',//'8% 8% 6% 7% 7% 8% 12% 10% 10% 10% 1fr',
            '& p': {
                //padding: '0px 3px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            },
            '& .btn-disabeld-flaticon': {
                '& .MuiSvgIcon-root': {
                    color: '#b9b8b8 !important'
                }
            },
            '& .btn-mui-svg': {
                padding: '0px 0.5rem!important'
            },
        },
        '& .history-body': {
            // overflowY: 'scroll',
            // height: 515
            '& > div': {
                backgroundColor: '#FFFFFF',
            },
        }
    }
}));


const useStyles1 = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
const MenuListComposition = props => {
    const classes = useStyles1();
    const [open, setOpen] = useState(false);
    const status = props.status;
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={props.onRevoke}>Revoke</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

MenuListComposition.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }